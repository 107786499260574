export const PERMISSIONS = {
  view_products: {
    slug: 'view_products',
    permissionType: 'Product',
  },
  create_products: {
    slug: 'create_products',
    permissionType: 'Product',
  },
  update_products: {
    slug: 'update_products',
    permissionType: 'Product',
  },
  download_product_images: {
    slug: 'download_product_images',
    permissionType: 'Product',
  },
  delete_products: {
    slug: 'delete_products',
    permissionType: 'Product',
  },
  create_catalogues: {
    slug: 'create_edit_catalogues',
    permissionType: 'Catalogue',
  },
  update_catalogues: {
    slug: 'create_edit_catalogues',
    permissionType: 'Catalogue',
  },
  update_catalogues_link_security: {
    slug: 'update_catalogues_link_security',
    permissionType: 'Catalogue',
  },
  delete_catalogues: {
    slug: 'delete_catalogues',
    permissionType: 'Catalogue',
  },
  view_catalogues: {
    slug: 'view_catalogue_history',
    permissionType: 'Catalogue',
  },
  global_permissions: {
    slug: 'global_permissions',
    permissionType: 'Global',
  },
  view_buyer_details: {
    slug: 'view_buyer_details',
    permissionType: 'Catalogue',
  },
  download_ppt_excel: {
    slug: 'access_ppt_excel_link',
    permissionType: 'Catalogue',
  },
  bulk_upload: {
    slug: 'bulk_upload',
    permissionType: 'Bulk Upload',
  },
  /**Adding costing and resources related permissions */
  view_costing: {
    slug: 'view_costing',
    permissionType: 'Costing',
  },
  view_packaging: {
    slug: 'view_packaging',
    permissionType: 'Costing',
  },
  edit_costing: {
    slug: 'edit_costing',
    permissionType: 'Costing',
  },
  edit_packaging: {
    slug: 'edit_packaging',
    permissionType: 'Costing',
  },
  /**Resource Library permissions */
  view_resources: {
    slug: 'view_resources',
    permissionType: 'Resource Library',
  },
  edit_resources: {
    slug: 'edit_resources',
    permissionType: 'Resource Library',
  },
  add_resources: {
    slug: 'add_resources',
    permissionType: 'Resource Library',
  },
  delete_resources: {
    slug: 'delete_resources',
    permissionType: 'Resource Library',
  },
  /**Contact Library Permission */
  view_contacts: {
    slug: 'view_contacts',
    permissionType: 'Contact Library',
  },
  add_contacts: {
    slug: 'add_contacts',
    permissionType: 'Contact Library',
  },
  edit_contacts: {
    slug: 'edit_contacts',
    permissionType: 'Contact Library',
  },
  delete_contacts: {
    slug: 'delete_contacts',
    permissionType: 'Contact Library',
  },
  /**Quotations */
  edit_quotation: {
    slug: 'edit_quotation',
    permissionType: 'Quotation',
  },
  create_quotation: {
    slug: 'create_quotation',
    permissionType: 'Quotation',
  },
  view_quotation: {
    slug: 'view_quotation',
    permissionType: 'Quotation',
  },
  view_recommendation: {
    slug: 'view_recommendation',
    permissionType: 'Recommendation',
  },
};
