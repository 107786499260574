import { useEffect, useState } from 'react';
import style from 'styled-components';
import { Row, Col, Typography, Button } from 'antd';
import './ReminderBanner.css';
import { UpgradePlanModal } from 'routes/SourceWiz/Guardrails/Components/PlanUpgrade/UpgradePlanModal';
import { ReminderBannerDiv } from '../CommonFunction/PlanDetailsDiv';
import { daysCalculator } from '../CommonFunction/commonFunctions';
import { PlanExpiredModal } from '../PlanExpired/PlanExpiredModal';
const { Title, Text } = Typography;

const ButtonContainer = style.div`
  .ant-btn-primary {
    background: black;
    color: white;
    border: none;
    margin-right: 1.5rem;
  }
`;
export const ReminderBanner = ({ isBanner, data }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [daysLeftInPlan, setDaysLeftInPlan] = useState(null);
  const [renewPlanModal, setRenewPlanModal] = useState(false);
  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    if (data) {
      if (data?.status === 'expired') {
        return;
      } else {
        if (data?.core_plan?.name === 'Trial') {
          setIsDemo(true);
        }
        if (data?.daysLeftInPlan) {
          setDaysLeftInPlan(data?.daysLeftInPlan);
        }
      }
      const daysLeft = daysCalculator(data?.end_date);
      setDaysLeftInPlan(daysLeft);
    }
  }, [data]);
  const handleSubscribe = () => {
    if (isDemo) {
      setIsModalVisible(true);
    } else {
      setRenewPlanModal(true);
    }
  };
  return (
    <>
      {daysLeftInPlan >= 0 && (
        <>
          <ReminderBannerDiv
            width={isDemo && !isBanner && '71.5%'}
            display={isBannerVisible ? 'block' : 'none'}
            padding={!isDemo && '1.18rem'}>
            <Row justify="space-between">
              <Col>
                <Row>
                  {isDemo ? (
                    <Title level={isBanner ? 4 : 5}>
                      {daysLeftInPlan}{' '}
                      {daysLeftInPlan > 1 || daysLeftInPlan === 0
                        ? 'days'
                        : 'day'}{' '}
                      left in your trial
                    </Title>
                  ) : (
                    <Title level={4}>
                      Your {data?.core_plan?.name?.toUpperCase()} plan is
                      expiring
                      {daysLeftInPlan === 0
                        ? ` today`
                        : ` in ${daysLeftInPlan}`}{' '}
                      {daysLeftInPlan > 1
                        ? 'days'
                        : daysLeftInPlan !== 0 && 'day'}
                    </Title>
                  )}
                </Row>
                <Text>
                  {isDemo
                    ? 'Keep getting benefits of Sourcewiz, upgrade now'
                    : 'Renew your plan to keep using our services'}
                </Text>
              </Col>
              <Col className="demo-subscribe-btn-row">
                {isBanner && isDemo ? (
                  <Button
                    onClick={() => setIsBannerVisible(false)}
                    size="large"
                    className="reminder-banner-dismiss-btn">
                    Dismiss
                  </Button>
                ) : (
                  <></>
                )}
                <ButtonContainer>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => handleSubscribe()}>
                    {isDemo ? 'Subscribe' : 'Renew plan'}
                  </Button>
                </ButtonContainer>
              </Col>
            </Row>
          </ReminderBannerDiv>
          <UpgradePlanModal
            forDemo={true}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
          <PlanExpiredModal
            isPlanExpired={renewPlanModal}
            setIsPlanExpired={setRenewPlanModal}
            forRenew={true}
            daysLeftInPlan={daysLeftInPlan}
          />
        </>
      )}
    </>
  );
};
