import { FilterActions } from 'redux/actions/FilterActions';

const initialState = {
  drawerState: false,
  drawerType: '',
  showStepIncrementField: false,
  filterData: {},
  totalMainFilter: null,
  fetchData: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FilterActions.TOGGLE_DRAWER: {
      const { drawerState, drawerType } = action.payload;
      return {
        ...state,
        drawerState: drawerState,
        drawerType: drawerType,
      };
    }

    case FilterActions.FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload,
      };
    }

    case FilterActions.UPDATE_FILTER: {
      const { name, value } = action.payload;
      let data = state?.filterData;

      Object.keys(data).map((key) => {
        if (key === 'meta') {
          const { is_primary } = data[key];
          if (name === 'step_increment') {
            data[key] = { ...data[key], step_increment: value };
          } else if (name === 'is_primary') {
            data[key] = { ...data[key], is_primary: !is_primary };
          }
        } else if (key === 'is_visible' && name === 'is_visible') {
          data[key] = !data[key];
        }
      });
      return {
        ...state,
        filterData: data,
      };
    }

    case FilterActions.SHOW_STEP_INCREMENT: {
      return {
        ...state,
        showStepIncrementField: action.payload,
      };
    }

    case FilterActions.GET_MAIN_FILTERS: {
      return {
        ...state,
        totalMainFilter: action.payload,
      };
    }

    case FilterActions.FETCH_DATA: {
      return {
        ...state,
        fetchData: !state.fetchData,
      };
    }

    default:
      return state;
  }
}
