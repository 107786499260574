import PYTHON_KEY from 'api/pythonService';
import API_KEY from 'api';
import axios from 'axios';
import { manufacturer } from 'store/actions/manufacturer';

const pythonBaseUrl = process.env.REACT_APP_API_SERVICE;
const directusURL = process.env.REACT_APP_DIRECTUS;

const user = localStorage.getItem('user');
const token = JSON.parse(user)?.access_token;

export const deleteProducts = (data) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(`${pythonBaseUrl}/bulk/products/delete`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const fetchTasks = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}bulk/actions`, {
    headers: { 'access-token': token },
  });
};

export const fetchCachedTasks = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}bulk/cached_actions`, {
    headers: { 'access-token': token },
  });
};

export const triggerAction = (data) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(`${pythonBaseUrl}bulk/trigger_action`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const dimissCompletedTasks = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}bulk/trigger_action/dismiss_completed`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const bulkDeleteData = (inputData) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/delete`,
    { product_ids: inputData },
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const getUsedProducts = (id) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}bulk/costing/bom/${id}`, {
    headers: { 'access-token': `${token}` },
  });
};
export const getProductNewPrice = (productId, materialId, newPrice) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}bulk/costing/new_price?id=${productId}&child_product_id=${materialId}&new_price=${newPrice}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const bulkCostingChange = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(`${pythonBaseUrl}bulk/costing/`, payload, {
    headers: { 'access-token': `${token}` },
  });
};

const bulkCategoryChange = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/change_products_category`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const bulkEditAttributes = (payload, signal) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/change_products_attribute`,
    payload,
    {
      headers: { 'access-token': `${token}` },
      signal: signal,
    }
  );
};

const updateTableAttributes = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/table/products_table/update_attribute`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const updateBulkUploadAttribute = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.put(
    `${pythonBaseUrl}bulk/upload/update_attribute`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const getDraftTableMeta = (identifier, signal) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}store/table/products_table/state`,
    {
      params: {
        unique_id: identifier,
        action_name: 'bulk_products_table',
      },
      headers: { 'access-token': `${token}` },
      signal: signal,
    }
  );
};

const saveBulkTable = (identifier, task_id) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/table/products_table/save`,
    {
      unique_id: identifier,
      task_id: task_id,
    },
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const bulkCreateProducts = (products) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/create_products`,
    products,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};
const getBulkUploadGrouping = (taskID) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.get(`${pythonBaseUrl}bulk/products/group_draft`, {
    headers: { 'access-token': `${token}` },
    params: { task_id: taskID },
  });
};

const patchProduct = (payload, id) => {
  return PYTHON_KEY.URL.patch(
    `${PYTHON_KEY.path.editItem('product', id)}`,
    payload
  );
};

//bulkupload/update task details (status, visible attributes)
const updateTaskMeta = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.post(
    // `${pythonBaseUrl}bulk/upload/update_task`,
    `${pythonBaseUrl}bulk/upload/state`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const saveBulkUploadTable = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/table/save`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const saveBulkUploadProducts = (taskID) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/table/save/${taskID}`,
    {},
    {
      headers: { 'access-token': `${token}` },
    }
  );
};
const getAllTasks = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.get(`${pythonBaseUrl}bulk/actions`, {
    headers: { 'access-token': `${token}` },
  });
};

const archiveProducts = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/delete_products`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const deleteBulkUploadProducts = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.delete(`${pythonBaseUrl}bulk/upload/table/group`, {
    headers: { 'access-token': `${token}` },
    data: payload,
  });
};

const duplicacyCheck = (task_id) => {
  return PYTHON_KEY.URL.post(
    `${PYTHON_KEY.path.bulkDuplicacyCheck}/${task_id}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const createGroups = (payload) => {
  payload.action = payload?.action || 'bulk_products_upload';
  return PYTHON_KEY.URL.post(`${pythonBaseUrl}bulk/upload/group`, payload, {
    headers: { 'access-token': `${token}` },
  });
};

const getGroupsByTask = (task_id, action = 'bulk_products_upload') => {
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}bulk/upload/group/${task_id}?action=${action}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const createGroup = (payload) => {
  payload.action = payload?.action || 'bulk_products_upload';
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/group/create`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

// Ungroup single group or all images
// to ungroup all images, pass 'group_id' as 'all'
const ungroupImages = (task_id, group_id, action) => {
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}bulk/upload/ungroup/${task_id}/${group_id}?action=${action}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const patchGroup = (payload, task_id, group_id) => {
  return PYTHON_KEY.URL.patch(
    `${pythonBaseUrl}bulk/upload/group/${task_id}/${group_id}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const deleteGroup = (task_id, group_id, action) => {
  return PYTHON_KEY.URL.delete(
    `${pythonBaseUrl}bulk/upload/group/${task_id}/${group_id}?action=${action}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const ungroupGroupImages = (task_id, group_id, action) => {
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}bulk/upload/ungroup/${task_id}/${group_id}?action=${action}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const downloadImageUrl = (payload) => {
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/image/excel`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const uploadMoreImages = (payload, task_id) => {
  return PYTHON_KEY.URL.patch(
    `${pythonBaseUrl}bulk/upload/group/${task_id}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const updateAllGroups = (payload) => {
  return PYTHON_KEY.URL.patch(
    `${pythonBaseUrl}bulk/upload/all_groups`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const importBulkExcel = (payload, task_id) => {
  return axios.post(`${pythonBaseUrl}bulk/upload/import/excel`, payload, {
    headers: { 'access-token': `${token}` },
    params: { task_id: task_id },
  });
};

const removeExcel = (task_id) => {
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/upload/remove/excel`,
    {},
    {
      headers: { 'access-token': `${token}` },
      params: { task_id: task_id },
    }
  );
};

const autoLinkProducts = (manufacturer_id, current_user, payload) => {
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}bulk/link/auto?manufacturer_id=${manufacturer_id}&current_user=${current_user}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const getLinkingTableData = (taskID, page, per_page) => {
  return PYTHON_KEY.URL.get(PYTHON_KEY.path.getLinkingTableData(taskID), {
    params: { page, per_page },
  });
};

const deleteBulkImgGroup = (taskId, groupId) => {
  return PYTHON_KEY.URL.delete(
    `${PYTHON_KEY.path.deleteBulkImgGroup}/${taskId}/${groupId}`
  );
};

const unlinkBulkImgProduct = (taskId, payload) => {
  return PYTHON_KEY.URL.patch(
    `${PYTHON_KEY.path.unlinkBulkImgProduct}/${taskId}`,
    payload
  );
};

const unlinkBulkImgAllGroups = (taskId) => {
  return PYTHON_KEY.URL.patch(
    `${PYTHON_KEY.path.unlinkBulkImgAllGroups}/${taskId}`
  );
};

const editBulkImgGroupImgs = (payload) => {
  return PYTHON_KEY.URL.patch(
    `${PYTHON_KEY.path.editBulkImgGroupImgs}`,
    payload
  );
};

const saveBulkImgUpdate = (taskId, payload) => {
  return PYTHON_KEY.URL.post(
    `${PYTHON_KEY.path.saveBulkImgUpdate}/${taskId}`,
    payload
  );
};

const linkSearchProducts = (mid, user, payload) => {
  return PYTHON_KEY.URL.post(
    `${PYTHON_KEY.path.linkSearchProducts}?manufacturer_id=${mid}&current_user=${user}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const linkProductToGroup = (task_id, payload) => {
  return PYTHON_KEY.URL.patch(
    `${PYTHON_KEY.path.linkProductToGroup}/${task_id}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export default {
  deleteProducts,
  fetchTasks,
  fetchCachedTasks,
  triggerAction,
  dimissCompletedTasks,
  bulkDeleteData,
  bulkCategoryChange,
  bulkEditAttributes,
  updateTableAttributes,
  getDraftTableMeta,
  saveBulkTable,
  bulkCreateProducts,
  getBulkUploadGrouping,
  patchProduct,
  updateBulkUploadAttribute,
  updateTaskMeta,
  saveBulkUploadTable,
  saveBulkUploadProducts,
  getAllTasks,
  archiveProducts,
  duplicacyCheck,
  createGroups,
  getGroupsByTask,
  createGroup,
  patchGroup,
  ungroupImages,
  deleteBulkUploadProducts,
  ungroupGroupImages,
  deleteGroup,
  downloadImageUrl,
  uploadMoreImages,
  updateAllGroups,
  importBulkExcel,
  removeExcel,
  autoLinkProducts,
  getLinkingTableData,
  deleteBulkImgGroup,
  unlinkBulkImgProduct,
  unlinkBulkImgAllGroups,
  editBulkImgGroupImgs,
  saveBulkImgUpdate,
  linkSearchProducts,
  linkProductToGroup,
};
