import { Row, Col, Typography, Divider } from 'antd';
import { Subdiv } from 'routes/SourceWiz/Guardrails/Components/CommonFunction/PlanDetailsDiv';
import { numberWithCommas } from 'routes/SourceWiz/Guardrails/Components/CommonFunction/commonFunctions';
import './BreakdownSection.css';

const { Title, Text } = Typography;
export const BreakdownSection = ({ width, data }) => {
  return (
    data && (
      <>
        <Subdiv width={width}>
          <Title level={5}>Breakdown</Title>
          <br />
          <Row justify="space-between">
            <Col>
              <Text>
                Subscription for {data?.current_plan?.plan_name?.toUpperCase()}{' '}
                plan
              </Text>
            </Col>
            <Col className="text-bold">
              ₹ {numberWithCommas(data?.current_plan?.amount)} / year
            </Col>
          </Row>
          {data?.current_plan?.discount_value && (
            <Row justify="space-between" className="discount-top-margin">
              <Col className="small-text">
                Discount ({data?.current_plan?.discount_value} %)
              </Col>
              <Col>
                - ₹ {numberWithCommas(data?.current_plan?.amount_deduct)}
              </Col>
            </Row>
          )}
          <br />
          {data?.recurring_addon &&
            data?.recurring_addon?.map((e) => {
              return (
                <>
                  <Row justify="space-between">
                    <Col>
                      <Text>
                        {e?.addon_label} {e?.has_limit && `(${e?.limit})`}
                      </Text>
                    </Col>
                    <Col className="text-bold">
                      ₹ {numberWithCommas(e?.amount)} / year
                    </Col>
                  </Row>
                  {e?.discount_value && (
                    <Row
                      justify="space-between"
                      className="discount-top-margin">
                      <Col className="small-text">
                        Discount ({e?.discount_value} %)
                      </Col>
                      <Col>- ₹ {numberWithCommas(e?.amount_deduct)}</Col>
                    </Row>
                  )}
                  <br />
                </>
              );
            })}
          <Row justify="space-between" className="breakdown-total-row">
            <Col>Total amount</Col>
            <Col className="text-bold">
              ₹ {numberWithCommas(data?.total_amount)} / year
            </Col>
          </Row>
        </Subdiv>
      </>
    )
  );
};
