import React, { useEffect, useState } from 'react';
import { Menu, Typography, Row, Col, Popover } from 'antd';
import { AiFillHome } from 'react-icons/ai';
import { BiCustomize } from 'react-icons/bi';
import {
  MdSettings,
  MdOutlineStyle,
  MdCategory,
  MdArticle,
  MdPeopleOutline,
  MdDownload,
} from 'react-icons/md';
import Cookies from 'js-cookie';
import { IoAnalyticsSharp, IoDownload, IoLogOutOutline } from 'react-icons/io5';
import { TiEdit } from 'react-icons/ti';
import { useGlobal } from '../../../store/index';
import { useHistory, useLocation } from 'react-router-dom';
import NewLogo from 'assets/images/light_logo.svg';
import 'react-common-components/common-style.css';
import { PERMISSIONS } from 'constants/permissions';
import ability from 'casl/ability';
import navImage from './new-navigation.png';
import PYTHON_KEY from 'api/pythonService';
import { useTracking } from 'react-tracking';
import { useIntl, FormattedMessage } from 'react-intl';
import { MODULE_LIST } from '../Guardrails/Heimdall/ModuleList';
import { StartTrial } from '../Dashboard/StartTrialModal';
import { ModuleUpgradeModal } from '../Guardrails/Components/ModuleUpgrade/ModuleUpgradeModal';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const { Text } = Typography;

const menuStyle = {
  // justify: "center",
  fontSize: '16px',
  borderRadius: '4px',
  alignItems: 'center',
  display: 'flex',
  padding: 16,
  borderTop: 0,

  // paddingLeft: "0.8em",
  // paddingTop: "0.4em",
  // paddingRight: "0.2em",
  // paddingBottom: "0.2em",

  // cursor: "pointer",
};

const iconStyle = {
  justifyContent: 'center',
  fontSize: '24px',
  minWidth: 24,
};

const Sidebar = ({ changeAppModalStatus }) => {
  const intl = useIntl();
  const history = useHistory();
  const feature = 'Sidebar';
  const [globalState, globalActions] = useGlobal();
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [primaryMenu, setPrimaryMenu] = useState([]);
  const [trialModalModuleID, setTrialModalModuleID] = useState(null);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [moduleDetails, setModuleDetails] = useState({});
  const [showBuyModal, setShowBuyModal] = useState(false);

  const [featureData, setFeatureData] = useState({
    activated: false,
    activated_date: null,
    discovered: true,
    discovered_date: null,
    feature: feature,
    stage: '',
  });
  const { sidebar, usage } = globalState;
  const [selectedKey, setSelectedKey] = useState('1');
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  useEffect(() => {
    if (trialModalModuleID && usage.length)
      setModuleDetails(
        (usage || []).find(
          (item) => item.module_name === trialModalModuleID.module_id
        )
      );

    // setModuleDetails(usage);
  }, [trialModalModuleID]);
  // const confirmMessage =
  //   "Are you sure you want to leave this page? You will lose your data!";
  const confirmMessage = intl.formatMessage({
    id: 'window.confirm.sureLeavingPage',
  });

  const setFeatureAPI = ({
    discovered,
    activated,
    stage,
    discovered_date,
    activated_date,
  }) => {
    /**
     * get datetime in str
     */
    const tempFeatureData = {
      discovered: discovered,
      activated: activated,
      stage: stage,
      feature: feature,
      activated_date: activated_date,
      discovered_date: discovered_date,
    };
    if (discovered && !discovered_date)
      tempFeatureData['discovered_date'] = Date.now();
    if (activated && !activated_date)
      tempFeatureData['activated_date'] = Date.now();
    setFeatureData(tempFeatureData);
    PYTHON_KEY.URL.post(
      PYTHON_KEY.path.setFeature(),
      {},
      {
        params: { ...tempFeatureData },
      }
    );
  };

  const showModal = (show, moduleDetails) => {
    if (show) {
      setTrialModalModuleID(moduleDetails);
      setShowTrialModal(true);
    } else {
      setTrialModalModuleID(moduleDetails);
      setShowTrialModal(true);
    }
  };
  const handleClick = (menu) => {
    globalActions.guardrails.setReloadUsageData(true);
    if (menu.isPlan && menu.hasTrialStart) {
      showModal(true, menu);
      return;
    }
    if (menu.onClick) {
      if (sidebar.confirmCheck) {
        if (window.confirm(confirmMessage)) {
          menu.onClick();
        }
      } else {
        menu.onClick();
      }
    } else {
      if (sidebar.confirmCheck) {
        if (window.confirm(confirmMessage)) {
          history.push(menu.url);
        }
      } else {
        history.push(menu.url);
      }
    }
    if (!featureData.activated) {
      setFeatureAPI({
        ...featureData,
        discovered: true,
        activated: true,
        stage: 'activated',
      });
    }
  };
  useEffect(() => {
    PYTHON_KEY.URL.get(PYTHON_KEY.path.getFeature(feature)).then((res) => {
      if (res.data.length === 0) {
        setLoading(false);
        setShowAnnouncement(true);
      } else {
        setFeatureData(res.data[0]);
        if (!res.data[0].discovered) setShowAnnouncement(true);
        setLoading(false);
      }
    });
  }, []);

  const handleLogout = () => {
    clearLocalStorageAndCookie();
    localStorage.setItem('logout-event', 'logout' + Math.random());
    if (sessionStorage.getItem('routing-path')) {
      history.push(sessionStorage.getItem('routing-path'));
    } else {
      history.push('/');
    }
    globalActions.authActions.signoutAction();
    window.location.reload();
  };

  const secondaryMenu = [
    {
      key: '8',
      icon: <MdSettings style={iconStyle} />,
      label: 'Profile',
      title: <FormattedMessage id={'sourcewiz.button.profileSettings'} />,
      url: '/profile-settings/1',
    },
    {
      key: '10',
      icon: <MdDownload style={iconStyle} />,
      label: 'Download app',
      title: <FormattedMessage id={'sourcewiz.button.downloadApp'} />,
      url: '/profile-settings',
      onClick: () => {
        changeAppModalStatus((state) => !state);
      },
    },
    {
      key: '15',
      icon: <IoLogOutOutline style={iconStyle} />,
      label: 'Log out',
      title: <FormattedMessage id={'sourcewiz.button.logout'} />,
      url: '/profile-settings',
      onClick: () => {
        handleLogout();
      },
    },
  ];

  const pref = localStorage.getItem('pref');
  const showBRDashboard = JSON.parse(pref);
  const staticPrimaryMenu = [
    {
      key: '1',
      icon: <AiFillHome style={iconStyle} />,
      url: '/',
      label: 'Dashboard',
      title: <FormattedMessage id={'sourcewiz.dashboard.dashboard'} />,
      permission: 'global_permissions',
      show: true,
    },
    {
      key: '2',
      icon: <MdOutlineStyle style={iconStyle} />,
      url: '/master-catalogue',
      label: 'Products',
      title: <FormattedMessage id={'sourcewiz.sidebar.products'} />,
      permission: 'view_products',
      show: true,
    },
    {
      key: '3',
      icon: <TiEdit style={iconStyle} />,
      url: '/manage-products',
      label: 'Manage Products',
      title: <FormattedMessage id={'sourcewiz.sidebar.manageProducts'} />,
      permission: 'view_catalogues',
      show: true,
    },
    {
      key: '6',
      icon: <IoAnalyticsSharp style={iconStyle} />,
      url: '/catalogue-history',
      label: 'Catalogues',
      title: <FormattedMessage id={'sourcewiz.sidebar.catalogues'} />,
      permission: 'view_catalogues',
      show: true,
    },
    {
      key: '7',
      icon: <BiCustomize style={iconStyle} />,
      url: '/create-collection',
      label: 'Create Catalogue',
      title: <FormattedMessage id={'sourcewiz.sidebar.createCatalogue'} />,
      permission: 'create_catalogues',
      show: true,
    },
    // {
    //   key: '4',
    //   icon: <HiOutlineLightBulb style={iconStyle} />,
    //   url: '/buyer-list',
    //   label: 'Recommendations',
    //   title: <FormattedMessage id={'sourcewiz.sidebar.recommendations'} />,
    //   permission: 'global_permissions',
    //   show: showBRDashboard?.hs_code ? true : false,
    // },
    {
      key: '5',
      icon: <MdCategory style={iconStyle} />,
      url: '/material-library',
      label: 'Resource Library',
      title: <FormattedMessage id={'sourcewiz.sidebar.resourceLibrary'} />,
      permission: 'view_resources',
      show: true,
    },
    // {
    //   key: "11",
    //   title: <FormattedMessage id={"quotations.quotations"} />,
    //   icon: <MdMonetizationOn style={iconStyle} />,
    //   url: "/enquiries",
    //   label: "Quotations",
    //   permission: PERMISSIONS.view_quotation.slug,
    //   show: true,
    // },

    {
      key: '12',
      title: 'Buyers',
      icon: <MdPeopleOutline style={iconStyle} />,
      url: '/buyers',
      label: 'Buyers',
      permission: PERMISSIONS.view_quotation.slug,
      show: true,
    },

    {
      key: '13',
      title: 'Invoices & Quotations',
      icon: <MdArticle style={iconStyle} />,
      url: `/invoices-orders/all-documents`,
      isPlan: true,
      module_id: MODULE_LIST.quotation.id,
      label: 'Invoices & Quotations',
      permission: PERMISSIONS.view_quotation.slug,
      show: true,
    },
  ];

  const getPlanDetails = (card) => {
    var currentModule = usage.find(
      (item) => item.module_name === card.module_id
    );
    const isTrialInactive =
      currentModule.plan_type === 'trial' &&
      currentModule.plan_status === 'inactive';

    return {
      hasTrialStart: isTrialInactive,
      showExtra: isTrialInactive,
    };
  };

  useEffect(() => {
    if (Object.keys(usage).length > 0) {
      const usageMappedCards = staticPrimaryMenu.map((item) => {
        const usageObject = item.isPlan ? getPlanDetails(item) : {};
        return { ...item, ...usageObject };
      });
      setPrimaryMenu(usageMappedCards);
    }
  }, [usage]);

  useEffect(() => {
    switch (history?.location?.pathname) {
      case '/':
        setSelectedKey('1');
        break;

      case '/master-catalogue':
        setSelectedKey('2');
        break;

      case '/manage-products':
        setSelectedKey('3');
        break;

      case '/buyer-list':
        setSelectedKey('4');
        break;

      case '/material-library':
        setSelectedKey('5');
        break;
      case '/process-library':
        setSelectedKey('5');
        break;
      case '/catalogue-history':
        setSelectedKey('6');
        break;

      case '/create-collection':
        setSelectedKey('7');
        break;

      case '/profile-settings':
        setSelectedKey('8');
        break;

      case '/enquiries':
        setSelectedKey('11');
        break;

      case '/buyers':
        setSelectedKey('12');
        break;
      case '/invoices-orders/all-documents':
      case '/invoices-orders/proforma-invoice':
      case '/invoices-orders/purchase-order':
      case '/invoices-orders/sales-order':
      case '/invoices-orders/packaging-list':
      case '/invoices-orders/quotation':
        setSelectedKey('13');
        break;
      default:
        break;
    }
  }, [history?.location?.pathname]);

  const { trackEvent } = useTracking();
  const location = useLocation();

  if (!sidebar) return <></>;

  return (
    <>
      {collapsed ? null : (
        <div
          onClick={() => {
            setCollapsed(true);
          }}
          style={{
            width: '100%',
            height: '100%',
            position: 'fixed',

            zIndex: 100,
            top: 0,
          }}
        />
      )}

      <ModuleUpgradeModal
        moduleData={moduleDetails}
        moduleId={moduleDetails?.module_id}
        isModalVisible={showBuyModal}
        setIsModalVisible={setShowBuyModal}
      />

      <StartTrial
        usage={usage}
        moduleDetails={moduleDetails}
        setTrialModalModuleID={setTrialModalModuleID}
        showModal={showTrialModal}
        setShowModal={setShowTrialModal}
        setShowBuyModal={setShowBuyModal}
        fromDashboard={true}
      />

      <div
        onMouseEnter={() => setCollapsed(false)}
        className={
          collapsed ? 'hide-sidebar-container' : 'show-sidebar-container'
        }
        onMouseLeave={() => setCollapsed(true)}>
        {/* <HeaderActivity visible={visible} setVisible={setVisible} /> */}

        <Row
          onClick={() => {
            if (sidebar.confirmCheck) {
              if (window.confirm(confirmMessage)) {
                history.push('/');
              }
            } else {
              history.push('/');
            }
          }}
          style={{
            cursor: 'pointer',
            alignItems: 'left',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              src={NewLogo}
              alt="Logo"
              style={{
                width: '2em',
                height: '2em',
                margin: '10px',
                marginLeft: '30px',
              }}
            />
            {!collapsed && (
              <Text
                style={{
                  fontSize: '25px',
                  fontWeight: 'bold',
                  color: '#092B00',
                  opacity: 0.38,
                  paddingTop: '5px',
                  fontFamily: 'SatoshiBold',
                }}>
                Sourcewiz
              </Text>
            )}
          </div>
        </Row>

        <Row
          className="sw-sidebar-content"
          style={{
            // height: "100vh",
            paddingBottom: 32,
            flexWrap: 'wrap',
            overflowY: 'scroll',
            maxWidth: '20vw',
            flexDirection: 'column',
            transition: 'all 1s ease !important',
          }}>
          <span
            style={{
              width: '100%',

              height: '100vh',
              position: 'relative',
            }}>
            {loading ? (
              <></>
            ) : showAnnouncement ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'fixed',
                  zIndex: 102,
                  top: 0,
                  left: 0,
                  background: 'rgba(0,0,0,0.12)',
                }}
                onClick={() => {
                  setFeatureAPI({
                    ...featureData,
                    discovered: true,
                    stage: 'discovered',
                  });
                  setShowAnnouncement(false);
                }}
              />
            ) : null}

            <Popover
              content={
                <div className="sw-new-announcement">
                  <img
                    alt=""
                    onClick={() => {
                      setFeatureAPI({
                        ...featureData,
                        discovered: true,
                        stage: 'discovered',
                      });
                      setShowAnnouncement(false);
                    }}
                    style={{ cursor: 'pointer' }}
                    width="100%"
                    src={navImage}
                  />
                  <h3>We got a new way of using platform</h3>
                  <p>
                    The new sidebar makes it quicker and easier than ever to
                    move between the features packed on our platform
                  </p>
                </div>
              }
              placement="rightTop"
              visible={showAnnouncement}>
              <Col style={{ height: '100vh' }}>
                {loading ? (
                  <></>
                ) : (
                  <Row className="primaryMenu">
                    <Menu
                      className="sw-sidebar"
                      theme="dark"
                      style={{
                        background: '#F7F7F7',
                        paddingTop: 16,
                        paddingBottom: 16,
                        paddingRight: '1em',
                        paddingLeft: '1em',
                        minHeight: 100,
                      }}
                      selectedKeys={selectedKey}
                      inlineCollapsed={collapsed}
                      inlineIndent={40}>
                      {primaryMenu.map((menu, index) => {
                        if (!menu.show) return <></>;
                        if (
                          ability.can(
                            PERMISSIONS[menu.permission].slug,
                            PERMISSIONS[menu.permission].permissionType
                          )
                        ) {
                          return (
                            <Menu.Item
                              key={menu.key}
                              style={menuStyle}
                              icon={menu.icon}
                              onClick={() => {
                                handleClick(menu);
                                trackEvent({
                                  name: menu.label
                                    .toLowerCase()
                                    .replace(' ', '_'),
                                  meta: {
                                    user_id: localStorage.getItem('userId'),
                                    type: 'single',
                                    location: location.pathname,
                                    modules: 'Overall',
                                    property: 'Sidebar',
                                  },
                                });
                              }}>
                              {menu.title}
                            </Menu.Item>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </Menu>
                  </Row>
                )}

                <Row></Row>
                <Row className="sw-sidebar-bottom">
                  <Col
                    style={{
                      position: 'relative',
                    }}>
                    <div
                      style={{
                        width: '100%',
                        // marginBottom: 32,
                        flexGrow: 1,
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}>
                      <Menu
                        // defaultSelectedKeys={["1"]}
                        selectedKeys={selectedKey}
                        // onClick={handleClick}

                        className="sw-sidebar"
                        theme="dark"
                        style={{
                          background: '#F7F7F7',
                          paddingRight: '1em',
                          paddingLeft: '1em',
                          paddingBottom: '1em',
                        }}
                        inlineCollapsed={collapsed}
                        inlineIndent={40}>
                        {secondaryMenu.map((menu) => (
                          <Menu.Item
                            key={menu.key}
                            style={menuStyle}
                            icon={menu.icon}
                            onClick={() => {
                              trackEvent({
                                name: menu.label
                                  .replace(' ', '_')
                                  .toLocaleLowerCase(),
                                meta: {
                                  user_id: localStorage.getItem('userId'),
                                  type: 'single',
                                  location: location.pathname,
                                  modules: 'Overall',
                                  property: 'Sidebar',
                                },
                              });
                              if (menu.onClick) {
                                if (sidebar.confirmCheck) {
                                  if (window.confirm(confirmMessage)) {
                                    menu.onClick();
                                  }
                                } else {
                                  menu.onClick();
                                }
                              } else {
                                if (sidebar.confirmCheck) {
                                  if (window.confirm(confirmMessage)) {
                                    history.push(menu.url);
                                  }
                                } else {
                                  history.push(menu.url);
                                }
                              }
                              if (!featureData.activated) {
                                setFeatureAPI({
                                  ...featureData,
                                  discovered: true,
                                  activated: true,
                                  stage: 'activated',
                                });
                              }
                            }}>
                            {menu.title}
                          </Menu.Item>
                        ))}
                      </Menu>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Popover>
          </span>
        </Row>
      </div>
    </>
  );
};

export default Sidebar;
