import React from 'react';
import { Row, Divider, Button } from 'antd';
import './index.less';
function Footer({ onBack, btnLabel }) {
  return (
    <div>
      <Row classname="spacingDiv" align="center">
        <div className="footer-div" />
        <Button
          onClick={() => {
            onBack();
          }}
          className="footer-div-btn">
          {btnLabel}
        </Button>
      </Row>
    </div>
  );
}

export default Footer;
