import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Progress,
  Modal,
  Input,
  Form,
  notification,
  Select,
  Typography,
  message,
} from 'antd';
import { useGlobal } from 'store/index';
import { updateClaimStatus } from 'api/rewardApis';
import OtpInput from 'react-otp-input';
import hooks from 'hooks';
import { updateMobileNumber } from 'api/customApis';
import { generateOTP, verifyOTP } from 'api/authApis';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import './title.less';

import * as animationData from './TrophyLottie.json';
import * as successAnimation from './SuccessLottie.json';

import Lottie from 'react-lottie';

import verify from '../../../assets/images/rewardsv2/verify.svg';

import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;

export const VoucherModal = ({ showModal, handleUpdate, closeModal }) => {
  const [globalState, globalActions] = useGlobal();
  const [userDetails, setUserDetails] = useState(globalState.userInfo);
  const info = ['mobile', 'email'];
  const [trophy, setTrophy] = useState(true);
  const [details, setDetails] = useState(false);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [form] = Form.useForm();
  const { milestoneInfo } = globalState;
  const location = useLocation();
  const { trackEvent } = useTracking();

  const [mobile, setMobile] = useState(globalState?.userInfo?.mobile);
  const [OtpValue, setOtpValue] = useState('');
  const [hasError, sethasError] = useState(false);
  const [resetOTPLoading, setresetOTPLoading] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const { seconds, start, stop, reset } = hooks.useTimer({
    seconds: 180,
    running: true,
  });

  const closeState = () => {
    setDetails(false);
    setDone(false);
    setTrophy(true);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select disabled style={{ width: 70 }} defaultValue="+91">
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptionsSuccess = {
    loop: true,
    autoplay: true,
    animationData: successAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleUserDetails = () => {
    const payload = form.getFieldValue();
    for (let item in info) {
      userDetails[info[item]] = payload[info[item]];
    }
  };

  const handleClaimVoucher = () => {
    const finalPayload = {};
    for (let item in info) {
      finalPayload[info[item]] = userDetails[info[item]];
    }
    setLoading(true);
    updateClaimStatus({
      ...finalPayload,
      milestone_id: milestoneInfo?.id,
      status: 'claimed',
    })
      .then((res) => {
        if (res?.status === 200) {
          stop();
          setLoading(false);
          setOtpStatus(false);
          setTrophy(false);
          setDetails(false);
          setDone(true);
          closeOtpModal();
          trackEvent({
            name: 'Rewards',
            meta: {
              location: location.pathname,
              modules: 'Rewards',
              property: 'Claim Voucher',
            },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setOtpStatus(false);
        message.error('Voucher Claim unsuccessful, Please try again');
      });
  };

  //otp
  const closeOtpModal = () => {
    setOtpModal(false);
  };

  const handleonOtpChange = (otp) => {
    sethasError(false);
    setOtpValue(otp);
  };

  const formatSeconds = (countDown) => {
    const sec = seconds % 60;
    const secLabel = sec < 10 ? `0${sec}` : sec;
    const min = Math.floor(seconds / 60);
    if (min) {
      return `0${min}:${secLabel} minutes`;
    }
    return `00:${secLabel} seconds`;
  };

  const resendOTP = () => {
    setresetOTPLoading(true);
    if (userDetails?.mobile.length === 10) {
      reset();
      generateOTP({
        email: userDetails?.email,
        mobile: userDetails?.mobile,
        country: '99',
      })
        .then(() => {
          start();
          sethasError(false);
          setresetOTPLoading(false);
          setOtpValue('');
          setOtpModal(true);
          message.success(`OTP sent successfully`);
        })
        .catch((e) => {
          setresetOTPLoading(false);
          message.error(`Failed to generate OTP`);
        });
    } else {
      setresetOTPLoading(false);
      message.error(`Invalid phone number`);
    }
  };

  const updateUserMobile = () => {
    if (!mobile) {
      updateMobileNumber({ mobile: userDetails?.mobile, country: 99 })
        .then((res) => {
          message.success('Mobile number updated successfully');
        })
        .catch(() => {
          message.error('Mobile number cannot be updated');
        });
    }
  };

  const handleVerifyOTP = () => {
    setLoading(true);
    verifyOTP({ otp: OtpValue })
      .then((res) => {
        if (res.data.verified) {
          setOtpStatus(true);
          handleClaimVoucher();
          updateUserMobile();
        } else {
          message.error('The OTP you have entered is not valid');
          sethasError(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        message.error('error in validating otp');
        sethasError(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        visible={showModal}
        onCancel={() => {
          closeState();
          closeModal();
        }}
        footer={null}
        width={700}
        bodyStyle={{
          padding: '0',
        }}>
        {trophy && (
          <Row className="gift-card modal" justify="center">
            <Col>
              <Row justify="center">
                <Lottie options={defaultOptions} height={'70%'} width={'70%'} />
              </Row>
              <Row className="title">
                Congratulations! You won ₹ {milestoneInfo?.reward}{' '}
                {milestoneInfo?.provider} gift card
              </Row>
              <Row className="desc trophy-text">
                Look out for more upcoming tasks on the platform.{' '}
              </Row>
              <Row justify="center">
                <Button
                  className="btn"
                  onClick={() => {
                    setTrophy(false);
                    setDone(false);
                    setDetails(true);
                  }}>
                  Claim your voucher
                </Button>
              </Row>
            </Col>
          </Row>
        )}

        {details && (
          <Row className="gift-card modal" justify="center">
            <Col>
              <Row
                className="title"
                style={{
                  paddingTop: `${userDetails?.mobile ? '5rem' : '3rem'}`,
                }}>
                Congratulations! You won ₹ {milestoneInfo?.reward}{' '}
                {milestoneInfo?.provider} gift card
              </Row>
              <Row className="text">
                Add and confirm your details below to receive voucher
              </Row>
              <Row className="form-data" justify="center">
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={{
                    mobile: userDetails?.mobile,
                    email: userDetails?.email,
                  }}
                  style={{ width: '65%' }}>
                  <Form.Item
                    name="mobile"
                    label="Phone Number"
                    validateStatus={otpStatus && 'success'}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!',
                      },
                    ]}>
                    <Input
                      maxLength={10}
                      addonBefore={prefixSelector}
                      id="success"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email id"
                    validateStatus={otpStatus && 'success'}
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}>
                    <Input />
                  </Form.Item>{' '}
                </Form>
              </Row>
              {otpStatus && (
                <Row justify="center">
                  <Text style={{ fontSize: '1rem' }}>
                    <img src={verify} alt="img" height={'25'} loading="lazy" />
                    verified
                  </Text>
                </Row>
              )}

              <Row justify="center">
                {!otpStatus ? (
                  <Button
                    loading={resetOTPLoading}
                    type="submit"
                    className="btn"
                    onClick={() => {
                      handleUserDetails();
                      resendOTP();
                    }}>
                    Verify OTP
                  </Button>
                ) : (
                  <Button
                    loading={loading}
                    className="btn"
                    onClick={() => {
                      handleClaimVoucher();
                    }}>
                    Claim Voucher
                  </Button>
                )}
              </Row>
            </Col>
          </Row>
        )}

        {done && (
          <Row className="gift-card modal" justify="center">
            <Col>
              <Row className="success-lottie" justify="center">
                <Lottie
                  options={defaultOptionsSuccess}
                  height={'50%'}
                  width={'50%'}
                />
              </Row>
              <Row className="title" justify="center">
                Voucher Claimed Succesfully
              </Row>
              <Row className="desc claim-success-text">
                Your {milestoneInfo?.provider} voucher will be sent to your
                phone number and email id
              </Row>
              <Row justify="center">
                <Button
                  className="btn done-btn"
                  style={{ width: '10rem' }}
                  onClick={() => {
                    closeModal();
                    handleUpdate();
                    closeState();
                  }}>
                  Done
                </Button>
              </Row>
            </Col>
          </Row>
        )}
      </Modal>

      <Modal
        visible={otpModal}
        onCancel={closeOtpModal}
        footer={null}
        width={550}
        style={{ marginTop: '5rem' }}>
        <Row style={{ padding: '1rem' }}>
          <Col>
            <Row style={{ marginTop: '1rem' }}>
              <Text className="text-reg">
                Enter the OTP received on {userDetails?.mobile} and{' '}
                {userDetails?.email}
              </Text>
            </Row>
            <Row className="otp-timer">
              OTP valid for {formatSeconds(seconds)}
            </Row>
            <Row style={{ width: '100%', marginTop: '1rem' }}>
              <Col span={24}>
                <OtpInput
                  isInputNum
                  containerStyle={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  value={OtpValue}
                  onChange={handleonOtpChange}
                  numInputs={6}
                  inputStyle={{
                    width: '3rem',
                    height: '3rem',
                    fontSize: '25px',
                    borderRadius: '8px',
                    border: '1px solid gray',
                  }}
                  isInputSecure={true}
                  hasErrored={hasError}
                  errorStyle={{ border: '1px solid red' }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => resendOTP()}
                loading={resetOTPLoading}>
                {resetOTPLoading ? 'Sending OTP...' : 'Resend OTP'}
              </Button>
            </Row>
            <Row className="otp-btns">
              <Col span={11}>
                <Button
                  type="secondary"
                  size="large"
                  style={{ width: '100%' }}
                  onClick={() => closeOtpModal()}>
                  Cancel
                </Button>
              </Col>
              <Col span={11} offset={2}>
                <Button
                  loading={loading}
                  disabled={OtpValue.length < 6}
                  type="primary"
                  size="large"
                  style={{ width: '100%' }}
                  onClick={() => handleVerifyOTP()}>
                  {loading ? 'Verifying..' : 'Verify OTP'}
                </Button>
              </Col>
            </Row>
            <Row className="contact-help" justify="center">
              Need more support? Contact
              <p className="claim-text">{'\u00a0\u00a0'}support@sourcewiz.co</p>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export function CounterRewardCard(data) {
  const rem = data?.progress?.target - data?.progress?.current;
  const given = moment(data?.end_date);
  const current = moment().startOf('day');
  const days = Math.floor(moment.duration(given.diff(current)).asDays());

  return (
    <>
      <Row
        className="reward-card"
        style={{
          padding: '0',
          margin: '0',
          borderRadius: '8px',
        }}>
        <Row
          className="text"
          style={{
            fontSize: '12px',
            background: '#FFD194',
            padding: '0.5rem',
            marginTop: '1rem',
            paddingLeft: '1rem',
            borderRadius: '4px',
          }}>
          {`Offer ends in ${days} ${days === 1 ? 'day' : 'days'}`}
        </Row>
        <Col span={24} style={{ padding: '1rem' }}>
          <Row className="reward-title">Just {rem} more to win!</Row>
          <Row style={{ paddingTop: '1rem' }}>
            {data?.progress?.current}/{data?.progress?.target} completed
          </Row>
          <Row>
            <Progress
              strokeColor={{
                '0%': '#0B7763',
                '100%': '#B6DA25',
              }}
              percent={(data?.progress?.current / data?.progress?.target) * 100}
              showInfo={false}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}

export const openNotificationv2 = (placement, data) => {
  notification.open({
    message: CounterRewardCard(data),
    placement,
    duration: null,
    style: { padding: '0', margin: '0', borderRadius: '8px' },
  });
};

export const handleRewardv2Notifications = (newData) => {
  const data = newData;

  if (data?.progress?.target === data?.progress?.current) {
    const openClaimModalEvent = new CustomEvent('open-claim-modal', {
      detail: data,
    });
    window.dispatchEvent(openClaimModalEvent);
  } else {
    openNotificationv2('bottomRight', data);
  }
};
