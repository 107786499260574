import React from 'react';
import { Input } from 'antd';

export default function Index({ style, children, ...props }) {
  return (
    <Input style={{ ...style }} {...props}>
      {children}
    </Input>
  );
}
