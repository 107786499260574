export const ActionTypes = Object.freeze({
  ADD_PRODUCT: 'productsActions/ADD_PRODUCT',
  REMOVE_PRODUCT: 'productsActions/REMOVE_PRODUCT',
  SET_PRODUCTS: 'productsActions/SET_PRODUCTS',
  ADD_MULTIPLE_PRODUCTS: 'productsActions/ADD_MULTIPLE_PRODUCTS',
  REMOVE_MULTIPLE_PRODUCTS: 'productsActions/REMOVE_MULTIPLE_PRODUCTS',
  RESET_PRODUCT: 'productsActions/RESET_PRODUCT',
});

export function addProduct(product, collectionId) {
  return {
    type: ActionTypes.ADD_PRODUCT,
    payload: {
      product,
      collectionId,
    },
  };
}

export function removeProduct(product, collectionId) {
  return {
    type: ActionTypes.REMOVE_PRODUCT,
    payload: {
      product,
      collectionId,
    },
  };
}

export function setProducts(products = [], collectionId) {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: {
      products,
      collectionId,
    },
  };
}

export function addMultipleProducts(products, collectionId) {
  return {
    type: ActionTypes.ADD_MULTIPLE_PRODUCTS,
    payload: {
      products,
      collectionId,
    },
  };
}

export function removeMultipleProducts(products, collectionId) {
  return {
    type: ActionTypes.REMOVE_MULTIPLE_PRODUCTS,
    payload: {
      products,
      collectionId,
    },
  };
}

export function resetProductData(id, isReset) {
  return {
    type: ActionTypes.RESET_PRODUCT,
    payload: {
      id,
      isReset,
    },
  };
}

const ACTION_HANDLERS = {
  ActionTypes,
  addProduct,
  removeProduct,
  setProducts,
  addMultipleProducts,
  removeMultipleProducts,
};

export default ACTION_HANDLERS;
