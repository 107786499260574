import { useEffect, useState } from 'react';
import {
  Modal,
  Typography,
  Row,
  Card,
  Col,
  Button,
  Divider,
  Badge,
  Skeleton,
} from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { UpgradeRequestModal } from '../UpgradeRequest/UpgradeRequestModal';
import { PYTHON_API_KEY } from 'api/customApis';
import { numberWithCommas } from '../CommonFunction/commonFunctions';
import './ModuleUpgrade.css';
import { MODULE_LIST } from '../../Heimdall/ModuleList';
import { PostRequestConstants } from '../CommonFunction/PostRequestContents';
import { HelpDesk } from '../HelpDesk/HelpDeskDemo';
const { Title, Text, Paragraph } = Typography;

export const ModuleUpgradeModal = ({
  // availableAddOn,
  // availablePlan,
  // handleUpgradeAddon,
  forAddOn,
  moduleId,
  moduleData,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [cardSelected, setCardSelected] = useState('');
  const [availableAddOnData, setAvailableAddOnData] = useState({});
  const [availablePlanData, setAvailablePlanData] = useState({});
  const [upgradeRequestModal, setUpgradeRequestModal] = useState(false);
  const [payloadData, setPayloadData] = useState();
  const [loading, setLoading] = useState(false);
  const [constants, setConstants] = useState({});
  const [isAddon, setIsAddon] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [isOnePlan, setIsOnePlan] = useState(false);
  const userData = localStorage.getItem('user');
  const user = userData && JSON.parse(userData);
  const access_token = user?.access_token;
  useEffect(() => {
    if (isModalVisible) {
      getAvailableUpgradeData();
    }
  }, [isModalVisible]);
  const getAvailableUpgradeData = () => {
    // const { module_id } = moduleData;
    setLoading(true);
    setCardSelected('');
    PYTHON_API_KEY.URL.get(`${PYTHON_API_KEY.path.moduleUpgrade}/${moduleId}`, {
      headers: { 'access-token': `${access_token}` },
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          const data = res?.data;
          checkAvailablePlan(data?.available_addon, data?.available_plans);
          getTexts(data?.available_addon, data?.available_plans);
          if (data?.pending_requests?.length > 0) {
            const isPlanRequestPending = data?.pending_requests?.filter(
              (item) => item?.feature_type === 'plan'
            );
            if (isPlanRequestPending?.length > 0) {
              // setIsModalVisible(false);
              setIsRequest(true);
              // setRequestStatusModal(true);
            }
            if (moduleId) {
              const isModuleRequest = data?.pending_requests?.filter(
                (item) => item?.module_id === moduleId
              );
              if (isModuleRequest?.length > 0) {
                // setIsModalVisible(false);
                setIsRequest(true);
                // setRequestStatusModal(true);
              } else {
                setAvailableAddOnData(res?.data?.available_addon);
                setAvailablePlanData(res?.data?.available_plans);
              }
            }
          } else {
            setIsRequest(false);
            setAvailableAddOnData(data?.available_addon);
            setAvailablePlanData(data?.available_plans);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleSendRequest = (id) => {
    if (!isAddon) {
      setPayloadData({
        request_type: 'upgrade',
        feature_type: 'plan',
        feature_identifier: id,
        module_id: moduleId,
      });
      setIsModalVisible(false);
      setUpgradeRequestModal(true);
    }
    if (isAddon && availablePlanData?.length === 0) {
      setPayloadData({
        request_type: 'upgrade',
        feature_type: 'addon',
        feature_identifier: availableAddOnData?.addon_details?.id,
        module_id: availableAddOnData?.addon_details?.module_id,
      });
      setIsModalVisible(false);
      setUpgradeRequestModal(true);
    }
    if (cardSelected === 'addon') {
      setPayloadData({
        request_type: 'upgrade',
        feature_type: cardSelected,
        feature_identifier: availableAddOnData?.addon_details?.id,
        module_id: availableAddOnData?.addon_details?.module_id,
      });
    } else if (cardSelected === 'plan' && isAddon) {
      setPayloadData({
        request_type: 'upgrade',
        feature_type: cardSelected,
        feature_identifier: availablePlanData[0]?.id,
        module_id: moduleId,
      });
    }
    setIsModalVisible(false);
    setUpgradeRequestModal(true);
  };
  const checkAvailablePlan = (addon, plan) => {
    if (Object.keys(addon)?.length > 0) {
      setIsAddon(true);
    } else if (Object.keys(addon)?.length === 0) {
      setIsAddon(false);
    } else if (Object.keys(addon)?.length === 0 || plan?.length === 0) {
      setIsOnePlan(true);
    } else {
      setIsOnePlan(false);
    }
  };
  const getTexts = (addon, plan) => {
    const module_name = moduleData?.module_name;
    const asArray = Object.entries(MODULE_LIST);
    const filteredModuleData = asArray?.filter(
      ([key, value]) => value?.id === module_name
    );
    if (filteredModuleData) {
      const moduleInfo = filteredModuleData[0];
      const {
        upgrade_title,
        upgrade_text,
        on_lite_title,
        on_lite_text,
        enterprise_title,
        enterprise_text,
      } = moduleInfo[1];
      setConstants({
        upgrade_title,
        upgrade_text,
        on_lite_title,
        on_lite_text,
        enterprise_title,
        enterprise_text,
      });
    }
  };
  return (
    <>
      <Modal
        visible={isModalVisible}
        width={isAddon ? 650 : 780}
        closable
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        afterClose={() => setIsRequest(false)}>
        <>
          {loading ? (
            <Row
              justify="space-between"
              style={{ minHeight: '22rem', marginTop: '0.5rem' }}>
              <Col style={{ width: 280 }}>
                <br />
                <Skeleton active />
                <br />
                <Skeleton active />
              </Col>
              <Col style={{ width: 280 }}>
                <br />
                <Skeleton active />
                <br />
                <Skeleton active />
              </Col>
            </Row>
          ) : isRequest ? (
            <PostRequestConstants />
          ) : (
            <>
              <Title level={4} style={{ fontSize: '1.1rem' }}>
                Update your plan
              </Title>
              <Text className="module-upgrade-subtext" type="secondary">
                {isAddon && availablePlanData?.length === 0
                  ? constants?.enterprise_text
                  : isAddon && availablePlanData?.length !== 0
                  ? constants?.upgrade_text
                  : constants?.on_lite_text}
              </Text>
              <br />

              <Row
                style={{ marginTop: '2rem' }}
                justify={isAddon ? 'space-around' : 'center'}>
                {availableAddOnData &&
                  Object.keys(availableAddOnData).length > 0 && (
                    <Col
                      style={{
                        width: `${availablePlanData?.length === 0 && '90%'}`,
                      }}>
                      <Card
                        className="upgrade-addon-card"
                        id="upgrade-addon-card"
                        style={{
                          cursor: 'pointer',
                          background: `${
                            cardSelected === 'addon' ? '#F0F8F6' : 'white'
                          }`,
                          border: `${
                            cardSelected === 'addon'
                              ? '1px solid #0B7764'
                              : '1px solid #CDCFCE'
                          }`,
                          borderRadius: '0.5rem',
                          minHeight: `${
                            availablePlanData?.length !== 0 ? '19rem' : 'auto'
                          }`,
                          // height: "auto",
                        }}
                        onClick={() => {
                          if (availablePlanData?.length > 0) {
                            setCardSelected('addon');
                          }
                        }}
                        actions={
                          isAddon &&
                          availablePlanData?.length > 0 && [
                            <Button
                              size="large"
                              onClick={() => setCardSelected('addon')}
                              style={{
                                background: `${
                                  cardSelected === 'addon' ? '#F0F8F6' : 'white'
                                }`,
                                color: `${
                                  cardSelected === 'addon' ? '#0B7764' : 'black'
                                }`,
                                border: `${
                                  cardSelected === 'addon'
                                    ? '1px solid #6DADA2'
                                    : '1px solid #CDCFCE'
                                }`,
                              }}>
                              {cardSelected === 'addon' && (
                                <CheckCircleFilled />
                              )}
                              {cardSelected === 'addon' ? 'Selected' : 'Select'}{' '}
                              addon
                            </Button>,
                          ]
                        }>
                        <Row align="center">
                          <Paragraph
                            className="plan-title-label"
                            level={5}
                            align="center">
                            {availableAddOnData?.addon_details?.has_limit ===
                              true &&
                              availableAddOnData?.addon_details?.limit}{' '}
                            {availableAddOnData?.addon_details?.label}
                          </Paragraph>
                        </Row>
                        <Divider style={{ margin: '0.8rem 0 0.8rem 0' }} />
                        {/* <DividerComponent/> */}
                        <Row align="center">
                          <Text>
                            <b style={{ fontSize: '1.3rem' }}>
                              + ₹ {numberWithCommas(availableAddOnData?.amount)}
                            </b>{' '}
                            / year
                          </Text>
                        </Row>
                        <Row style={{ marginTop: '0.7rem' }} align="center">
                          <Paragraph
                            align="center"
                            className="module-upgrade-subtext">
                            {availableAddOnData?.addon_details?.description}
                          </Paragraph>
                        </Row>
                      </Card>
                    </Col>
                  )}
                {availablePlanData?.length > 0 && !isAddon && (
                  <Col>
                    {availablePlanData?.map((item) => {
                      return (
                        <Badge
                          count={isAddon ? 'Recommended' : 'Popular'}
                          offset={isAddon ? [-121, 2] : [-85, 2]}
                          style={{
                            display: `${
                              item?.name?.toUpperCase() === 'GOLD'
                                ? 'block'
                                : 'none'
                            }`,
                          }}
                          color="linear-gradient(92.76deg, #694CBB -4.7%, #FFA42B 150.67%)">
                          <Card
                            style={{
                              border: `${
                                cardSelected === 'plan'
                                  ? '1px solid #0B7764'
                                  : '1px solid #CDCFCE'
                              }`,
                              margin: `${!isAddon && '0 0.3rem 0.5rem 0.3rem'}`,
                            }}
                            className="upgrade-plan-card">
                            <Row align="center">
                              <Title level={5} style={{ marginTop: '0.5rem' }}>
                                {isAddon
                                  ? `Upgrade to ${item?.label?.toUpperCase()} `
                                  : item?.label?.toUpperCase()}
                              </Title>
                            </Row>
                            <Divider style={{ margin: '0.8rem 0 0.8rem 0' }} />
                            {isAddon &&
                              item?.meta?.line_items?.map((e) => {
                                return (
                                  <Row style={{ marginTop: '0.4rem' }}>
                                    <Col style={{ marginRight: '0.5rem' }}>
                                      <CheckCircleFilled
                                        style={{
                                          color: `${
                                            cardSelected === 'plan'
                                              ? '#0B7764'
                                              : '#E6A145'
                                          }`,
                                        }}
                                      />
                                    </Col>
                                    <Col>
                                      <Text className="module-upgrade-subtext">
                                        {e}
                                      </Text>
                                    </Col>
                                  </Row>
                                );
                              })}
                            {!isAddon && (
                              <>
                                <Title level={5} align="center">
                                  ₹ {numberWithCommas(item?.amount)}
                                </Title>
                                <Row align="center">
                                  <Text>per year</Text>
                                </Row>
                                <br />
                                <Button
                                  onClick={() => handleSendRequest(item?.id)}>
                                  Send Request
                                </Button>
                              </>
                            )}
                          </Card>
                        </Badge>
                      );
                    })}
                  </Col>
                )}
                {availablePlanData?.length > 0 && isAddon && (
                  <Col style={{ width: '45%' }}>
                    <div style={{ height: '100%' }}>
                      <Badge
                        count={'Recommended'}
                        offset={[-121, 2]}
                        color="linear-gradient(92.76deg, #694CBB -4.7%, #FFA42B 150.67%)"
                        className="upgrade-module-badge">
                        <Card
                          className="upgrade-plan-card"
                          style={{
                            cursor: 'pointer',
                            background: `${
                              cardSelected === 'plan' ? '#F0F8F6' : 'white'
                            }`,
                            border: `${
                              cardSelected === 'plan'
                                ? '1px solid #0B7764'
                                : '1px solid #CDCFCE'
                            }`,
                            borderRadius: '0.5rem',
                            minHeight: '19rem',

                            // height: "auto",
                          }}
                          actions={[
                            <Button
                              size="large"
                              onClick={() => setCardSelected('plan')}
                              style={{
                                background: `${
                                  cardSelected === 'plan' ? '#F0F8F6' : 'white'
                                }`,
                                color: `${
                                  cardSelected === 'plan' ? '#0B7764' : 'black'
                                }`,
                                border: `${
                                  cardSelected === 'plan'
                                    ? '1px solid #6DADA2'
                                    : '1px solid #CDCFCE'
                                }`,
                              }}>
                              {cardSelected === 'plan' ? (
                                <CheckCircleFilled />
                              ) : (
                                <></>
                              )}
                              {cardSelected === 'plan' ? 'Selected' : 'Select'}{' '}
                              plan
                            </Button>,
                          ]}
                          onClick={() => setCardSelected('plan')}>
                          <Row align="center">
                            <Paragraph
                              align="center"
                              className="plan-title-label">
                              Upgrade to{' '}
                              {availablePlanData[0]?.label?.toUpperCase()}{' '}
                            </Paragraph>
                          </Row>
                          <Divider style={{ margin: '0.8rem 0 1rem 0' }} />
                          {availablePlanData[0]?.meta?.benefits?.map((e) => {
                            return (
                              <Row style={{ marginTop: '0.4rem' }}>
                                <Col style={{ marginRight: '0.5rem' }}>
                                  <CheckCircleFilled
                                    style={{
                                      color: `${
                                        cardSelected === 'plan'
                                          ? '#0B7764'
                                          : '#E6A145'
                                      }`,
                                    }}
                                  />
                                </Col>
                                <Col style={{ width: '90%' }}>
                                  <Text className="module-upgrade-subtext">
                                    {e}
                                  </Text>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card>
                      </Badge>
                    </div>
                  </Col>
                )}
              </Row>

              {/* footer */}
              {isAddon ? (
                <>
                  <br />
                  <br />
                  <Row justify="space-between">
                    <Col>
                      <Button
                        size="large"
                        style={{ width: '100%' }}
                        onClick={() => setIsModalVisible(false)}>
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        size="large"
                        disabled={
                          availablePlanData?.length > 0 && !cardSelected
                        }
                        onClick={handleSendRequest}
                        style={{
                          background:
                            cardSelected && availablePlanData?.length > 0
                              ? '#1D2624'
                              : isAddon &&
                                availablePlanData.length === 0 &&
                                '#1D2624',
                          color:
                            cardSelected && availablePlanData?.length > 0
                              ? 'white'
                              : isAddon &&
                                availablePlanData.length === 0 &&
                                'white',
                          border:
                            cardSelected && availablePlanData?.length > 0
                              ? 'none'
                              : isAddon &&
                                availablePlanData.length === 0 &&
                                'none',
                        }}>
                        Send request to{' '}
                        {cardSelected === 'plan' ? 'upgrade' : 'buy'}
                      </Button>
                    </Col>
                  </Row>{' '}
                </>
              ) : (
                <HelpDesk forExpiredModal={true} />
              )}
            </>
          )}
        </>
      </Modal>
      <UpgradeRequestModal
        requestPayload={payloadData}
        upgradeRequestModal={upgradeRequestModal}
        setUpgradeRequestModal={setUpgradeRequestModal}
      />
    </>
  );
};
