import React from 'react';
import axios from 'axios';
import { DatePicker, Form, Input, Select, Skeleton } from 'antd';
import PhoneNumber from './PhoneNumber';
import useSelect from 'react-common-components/hooks/useSelectData';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useCustomFetch } from '../hooks/useFetch';
import SelectAdd from './SelectorAdd';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { preventMinus } from 'react-common-components/utils';
import ConfigurableSelect from './ConfigureableSelect';

const { Dragger } = Upload;
const { Option } = Select;

function Factory({ field, rest, axiosInstance, facetsData, addId }) {
  const [data, loading] = useSelect(field?.datasource, field?.url);
  const options = [];
  let name = '';
  switch (field?.type) {
    case 'secondary_category':
      return <SelectAdd field={field} addId={addId} rest={rest} />;

    case 'select_add':
      return <SelectAdd field={field} addId={addId} rest={rest} />;

    case 'text':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
            },
          ]}>
          <Input placeholder={field?.placeholder} size="large" />
        </Form.Item>
      );
    case 'email':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          validateTrigger="onBlur"
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
              type: field?.type,
            },
          ]}>
          <Input placeholder={field?.placeholder} size="large" />
        </Form.Item>
      );

    case 'textarea':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
            },
          ]}>
          <Input.TextArea
            rows={5}
            placeholder={field?.placeholder}
            size="large"
          />
        </Form.Item>
      );
    case 'phone':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return <PhoneNumber name={name} field={field} rest={rest} />;
    case 'date':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
            },
          ]}>
          <DatePicker
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
            placeholder={field?.placeholder}
            size="large"
          />
        </Form.Item>
      );
    case 'select_buyer_form':
      if (field.facets) {
        if (field.facets in facetsData) {
          for (const property in facetsData[field.facets]) {
            options.push({ label: property, value: property });
          }
        }
        field.options = options;
      }
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
            },
          ]}>
          <Select
            placeholder={field?.placeholder}
            size="large"
            showSearch
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }>
            {loading ? (
              <Skeleton active />
            ) : (
              data?.map((item, index) => (
                <Select.Option key={index} value={item?.value || item}>
                  {item?.label || item}
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
      );
    case 'sub_category':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      if (field.facets) {
        if (field.facets in facetsData) {
          for (const property in facetsData[field.facets]) {
            options.push({ label: property, value: property });
          }
        }
        field.options = options;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
            },
          ]}>
          <Select
            mode="tags"
            placeholder={field?.placeholder}
            size="large"
            showSearch
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            notFoundContent={<> start typing to add ...</>}>
            {field?.options?.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case 'csv':
    case 'select':
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <ConfigurableSelect
          rest={rest}
          field={field}
          facetsData={facetsData}
          name={name}
        />
      );
    case 'size':
      return <Size axiosInstance={axiosInstance} addId={addId} field={field} />;
    case 'price':
      return (
        <Price axiosInstance={axiosInstance} addId={addId} field={field} />
      );
    case 'file':
      field.listType = 'text';
      field.accept =
        '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.zip,.rar';
      return <CustomFileUploader addId={addId} field={field} />;
    case 'product_images':
      field.accept = 'image/*';
      return <CustomFileUploader addId={addId} field={field} />;
    default:
      name = rest ? [rest.name, field.name] : field?.name;
      if (addId) {
        name += `:${field.item_id ? field.item_id : 0}`;
      }
      return (
        <Form.Item
          {...rest}
          name={name}
          label={field?.label}
          rules={[
            {
              required: field?.required,
              message: field?.label + ' is required',
            },
          ]}>
          <Input placeholder={field?.placeholder} size="large" />
        </Form.Item>
      );
  }
}

const UnitFormItem = ({ field, axiosInstance, addId }) => {
  const { isLoading, serverError, apiData } = useCustomFetch(
    axiosInstance,
    field.getURL ? field.getURL : axiosInstance.path.getUOMViaCategory('uom')
  );
  if (isLoading) return 'Loading...';
  if (serverError) return 'server error...';
  if (!apiData) return 'Loading...';
  return <UnitSimpleFormItem field={field} apiData={apiData} addId={addId} />;
};

const UnitSimpleFormItem = ({ field, fieldName, id, apiData, mode }) => {
  const key = field.id;
  const showAreaUnits = fieldName === 'carton_rate_unit' ? true : false;
  return (
    <Form.Item noStyle name={`${key}__${fieldName}__${id}__${field.dtype}`}>
      <Select
        dropdownStyle={{ zIndex: 2001 }}
        disabled={mode === 'view'}
        style={{ width: showAreaUnits ? 90 : 65 }}
        onChange={() => {
          if (field.setChanged) {
            field.setChanged(true);
          }
          if (field.onBlur) {
            field.onBlur(`${key}__${fieldName}__${id}__packaging`);
          }
        }}
        placeholder={`Unit`}>
        {apiData
          ? showAreaUnits
            ? apiData?.Area?.map((val) => (
                <Option value={`${val?.id}--${val?.short_name}`} key={val?.id}>
                  {getUnitShortName(val?.short_name)}
                </Option>
              ))
            : apiData?.Length?.map((val) => (
                <Option value={`${val?.id}--${val?.short_name}`} key={val?.id}>
                  {val?.short_name}
                </Option>
              ))
          : 'No data fetched'}
      </Select>
    </Form.Item>
  );
};

const getUnitShortName = (unit) => {
  switch (unit) {
    case 'sq-yard': {
      return 'sq-yard';
    }
    case 'sq-inch': {
      return 'sq-in';
    }
    case 'sq-cm': {
      return 'sq-cm';
    }
    case 'sq-meter': {
      return 'sq-m';
    }
    case 'sq-feet': {
      return 'sq-ft';
    }
    case 'sq-mm': {
      return 'sq-mm';
    }
    default: {
      return unit;
    }
  }
};

export const packagingForm = (field, id, uomData, mode, showRateOfCarton) => {
  const customStyle = () => {
    if (showRateOfCarton) {
      return { marginTop: '1.5em' };
    }
    return {};
  };
  const key = field.id;
  let description = (
    <Form.Item
      noStyle
      key={`${key}__description`}
      style={{ marginBottom: 0 }}
      label={field?.label}
      name={`${key}__description__${id}__packaging`}>
      <Input
        disabled={mode === 'view'}
        onBlur={() => {
          if (field.onBlur)
            setTimeout(() => {
              field.onBlur(`${key}__description__${id}__packaging`);
            }, 100);
        }}
        onFocus={() => {
          if (field.setChanged) {
            field.setChanged(false);
          }
        }}
        placeholder={field?.placeholder}
      />
    </Form.Item>
  );
  let size = (
    <Input.Group
      compact
      key={`${key}__size`}
      style={{ display: 'flex', width: 180, ...customStyle() }}>
      <Form.Item
        style={{ marginBottom: 0 }}
        noStyle
        name={`${key}__length__${id}__packaging`}>
        <Input
          min={0}
          onKeyDown={preventMinus}
          type="number"
          disabled={mode === 'view'}
          className="size_attribute_input"
          style={{ borderRadius: '4px 0 0 4px' }}
          placeholder={`Length`}
          onFocus={() => {
            if (field.setChanged) {
              field.setChanged(false);
            }
          }}
          onChange={() => {
            if (field.setChanged) {
              field.setChanged(true);
            }
          }}
          onBlur={() => {
            if (field.onBlur)
              setTimeout(() => {
                field.onBlur(`${key}__length__${id}__packaging`);
              }, 100);
          }}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Form.Item>

      <Form.Item
        noStyle
        style={{ marginBottom: 0 }}
        name={`${key}__width__${id}__packaging`}>
        <Input
          min={0}
          onKeyDown={preventMinus}
          disabled={mode === 'view'}
          className="size_attribute_input"
          style={{ borderRadius: '0' }}
          type="number"
          placeholder={`Breadth`}
          onFocus={() => {
            if (field.setChanged) {
              field.setChanged(false);
            }
          }}
          onChange={() => {
            if (field.setChanged) {
              field.setChanged(true);
            }
          }}
          onWheel={(e) => e.currentTarget.blur()}
          onBlur={() => {
            if (field.onBlur)
              setTimeout(() => {
                field.onBlur(`${key}__width__${id}__packaging`);
              }, 100);
          }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        style={{ marginBottom: 0 }}
        name={`${key}__height__${id}__packaging`}>
        <Input
          disabled={mode === 'view'}
          type="number"
          min={0}
          onKeyDown={preventMinus}
          className="size_height"
          placeholder={`Height`}
          onFocus={() => {
            if (field.setChanged) {
              field.setChanged(false);
            }
          }}
          onChange={() => {
            if (field.setChanged) {
              field.setChanged(true);
            }
          }}
          onBlur={() => {
            if (field.onBlur)
              setTimeout(() => {
                field.onBlur(`${key}__height__${id}__packaging`);
              }, 100);
          }}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Form.Item>
      <UnitSimpleFormItem
        field={field}
        fieldName={'unit'}
        mode={mode}
        apiData={uomData}
        id={id}
      />
    </Input.Group>
  );
  let unit = null;
  let pieces = (
    <Form.Item
      noStyle
      key={`${key}__no_of_pieces`}
      style={{ marginBottom: 0 }}
      label={field?.label}
      name={`${key}__no_of_pieces__${id}__packaging`}>
      <Input
        disabled={mode === 'view'}
        min={0}
        style={{ ...customStyle() }}
        onKeyDown={preventMinus}
        type="number"
        onFocus={() => {
          if (field.setChanged) {
            field.setChanged(false);
          }
        }}
        onBlur={() => {
          if (field.onBlur)
            setTimeout(() => {
              field.onBlur(`${key}__no_of_pieces__${id}__packaging`);
            }, 100);
        }}
        placeholder="Enter the Pcs"
      />
    </Form.Item>
  );
  let cost = (
    <Form.Item
      noStyle
      style={{ marginBottom: 0 }}
      key={`${key}__cost_of_carton`}
      label={field?.label}
      name={`${key}__cost_of_carton__${id}__packaging`}>
      <Input
        readOnly={mode === 'view' || showRateOfCarton}
        min={0}
        disabled={showRateOfCarton}
        onKeyDown={preventMinus}
        style={{ ...customStyle() }}
        type="number"
        onFocus={() => {
          if (field.setChanged) {
            field.setChanged(false);
          }
        }}
        onWheel={(e) => e.currentTarget.blur()}
        onBlur={(e) => {
          if (field.onBlur)
            setTimeout(() => {
              field.onBlur(`${key}__cost_of_carton__${id}__packaging`);
            }, 100);
        }}
        placeholder="Enter the Cost"
      />
    </Form.Item>
  );
  let costPerPcs = (
    <Form.Item
      noStyle
      key={`${key}__cost`}
      label={field?.label}
      name={`${key}__cost__${id}__packaging`}>
      <p></p>
    </Form.Item>
  );
  let CBM = (
    <Form.Item
      noStyle
      style={{ marginBottom: 0 }}
      key={key}
      label={field?.label}
      name={`${key}__CBM__${id}__packaging`}>
      <Input
        readOnly
        min={0}
        disabled
        style={{ ...customStyle() }}
        onFocus={() => {
          if (field.setChanged) {
            field.setChanged(false);
          }
        }}
        type="number"
        placeholder="Enter the CBM"
      />
    </Form.Item>
  );
  let rate_of_carton = (
    <Input.Group
      compact
      key={`${key}__rate_carton`}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        ...customStyle(),
      }}>
      <Form.Item
        noStyle
        style={{ marginBottom: 0 }}
        key={`${key}__rate_of_carton`}
        label={field?.label}
        name={`${key}__rate_of_carton__${id}__packaging`}>
        <Input
          readOnly={mode === 'view'}
          min={0}
          style={{ width: 60 }}
          onKeyDown={preventMinus}
          type="number"
          onFocus={() => {
            if (field.setChanged) {
              field.setChanged(false);
            }
          }}
          onWheel={(e) => e.currentTarget.blur()}
          onBlur={(e) => {
            if (field.onBlur)
              setTimeout(() => {
                field.onBlur(`${key}__rate_of_carton__${id}__packaging`);
              }, 100);
          }}
          placeholder="Enter"
        />
      </Form.Item>
      <UnitSimpleFormItem
        field={field}
        fieldName={'carton_rate_unit'}
        mode={mode}
        apiData={uomData}
        id={id}
        showRateOfCarton={showRateOfCarton}
      />
    </Input.Group>
  );
  return {
    description: description,
    size: size,
    unit: unit,
    no_of_pieces: pieces,
    CBM: CBM,
    cost: cost,
    costPerPcs: costPerPcs,
    rate_of_carton,
  };
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.field === nextProps.field;
};

export default React.memo(Factory, areEqual);
// export default Factory;

const Price = ({ field, axiosInstance, addId }) => {
  const { isLoading, serverError, apiData } = useCustomFetch(
    axiosInstance,
    field.getURL
      ? field.getURL
      : axiosInstance.path.getUOMViaCategory('currency', true)
  );
  const uom = useCustomFetch(
    axiosInstance,
    field.getURL ? field.getURL : axiosInstance.path.getUOMViaCategory('uom')
  );

  if (uom.isLoading) return 'UOM Loading...';
  if (uom.serverError) return 'UOM server error...';
  if (!uom.apiData) return 'UOM Loading...';
  if (isLoading) return 'Loading...';
  if (serverError) return 'server error...';
  if (!apiData) return 'Loading...';
  var units = [];
  var unitOptions = [];
  for (const key in uom.apiData) {
    if (key !== 'Quality') {
      units = [...units, ...uom.apiData[key]];
      const tempUnitOption = (
        <Select.OptGroup key={key} label={key}>
          {uom.apiData[key].map((val) => (
            <Option value={`${val.id}--${val.short_name}`} key={val.id}>
              per {val.short_name}
            </Option>
          ))}
        </Select.OptGroup>
      );
      unitOptions.push(tempUnitOption);
    }
  }
  var defaultCurrency = undefined;
  var defaultUnit = undefined;
  if (_.get(field, 'defaults.currency') === 'base') {
    for (let i = 0; i < apiData.Currency.length; i++) {
      const val = apiData.Currency[i];
      if (val.short_name === 'INR') {
        defaultCurrency = `${val.id}--${val.short_name}`;
      }
    }
  }
  if (_.get(field, 'defaults.unit')) {
    for (let key in uom.apiData) {
      const val = uom.apiData[key];
      for (let i = 0; i < val.length; i++) {
        if (_.get(field, 'defaults.unit') === val[i].short_name) {
          defaultUnit = `${val[i].id}--${val[i].short_name}`;
        }
      }
    }
  }
  let idString = '';
  if (addId) {
    idString = `:${field.item_id ? field.item_id : 0}`;
  }
  return (
    <Form.Item label={field.label} name={`${field.name}__price${idString}`}>
      <Input.Group
        style={{ display: 'flex', justifyContent: 'space-between' }}
        compact>
        <Form.Item noStyle name={`${field.name}__currency${idString}`}>
          <Select
            disabled={field.readOnly ? true : false}
            placeholder="Currency"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {apiData
              ? apiData.Currency?.map((val) => {
                  if (_.get(field, 'defaults.currency') === 'base') {
                    if (val.short_name !== 'INR') return null;
                  }
                  return (
                    <Option value={`${val.id}--${val.short_name}`} key={val.id}>
                      {val.short_name}
                    </Option>
                  );
                })
              : 'No data fetched'}
          </Select>
        </Form.Item>
        <Form.Item name={`${field.name}__amount${idString}`} noStyle>
          <Input
            min={0}
            step="0.01"
            type="number"
            placeholder="Enter the Price"
          />
        </Form.Item>
        <Form.Item noStyle name={`${field.name}__unit${idString}`}>
          <Select
            disabled={field.readOnly ? true : false}
            placeholder="Quantity"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {unitOptions.map((val) => val)}
            {/* {units.map((val) => (
              <Option value={`${val.id}--${val.short_name}`} key={val.id}>
                per {val.short_name} ({val.name})
              </Option>
            ))} */}
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

const Size = ({ field, axiosInstance, addId }) => {
  const { isLoading, serverError, apiData } = useCustomFetch(
    axiosInstance,
    field.getURL ? field.getURL : axiosInstance.path.getUOMViaCategory('uom')
  );
  if (isLoading) return 'Loading...';
  if (serverError) return 'server error...';
  if (!apiData) return 'Loading...';
  let idString = '';
  if (addId) {
    idString = `:${field.item_id ? field.item_id : 0}`;
  }
  return (
    <Form.Item name={`${field.name}__size${idString}`} label={field.label}>
      <Input.Group
        compact
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form.Item noStyle name={`${field.name}__length${idString}`}>
          <Input
            type="number"
            className="size_attribute_input"
            style={{ borderRadius: '4px 0 0 4px' }}
            placeholder={`Length`}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </Form.Item>

        <Form.Item noStyle name={`${field.name}__width${idString}`}>
          <Input
            className="size_attribute_input"
            style={{ borderRadius: '0' }}
            type="number"
            placeholder={`Breadth`}
            onWheel={(e) => e.currentTarget.blur()}
            // style={{
            //   width: "25%",
            // }}
          />
        </Form.Item>
        <Form.Item noStyle name={`${field.name}__height${idString}`}>
          <Input
            type="number"
            className="size_height"
            placeholder={`Height`}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </Form.Item>
        <Form.Item noStyle name={`${field.name}__unit${idString}`}>
          <Select onSelect={(e, x) => {}} placeholder={`Unit`}>
            {apiData
              ? apiData.Length?.map((val) => (
                  <Option value={`${val.id}--${val.short_name}`} key={val.id}>
                    {val.short_name}
                  </Option>
                ))
              : 'No data fetched'}
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

const uploadFile = async (options, fileUploaded) => {
  const { onSuccess, onError, file, onProgress } = options;

  const fmData = new FormData();
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: (event) => {
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  };
  fmData.append('image', file);

  axios
    .post(process.env.REACT_APP_ARTIFACTS, fmData, config)
    .then((response) => {
      if (response.status === 200) {
        if (fileUploaded) fileUploaded(response.data);
        console.log('response', response?.data?.data);
        file.swID = response.data.data.id;
        onSuccess('File Uploaded Successfully', file);
      }
    })
    .catch((err) => {
      onError({ err });
    });
};

const CustomFileUploader = ({ field, addId }) => {
  var listType = 'text';
  var accept = undefined;
  if (field.listType) listType = field.listType;
  if (field.accept) accept = field.accept;
  if (!field.fileList) field.fileList = [];
  let idString = '';
  if (addId) {
    idString = `:${field.item_id ? field.item_id : 0}`;
  }
  const props = {
    name: 'image',
    headers: { 'content-type': 'multipart/form-data' },
    multiple: true,
    action: `${process.env.REACT_APP_ARTIFACTS}`,
    accept: accept,
    listType: listType,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload(file, fileList) {},
  };
  return (
    <>
      <Form.Item
        rules={[
          {
            required: field?.required,
            message: field?.label + ' is required',
          },
        ]}
        label={field.label}
        name={`${field.name}${idString}`}>
        <Dragger
          defaultFileList={field.fileList}
          {...props}
          customRequest={(options) => uploadFile(options)}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">File uploader for {field.label}</p>
        </Dragger>
      </Form.Item>
    </>
  );
};
