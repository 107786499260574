export const getAddProductPayload = (checkedProducts, newProduct) => {
  const existingProducts = checkedProducts?.map((product) =>
    parseInt(product?.id)
  );
  const tobeAdded = newProduct;
  var newProducts = [];
  if (existingProducts?.includes(parseInt(tobeAdded?.id))) {
    let current = checkedProducts?.find(
      (item) => item?.id?.toString() === tobeAdded?.id?.toString()
    );
    current.meta.selected_variants = tobeAdded?.meta?.selected_variants;
    newProducts = checkedProducts;
  } else {
    newProduct.id = newProduct?.id?.toString();
    newProducts = [...checkedProducts, newProduct];
  }
  return newProducts;
};

export const getAddMultipleProductsPayload = (checkedProducts, products) => {
  const existingProducts = checkedProducts?.map((product) =>
    parseInt(product?.id)
  );
  const tobeAdded = products;
  const nonExistingProducts = tobeAdded?.filter(
    (product) => !existingProducts.includes(parseInt(product?.id))
  );

  const updatedProductsId = tobeAdded
    ?.filter((item) => existingProducts?.includes(parseInt(item?.id)))
    ?.map((element) => element?.id?.toString());

  if (updatedProductsId?.length) {
    checkedProducts
      ?.filter((item) => updatedProductsId?.includes(item?.id))
      ?.forEach((product) => {
        const updatedSelection = tobeAdded?.find(
          (item) => item?.id?.toString() === product?.id
        );
        product.meta.selected_variants =
          updatedSelection?.meta?.selected_variants;
      });
  }
  const newProducts = [...checkedProducts, ...nonExistingProducts];
  return newProducts;
};

//reducer payload function for removing a single quotation product from selection
export const getRemoveProductPayload = (existingProducts, product) => {
  const tobeRemoved = parseInt(product?.id);
  const newProducts = existingProducts.filter(
    (pro) => parseInt(pro?.id) !== tobeRemoved
  );
  return newProducts;
};

//reducer payload function for removing multiple quotation product from selection
export const getRemoveMultipleProductsPayload = (
  existingProducts,
  products
) => {
  const tobeRemoved = products?.map((product) => parseInt(product?.id));
  const newProducts = existingProducts?.filter(
    (product) => !tobeRemoved.includes(parseInt(product?.id))
  );
  return newProducts;
};
