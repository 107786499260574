import React, { useState, useEffect } from 'react';
import BRDashboard from './BRDashboard';
import Dashboard from './Dashboard';
import API_KEY from 'api';
import TermsAndConditions from 'components/TermsAndConditions';
import WhatsappAcko from 'components/WhatsappAcknowledgement';
import VerifySuccess from 'components/VerifySuccess';
import { FormattedMessage, useIntl } from 'react-intl';

const BASE_URL = process.env.REACT_APP_API_SERVICE;
const user = localStorage.getItem('user');
const token = JSON.parse(user)?.access_token;

export default function SZDashboard() {
  const pref = localStorage.getItem('pref');
  const showBRDashboard = JSON.parse(pref);
  const isAdmin = localStorage.getItem('isAdmin');
  const [showTnC, setShowTnC] = useState(false);
  const [policyStatus, setPolicyStatus] = useState(null);
  const [showWhatsappAck, setShowWhatsappAck] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const isAdminFlag = JSON.parse(isAdmin);

  const handleNext = () => {
    setShowTnC(false);

    API_KEY.URL.post(
      `${BASE_URL}policy/accept/${
        policyStatus?.find((item) => item.type === 'agreement').id
      }`,
      {},
      {
        headers: {
          'access-token': token,
        },
      }
    );

    if (policyStatus?.find((item) => item.type === 'whatsapp_notification'))
      setShowWhatsappAck(true);
  };

  const handleSubmit = () => {
    setShowWhatsappAck(false);

    API_KEY.URL.post(
      `${BASE_URL}policy/accept/${
        policyStatus?.find((item) => item.type === 'whatsapp_notification').id
      }`,
      {},
      {
        headers: {
          'access-token': token,
        },
      }
    );

    setShowSuccess(true);
  };

  const whatsappReminder = () => {
    const dismissDate = Number(localStorage.getItem('dismissDate'));
    const currentDate = new Date().getTime();
    const daysDiff = Math.floor((currentDate - dismissDate) / 86400000);
    if (daysDiff === 6) return true;
    else return false;
  };

  const handleDismiss = () => {
    setShowWhatsappAck(false);

    const currentDate = new Date().getTime();
    localStorage.setItem('dismissDate', currentDate.toString());
  };

  useEffect(() => {
    API_KEY.URL.get(`${BASE_URL}policy/pending`, {
      headers: {
        'access-token': token,
      },
    }).then((res) => {
      setPolicyStatus(res.data);
      if (res.data.find((item) => item.type === 'agreement')) {
        setShowTnC(true);
        return;
      }

      if (res.data.find((item) => item.type === 'whatsapp_notification')) {
        if (localStorage.getItem('dismissDate')) {
          if (whatsappReminder()) setShowWhatsappAck(true);
        } else {
          setShowWhatsappAck(true);
        }
      }
    });
  }, []);

  return (
    <div>
      <TermsAndConditions visible={showTnC} onNext={handleNext} />
      <VerifySuccess
        show={showSuccess}
        onCancel={() => setShowSuccess(false)}
      />
      <WhatsappAcko
        visible={showWhatsappAck}
        verifySuccess={handleSubmit}
        dismissWhatsapp={handleDismiss}
      />

      <Dashboard />
    </div>
  );
}
