import AttributeSettingActions from 'redux/actions/attributeActions';
const { AttributeActions } = AttributeSettingActions;

const initialState = {
  modalState: false,
  modalType: '',
  attributeSectionData: [],
  deleteAttribute: {},
  fetchData: false,
  allAttributesData: [],
  selectedOption: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AttributeActions.TOGGLE_MODAL: {
      const { modalState, modalType } = action.payload;
      return {
        ...state,
        modalState: modalState,
        modalType: modalType,
      };
    }

    case AttributeActions.GET_ALL_ATTRIBUTE_DATA: {
      return {
        ...state,
        allAttributesData: action.payload,
      };
    }

    case AttributeActions.GET_ATTRIBUTE_SECTION_DATA: {
      return {
        ...state,
        attributeSectionData: action.payload,
      };
    }
    case AttributeActions.DELETE_ATTRIBUTE: {
      let data = { id: action.payload };
      return {
        ...state,
        deleteAttribute: data,
      };
    }
    case AttributeActions.FETCH_DATA: {
      return {
        ...state,
        fetchData: action.payload,
      };
    }
    case AttributeActions.TOGGLE_RADIOBUTTON: {
      return {
        ...state,
        selectedOption: action.payload,
      };
    }
    default:
      return state;
  }
}
