import DocumentActions from 'redux/actions/documentActions';
import DOCUMENT_CONSTANTS from 'constants/document';
import { getUnitString } from 'routes/SourceWiz/DocumentManagement/utils';
import { currencyHexCoder } from 'routes/SourceWiz/DocumentManagement/utils';
const { ACTION_TYPES } = DocumentActions;

const initialState = {
  documentTable: {},
  total_products: 0,
  document_amounts: {},
  total_amount: 0,
  taxed_total: 0,
  product_count: {},
  taxes: {},
  tax_dict: {},
  tableSaving: false,
  priceUnits: {},
  facetsData: {},
  baseCurrency: DOCUMENT_CONSTANTS.defaultCurrency,
  remarks: '',
  currencyData: {},
  documentDetailsLoading: {
    consignee: true,
    contact: true,
    bankDetails: true,
    exporter: true,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_TOTAL: {
      const { key, value } = action.payload;
      const productAmountState = {
        ...state,
        document_amounts: { ...state.document_amounts, [key]: value },
      };
      const calculatedTotal = Object.values(
        productAmountState.document_amounts
      ).reduce((agg, item) => {
        return agg + parseFloat(item);
      }, 0);

      const fixedTotal = parseFloat((calculatedTotal || 0).toFixed(2));
      return {
        ...productAmountState,
        total_amount: fixedTotal,
      };
    }

    case ACTION_TYPES.UPDATE_PRODUCT_COUNT: {
      const { removeKey, key, value } = action.payload;

      //if product is removed.
      if (removeKey) {
        const removedProductState = {
          ...state,
          product_count: Object.keys(state.product_count)
            .filter((item) => item !== key)
            .reduce((agg, item) => {
              return { ...agg, [item]: state.product_count[item] };
            }, {}),
        };
        return {
          ...removedProductState,
          total_products: Object.values(
            removedProductState.product_count
          ).reduce((agg, item) => {
            return agg + parseFloat(item);
          }, 0),
        };
      }

      const newState = {
        ...state,
        product_count: { ...state.product_count, [key]: value || 0 },
      };

      const productUnits = newState.priceUnits;
      const productCounts = newState.product_count;

      const unitCountMap = Object.keys(productCounts).reduce((agg, item) => {
        return {
          ...agg,
          [productUnits[item]]: agg[productUnits[item]]
            ? parseFloat(
                parseFloat(agg[productUnits[item]]) +
                  parseFloat(productCounts[item])
              ).toFixed(2)
            : parseFloat(parseFloat(productCounts[item]).toFixed(2)),
        };
      }, {});

      const totalCountString = Object.keys(unitCountMap).reduce((agg, item) => {
        return [
          ...agg,
          `${parseFloat(unitCountMap[item])} ${getUnitString(item)}`,
        ];
      }, []);
      return {
        ...newState,
        total_products: totalCountString.join(', '),
      };
    }

    case ACTION_TYPES.SET_TAX_LIST: {
      return {
        ...state,
        tax_list: action.payload,
        tax_dict: action.payload.reduce((agg, item) => {
          return { ...agg, [item.uid]: item };
        }, {}),
      };
    }

    case ACTION_TYPES.UPDATE_DOCUMENT_META_COUNT: {
      const { id } = action.payload;

      const copiedIDs = Object.assign({}, state.meta.product_ids);
      delete copiedIDs[id];
      return {
        ...state,
        meta: { ...state.meta, product_ids: copiedIDs },
      };
    }

    case ACTION_TYPES.UPDATE_TAX_PROPERTY: {
      const { property, value, key } = action.payload;

      const updatedTax = {
        ...state,
        tax_dict: {
          ...state.tax_dict,
          [key]: { ...state.tax_dict[key], [property]: value },
        },
      };

      const currentTax = updatedTax.tax_dict[key];
      const currentTaxType = currentTax.type;
      const currentTotal = updatedTax.total_amount;
      const newTaxAmount =
        currentTaxType === 'percent'
          ? (parseFloat(currentTax.value) * currentTotal * 0.01).toFixed(2)
          : parseFloat(currentTax.value).toFixed(2);

      const updatedTaxState = {
        ...updatedTax,
        tax_dict: {
          ...updatedTax.tax_dict,
          [key]: { ...updatedTax.tax_dict[key], tax_amount: newTaxAmount },
        },
      };
      return {
        ...updatedTaxState,
        taxed_total: (
          updatedTaxState.total_amount +
          Object.values(updatedTaxState.tax_dict).reduce((agg, item) => {
            return agg + parseFloat(item.tax_amount);
          }, 0)
        ).toFixed(2),
      };
    }

    case ACTION_TYPES.UPDATE_TAX_AMOUNT: {
      const { value, uid } = action.payload;
      const currentTax = state.tax_dict[uid];
      const currentTaxType = currentTax.type;
      const currentTotal = state.total_amount;
      const newTaxAmount =
        currentTaxType === 'percent'
          ? (parseFloat(value) * currentTotal * 0.01).toFixed(2)
          : parseFloat(value).toFixed(2);

      const updatedTaxState = {
        ...state,
        tax_dict: {
          ...state.tax_dict,
          [uid]: {
            ...state.tax_dict[uid],
            value: value,
            tax_amount: newTaxAmount,
          },
        },
      };
      return {
        ...updatedTaxState,
        taxed_total: (
          updatedTaxState.total_amount +
          Object.values(updatedTaxState.tax_dict).reduce((agg, item) => {
            return agg + parseFloat(item.tax_amount);
          }, 0)
        ).toFixed(2),
      };
    }

    case ACTION_TYPES.NEW_TAX_ITEM: {
      const { key, item } = action.payload;
      return {
        ...state,
        tax_dict: {
          ...state.tax_dict,
          [key]: item,
        },
      };
    }
    case ACTION_TYPES.DELETE_TAX: {
      const { uid } = action.payload;
      const { [uid]: oldID, ...rest } = state?.tax_dict || {};
      return {
        ...state,
        tax_dict: rest,
        tax_list: state.tax_list.filter((item) => item.uid !== uid),
      };
    }
    case ACTION_TYPES.TOGGLE_IS_TABLE_SAVING: {
      const { isSaving } = action.payload;
      return {
        ...state,
        tableSaving: isSaving || false,
      };
    }

    case ACTION_TYPES.SET_FACETS_DATA: {
      return {
        ...state,
        facetsData: action.payload,
      };
    }
    case ACTION_TYPES.SET_BASE_CURRENCY: {
      return {
        ...state,
        baseCurrency: action.payload,
      };
    }
    case ACTION_TYPES.RESET_STATE: {
      return initialState;
    }

    case ACTION_TYPES.UPDATE_DOCUMENT: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ACTION_TYPES.SET_TAX_VALUE: {
      const { uid, tax_value, tax_value_id, taxType, isFetched } =
        action.payload;
      const currentTax = state.tax_dict[uid];

      const currentTaxType = currentTax.type;
      const currentTotal = state.total_amount;
      const taxAmount = !tax_value
        ? 0
        : currentTaxType === 'percent'
        ? (parseFloat(tax_value) * currentTotal * 0.01).toFixed(2)
        : parseFloat(tax_value).toFixed(2);

      const newState = {
        ...state,
        tax_dict: {
          ...state.tax_dict,
          [uid]: {
            ...state.tax_dict[uid],
            value: parseFloat(
              tax_value ? tax_value : state.tax_dict[uid].value
            ),
            tax_value_id: tax_value_id || null,
            type: taxType ? taxType : state.tax_dict[uid].type,
            isFetched: isFetched,
            tax_amount: parseFloat(taxAmount),
          },
        },
      };
      return {
        ...newState,
        tax_list: Object.values(newState.tax_dict),
        taxed_total: (
          newState.total_amount +
          Object.values(newState.tax_dict).reduce((agg, item) => {
            return agg + item.tax_amount;
          }, 0)
        ).toFixed(2),
        // tax_list: newState.tax_dict.reduce((agg, item) => {
        //   return { ...agg, [item.uid]: item };
        // }, {}),
      };
    }

    case ACTION_TYPES.RECOMPUTE_TAXES: {
      const currentTotal = state.total_amount;
      const taxDict = state.tax_dict;
      const newTaxDict = Object.keys(taxDict).reduce((agg, item) => {
        const tax = !taxDict[item]?.value
          ? 0
          : taxDict[item]?.type === 'percent'
          ? (parseFloat(taxDict[item]?.value) * currentTotal * 0.01).toFixed(2)
          : parseFloat(taxDict[item]?.value).toFixed(2);
        return { ...agg, [item]: { ...taxDict[item], tax_amount: tax } };
      }, {});
      return {
        ...state,
        tax_dict: newTaxDict,
      };
    }

    case ACTION_TYPES.RECOMPUTE_TOTAL: {
      return {
        ...state,
        taxed_total:
          state.total_amount +
          Object.values(state.tax_dict).reduce((agg, item) => {
            return (
              agg + parseFloat(parseFloat(item.tax_amount || 0).toFixed(2))
            );
          }, 0),
      };
    }

    case ACTION_TYPES.UPDATE_PRODUCT_LIST: {
      const { id } = action.payload;
      return {
        ...state,
        meta: {
          ...state.meta,
          product_ids: Object.keys(state.meta?.product_ids)
            .filter((item) => item !== id)
            .reduce((agg, item) => {
              return [...agg, state.meta.product_ids[item]];
            }, []),
        },
      };
    }

    case ACTION_TYPES.RECOMPUTE_BASE_TOTAL: {
      const { id } = action.payload;
      const existingAmounts = state.document_amounts;
      const existingUnits = state.priceUnits;
      const productCounts = state.product_count;

      const updatedAmounts = Object.keys(existingAmounts)
        .filter((item) => item !== id)
        .reduce((agg, item) => {
          return { ...agg, [item]: existingAmounts[item] };
        }, {});

      const updatedUnits = Object.keys(existingUnits)
        .filter((item) => item !== id)
        .reduce((agg, item) => {
          return { ...agg, [item]: existingUnits[item] };
        }, {});

      const updatedProductCount = Object.keys(productCounts)
        .filter((item) => item !== id)
        .reduce((agg, item) => {
          return { ...agg, [item]: productCounts[item] };
        }, {});

      const unitCountMap = Object.keys(updatedProductCount).reduce(
        (agg, item) => {
          return {
            ...agg,
            [updatedUnits[item]]: agg[updatedUnits[item]]
              ? parseFloat(
                  parseFloat(agg[updatedUnits[item]]) +
                    parseFloat(updatedProductCount[item])
                ).toFixed(2)
              : parseFloat(parseFloat(updatedProductCount[item]).toFixed(2)),
          };
        },
        {}
      );

      const totalCountString = Object.keys(unitCountMap).reduce((agg, item) => {
        return [
          ...agg,
          `${parseFloat(unitCountMap[item])} ${getUnitString(item)}`,
        ];
      }, []);

      return {
        ...state,
        document_amounts: updatedAmounts,
        product_count: updatedProductCount,
        total_amount: Object.values(updatedAmounts).reduce((agg, item) => {
          return agg + parseFloat(item);
        }, 0),
        total_products: totalCountString.join(', '),
      };
    }

    case ACTION_TYPES.SET_CURRENCY_DATA: {
      return {
        ...state,
        currencyData: action.payload,
      };
    }

    case ACTION_TYPES.SET_DATASOURCE: {
      return {
        ...state,
        dataSource: action.payload,
      };
    }

    case ACTION_TYPES.SET_DOCUMENT_DETAILS_LOADING: {
      const { key, value } = action.payload;
      return {
        ...state,
        documentDetailsLoading: {
          ...state.documentDetailsLoading,
          [key]: value,
        },
      };
    }
    case ACTION_TYPES.SET_ROW_UNIT: {
      const { key, value } = action.payload;
      return { ...state, priceUnits: { ...state.priceUnits, [key]: value } };
    }

    case ACTION_TYPES.SET_ROW_UNIT_UPDATE_COUNT: {
      //updates unit for a row, and calculates new separated quantity by unit
      const { key, value } = action.payload;
      const newState = {
        ...state,
        priceUnits: { ...state.priceUnits, [key]: value },
      };
      const productUnits = newState.priceUnits;
      const productCounts = newState.product_count;

      const unitCountMap = Object.keys(productCounts).reduce((agg, item) => {
        return {
          ...agg,
          [productUnits[item]]: agg[productUnits[item]]
            ? parseFloat(
                parseFloat(agg[productUnits[item]]) +
                  parseFloat(productCounts[item])
              ).toFixed(2)
            : parseFloat(parseFloat(productCounts[item])).toFixed(2),
        };
      }, {});

      const totalCountString = Object.keys(unitCountMap).reduce((agg, item) => {
        return [
          ...agg,
          `${parseFloat(unitCountMap[item])} ${getUnitString(item)}`,
        ];
      }, []);
      return {
        ...newState,
        total_products: totalCountString.join(', '),
      };
    }

    default:
      return state;
  }
}
