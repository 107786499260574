import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  message,
  Row,
  Typography,
  Checkbox,
  Modal,
  notification,
} from 'antd';
import API_KEY from 'api';
import eyeView from '../../../../../assets/images/eyeViewOriginal.svg';
import qrTick from '../../../../../assets/images/qrTick.svg';
import greenArrow from '../../../../../assets/images/greenArrow.svg';
import { useTracking } from 'react-tracking';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PYTHON_KEY from 'api/pythonService';
import { DOWNLOAD_QR_CODE } from 'analytics/events';
import './qrLabel.less';
import { getAccountDetails } from 'api/customApis';
import { Skeleton } from 'antd';
import { filterAttribute } from 'react-common-components/utils';
import { QRTrialReminder } from 'routes/SourceWiz/Guardrails/Components/QRLabel/QRTrialReminder';
import { useGlobal } from '../../../../../store/index';

const userData = localStorage.getItem('user');
const user = userData && JSON.parse(userData);

export default function QrLabelListing({
  qrData,
  id,
  setAttributeEnable,
  attributeEnable,
  setShow,
  show,
  isProfile,
  getQRData,
  selectedItem,
  loading,
  setLoading,
  setSelectedItem,
  defaultSelectedTemplates,
  bulk,
  attributesData,
  qrDataGuardrails,
  setShowQRDownloadedModal,
}) {
  const { Text } = Typography;
  const intl = useIntl();
  const { trackEvent } = useTracking();
  const [globalState, globalActions] = useGlobal();
  const { selectedtemp } = globalState;
  const location = useLocation();
  const [downloading, setDownloading] = useState(false);
  const [active, setActive] = useState('');
  const [defaultStat, setDefaultStat] = useState(false);
  const [labelPreview, setLabelPreview] = useState(false);
  const [itemData, setItemData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [count, setCount] = useState(0);

  const [trialReminderModal, setTrialReminderModal] = useState(false);

  useEffect(() => {
    setSelectedItem(selectedtemp);
    setActive(selectedtemp?.id);
    if (selectedtemp?.max_attributes_allowed > 0) {
      if (selectedtemp?.id === active) {
        setAttributeEnable(true);
      } else {
        setAttributeEnable(true);
      }
    } else {
      setAttributeEnable(false);
    }
  }, [selectedtemp]);

  useEffect(() => {
    retrieveData();
  }, [tableData, defaultSelectedTemplates, selectedItem]);

  const retrieveData = () => {
    if (tableData) {
      const mappedData = tableData?.map((val) => {
        return { ...val, visible: selectedItem?.attributes?.includes(val?.id) };
      });
      setData(mappedData);
      const visibleAttrsCount = mappedData?.filter(
        (item) => item?.visible
      )?.length;
      setCount(visibleAttrsCount);
    }
  };

  useEffect(() => {
    if (attributesData?.length > 0) {
      const filteredAttrs = attributesData.filter(
        (val) => val.id && val.is_internal == false
      );
      setTableData(filteredAttrs);
    }
  }, [attributesData]);

  const handleDone = (data) => {
    let qr_code_data = data
      .filter((val) => val.visible)
      .map((e) => ({ id: e.id, name: e.name }));
    let recommendation_preferences = null;

    API_KEY.URL.get(`${API_KEY.path.manufacturer}`, {
      params: {
        fields: 'recommendation_preferences',
      },
    })
      .then((res) => {
        recommendation_preferences =
          res?.data?.data[0]?.recommendation_preferences || {};
        recommendation_preferences.qr_code_data = qr_code_data;

        let manufacturer_id;
        getAccountDetails()
          .then((res) => {
            manufacturer_id = res?.data?.data.org;
          })
          .then((res) => {
            API_KEY.URL.patch(
              `${API_KEY.path.manufacturer}/${manufacturer_id}`,
              { recommendation_preferences: recommendation_preferences }
            );
          })
          .then((res) => {
            message.success(
              `${intl.formatMessage({
                id: 'sourcewiz.profileSettings.companyDetails.attrUpdateQR',
              })}`
            );
          });
      })
      .catch((error) => {
        message.error(
          `${intl.formatMessage({
            id: 'sourcewiz.profileSettings.companyDetails.attrUpdateFailed',
          })}`
        );
        console.log(error);
      });
  };

  const handleSelect = (item) => {
    setSelectedItem && setSelectedItem(item);
    trackEvent({
      name: isProfile
        ? 'label_select_profile_drawerview'
        : 'label_select_product_page',
      meta: {
        user_id: localStorage.getItem('userId'),
        name: item?.name,
        selected: item,
        modules: 'Label generator',
        property: 'label select',
      },
    });
    if (item.id === active && !isProfile) {
      // setActive(0);
      // setItemData(item);
      // setAttributeEnable(false);
    } else {
      setActive(item.id);
      setItemData(item ? item : {});
      globalActions.qrEnable.setSelectedTemp(item);
      if (item.max_attributes_allowed > 0) {
        if (item.id === active) {
          setAttributeEnable(true);
        } else {
          setAttributeEnable(true);
        }
      } else {
        setAttributeEnable(false);
      }
    }
  };

  const toggleCheckedBox = () => {
    trackEvent({
      name: 'set_as_default_label',
      meta: {
        user_id: localStorage.getItem('userId'),
        modules: 'Label generator',
        property: 'label select',
      },
    });
    setDefaultStat(!defaultStat);
  };

  const togglePreview = () => {
    setLabelPreview(true);
  };

  const handleOnClick = () => {
    setDownloading(true);
    setLoading(true);

    let productIds;
    if (bulk) {
      productIds =
        Object.keys(id || {}).filter((item, index) => id[item]?.value) || [];
    } else {
      productIds = [id];
    }

    let attrs = data.filter((val) => val.visible).map((e) => e.id);
    if (defaultStat || isProfile) {
      PYTHON_KEY.URL.post(
        `${process.env.REACT_APP_API_SERVICE}qr_code_pdf_service/update_qr_template`,
        {
          template_json: selectedItem ? selectedItem : itemData,
          attributes: selectedItem
            ? selectedItem?.max_attributes_allowed > 0
              ? attrs
              : []
            : itemData?.max_attributes_allowed > 0
            ? attrs
            : [],
        },
        { headers: { 'access-token': user?.access_token } }
      )
        .then((res) => {
          trackEvent({
            name: 'save_label_template',
            meta: {
              user_id: localStorage.getItem('userId'),
              location: location.pathname,
              modules: 'Label generator',
              property: 'label select',
            },
          });
          setShow(false);
          globalActions.qrEnable.setQrLabelEnable(false);
          globalActions.qrEnable.setSelectedTemp({});
          getQRData && getQRData();

          message.success('Updated QR Label template successfully');
          setLoading(false);
        })
        .catch((err) => {
          console.log('err', err);
          setLoading(false);
          setDownloading(false);
        });
    }

    if (!isProfile) {
      PYTHON_KEY.URL.post(
        `${process.env.REACT_APP_API_SERVICE}qr_code_pdf_service`,
        {
          product_ids: productIds,
          template_settings: {
            template_json: selectedItem ? selectedItem : itemData,
            attributes: selectedItem
              ? selectedItem?.max_attributes_allowed > 0
                ? attrs
                : []
              : itemData?.max_attributes_allowed > 0
              ? attrs
              : [],
          },
        },
        { headers: { 'access-token': user?.access_token } }
      )
        .then((res) => {
          setShow(false);
          setDownloading(false);
          globalActions.qrEnable.setQrLabelEnable(false);
          globalActions.qrEnable.setSelectedTemp({});
          setLoading(false);
          setShowQRDownloadedModal(true);
          // getQRData && getQRData();
          if (
            qrDataGuardrails?.plan_type === 'trial' &&
            qrDataGuardrails?.plan_status === 'active'
          ) {
            setTrialReminderModal(true);
          }
          trackEvent({
            name: DOWNLOAD_QR_CODE,
            meta: {
              user_id: localStorage.getItem('userId'),
              location: location.pathname,
              data: res?.data?.data,
              productCount: productIds?.length,
              modules: 'Label generator',
              property: 'label select',
            },
          });
          return res.data.pdf_url;
        })
        .then((res) => {
          setShow(false);
          setDownloading(false);
          setLoading(false);

          window.open(res);
        })
        .catch((err) => {
          setDownloading(false);
          setLoading(false);
          console.log('error :', err);
        });
    }
  };

  const onChangeCheckbox = (e, id) => {
    let newData = data;

    // (itemData?.max_attributes_allowed
    //   ? itemData?.max_attributes_allowed
    //   : selectedItem?.max_attributes_allowed)

    setData(
      newData.map(function (element) {
        if (element.id === id) {
          if (
            count >=
              (itemData?.max_attributes_allowed
                ? itemData?.max_attributes_allowed
                : selectedItem?.max_attributes_allowed) &&
            element.id === id &&
            element.visible === true
          ) {
            setCount(count - 1);
            element.visible = e.target.checked;
          } else if (
            count <
              (itemData?.max_attributes_allowed
                ? itemData?.max_attributes_allowed
                : selectedItem?.max_attributes_allowed) &&
            element.id === id &&
            element.visible === true
          ) {
            setCount(count - 1);
            element.visible = e.target.checked;
          } else if (
            count <
              (itemData?.max_attributes_allowed
                ? itemData?.max_attributes_allowed
                : selectedItem?.max_attributes_allowed) &&
            element.id === id
          ) {
            setCount(count + 1);
            element.visible = e.target.checked;
          } else {
            notification.warning({
              message: `You have selected maximum atributes`,
              closeIcon: <></>,
            });
          }
        }
        return element;
      })
    );

    if (isSelectedAll(newData)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const save = () => {
    handleDone(data);
  };

  const isSelectedAll = (d) => {
    let flag = true;
    d.forEach(function (item) {
      if (item?.is_internal === false && item?.id && item?.visible === false) {
        flag = false;
      }
    });
    return flag;
  };

  const handleSelectAll = (e) => {
    const val = e.target.checked;
    let newData = data;

    newData.forEach(function (item) {
      if (item?.is_internal === false && item?.id) {
        item.visible = val;
      }
    });
    setSelectAll(val);
    setData(newData);
  };

  return (
    <>
      <QRTrialReminder
        data={qrDataGuardrails}
        trialReminderModal={trialReminderModal}
        setTrialReminderModal={setTrialReminderModal}
      />

      <Modal
        //   className="create-enquiry-modal"
        title={null}
        width={'50%'}
        visible={labelPreview}
        onCancel={() => setLabelPreview(false)}
        footer={null}
        closable={false}
        destroyOnClose>
        <Row>
          <Col span={12}>
            <Text className="qrDrawerTextLarge" strong>
              Label preview
            </Text>
            <li className="qrbox_desc_listItemStyle">
              Downloaded label will look like this
            </li>
            <div className="qrModalContent">
              <Col>
                <Text className="qrDrawerTextLarge2">{itemData?.name}</Text>
              </Col>
              <Row>
                <ul>
                  <li className="qrbox_desc_listItemStyle">
                    {itemData?.description}
                  </li>
                  {itemData?.max_attributes_allowed > 0 ? (
                    <li className="qrbox_desc_listItemStyle">
                      Upto {itemData?.max_attributes_allowed} attributes can be
                      added
                    </li>
                  ) : (
                    <li className="qrbox_desc_listItemStyle">
                      no attributes can be added
                    </li>
                  )}
                </ul>
              </Row>
            </div>
            <Button
              style={{ backgroundColor: 'black', color: 'white' }}
              // type="primary"
              htmlType="submit"
              className="modalCloseBtnView"
              onClick={() => setLabelPreview(false)}>
              Okay, got it!
            </Button>
          </Col>

          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#E6E7E7',
            }}>
            <img
              onClick={() => togglePreview()}
              src={`${process.env.REACT_APP_DIRECTUS}assets/${itemData?.preview_image}`}
              style={{ width: '100%', height: '100%' }}
            />
          </Col>
        </Row>
      </Modal>

      <Row span={24} className="qrMain">
        <Col span={attributeEnable ? 11 : 24}>
          <div className="headerQR">
            <Row className="">
              <Text className="qrDrawerTextLarge">Select QR label</Text>
            </Row>
            <Row>
              <Text className="qrDrawerTextMedium">
                Customize attributes you want to show on QR Label
              </Text>
            </Row>
          </div>

          <Text className="qrDrawerTextsmall">
            {qrData?.length} formats available
          </Text>
          <Row className="qrlabel">
            {qrData ? (
              qrData.length > 0 &&
              qrData.map((item) => {
                return (
                  <Row
                    key={item.id}
                    className={`qrbox ${
                      selectedItem
                        ? selectedItem?.id === item.id && 'qrBoxActive'
                        : active === item.id && 'qrBoxActive'
                    }`}
                    onClick={() => {
                      handleSelect(item);
                    }}>
                    <Col className="qrbox_desc" span={16}>
                      <Text className="qrDrawerTextMedium2" strong>
                        {item?.name}
                      </Text>

                      {selectedItem
                        ? selectedItem?.id === item.id && (
                            <img className="qrbox_desc_tick" src={qrTick} />
                          )
                        : active === item.id && (
                            <img className="qrbox_desc_tick" src={qrTick} />
                          )}

                      <li
                        style={{ marginTop: '8px' }}
                        className="qrbox_desc_listItemStyle">
                        {item?.description}
                      </li>
                      {item?.max_attributes_allowed > 0 ? (
                        <li
                          style={{ marginBottom: '8px' }}
                          className="qrbox_desc_listItemStyle">
                          Upto {item?.max_attributes_allowed} attributes can be
                          added
                        </li>
                      ) : (
                        <li className="qrbox_desc_listItemStyle">
                          no attributes can be added
                        </li>
                      )}

                      {selectedItem
                        ? selectedItem?.id === item.id &&
                          (selectedItem?.id || active) === item.id &&
                          item.max_attributes_allowed > 0 && (
                            <Text
                              className="qrDrawerTextGreen"
                              onClick={() => {
                                setActive(item.id);
                                setAttributeEnable(true);
                              }}
                              strong>
                              Change attributes
                              <img
                                alt="qr-code"
                                src={greenArrow}
                                style={{ marginLeft: '5px' }}
                              />
                            </Text>
                          )
                        : active === item.id &&
                          item.max_attributes_allowed > 0 && (
                            <Text
                              className="qrDrawerTextGreen"
                              onClick={() => {
                                setActive(item.id);
                                setAttributeEnable(true);
                              }}
                              strong>
                              Change attributes
                              <img
                                alt="qr-code"
                                src={greenArrow}
                                style={{ marginLeft: '5px' }}
                              />
                            </Text>
                          )}
                    </Col>
                    <Col
                      className={`qrbox_imgDiv ${
                        selectedItem
                          ? selectedItem?.id === item.id && 'qrbox_imgDivActive'
                          : active === item.id && 'qrbox_imgDivActive'
                      }`}
                      span={8}>
                      <img
                        alt="qr-code"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setActive(item.id);
                          setItemData(item ? item : {});
                          togglePreview();
                          trackEvent({
                            name: 'preview_drawer_view',
                            meta: {
                              user_id: localStorage.getItem('userId'),
                              modules: 'Label generator',
                              property: 'label select',
                            },
                          });
                        }}
                        src={eyeView}
                        className="qrbox_imgDiv_eyeView"
                      />
                      <img
                        alt="qr-code"
                        className="qrbox_imgDiv_preView"
                        src={`${process.env.REACT_APP_DIRECTUS}assets/${item?.skeleton_image}`}
                      />
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Skeleton active />
            )}
          </Row>

          {/* </Col> */}
        </Col>

        <Col className="atrributeSectionLayout" span={attributeEnable ? 11 : 0}>
          <Text className="qrDrawerTextLarge">
            You can select upto{' '}
            {itemData?.max_attributes_allowed
              ? itemData?.max_attributes_allowed
              : selectedItem?.max_attributes_allowed}{' '}
            attributes only
          </Text>
          <div style={{ width: '100%' }}>
            <div>
              {data ? (
                <>
                  <Row
                    style={{
                      maxHeight: '49em',
                      overflowY: 'scroll',
                      marginTop: '12px',
                    }}>
                    {data?.filter(filterAttribute)?.map((item, index) => (
                      <Col span={12} key={item?.id} style={{ padding: 5 }}>
                        <Checkbox
                          onChange={(e) => onChangeCheckbox(e, item?.id)}
                          checked={item.visible}>
                          {item?.name}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                  <Row style={{ marginTop: '1.5em' }} justify="start"></Row>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Col>

        <Row
          justify="space-between"
          style={{
            display: 'flex',
            position: 'absolute',
            width: '100%',
            bottom: 0,
            padding: 12,
            background: 'white',
            paddingBottom: 12,
            borderTop: '1px solid lightgray',
          }}>
          <Col span={24}>
            {isProfile ? (
              <Row justify="space-between">
                <Button
                  type="default"
                  size="large"
                  style={{ width: '10em' }}
                  onClick={() => {
                    globalActions.qrEnable.setQrLabelEnable(false);
                    globalActions.qrEnable.setSelectedTemp({});
                  }}>
                  Close
                </Button>
                <Button
                  size="large"
                  className={active ? 'qr-primary-cta' : null}
                  // style={{ minWidth: 120, minHeight: 40 }}
                  onClick={() => handleOnClick()}
                  // htmlType="submit"
                  disabled={!active}
                  loading={loading}>
                  Set as default
                </Button>
              </Row>
            ) : (
              <Row justify="space-between">
                <div
                  onClick={() => toggleCheckedBox()}
                  style={{
                    border: '1px solid lightgray',
                    padding: '4px 12px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '12em',
                  }}>
                  <Checkbox style={{ fill: '#9AC421' }} checked={defaultStat} />
                  <Text
                    style={{
                      marginLeft: '10px',
                      color: '#4F5655',
                      fontWeight: 700,
                      cursor: 'pointer',
                      paddingTop: '5px',
                    }}>
                    Set as default
                  </Text>
                </div>

                <Button
                  className={active ? 'qr-primary-cta' : null}
                  size="large"
                  onClick={() => handleOnClick()}
                  loading={loading}
                  htmlType="submit"
                  style={{ width: '12em' }}
                  disabled={!active}>
                  Download QR
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </Row>
    </>
  );
}
