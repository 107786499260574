import { PYTHON_API_KEY } from './customApis';
import axios from 'axios';
import { getAccessToken } from 'react-common-components/utils';
import { notification } from 'antd';
import qs from 'qs';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const baseURL = process.env.REACT_APP_QUOTATION;

const org = localStorage.getItem('org');
const orgId = JSON.parse(org)?.id;

export const QUOTATION_KEY = {
  URL: axios.create({
    baseURL: baseURL,
    params: {
      access_token: getAccessToken(),
    },
  }),
  path: {
    getAttributes: (type, addBaseURL) => `${baseURL}attribute/${type}`,
    getUOMViaCategory: (category, addBaseURL) => `${baseURL}uom/${category}`,
    addItem: (itemType, addBaseURL) =>
      `${baseURL}quotation/product/products/${itemType}`,
    getItem: (itemId, addBaseURL) =>
      `${baseURL}quotation/product/products/${itemId}`,
    deleteItem: (itemId, addBaseURL) =>
      `${baseURL}quotation/product/products/${itemId}`,
    editItem: (itemType, itemId, addBaseURL) =>
      `${baseURL}quotation/product/products/${itemType}/${itemId}`,
    getPackagingDetails: (type, addBaseURL) =>
      `${baseURL}attribute/product/${type}`,
    getDefaultContainers: (addBaseURL) =>
      `${baseURL}quotation/product/loadability/containers`,
    getProductContainers: (productId, addBaseURL) =>
      `${baseURL}quotation/product/loadability/containers/${productId}`,
    getBOM: (productId, addBaseURL) =>
      `${baseURL}quotation/product/bom/bom/${productId}`,
    getOverheads: (productId, addBaseURL) =>
      `${baseURL}quotation/product/overheads/overheads/${productId}`,
    updateCosting: (productId, addBaseURL) =>
      `${baseURL}quotation/product/products/costing/update/${productId}`,
    getCostingInfo: (productId, addBaseURL) =>
      `${baseURL}quotation/product/products/get_product_costing/${productId}`,
    updateAttributes: (productId, addBaseURL) =>
      `${baseURL}quotation/product/products/attribute_values/${productId}`,
    downloadQrTemplate: `${baseURL}qr_code_pdf_service/`,
    getQrTemplates: `${baseURL}qr_code_pdf_service/get_qr_templates`,
    defaultTemplate: `${baseURL}qr_code_pdf_service/default_template`,
    qrPublic: `${baseURL}qr/public/settings`,
    qrPublicToggle: `${baseURL}qr/public/public_toggle`,
    qrPublicActiveCatalogues: `${baseURL}qr/public/active_catalogues`,
    qrPublicCatalogues: `${baseURL}qr/public/public_catalogues`,
    getImportSteps: `${baseURL}manufacturer/get_settings`,
  },
};

QUOTATION_KEY.URL.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response && error?.response?.status === 401) {
      notification.error({
        message: <span>Session expired</span>,
        description: 'Your session is expired. Please login again',
        style: {
          borderRadius: 8,
        },
      });
      setTimeout(() => {
        clearLocalStorageAndCookie();
        window.location = '/'; // <-- add your path
        // window.location.reload();
        localStorage.setItem('logout-event', 'logout' + Math.random());
      }, 3000);
    } else return Promise.reject(error);
  }
);

export const createEnquiry = (payload) => {
  return PYTHON_API_KEY.URL.post(PYTHON_API_KEY.path.createEnquiries, payload);
};

// Api for creating quotation
export const createQuotation = (payload) => {
  return PYTHON_API_KEY.URL.post(
    `${PYTHON_API_KEY.path.createQuotation}`,
    payload
  );
};

// Api to update product attributes of quotation table
export const updateAttributesQuotation = (payload) => {
  return PYTHON_API_KEY.URL.put(
    `${PYTHON_API_KEY.path.updateAttributes}`,
    payload
  );
};

// Api to delete quotation table product
export const deleteQuotationTableProduct = (payload) => {
  return PYTHON_API_KEY.URL.post(
    `${PYTHON_API_KEY.path.deleteProduct}`,
    payload
  );
};

// Api to update quotation
export const updateQuotationTable = (quotationId, payload) => {
  return PYTHON_API_KEY.URL.patch(
    `${PYTHON_API_KEY.path.updateQuotation}/${quotationId}`,
    payload
  );
};

// Api to download quotation excel
export const downloadQuotationExcel = (quotationId) => {
  return PYTHON_API_KEY.URL.get(PYTHON_API_KEY.path.downloadExcel, {
    params: {
      quotation_id: quotationId,
    },
  });
};

// Api to download quotation pdf
export const downloadQuotationPdf = (quotationId) => {
  return PYTHON_API_KEY.URL.get(PYTHON_API_KEY.path.downloadPdf, {
    params: {
      quotation_id: quotationId,
    },
  });
};

// Api to delete quotation
export const deleteQuotationById = (quotationId) => {
  return PYTHON_API_KEY.URL.patch(
    PYTHON_API_KEY.path.updateQuotation + '/' + quotationId,
    {
      status: 'archived',
    }
  );
};

// APi to copy quotation
export const copyQuotation = (quotationId) => {
  return PYTHON_API_KEY.URL.post(PYTHON_API_KEY.path.copyQuotation, {
    quotation_id: quotationId,
  });
};

// Api for quotation table
export const quotationTable = (quotationId) => {
  return PYTHON_API_KEY.URL.get(PYTHON_API_KEY.path.quotationTable, {
    params: {
      quotation_id: quotationId,
    },
  });
};

// Api for updating draft quotation
export const updateDraftQuotation = (value, quotationId) => {
  return PYTHON_API_KEY.URL.patch(
    PYTHON_API_KEY.path.updateQuotation + '/' + quotationId,
    {
      status: value,
    }
  );
};

// API to update quotation enquiry
export const updateEnquiry = (enquiryId, payload) => {
  return PYTHON_API_KEY.URL.put(
    PYTHON_API_KEY.path.updateEnquiry + '/' + enquiryId,
    payload
  );
};

// Patch api for prospectPost
export const updateProspectsPost = (param, prospectPayload) => {
  return PYTHON_API_KEY.URL.patch(
    PYTHON_API_KEY.path.prospectsPost + '/' + param,
    prospectPayload
  );
};

// Creates Custom Quotation Attributes
export const createCustomAttribute = (payload) => {
  return PYTHON_API_KEY.URL.post(PYTHON_API_KEY.path.attribute(orgId), payload);
};

export const getAttributes = (payload) => {
  return PYTHON_API_KEY.URL.get(PYTHON_API_KEY.path.attribute(orgId), {
    params: payload,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};
