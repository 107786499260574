import React, { useState } from 'react';
import { Row, Col, Button, Typography, Alert, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { RiErrorWarningFill } from 'react-icons/ri';
import OtpInput from 'react-otp-input';

import { Colors } from 'theme';
import HomeSVG from './Home.svg';
import Logo from 'assets/images/logo64.png';
import hooks from 'hooks';
import { generateOTP, verifyOTP } from 'api/authApis';
import { useGlobal } from 'store/index';
import { updateMobileNumber } from 'api/customApis';

const { Title, Text } = Typography;

const Imagecontainer = styled.div`
  img {
    width: 95%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export default function OtpVerify() {
  const location = useLocation();
  const params = location.state;
  const [OtpValue, setOtpValue] = useState('');
  const [hasError, sethasError] = useState(false);
  const [error, seterror] = useState('');
  const [resetOTPLoading, setresetOTPLoading] = useState(false);
  const [verifyOTPButtonLoading, setverifyOTPButtonLoading] = useState(false);
  const [globalState, globalActions] = useGlobal();

  const { seconds, start, stop, reset } = hooks.useTimer({
    seconds: 180,
    running: true,
  });

  const history = useHistory();

  const formatSeconds = (countDown) => {
    const sec = seconds % 60;
    const secLabel = sec < 10 ? `0${sec}` : sec;
    const min = Math.floor(seconds / 60);
    if (min) {
      return `0${min}:${secLabel} minutes`;
    }
    return `00:${secLabel} seconds`;
  };

  const handleonOtpChange = (otp) => {
    sethasError(false);
    setOtpValue(otp);
  };

  const handleVerifyOTP = async () => {
    setverifyOTPButtonLoading(true);
    verifyOTP({ otp: OtpValue }, params.token)
      .then((res) => {
        if (res.data.verified) {
          stop();
          if (params.updateMobileNum) {
            handleUpdateMobileNum();
          }
          globalActions.authActions.otpVerify();
          setverifyOTPButtonLoading(false);
        } else {
          seterror('The OTP you have entered is not valid');
          sethasError(true);
          setverifyOTPButtonLoading(false);
        }
      })
      .catch((err) => {
        seterror('error in validating otp');
        sethasError(true);
        setverifyOTPButtonLoading(false);
      });
  };

  const handleUpdateMobileNum = () => {
    updateMobileNumber(
      { mobile: params.mobile, country: params.country.id },
      params.token
    )
      .then((res) => {
        message.success(`mobile number added`);
      })
      .catch(() => {
        message.error(`Failed to update mobile number`);
      });
  };

  const resendOTP = () => {
    setresetOTPLoading(true);
    reset();
    generateOTP(
      {
        email: params?.email,
        mobile: params?.mobile,
        country: params?.country?.id,
      },
      params.token
    )
      .then(() => {
        start();
        seterror('');
        sethasError(false);
        setresetOTPLoading(false);
        setOtpValue('');
        message.success(`OTP sent`);
      })
      .catch((e) => {
        message.error(`Failed to generate OTP`);
      });
  };

  const handleMobileRegistration = () => {
    history.push({
      pathname: '/mobile-registration',
      state: {
        token: params.token,
      },
    });
  };

  const getTitleLabel = () => {
    if (params.onlyMobileNum) {
      return `Enter the OTP received on +${params.country.phonecode}-${params.mobile}`;
    }
    if (params.email && params.mobile) {
      return `Enter the OTP received on ${params.email} or mobile number +${params.country.phonecode}-${params.mobile}`;
    }
    return `Enter the OTP received on ${params.email}`;
  };

  const renderMobileRegiserBox = () => {
    return (
      <Row
        style={{
          paddingLeft: 10,
          paddingTop: 14,
          paddingBottom: 14,
          paddingRight: 0,
          backgroundColor: '#F5F5F5',
          marginTop: '1rem',
        }}>
        <Row style={{ width: '100%', marginBottom: 5 }}>
          <Col span={2} type="flex" align="middle">
            <RiErrorWarningFill
              size={16}
              color="#9B9F9E"
              style={{ verticalAlign: 'middle' }}
            />
          </Col>
          <Col span={22}>
            <Text style={{ color: '#040E0C' }}>
              Having trouble signing in using the email ?
            </Text>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={2} />
          <Col span={22}>
            <Text
              onClick={handleMobileRegistration}
              style={{
                cursor: 'pointer',
                color: '#0B7764',
                fontWeight: 700,
              }}>
              Register your mobile number
            </Text>
          </Col>
        </Row>
      </Row>
    );
  };

  return (
    <div
      style={{
        height: '100vh',
        background: '#E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Row
        style={{
          width: '80%',
          background: 'white',
          padding: '4rem',
          borderRadius: 12,
        }}>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <Row align="middle">
            <img src={Logo} alt="sourcewiz" width="40px" height="40px" />
            <span
              style={{
                fontSize: '32px',
                color: Colors.black,
                fontFamily: 'SatoshiBold',
                marginLeft: '0.5rem',
              }}>
              Sourcewiz
            </span>
          </Row>
          <Row style={{ marginTop: '1.5rem' }}>
            <Col span={23}>
              <Row style={{ marginTop: '4rem' }}>
                <Title level={5}>{getTitleLabel()}</Title>
              </Row>
              <Row
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginTop: '1.5em',
                  marginBottom: '0.8em',
                }}>
                OTP valid for {formatSeconds(seconds)}
              </Row>
              <Row style={{ width: '100%', marginTop: '1rem' }}>
                <Col span={24}>
                  <OtpInput
                    isInputNum
                    containerStyle={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                    value={OtpValue}
                    onChange={handleonOtpChange}
                    numInputs={6}
                    inputStyle={{
                      width: '50px',
                      height: '3rem',
                      fontSize: '25px',
                      borderRadius: '8px',
                      border: '1px solid gray',
                    }}
                    isInputSecure={true}
                    hasErrored={hasError}
                    errorStyle={{ border: '1px solid red' }}
                  />
                </Col>
              </Row>
              {error && (
                <Alert
                  style={{ marginTop: '1rem' }}
                  type="error"
                  message={error}
                  banner
                />
              )}
              <Row style={{ marginTop: '1rem' }}>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={resendOTP}
                  loading={resetOTPLoading}>
                  {resetOTPLoading ? 'Sending OTP...' : 'Resend OTP'}
                </Button>
              </Row>

              {!params.mobile && renderMobileRegiserBox()}

              <Row style={{ marginTop: '7rem' }}>
                <Col span={11}>
                  <Button
                    loading={verifyOTPButtonLoading}
                    type="secondary"
                    size="large"
                    style={{ width: '100%' }}
                    onClick={() => {
                      history.goBack();
                    }}>
                    {'Go back'}
                  </Button>
                </Col>
                <Col span={11} offset={2}>
                  <Button
                    loading={verifyOTPButtonLoading}
                    disabled={OtpValue.length < 6}
                    type="primary"
                    size="large"
                    style={{ width: '100%' }}
                    onClick={handleVerifyOTP}>
                    {verifyOTPButtonLoading ? 'Verifying..' : 'Verify OTP'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={24} xs={24} md={12} lg={12} xl={12}>
          <Imagecontainer>
            <img src={HomeSVG} alt="sourcewiz" />
          </Imagecontainer>
        </Col>
      </Row>
    </div>
  );
}
