import React from 'react';
import globalHook from 'use-global-hook';
import actions from './actions/index';

const initialState = {
  isloggedIn: localStorage.getItem('user'),
  signupNew: false,
  signupError: null,
  showFilters: false,
  loading: false,
  postRequirements: null,
  error: '',
  isOnboarding: true,
  filters: null,
  products: null,
  createCollection: null,
  userCategories: null,
  collections: null,
  selectedProdutcs: [],
  buyerCollectionData: null,
  shortlistedProducts: [],
  collection_link: null,
  queriesData: null,
  editCollectionProducts: null,
  tableInternalColumns: null,
  permissions: null,
  selectedRows: [],
  sidebar: {
    show: true,
    confirmCheck: false,
  },
  quotationProducts: [],
  quotationAttributes: [],
  showDrawer: false,
  quotationSelectedAttributes: [],
  productPage: {
    mode: '',
    data: {},
    refreshData: false,
    shouldReindexProduct: false,
  },
  parts: [],
  packagingAttributes: [],
  newResouceId: null,
  packagingFields: {},
  shipping: 0,
  overhead: 0,
  margin: 0,
  tax: 0,
  sellingPrice: 0,
  productAttributes: [],
  manualCost: {},
  containers: [],
  productContainers: [],
  currencySecondaryUnit: {},
  productData: {},
  costingInfo: [],
  secondaryUOM: {
    unit: 'piece',
    conversion: 1,
  },
  unsavedChanges: false,
  defaultReload: true,
  reloadURL: '',
  pageLoading: false,
  userInfo: {},
  pageDetails: null,
  appliedFilters: [],
  moreFilters: [],
  appliedMoreFilterList: [],
  appliedPrimaryFilterList: [],
  quotationTableState: [],
  smartSearch: false,
  documentId: '',
  documentSelectedAttributes: [],
  documentAttributes: [],
  documentProducts: [],
  language: 'en',
  quotationId: '',
  collectionId: '',
  milestoneInfo: {
    milestoneId: '',
    reward: '',
  },
  rewardsData: '',
  countriesList: [],
  usage: [],
  moduleData: {},
  planData: {},
  reloadUsageData: false,
  showTrialReminder: false,
  packagingMaterials: [],
  costingPrefs: {},
  qrLabelEnable: false,
  productIdForQr: '',
  isImportExportDrawerActive: false,
  selectedProducts: [],
  refreshAlgoliaHits: true,
  isUploadOpen: false,
  isBulkUploadExcel: false,
  bulkUploadTaskId: null,
  importSocketData: [],
  viewSocketData: {},
  isImportSocketEqual: false,
  overheadsData: [],
};

export const useGlobal = globalHook(React, initialState, actions);
