import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Can from '../../casl/Can';
import { PERMISSIONS } from 'constants/permissions';
import Dashboard from './Dashboard/index';
import './Quotation/styles.less';
import lazyRetry from '../../utils/lazyLoading';
import ClearLocalStorage from './ClearLocalStorage';
const EditSpec = lazy(() =>
  lazyRetry(() => import('./ProductV2/Product/Tabs/SpecSheetTab/EditSpec'))
);

const MasterCatalogue = lazy(() =>
  lazyRetry(() => import('./MasterCatalogue'))
);
const ManageProducts = lazy(() => lazyRetry(() => import('./ManageProducts')));
const CategorywiseProducts = lazy(() =>
  lazyRetry(() =>
    import('./MasterCatalogue/Categories/CategorywiseProducts/index')
  )
);
const CreateCollection = lazy(() =>
  lazyRetry(() => import('./CreateCollection'))
);
const SelectProducts = lazy(() =>
  lazyRetry(() => import('./CreateCollection/SelectProducts/index'))
);
const QuotationSelectProducts = lazy(() =>
  lazyRetry(() => import('./Quotation/EnquiryQuotation/SelectProducts/index'))
);
const CollectionLink = lazy(() =>
  lazyRetry(() => import('./CreateCollection/CollectionLink'))
);

// DEPRECATED
// const AddNewProduct = lazy(() =>
//   lazyRetry(() => import('./AddNewProduct/index'))
// );
// const EditNewProduct = lazy(() =>
//   lazyRetry(() => import('./AddNewProduct/EditProduct'))
// );
const AddProductFromCategory = lazy(() =>
  lazyRetry(() =>
    import(
      './ProductV2/Product/Tabs/BasicDetailsTab/modes/AddProductFromCategory'
    )
  )
);

const AllCatalogues = lazy(() => lazyRetry(() => import('./CatalogueHistory')));
const BuyerActivityDetails = lazy(() =>
  lazyRetry(() => import('./BuyerActivity/index'))
);
const ProfileSettings = lazy(() =>
  lazyRetry(() => import('./ProfileSettings'))
);
const NotAllowed = lazy(() => lazyRetry(() => import('./NotAllowed')));

const Contacts = lazy(() => lazyRetry(() => import('./Contacts')));
const EditTable = lazy(() =>
  lazyRetry(() => import('./ManageProducts/TableEdit'))
);
const BulkUpload = lazy(() =>
  lazyRetry(() => import('./ManageProducts/BulkUpload'))
);
const BulkUploadTable = lazy(() =>
  lazyRetry(() => import('./ManageProducts/BulkUpload/Table'))
);
const TableV2 = lazy(() =>
  lazyRetry(() => import('./CreateCollection/TableV2'))
);
const Enquiries = lazy(() => lazyRetry(() => import('./Quotation/index')));
const Quotations = lazy(() =>
  lazyRetry(() => import('./Quotation/EnquiryQuotation/index'))
);
const QuotationsTable = lazy(() =>
  lazyRetry(() => import('./Quotation/EnquiryQuotation/QuotationTable/index'))
);
const QuotationSelectReferenceImages = lazy(() =>
  lazyRetry(() =>
    import(
      './Quotation/EnquiryQuotation/QuotationTable/ReferenceImage/SelectReferenceImage/index'
    )
  )
);

const ResourceLibrary = lazy(() =>
  lazyRetry(() => import('./ResourceLibrary'))
);
const ProductV2 = lazy(() => lazyRetry(() => import('./ProductV2')));
const InvoiceOrders = lazy(() =>
  lazyRetry(() => import('./DocumentManagement/pages/DocumentsList'))
);
const Documents = lazy(() => lazyRetry(() => import('./DocumentManagement')));

const BulkImageUpload = lazy(() => import('./ManageProducts/BulkImageUpload'));

export default function AppRoutes() {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        {/* All Products */}
        <Route
          path="/master-catalogue"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_products.slug}
              a={PERMISSIONS.view_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <MasterCatalogue {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/master-catalogue/:key"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_products.slug}
              a={PERMISSIONS.view_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <MasterCatalogue {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        {/* Product Details  */}
        <Route
          path="/:mode/:category_name/:category_id/product-details/add"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_products.slug}
              a={PERMISSIONS.create_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ProductV2 {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        {/* Specsheet */}
        <Route
          path="/edit-product-details/:id/specsheet/:specsheetMode/:templateID/:specsheetID"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <EditSpec {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/product-details/:mode/:id/specsheet/:specsheetMode/:templateID/:specsheetID"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <EditSpec {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/product-details/:mode/:id/specsheet/create"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <EditSpec {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        {/* (Permissions are handled inside the components) */}
        <Route
          path="/product-details/:mode/:id/:tab"
          render={(props) => <ProductV2 {...props} />}
        />
        <Route
          path="/product-details/:mode/:id"
          render={(props) => <ProductV2 {...props} />}
        />
        <Route
          path="/product-details/:mode"
          render={(props) => <ProductV2 {...props} />}
        />
        {/* <Route
          path="/categorywise-products/:category_name/:id/add-product"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_products.slug}
              a={PERMISSIONS.create_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <AddProductFromCategory {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}

        <Route
          path="/categorywise-products/:category_name/:category_id"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_products.slug}
              a={PERMISSIONS.view_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <CategorywiseProducts {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        {/* DEPRECATED */}

        {/* <Route
          path="/create-product"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_products.slug}
              a={PERMISSIONS.create_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ProductV2 {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}
        {/* <Route
          path="/edit-product-details/:id/:tab"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ProductV2 {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/edit-product-details/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ProductV2 {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}
        {/* <Route
          path="/view-product-details/:id/:tab"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_products.slug}
              a={PERMISSIONS.view_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ProductV2 {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/view-product-details/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_products.slug}
              a={PERMISSIONS.view_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ProductV2 {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}
        {/* <Route
          path="/add-new-product"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_products.slug}
              a={PERMISSIONS.create_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <AddNewProduct {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}
        {/* <Route
          path="/add-product"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_products.slug}
              a={PERMISSIONS.create_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <AddNewProduct {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}
        {/* <Route
          path="/edit-product/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <EditNewProduct {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        /> */}

        {/* Bulk Upload */}
        <Route
          path="/bulk-upload/"
          render={(props) => {
            return (
              <Can
                I={PERMISSIONS.bulk_upload.slug}
                a={PERMISSIONS.bulk_upload.permissionType}
                passThrough>
                {(allowed) =>
                  allowed ? (
                    <BulkUpload key="bulkUpload" {...props} />
                  ) : (
                    <NotAllowed />
                  )
                }
              </Can>
            );
          }}
        />
        <Route
          path="/bulk-upload-group/:id"
          render={(props) => {
            return (
              <Can
                I={PERMISSIONS.bulk_upload.slug}
                a={PERMISSIONS.bulk_upload.permissionType}
                passThrough>
                {(allowed) =>
                  allowed ? (
                    <BulkUpload key="bulkUploadTasked" {...props} />
                  ) : (
                    <NotAllowed />
                  )
                }
              </Can>
            );
          }}
        />
        <Route
          path="/bulk-upload-table/:id"
          render={(props) => {
            return (
              <Can
                I={PERMISSIONS.bulk_upload.slug}
                a={PERMISSIONS.bulk_upload.permissionType}
                passThrough>
                {(allowed) =>
                  allowed ? <BulkUploadTable {...props} /> : <NotAllowed />
                }
              </Can>
            );
          }}
        />

        {/* Manage Products */}
        <Route
          path="/manage-products"
          render={(props) => (
            <Can
              I={PERMISSIONS.update_products.slug}
              a={PERMISSIONS.update_products.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <ManageProducts {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/bulk-edit-table/:identifier"
          render={
            (props) => (
              // <Can
              //   I={PERMISSIONS.create_products.slug}
              //   a={PERMISSIONS.create_products.permissionType}
              //   passThrough
              // // >
              // (allowed) =>
              //   allowed ?
              <EditTable {...props} />
            )
            // : <NotAllowed />
          }
        />

        {/* Catalogue */}
        <Route
          path="/create-collection"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_catalogues.slug}
              a={PERMISSIONS.create_catalogues.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <CreateCollection {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/select-products/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_catalogues.slug}
              a={PERMISSIONS.create_catalogues.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <SelectProducts {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/catalogue-table/:id/:type?"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_catalogues.slug}
              a={PERMISSIONS.create_catalogues.permissionType}
              passThrough>
              {(allowed) => (allowed ? <TableV2 {...props} /> : <NotAllowed />)}
            </Can>
          )}
        />
        <Route
          path="/catalogue-history"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_catalogues.slug}
              a={PERMISSIONS.view_catalogues.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <AllCatalogues {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        <Route
          path="/collection-link/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_catalogues.slug}
              a={PERMISSIONS.create_catalogues.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <CollectionLink {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/buyer-activity-details/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_catalogues.slug}
              a={PERMISSIONS.view_catalogues.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <BuyerActivityDetails {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        {/* Contact Library */}
        <Route exact path="/buyers" component={Contacts} />

        {/* Quotation */}
        {/* chnage permissions for enquiry table & quotation table */}
        <Route
          path="/enquiries"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_quotation.slug}
              a={PERMISSIONS.view_quotation.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <Enquiries {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/quotations/:enquiry_id/select-quotation-products/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_quotation.slug}
              a={PERMISSIONS.create_quotation.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? (
                  <QuotationSelectProducts {...props} />
                ) : (
                  <NotAllowed />
                )
              }
            </Can>
          )}
        />
        <Route
          path="/quotations/:enquiry_id/select-images/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.create_quotation.slug}
              a={PERMISSIONS.create_quotation.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? (
                  <QuotationSelectReferenceImages {...props} />
                ) : (
                  <NotAllowed />
                )
              }
            </Can>
          )}
        />

        <Route
          path="/quotations/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.view_quotation.slug}
              a={PERMISSIONS.view_quotation.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <Quotations {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />
        <Route
          path="/quotation-table/:enquiry_id/:id"
          render={(props) => (
            <Can
              I={PERMISSIONS.edit_quotation.slug}
              a={PERMISSIONS.edit_quotation.permissionType}
              passThrough>
              {(allowed) =>
                allowed ? <QuotationsTable {...props} /> : <NotAllowed />
              }
            </Can>
          )}
        />

        {/* Document Manager */}
        <Route
          path="/invoices-orders/:documentType/:page/:docID"
          render={(props) => {
            return <Documents />;
          }}
        />

        <Route
          path="/invoices-orders/:documentType"
          render={(props) => {
            return (
              <Can
                I={PERMISSIONS.view_quotation.slug}
                a={PERMISSIONS.view_quotation.permissionType}
                passThrough>
                {(allowed) => (allowed ? <InvoiceOrders /> : <NotAllowed />)}
              </Can>
            );
          }}
        />

        {/* Resource Library */}
        <Route
          render={(props) => {
            return (
              <Can
                I={PERMISSIONS.view_resources.slug}
                a={PERMISSIONS.view_resources.permissionType}
                passThrough>
                {(allowed) =>
                  allowed ? (
                    <ResourceLibrary selected="material" />
                  ) : (
                    <NotAllowed />
                  )
                }
              </Can>
            );
          }}
          path="/material-library"
        />
        <Route
          render={(props) => {
            return (
              <Can
                I={PERMISSIONS.view_resources.slug}
                a={PERMISSIONS.view_resources.permissionType}
                passThrough>
                {(allowed) =>
                  allowed ? (
                    <ResourceLibrary selected="process" />
                  ) : (
                    <NotAllowed />
                  )
                }
              </Can>
            );
          }}
          path="/process-library"
        />

        {/* Profile Settings */}
        <Route path="/profile-settings/:id">
          <ProfileSettings />
        </Route>

        {/* Miscellaneous */}
        <Route
          path="/clear"
          render={(props) => {
            return <ClearLocalStorage />;
          }}
        />

        <Route
          path="/bulk-image-upload/upload/"
          render={(props) => {
            return <BulkImageUpload />;
          }}
        />
        <Route
          path="/bulk-image-upload/grouping/:id"
          render={(props) => {
            return <BulkImageUpload />;
          }}
        />
        <Route
          path="/bulk-image-upload/linking/:id"
          render={(props) => {
            return <BulkImageUpload />;
          }}
        />

        {/* Removed from Guardrails */}
        {/* <Route exact path="/buyer-list" component={BuyerList} />
        <Route exact path="/prospects" component={Prospects} />
        <Route exact path="/buyer-details/:id" component={BuyerDetails} />
        <Route path="/buyer-activity-details/:id">
          <BuyerActivityDetails />
        </Route> */}

        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </Suspense>
  );
}
