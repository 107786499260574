import PYTHON_KEY from 'api/pythonService';
const user = localStorage.getItem('user');
const token = JSON.parse(user)?.access_token;

export const sendDataRedis = async (products, collectionId) => {
  let product_ids = {};
  products.forEach(
    (item, index) =>
      (product_ids[item?.id] = {
        image: item?.image,
        is_exclusive_product: item?.is_exclusive_product,
        meta: {
          exclusive_buyer_details: item?.meta?.exclusive_buyer_details,
          product_id: item?.meta?.product_id,
          selected_variants: item?.meta?.selected_variants,
        },
      })
  );
  const data = {
    model_id: collectionId,
    product_ids: product_ids,
  };

  try {
    return await PYTHON_KEY.URL.post(`items/cache/collection`, data, {
      headers: { 'access-token': `${token}` },
    });
  } catch (err) {
    console.error(err);
  }
};

export const deleteDataRedis = async (products, collectionId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  const data = products.map((pro) => parseInt(pro?.id));

  try {
    return await PYTHON_KEY.URL.delete(
      `items/cache/collection?model_id=${collectionId}`,
      {
        headers: { 'access-token': `${token}` },
        data: data,
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const getDataRedis = async (collectionId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  try {
    return await PYTHON_KEY.URL.get(
      `items/cache/collection?model_id=${collectionId}`,
      {
        headers: { 'access-token': `${token}` },
      }
    );
  } catch (err) {
    console.error(err);
  }
};

//quotations
export const sendQuotationDataRedis = async (products, quotationId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  let product_ids = {};
  products.forEach(
    (item, index) =>
      (product_ids[item?.id] = {
        image: item?.image,
        is_exclusive_product: item?.is_exclusive_product,
        meta: {
          exclusive_buyer_details: item?.meta?.exclusive_buyer_details,
          product_id: item?.meta?.product_id,
          selected_variants: item?.meta?.selected_variants,
        },
      })
  );

  const data = {
    model_id: quotationId,
    product_ids: product_ids,
  };
  return await PYTHON_KEY.URL.post(`items/cache/quotation`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const deleteQuotationDataRedis = async (products, quotationId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  const data = products.map((pro) => parseInt(pro?.id));
  return await PYTHON_KEY.URL.delete(
    `items/cache/quotation?model_id=${quotationId}`,
    {
      headers: { 'access-token': `${token}` },
      data: data,
    }
  );
};

export const getQuotationDataRedis = async (quotationId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  try {
    return await PYTHON_KEY.URL.get(
      `items/cache/quotation?model_id=${quotationId}`,
      {
        headers: { 'access-token': `${token}` },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const sendDocumentDataRedis = (products, documentId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  const product_ids = products.map((prod) => parseInt(prod?.id));
  const data = {
    module: 'documentRecord',
    id: documentId,
    product_ids: product_ids,
    product_types: 'product',
  };

  return PYTHON_KEY.URL.post(`redis/cache`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const deleteDocumentDataRedis = (products, documentId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  const data = products.map((prod) => parseInt(prod?.id));

  return PYTHON_KEY.URL.delete(
    `redis/cache/delete_products?module=documentRecord&id=${documentId}&product_types=product`,
    {
      headers: { 'access-token': `${token}` },
      data: data,
    }
  );
};

export const getDocumentDataRedis = (documentId) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return PYTHON_KEY.URL.get(
    `redis/cache?module=documentRecord&id=${documentId}&product_types=product&access-token=${token}`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};
