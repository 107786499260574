import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, Button, message } from 'antd';
import MobileField from 'routes/SourceWiz/ProfileSettings/MobileField';
import { getAccountDetails, getCountryCodes } from 'api/customApis';
import './index.less';
import { useIntl } from 'react-intl';

const WhatsappAcko = (props) => {
  const intl = useIntl();
  const { visible, verifySuccess } = props;
  const [accountDetails, setAccountDetails] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);
  const [whatsappVerified, setWhatsappVerified] = useState(false);
  const [checked, setChecked] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [ButtonStatus, setButtonStatus] = useState(false);

  const fetchData = async () => {
    try {
      const res1 = await getAccountDetails();
      setAccountDetails(res1?.data?.data);
    } catch (e) {
      // message.error("Failed to fetch data");
      message.error(
        intl.formatMessage({ id: 'message.error.failedToFetchData' })
      );
    }
  };

  useEffect(() => {
    if (checked & !disabled) {
      setButtonStatus(true);
    } else {
      setButtonStatus(false);
    }
  }, [checked, disabled]);

  useEffect(async () => {
    try {
      await fetchData();
      const res3 = await getCountryCodes();
      setCountryCodes(res3.data.data);
    } catch (e) {
      // message.error("Failed to fetch data");
      message.error(
        intl.formatMessage({ id: 'message.error.failedToFetchData' })
      );
    }
  }, []);

  const handleWhatsappVerified = () => {
    setWhatsappVerified(true);
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {}}
      closable={false}
      closeIcon={null}
      footer={null}
      centered
      width={'538px'}
      bodyStyle={{ padding: '2em' }}
      destroyOnClose={true}>
      <div style={{ width: '100%', height: '379px' }}>
        <div className="ack-header">
          {/* Get notifications on your Whatsapp number */}
          {intl.formatMessage({ id: 'sourcewiz.whatsappModal.title' })}
        </div>
        <div className="ack-subheader">
          {/* You will receive an NDA on the number below within 24 hours */}
          {intl.formatMessage({ id: 'sourcewiz.whatsappModal.subTitle' })}
        </div>
        <div className="contact-container">
          <MobileField
            editable
            countryCodes={countryCodes}
            mobile={accountDetails?.mobile}
            country={accountDetails?.country}
            email={accountDetails?.email}
            showOTP={showOTP}
            whatsappVerified={handleWhatsappVerified}
            fetchData={fetchData}
            verifySuccess={verifySuccess}
            otpVerified={verifyOTP}
            goBack={() => setShowOTP(false)}
            forWhatsapp={true}
            setDisabled={setDisabled}
            setVerifyOTP={setVerifyOTP}
          />
        </div>
        {!showOTP && (
          <div className="ack-section" style={{ marginTop: '80px' }}>
            <Checkbox
              style={{ alignItems: 'center', marginTop: '1rem' }}
              checked={checked}
              onChange={(e) => {
                console.log(e.target.checked, 'CHECKED', disabled, 'DISABLED');
                setChecked(e.target.checked);
                console.log(!checked && disabled, 'D');
              }}>
              <div className="ack-statement">
                {/* I agree to receive Sourcewiz notifications on my Whatsapp number */}
                {intl.formatMessage({ id: 'sourcewiz.whatsappModal.agree' })}
              </div>
            </Checkbox>
          </div>
        )}
        <div
          className="cta-container"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          {(!showOTP && (
            <>
              <Button
                size="large"
                onClick={props.dismissWhatsapp}
                style={{
                  width: '171px',
                  padding: '8px 16px',
                  fontSize: '14px',
                  border: '1px solid #CDCFCE',
                  color: 'black',
                }}>
                {/* Remind me later */}
                {intl.formatMessage({
                  id: 'sourcewiz.whatsappModal.remindMeLater',
                })}
              </Button>
              {/* {
                  JSON.stringify({cansubmit:ButtonStatus, checked:checked , disabled:disabled, buttonstat: (!checked && disabled)})
              } */}
              <Button
                size="large"
                onClick={() => setShowOTP(true)}
                disabled={!ButtonStatus ? true : false}
                type="primary"
                style={{
                  background: '#1D2624',
                  opacity: !ButtonStatus ? 0.4 : 1,
                  width: '171px',
                  padding: '8px 16px',
                  fontSize: '14px',
                  color: 'white',
                  border: 0,
                }}>
                {/* Submit */}
                {intl.formatMessage({ id: 'sourcewiz.button.submit' })}
              </Button>
            </>
          )) || (
            <>
              <Button
                size="large"
                onClick={() => setShowOTP(false)}
                disabled={checked && disabled}
                style={{
                  // background: "#1D2624",
                  opacity: disabled && checked ? 0.4 : 1,
                  width: '171px',
                  padding: '8px 16px',
                  border: '1px solid #CDCFCE',
                  fontSize: '14px',
                  color: 'black',
                }}>
                {/* Go back */}
                {intl.formatMessage({ id: 'sourcewiz.button.goBack' })}
              </Button>
              <Button
                size="large"
                onClick={() => setVerifyOTP(true)}
                disabled={checked && disabled}
                style={{
                  background: '#1D2624',
                  opacity: disabled && checked ? 0.4 : 1,
                  width: '171px',
                  padding: '8px 16px',
                  fontSize: '14px',
                  color: 'white',
                  border: 0,
                }}>
                {/* Verify OTP */}
                {intl.formatMessage({ id: 'sourcewiz.login.verifyOTP' })}
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WhatsappAcko;
