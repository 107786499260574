export const ReferenceImgActionTypes = Object.freeze({
  ADD_MAIN_PRODUCT_IMAGE: 'referenceImageActions/ADD_MAIN_PRODUCT_IMAGE',
  SET_REFERENCE_IMAGES: 'referenceImageActions/SET_REFERENCE_IMAGES',
  ADD_MULTIPLE_PRODUCT_IMAGES:
    'referenceImageActions/ADD_MULTIPLE_PRODUCT_IMAGES',
  REMOVE_SINGLE_PRODUCT_IMAGE:
    'referenceImageActions/REMOVE_SINGLE_PRODUCT_IMAGE',
  REMOVE_MULTIPLE_PRODUCT_IMAGES:
    'referenceImageActions/REMOVE_MULTIPLE_PRODUCT_IMAGES',
  SHOW_REFERENCE_IMAGE_DRAWER:
    'referenceImageActions/SHOW_REFERENCE_IMAGE_DRAWER',
  SET_CURR_ROW_DATA: 'referenceImageActions/SET_CURR_ROW_DATA',
  SET_UPLOADED_IMAGES: 'referenceImageActions/SET_UPLOADED_IMAGES',
  HAS_SELECTED_IMAGES: 'referenceImageActions/HAS_SELECTED_IMAGES',
});

export function addMainProductImage(productData) {
  return {
    type: ReferenceImgActionTypes.ADD_MAIN_PRODUCT_IMAGE,
    payload: {
      productData,
    },
  };
}

export function setReferenceImages(data) {
  return {
    type: ReferenceImgActionTypes.SET_REFERENCE_IMAGES,
    payload: {
      data,
    },
  };
}

export function addMultipleProductImages(imgIds, productData) {
  return {
    type: ReferenceImgActionTypes.ADD_MULTIPLE_PRODUCT_IMAGES,
    payload: {
      imgIds,
      productData,
    },
  };
}

export function removeSingleProductImage(imgId, productData) {
  return {
    type: ReferenceImgActionTypes.REMOVE_SINGLE_PRODUCT_IMAGE,
    payload: {
      imgId,
      productData,
    },
  };
}
export function removeMultipleProductImages(productData) {
  return {
    type: ReferenceImgActionTypes.REMOVE_MULTIPLE_PRODUCT_IMAGES,
    payload: {
      productData,
    },
  };
}

export function setShowReferenceImageDrawer(value) {
  return {
    type: ReferenceImgActionTypes.SHOW_REFERENCE_IMAGE_DRAWER,
    payload: {
      value,
    },
  };
}

export function setCurrRowData(data) {
  return {
    type: ReferenceImgActionTypes.SET_CURR_ROW_DATA,
    payload: {
      data,
    },
  };
}

export function setUploadedImages(data) {
  return {
    type: ReferenceImgActionTypes.SET_UPLOADED_IMAGES,
    payload: {
      data,
    },
  };
}

export function setHasSelectedImages(value) {
  return {
    type: ReferenceImgActionTypes.HAS_SELECTED_IMAGES,
    payload: {
      value,
    },
  };
}

const ACTION_HANDLERS = {
  ReferenceImgActionTypes,
  addMainProductImage,
  setReferenceImages,
  addMultipleProductImages,
  removeSingleProductImage,
  removeMultipleProductImages,
  setShowReferenceImageDrawer,
  setCurrRowData,
  setHasSelectedImages,
};

export default ACTION_HANDLERS;
