import { useState } from 'react';
import { Modal, Typography, Row, Col } from 'antd';
import svgSent from '../../../../../assets/images/bro.svg';
import postUpgradeRequestSvg from '../../../../../assets/images/postupgraderequest.svg';
import './UpgradeRequest.css';

const { Title, Text } = Typography;
export const RequestStatusModal = ({
  data,
  requestStatus,
  requestStatusModal,
  setRequestStatusModal,
}) => {
  return (
    <>
      <Modal
        visible={requestStatusModal}
        closable
        onCancel={() => setRequestStatusModal(false)}
        footer={null}>
        <Row align="center">
          <img
            src={
              requestStatus === 'post_request' ? postUpgradeRequestSvg : svgSent
            }
            alt="img"
          />
        </Row>
        <br />
        <br />
        <Row align="center">
          <Title level={5}>{data?.title}</Title>
        </Row>
        <Row align="center">
          <Text>{data?.text}</Text>
        </Row>
        <br />
        <br />
        {requestStatus === 'payment pending' ? (
          <></>
        ) : (
          <Row align="center">
            <Text>Need help? </Text>
            <Text className="upgrade-request-help-text">
              +919215777736 |{' '}
              <a href="mailto:support@sourcewiz.co">support@sourcewiz.co</a>
            </Text>
          </Row>
        )}
      </Modal>
    </>
  );
};
