import React from 'react';
import { Layout } from 'antd';
import HeaderComponent from './Header';
import StepsComponent from './Steps';

const { Content } = Layout;

export default function Onboarding() {
  return (
    <Layout>
      <HeaderComponent />
      <Content
        className="site-layout"
        style={{ padding: '0 50px', marginTop: 64, background: 'white' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 380 }}>
          <StepsComponent />
        </div>
      </Content>
    </Layout>
  );
}
