const Colors = {
  primaryColor: '#278977',
  primaryBorderColor: '#C9E4DF',
  secondaryColor: '#B9DFD6',
  success: '#05CA01',
  danger: '#FA5762',
  white: '#fff',
  black: '#000',
  offWhite: '#FEE8FF',
  gray: '#797F7D',
  primaryColorTint: '#F0F8F6',
  purple: '#F3EFFD',
  lemon: '#F6FBE9',
  lightgray: '#FAFAFA',
  neutral: '#4F5655',
};

export { Colors };
