import PYTHON_KEY from 'api/pythonService';
const pythonBaseUrl = process.env.REACT_APP_API_SERVICE;

const user = localStorage.getItem('user');
const token = JSON.parse(user)?.access_token;

export const fetchAllUOM = (signal) => {
  return PYTHON_KEY.URL.get(PYTHON_KEY.path.getUOMViaCategory('uom'), {
    headers: { 'access-token': `${token}` },
    signal,
  });
};

export const fetchCachedData = (key) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}redis/cached_data`, {
    headers: { 'access-token': token },
    params: {
      key: key,
    },
  });
};
