import React from 'react';
import { Modal, Typography, Row, Col } from 'antd';
import QRLabel from 'assets/images/QRLabel.svg';

const QRDownloaded = ({ visible, onClose, setVisible, bulk, productDict }) => {
  const { Title } = Typography;
  const totalQRCount = Object.values(productDict).reduce((agg, item) => {
    return agg + item?.length || 0;
  }, 0);

  const getQRText = () => {
    if (bulk) {
      return `${totalQRCount} QR labels downloaded successfully of ${
        productDict?.mainProducts?.length || ''
      } products and their variants`;
    } else {
      const variantsText =
        productDict?.variants.length > 0
          ? `with ${productDict?.variants.length} variants`
          : '';
      return `QR label downloaded successfully for ID - ${
        productDict?.product_id || ''
      } ${variantsText}`;
    }
  };

  const renderBody = () => {
    return (
      <>
        <Row justify="center">
          <Col>
            <img width="100%" height="100%" src={QRLabel} alt="qr-label" />
          </Col>
        </Row>
        <Row justify="center">
          <Title style={{ textAlign: 'center' }} level={4}>
            {getQRText()}
          </Title>
        </Row>
      </>
    );
  };
  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setVisible(false)}
      closable
      centered
      bodyStyle={{ padding: '3em 4em' }}>
      {renderBody()}
    </Modal>
  );
};

export default QRDownloaded;
