import { Modal, Typography, Row, Divider, Col, Button } from 'antd';
import style from 'styled-components';
import earlybirdSvg from '../../../../../assets/images/earlybird.svg';
import PYTHON_KEY from 'api/pythonService';
import './EarlyBirdNotification.css';
import { useHistory } from 'react-router-dom';
const { Title, Text } = Typography;
const PlanDiv = style.div`
    width: 100%;
    margin: auto;
    padding: ${(props) =>
      props.type === 'no_sku_usage'
        ? '0.3rem 0 0.3rem 1.8rem'
        : '0.8rem 0rem 0.6rem 2rem'} ;
    border-radius: 0.5rem;
    background: ${(props) =>
      props.type === 'no_sku_usage'
        ? 'linear-gradient(258.34deg, #F2F3EC 31.58%, #E5FBDF 127.55%)'
        : 'linear-gradient(258.34deg, #F2F3EC 31.58%, #E6DFFB 127.55%)'} ;
    mix-blend-mode: normal;
`;
const ButtonContainer = style.div`
    .ant-btn-default{
        background: #1D2624; 
        border: none;
        color: white;
    }
`;
export const EarlyBirdNotification = ({
  earlyBirdModal,
  type,
  setEarlyBirdModal,
  data,
}) => {
  const history = useHistory();
  const handleDismiss = () => {
    const userData = localStorage.getItem('user');
    const user = userData && JSON.parse(userData);
    const access_token = user?.access_token;
    const payload = {
      id: data?.id,
    };
    PYTHON_KEY.URL.post(PYTHON_KEY.path.dismiss_notification, payload, {
      headers: { 'access-token': `${access_token}` },
    })
      .then((res) => {
        if (res?.status === 200) {
          setEarlyBirdModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClick = () => {
    history.push('/profile-settings/6');
    handleDismiss();
  };
  return (
    <>
      <Modal
        width={type === 'no_sku_usage' ? 550 : 670}
        visible={earlyBirdModal}
        onCancel={() => setEarlyBirdModal(false)}
        footer={null}
        closable={false}>
        <PlanDiv type={type}>
          <Row justify="spacee-between">
            <Col className="early-bird-col">
              <Title level={4}>
                {type === 'no_sku_usage'
                  ? 'SKU plan & Limit'
                  : 'Free upgrade on SKUs!'}
              </Title>
              <Row className="early-bird-subtitle-row">
                <Text className="early-bird-subtitle">
                  {type === 'no_sku_usage'
                    ? 'You have not utilized your SKU plan completely!'
                    : 'Congratulations! You are one of the valued customers of Sourcewiz'}
                </Text>
              </Row>
            </Col>
            <Col>
              <img
                src={earlybirdSvg}
                width={type === 'no_sku_usage' ? 140 : 120}
                alt="img"
              />
            </Col>
          </Row>
        </PlanDiv>
        <br />
        <Row align="center" className="early-bird-subtext-row">
          <Text className="early-bird-subtext">
            {type === 'no_sku_usage'
              ? 'Add products to improve your catalogue, quotations and document generation experience.'
              : 'To show our appreciation we have increased your SKU plan for FREE. Go to your profile settings to view complete details about your plan'}
          </Text>
        </Row>
        <Divider />
        <Row justify="space-between">
          <Col>
            <Button size="large" onClick={() => handleDismiss()}>
              Dismiss
            </Button>
          </Col>
          <Col>
            <ButtonContainer>
              <Button size="large" onClick={() => handleClick()}>
                View plan details
              </Button>
            </ButtonContainer>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
