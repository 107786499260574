import React from 'react';
import { Drawer } from 'antd';

export default function Index({
  onClose,
  visible,
  title,
  children,
  closable,
  width,
  ...props
}) {
  return (
    <>
      <Drawer
        title={title}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={closable}
        width={width}
        {...props}>
        {children}
      </Drawer>
    </>
  );
}
