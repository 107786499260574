import React, { useState } from 'react';
import { Row, Col, Typography, Space, Skeleton } from 'antd';
import Selectors from '../selectors';
import '../BulkUpdate/index.less';
import './bulkExportExcel.less';
import SWDrawer from 'react-common-components/SWDrawer';
import { useGlobal } from 'store/index';
import { useSelector } from 'react-redux';
import { Button } from 'components/index';
import {
  downloadBulkExcel,
  downloadBulkUploadExcel,
  updateTemplate,
} from 'api/collectionApis';
import { MdDelete } from 'react-icons/md';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiBookmark, BiStar } from 'react-icons/bi';
import IncludeImageUrl from '../BulkUpload/IncludeImageUrls';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import ExcelTemplateFooter from './ExcelTemplateFooter';
import { BULK_UPLOAD_DOWNLOAD_TEMPLATE_START } from 'analytics/events';
const { Text } = Typography;

const ChangeTemplate = ({
  isChangeTemplate,
  setIsChangeTemplate,
  templateList,
  setTemplateList,
  setIsCreateTemplate,
  setIsBulkExcelDownload,
  setDefaultBulkExcelTemplate,
  handleTemplateDelete,
  selectedCard,
  setSelectedCard,
  setIsDiscard,
  viewDetailsActive,
  setViewDetailsActive,
  defaultBulkExcelTemplate,
  title,
  subTitle,
  includeimageUrl,
  setIsImageUrl,
  isImageUrl,
  defaultAttributes,
  attributesList,
  isBulkUpload = false,
  taskId = 0,
  downloadImageUrlExcel,
  isImageUrlActive,
  setIsImageUrlActive,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [globalState, globalActions] = useGlobal();
  const { trackEvent } = useTracking();
  const location = useLocation();
  const [isBulkUploadExcelDownloading, setIsBulkUploadExcelDownloading] =
    useState(false);

  const viewTemplateDetails = (attributes, name) => {
    trackEvent({
      name: 'export_view_details',
      meta: {
        data: name,
        attributes: attributes,
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setViewDetailsActive(true);
    setSelectedTemplate({
      name: name,
      attributes: attributes,
    });
  };

  const setAsDefault = (template) => {
    setDefaultBulkExcelTemplate(template);
    const payload = {
      is_default: true,
    };
    const { templates } = templateList;

    const defaultTemplate = templates?.filter(
      (item) => item?.id === template?.id
    );
    const restTemplates = templates?.filter(
      (item) => item?.id !== template?.id
    );
    setTemplateList({
      templates: [...defaultTemplate, ...restTemplates],
      default_template_id: template?.id,
    });
    updateTemplate(template?.id, payload);
  };

  const viewDetails = () => {
    const attributeList = defaultAttributes?.map((item, index) => {
      return (
        <Row className="view-attributes" key={item}>
          {index + 1}. {item}
        </Row>
      );
    });

    return (
      <Row className="template-detail-container">
        <Row className="excel-container-width">
          <Text className="selected-template">{selectedTemplate?.name}</Text>
        </Row>
        <Row className="excel-container-width">
          <Text className="excel-create-template-text">
            {!(Object.values(selectedTemplate?.attributes)?.[0] === 'all')
              ? Object.values(selectedTemplate?.attributes?.value)?.length
              : Object.values(attributesList)?.length}{' '}
            attributes added
          </Text>
        </Row>
        <Space direction="vertical" className="view-attributes-list">
          {!(Object.values(selectedTemplate?.attributes)?.[0] === 'all')
            ? Object.values(selectedTemplate?.attributes?.value)?.map(
                (item, index) => (
                  <Row className="attribute-listing" key={item}>
                    {index + 1}. {attributesList[item]}
                  </Row>
                )
              )
            : attributeList}
        </Space>
      </Row>
    );
  };

  const selectedProduct = useSelector((state) =>
    Selectors.selectTempCheckedProducts()(state)
  );

  const downloadExcelSheet = (id) => {
    let data = {};
    for (let items in selectedProduct) {
      let variantIds = selectedProduct[items]?.variants?.variant_ids;
      variantIds = variantIds.filter((elem) => elem !== Number(items));
      data[items] = variantIds;
    }
    if (isBulkUpload) {
      const payload = taskId
        ? {
            template_id: id,
            task_id: taskId,
          }
        : {
            template_id: id,
          };
      trackEvent({
        name: 'export_download_change_template',
        meta: {
          data: payload,
          location: location.pathname,
          modules: 'Manage Products',
          property: isImageUrlActive
            ? 'Bulk Upload Image url download'
            : 'Bulk Upload excel download',
        },
      });
      if (isImageUrlActive) {
        downloadImageUrlExcel(id);
        setIsBulkExcelDownload(true);
        setIsChangeTemplate(false);
      } else {
        setIsBulkExcelDownload(false);
        setIsBulkUploadExcelDownloading(true);
        downloadBulkUploadExcel(payload, setIsBulkUploadExcelDownloading).then(
          () => {
            setIsChangeTemplate(false);
          }
        );
      }
    } else {
      const payload = {
        product_ids: Object.keys(selectedProduct)?.length ? data : 'all',
        template_id: id,
      };
      trackEvent({
        name: 'export_download_change_template',
        meta: {
          data: payload,
          location: location.pathname,
          modules: 'Manage Products',
          property: 'Bulk excel Export',
        },
      });
      downloadBulkExcel(payload);
      setIsBulkExcelDownload(true);
      setIsChangeTemplate(false);
    }
    globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
  };

  const selectCard = (template) => {
    setSelectedCard(template);
    if (viewDetailsActive) {
      viewTemplateDetails(template?.selected_attributes, template?.name);
    }
  };

  const handleCreateTemplate = () => {
    setIsDiscard(false);
    setIsCreateTemplate(true);
  };

  return (
    <SWDrawer
      success="Success"
      dismiss="Cancel"
      width={viewDetailsActive ? 804 : 459}
      show={isChangeTemplate}
      closable
      setShow={false}
      onClose={() => {
        setIsChangeTemplate(false);
        selectCard(defaultBulkExcelTemplate);
      }}
      BottomActionBar={<></>}
      hideButtons={true}
      className={'export-bulk-excel-sheet'}>
      <Row className="excel-container-width height">
        <Col span={viewDetailsActive ? 12 : 24} className="template-container">
          <Row className="view-details-header">
            <Row className="import-export-title">
              <Text className="drawer-header">{title}</Text>
            </Row>
            <Row className="change-template-description">
              <Text className="importExportWrapper_sub">{subTitle}</Text>
            </Row>
            {includeimageUrl ? (
              <IncludeImageUrl
                isImageUrl={isImageUrl}
                setIsImageUrl={setIsImageUrl}
              />
            ) : (
              <></>
            )}
            <Row justify="space-between" className="total-templates">
              <Col>
                <Text>
                  {templateList?.templates?.length} template(s) available
                </Text>
              </Col>
              <Col>
                <Text
                  className="create-new-template"
                  onClick={handleCreateTemplate}>
                  Create new template
                </Text>
              </Col>
            </Row>
            {!templateList?.templates?.length ? (
              <div
                className={
                  includeimageUrl
                    ? 'change-template-container-with-url'
                    : 'change-template-container'
                }>
                <Space className="change-template-skeleton">
                  {Array.from(Array(4).keys()).map((item) => (
                    <Skeleton.Input
                      size="large"
                      active={true}
                      key={item}
                      className="change-template-skeleton-item"
                      paragraph={{ width: '100%' }}
                      style={{ height: 120, width: 400 }}
                    />
                  ))}
                </Space>
              </div>
            ) : (
              <div
                className={
                  includeimageUrl
                    ? 'change-template-container-with-url'
                    : 'change-template-container'
                }>
                {templateList?.templates?.map((template) => (
                  <Row
                    key={template?.id}
                    onClick={() => selectCard(template)}
                    className={
                      template?.id === selectedCard?.id
                        ? 'template-card-active'
                        : 'template-card-inactive'
                    }>
                    <Row className="excel-container-width">
                      {template?.template_type === 'internal_custom' && (
                        <Col className="custom-tag">
                          <Col className="default-icon">
                            <BiStar />
                          </Col>
                          <Col className="default-tag-container">
                            <Text className="custom-tag-container-text">
                              CUSTOM
                            </Text>
                          </Col>
                        </Col>
                      )}
                      {template?.id === templateList?.default_template_id && (
                        <Col className="default-tag">
                          <Col className="default-icon">
                            <BiBookmark />
                          </Col>
                          <Col className="default-tag-container">
                            <Text className="default-tag-container-text">
                              DEFAULT
                            </Text>
                          </Col>
                        </Col>
                      )}
                    </Row>
                    <Row
                      className="excel-container-width"
                      justify={'space-between'}>
                      <Col>
                        <Text className="change-template-name">
                          {template?.name}
                        </Text>
                        {template?.id === selectedCard?.id && (
                          <Text className="change-template-icon">
                            <AiFillCheckCircle fontSize={18} />
                          </Text>
                        )}
                      </Col>

                      {!(
                        template?.id === templateList?.default_template_id ||
                        template?.template_type === 'default_custom'
                      ) && (
                        <Col>
                          <Text
                            onClick={(e) => {
                              e.stopPropagation();
                              trackEvent({
                                name: 'export_delete_template',
                                meta: {
                                  data: template,
                                  location: location.pathname,
                                  modules: 'Manage Products',
                                  property: 'Bulk excel Export',
                                },
                              });
                              return handleTemplateDelete(template);
                            }}
                            className="delete-template">
                            <MdDelete fontSize={20} color="#686E6D" />
                          </Text>
                        </Col>
                      )}
                    </Row>
                    <Row className="excel-container-width">
                      <Text className="excel-create-template-text">
                        {template?.selected_attributes?.value === 'all'
                          ? Object.keys(attributesList)?.length
                          : Object.keys(template?.selected_attributes?.value)
                              ?.length}{' '}
                        attributes
                      </Text>
                    </Row>
                    <Row className="excel-container-width">
                      <Col>
                        <Text
                          className="template-button"
                          onClick={() =>
                            viewTemplateDetails(
                              template?.selected_attributes,
                              template?.name
                            )
                          }>
                          View Details
                        </Text>
                      </Col>
                      {!(template?.id === templateList?.default_template_id) &&
                        template?.id === selectedCard?.id && (
                          <Col className="default-template-button-container">
                            <Text
                              className="template-default-button template-button"
                              onClick={() => {
                                trackEvent({
                                  name: 'export_set_as_default',
                                  meta: {
                                    location: location.pathname,
                                    modules: 'Manage Products',
                                    property: 'Bulk excel Export',
                                  },
                                });
                                setAsDefault(template);
                              }}>
                              Set as default
                            </Text>
                          </Col>
                        )}
                    </Row>
                  </Row>
                ))}
              </div>
            )}
          </Row>
          <ExcelTemplateFooter
            setIsDrawerActive={setIsChangeTemplate}
            prevButton={'Back'}
            nextButton={'Download'}
            nextButtonAction={() => {
              trackEvent({
                name: BULK_UPLOAD_DOWNLOAD_TEMPLATE_START,
                meta: {
                  modules: 'Bulk Upload',
                },
              });
              downloadExcelSheet(selectedCard?.id);
              selectCard(defaultBulkExcelTemplate);
              if (isBulkUpload) setIsImageUrlActive(false);
            }}
            prevButtonAction={() => selectCard(defaultBulkExcelTemplate)}
            isLoading={isBulkUploadExcelDownloading}
          />
        </Col>
        <Col
          span={viewDetailsActive ? 12 : 0}
          className="template-details-container template-container">
          {viewDetailsActive && viewDetails()}
          {viewDetailsActive && (
            <Row className="template-button-text" justify="center">
              <Button
                variant="outline_gray"
                className="close-button"
                onClick={() => setViewDetailsActive(false)}>
                <Text>Close</Text>
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </SWDrawer>
  );
};

export default ChangeTemplate;
