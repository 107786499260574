import { authActions } from './authActions';
import { settings } from './settings';
import { requirements } from './requirements';
import { filters } from './filters';
import { products } from './products';
import { collections } from './collections';
import { manufacturer } from './manufacturer';
import { buyerActions } from './buyerActions';
import { permissions } from './permissions';
import { sidebar } from './sidebar';
import { contactActions } from './contactsActions';
import { productActions } from './productV2Actions';
import { packagingAttributes } from './packagingAttributes';
import { bulkTableActions } from './bulkTableActions';
import { bulkUpdateActions } from './bulkUpdateActions';
import { quotationActions } from './quotationActions';
import {
  showDrawerAction,
  userSetAction,
  showModalAction,
  milestoneInfoAction,
} from './rewardsInfoActions';
import { packagingFields } from './packagingFields';
import { shipping } from './shipping';
import { manualCost } from './manualCost';
import { productAttributes } from './productAttributes';
import { containers } from './containers';
import { productContainers } from './productContainers';
import { currencySecondaryUnit } from './currencySecondaryUnit';
import { productData } from './productData';
import { costingInfo } from './CostingInfo';
import { secondaryUOM } from './secondaryUOM';
import { unsavedChanges } from './unsavedChanges';
import { defaultReload } from './defaultReload';
import { reloadURL } from './reloadURL';
import { pageLoading } from './pageLoading';
import { locale } from './locale';
import { smartSearch } from './smartSearch';
import { documentActions } from './documentActions';
import { countriesList } from './countriesList';
import { packagingMaterials } from './packagingMaterials';
import { costingPrefs } from './costingPrefs';
import { qrEnable } from './qrLabelEnable';
import { refreshAlgoliaHits } from './refreshAlgoliaHits';

import { guardrails } from './guardRails';
import { costingOverheads } from './costingOverheads';

const actions = {
  authActions,
  settings,
  requirements,
  filters,
  products,
  collections,
  manufacturer,
  buyerActions,
  permissions,
  sidebar,
  bulkTableActions,
  quotationActions,
  showDrawerAction,
  productActions,
  packagingAttributes,
  contactActions,
  packagingFields,
  shipping,
  manualCost,
  productAttributes,
  containers,
  productContainers,
  currencySecondaryUnit,
  productData,
  costingInfo,
  secondaryUOM,
  unsavedChanges,
  defaultReload,
  reloadURL,
  pageLoading,
  userSetAction,
  locale,
  smartSearch,
  documentActions,
  showModalAction,
  milestoneInfoAction,
  countriesList,
  guardrails,
  packagingMaterials,
  costingPrefs,
  qrEnable,
  bulkUpdateActions,
  refreshAlgoliaHits,
  costingOverheads,
};

export default actions;
