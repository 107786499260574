import { Row, Col } from 'antd';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import { Button } from 'components/index';

const ExcelTemplateFooter = ({
  setIsDrawerActive,
  prevButton = 'Back',
  nextButton = 'Download',
  nextButtonAction = '',
  prevButtonAction = '',
  isLoading,
}) => {
  const { trackEvent } = useTracking();
  const location = useLocation();
  const closeDrawer = () => {
    trackEvent({
      name: 'export_back',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setIsDrawerActive(false);
    prevButtonAction();
  };
  return (
    <Row className="create-excel-template-container" justify="space-between">
      <Col>
        <Button
          variant="outline_gray"
          size="large"
          className="excel-export-footer-button standard-button"
          onClick={closeDrawer}>
          {prevButton}
        </Button>
      </Col>
      <Col>
        <Button
          loading={isLoading}
          variant="dark"
          size="large"
          className="excel-export-footer-button standard-button"
          onClick={() => {
            nextButtonAction();
          }}>
          {nextButton}
        </Button>
      </Col>
    </Row>
  );
};

export default ExcelTemplateFooter;
