import { track } from 'react-tracking';
import { sendEvent } from './analytics.api';
import { getSession } from './sessionTracker';
import { parseJson } from '../utils/commonFunctions';

const BATCHING_COUNT = 0;
let globalBatch = [];

const sendBatch = async (batch) => {
  const session = await getSession();
  if (!session) return;

  const email = localStorage.getItem('email');
  if (email) {
    batch = batch.map((b) => ({ ...{ email: parseJson(email) }, ...b }));
  }
  const userDetails = localStorage.getItem('user');
  if (userDetails) {
    const user = parseJson(userDetails);
    batch = batch.map((b) => ({ ...{ user: !!user }, ...b }));
  }
  const requests = batch.map((b) => {
    return sendEvent(session?.sessionId, b.name, b).catch((e) => {
      console.log(`Error sending event ${b.name}`, e);
    });
  });

  try {
    await Promise.all(requests);
  } catch (e) {
    console.log('error calling the Batch ', e);
    return;
  }
};

const processDispatch = (data) => {
  globalBatch.push(data);
  if (globalBatch.length >= BATCHING_COUNT) {
    sendBatch(globalBatch);
    // reset batch
    globalBatch = [];
  }
};

const tracker = (Component) =>
  track(
    { app: 'sourceWiz' },
    {
      dispatch: processDispatch,
    }
  )(Component);

export default tracker;
