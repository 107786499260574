import { Col } from 'antd';
import { useGlobal } from 'store/index';
import { IoLogOutOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import {
  clearLocalStorageAndCookie,
  getRootDomain,
} from '../../../../../utils/logoutUsingCookies';
import Cookies from 'js-cookie';

export const LogoutComponent = () => {
  const [globalState, globalActions] = useGlobal();
  const history = useHistory();

  const handleLogOut = () => {
    clearLocalStorageAndCookie();
    localStorage.setItem('logout-event', 'logout' + Math.random());
    if (sessionStorage.getItem('routing-path')) {
      history.push(sessionStorage.getItem('routing-path'));
    } else {
      history.push('/');
    }
    globalActions.authActions.signoutAction();
    window.location.reload();
  };
  return (
    <>
      <Col
        style={{ color: '#4F5655', cursor: 'pointer' }}
        onClick={handleLogOut}>
        <IoLogOutOutline size={18} style={{ margin: '0.2rem 0.5rem 0 0' }} />
        <span style={{ verticalAlign: 'top' }}>Logout</span>
      </Col>
    </>
  );
};
