export const ActionTypes = Object.freeze({
  TOGGLE_CHECKBOX: 'manageProductsActions/TOGGLE_CHECKBOX',
  TEMP_TO_PROCESSING: 'manageProductsActions/TEMP_TO_PROCESSING',
  CLEAR_SELECTION: 'manageProductsActions/CLEAR_SELECTION',
  BULK_ACTION_ON_MESSAGE: 'manageProductsActions/BULK_ACTION_ON_MESSAGE',
  RETRY_TASK: 'manageProductsActions/RETRY_TASK',
  IGNORE_TASK: 'manageProductsActions/IGNORE_TASK',
  SET_INITIAL_DATA: 'manageProductsActions/SET_INITIAL_DATA',
  TOGGLE_ALL_DATA: 'manageProductsActions/TOGGLE_ALL_DATA',
  DISMISS_COMPLETED_TASKS: 'manageProductsActions/DISMISS_COMPLETED_TASKS',
});

export function toggleCheckbox(data) {
  return {
    type: ActionTypes.TOGGLE_CHECKBOX,
    payload: data,
  };
}

export function moveProductsFromTemoToProcessing(data) {
  return {
    type: ActionTypes.TEMP_TO_PROCESSING,
    payload: data,
  };
}

export function clearSelection() {
  return {
    type: ActionTypes.CLEAR_SELECTION,
  };
}

export function bulkActionOnMessage(data) {
  return {
    type: ActionTypes.BULK_ACTION_ON_MESSAGE,
    payload: data,
  };
}

export function retryTask(data) {
  return {
    type: ActionTypes.RETRY_TASK,
    payload: data,
  };
}

export function ignoreTask(data) {
  return {
    type: ActionTypes.IGNORE_TASK,
    payload: data,
  };
}

export function setInitialData(data) {
  return {
    type: ActionTypes.SET_INITIAL_DATA,
    payload: data,
  };
}

export function toggleAllData(data) {
  return {
    type: ActionTypes.TOGGLE_ALL_DATA,
    payload: data,
  };
}

export function dimissCompletedTasks(data) {
  return {
    type: ActionTypes.DISMISS_COMPLETED_TASKS,
    payload: data,
  };
}

export default {
  toggleCheckbox,
  moveProductsFromTemoToProcessing,
  clearSelection,
  bulkActionOnMessage,
  retryTask,
  ignoreTask,
  setInitialData,
  toggleAllData,
  dimissCompletedTasks,
  ActionTypes,
};
