import { Checkbox, Col, Divider, Row, Typography } from 'antd';
import { useEffect } from 'react';

const { Text } = Typography;

const SelectBulkAttributes = ({
  isCheck,
  setIsCheck,
  handleCheckBoxClick,
  isCheckAll,
  setIsCheckAll,
  attributesList,
  isBulkUpload = false,
}) => {
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    const attributes = attributesList?.map((attribute) => {
      return { id: attribute?.id, name: attribute?.name };
    });
    setIsCheck([
      ...isCheck,
      ...attributes.filter((attr) => {
        return !isCheck.map((item) => item.id).includes(attr.id);
      }),
    ]);
    if (isCheckAll) {
      setIsCheck(attributesList?.filter((item) => item?.isDisabled));
    }
  };

  useEffect(() => {
    if (isCheck.length === attributesList.length && isCheck.length !== 0) {
      setIsCheckAll(true);
    }
  }, [isCheck]);

  const CustomCheckBox = ({
    id,
    type,
    name,
    handleCheckBoxClick,
    isChecked,
    isDisabled,
  }) => {
    return (
      <Checkbox
        id={id}
        name={name}
        type={type}
        disabled={isDisabled}
        onChange={handleCheckBoxClick}
        checked={isChecked}>
        <Text className="select-attribute-checkbox"> {name}</Text>
      </Checkbox>
    );
  };

  const attributeList = attributesList?.map(({ id, name, isDisabled }) => {
    const isCheckboxChecked = () => {
      let flag = 0;
      for (let items of isCheck) {
        if (items.id === id) {
          flag = 1;
          return true;
        }
      }
      if (flag) {
        return false;
      }
    };
    return (
      <Row className="custom-checkbox-container" key={id}>
        <CustomCheckBox
          key={id}
          type="checkbox"
          name={name}
          id={id}
          isDisabled={isDisabled}
          handleCheckBoxClick={handleCheckBoxClick}
          isChecked={isCheckboxChecked()}
        />
      </Row>
    );
  });

  return (
    <Col className="select-attribute-container">
      <Row className="checkbox-container">
        <Col>
          <CustomCheckBox
            type="checkbox"
            name="Select All"
            id="selectAll"
            handleCheckBoxClick={handleSelectAll}
            isChecked={isCheckAll}
            isBulkUpload={isBulkUpload}
          />
        </Col>
      </Row>
      <Divider className="select-attribute-divider" />
      <Col className="select-attribute-list">
        {attributesList ? attributeList : 'Loading...'}
      </Col>
    </Col>
  );
};

export default SelectBulkAttributes;
