import DOCUMENT_CONSTANTS from 'constants/document';
import getSymbolFromCurrency from 'currency-symbol-map';

export const getConvertedValue = (value, unitTo) => {
  value = parseFloat(value);
  if (isNaN(value)) return '';
  const conversionMap = {
    inches: 0.393701,
    cm: 2.54,
    cms: 2.54,
  };

  return (value * conversionMap[unitTo]).toFixed(1);
};

export const getSanitizedSymbolFromCurrency = (currency) => {
  return getSymbolFromCurrency(currency) || currency;
};

export const sanitizeNAN = (text) => {
  return isNaN(text) ? '' : text;
};

export const convertPrice = (
  fromCurrency = DOCUMENT_CONSTANTS.defaultCurrency,
  toCurrency,
  value = 0,
  uomData = [],
  precision = 2
) => {
  const currencyArray = uomData;

  const referenceCurrency = (currencyArray || []).filter(
    (item) => item.reference_unit
  )[0];

  const currentCurrency = (currencyArray || []).filter(
    (item) => item.short_name === fromCurrency
  )[0];

  const conversionCurrency = (currencyArray || []).filter(
    (item) => item.short_name === toCurrency
  )[0];

  if (
    !currentCurrency ||
    currentCurrency?.short_name === conversionCurrency?.short_name
    // conversionCurrency.short_name === referenceCurrency.short_name
  ) {
    return value;
  }

  return parseFloat(
    (parseFloat(value || 0) * conversionCurrency.conversion_factor) /
      currentCurrency?.conversion_factor
  ).toFixed(precision);
};

// Get percentage value
export const getPercentageValue = (value, percentage) => {
  if (value && percentage) {
    const currVal = parseFloat(value);
    const percentageToCalc = parseFloat(percentage);
    const percentageVal = (currVal * percentageToCalc) / 100;
    return percentageVal;
  }
};
