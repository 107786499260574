import style from 'styled-components';
import { Modal, Typography, Row } from 'antd';
import { AvailablePlans } from './AvailablePlans/AvailablePlans';
import { HelpDesk } from 'routes/SourceWiz/Guardrails/Components/HelpDesk/HelpDeskDemo';
import { LogoutComponent } from './CommonFunction/LogoutComponent';

const { Title, Text } = Typography;
const PlanDiv = style.div`
margin-bottom: 1.7rem;
padding: 1.2rem;
border-radius: 8px;
background: linear-gradient(269.39deg, rgb(230, 223, 251, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
mix-blend-mode: normal;
`;
export const DemoExpiredModal = ({ isModalVisible, setIsModalVisible }) => {
  return (
    <>
      <Modal
        width={750}
        visible={isModalVisible}
        footer={null}
        closable={false}>
        <PlanDiv>
          <Row align="center">
            <Title level={4}>Your free trial has expired</Title>
          </Row>
          <Row align="center">
            <Text style={{ color: '#4F5655' }}>
              Select from our range of plans that best suit your requirements
            </Text>
          </Row>
        </PlanDiv>
        <AvailablePlans forExpiredModal={true} />
        <HelpDesk forExpiredModal={true} />
        <Row align="center">
          <LogoutComponent />
        </Row>
      </Modal>
    </>
  );
};
