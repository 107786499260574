import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Modal,
  Badge,
  Divider,
  Button,
  Skeleton,
} from 'antd';
import './UpgradePlanModal.css';
import { UpgradeRequestModal } from 'routes/SourceWiz/Guardrails/Components/UpgradeRequest/UpgradeRequestModal';
import { numberWithCommas } from 'routes/SourceWiz/Guardrails/Components/CommonFunction/commonFunctions';
import PYTHON_KEY from 'api/pythonService';
import { PostRequestConstants } from '../CommonFunction/PostRequestContents';
import style from 'styled-components';
const { Title, Text } = Typography;
const ButtonContainer = style.div`
  .ant-btn-default{
    margin: 1rem 0 1rem 0;
    background: ${(props) => props.is_current && '#C9E4DF'};
    color: ${(props) => (props.is_current ? '#0B7764' : '#4F5655')};
    border: ${(props) => props.is_current && 'none'};
    pointer-events: ${(props) => props.is_current && 'none'};
  }
`;
export const UpgradePlanModal = ({
  forDemo,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [upgradeRequest, setUpgradeRequest] = useState(false);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [isRequest, setIsRequest] = useState(false);

  useEffect(() => {
    // need to refactor  //
    if (isModalVisible) {
      getAvailablePlans();
    }
  }, [isModalVisible]);
  const getAvailablePlans = () => {
    setLoading(true);
    PYTHON_KEY.URL.get(PYTHON_KEY.path.availableplans, {})
      .then((res) => {
        if (res?.status === 200) {
          const data = res?.data;
          setAvailablePlans(data?.plan);
          const pendingPlanRequest = data?.pending_requests?.filter(
            (item) =>
              item?.feature_type === 'plan' && item?.status === 'pending'
          );
          if (pendingPlanRequest?.length > 0) {
            setIsRequest(true);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleSendRequest = (selected) => {
    const currentPlan = availablePlans?.filter((e) => e?.is_current === true);
    if (currentPlan[0]?.priority > selected?.priority) {
      setSelectedPlanData({
        request_type: 'downgrade',
        feature_type: 'plan',
        feature_identifier: selected?.id,
      });
    } else {
      setSelectedPlanData({
        request_type: 'upgrade',
        feature_type: 'plan',
        feature_identifier: selected?.id,
      });
    }
    if (selectedPlanData) {
      setIsModalVisible(false);
      setUpgradeRequest(true);
    }
  };
  return (
    <>
      <Modal
        width={650}
        visible={isModalVisible}
        closable
        footer={null}
        onCancel={() => setIsModalVisible(false)}>
        {loading ? (
          <Row
            justify="space-around"
            align="center"
            className="upgrade-cards-row">
            <Col className="plan-upgrade-loading-col">
              <br />
              <Skeleton active />
              <br />
              <br />
              <Skeleton active />
            </Col>
            <Col className="plan-upgrade-loading-col">
              <br />
              <Skeleton active />
              <br />
              <br />
              <Skeleton active />
            </Col>
            <Col className="plan-upgrade-loading-col">
              <br />
              <Skeleton active />
              <br />
              <br />
              <Skeleton active />
            </Col>
            <Col className="plan-upgrade-loading-col">
              <br />
              <Skeleton active />
              <br />
              <br />
              <Skeleton active />
            </Col>
          </Row>
        ) : isRequest ? (
          <PostRequestConstants />
        ) : (
          <>
            <Row align="center">
              <Title level={4}>
                {forDemo ? 'Subscribe to Sourcewiz' : 'Upgrade plan'}
              </Title>
            </Row>
            <Row align="center">
              <Text className="upgrade-modal-subtext">
                Select from our range of plans that best suit your requirements
              </Text>
            </Row>
            <>
              <Row
                align="center"
                justify="space-around"
                className="upgrade-cards-row">
                {availablePlans?.map((e) => {
                  return (
                    <Badge
                      count={'Popular'}
                      style={{
                        display: `${
                          e?.name?.toUpperCase() === 'GOLD' ? 'block' : 'none'
                        }`,
                      }}
                      offset={[-87, 2]}
                      color="linear-gradient(92.76deg, #694CBB -4.7%, #FFA42B 150.67%)">
                      <Card
                        align="center"
                        className="upgrade-plan-cards"
                        hoverable>
                        <Text>{e?.name?.toUpperCase()}</Text>
                        <Divider className="upgarde-plan-modal-divider" />
                        <Title level={5}>₹ {numberWithCommas(e?.amount)}</Title>
                        <Text className="upgrade-text-small">per year</Text>
                        <div
                          className={
                            e?.is_current
                              ? 'upgrade-wrapper-button-disabled'
                              : 'upgrade-wrapper-button-active'
                          }>
                          <ButtonContainer is_current={e?.is_current}>
                            <Button onClick={() => handleSendRequest(e)}>
                              {e?.is_current ? 'Current plan' : 'Send request'}
                            </Button>
                          </ButtonContainer>
                        </div>
                        {e?.meta?.benefits?.map((el) => {
                          return (
                            <>
                              <Row
                                align="center"
                                className="upgrade-row-benefits">
                                <Text className="upgrade-modal-subtext">
                                  {el}
                                </Text>
                              </Row>
                            </>
                          );
                        })}
                      </Card>
                    </Badge>
                  );
                })}
              </Row>
              <br />
              <div
                align="center"
                className="complete-plan-div"
                onClick={() =>
                  window.open('https://www.sourcewiz.co/pricing', '_blank')
                }>
                <Text>View complete plan details</Text>
              </div>
              <br />
              <Row align="center">
                <Text className="upgrade-modal-subtext">
                  Call <b>+919215777736</b> or mail us at
                  <a
                    href="mailto: support@sourcewiz.co"
                    className="upgrade-plan-support-text">
                    {' '}
                    support@sourcewiz.co
                  </a>{' '}
                  for enterprise plan
                </Text>
              </Row>
            </>
          </>
        )}
      </Modal>
      <UpgradeRequestModal
        requestPayload={selectedPlanData}
        upgradeRequestModal={upgradeRequest}
        setUpgradeRequestModal={setUpgradeRequest}
      />
    </>
  );
};
