import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Tag,
  Tooltip,
  Upload,
  message,
  Form,
  Input,
  Progress,
  Alert,
} from 'antd';
import { Colors } from '../../theme';
import API_KEY from '../../api';
import axios from 'axios';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

const { CheckableTag } = Tag;
const { Title, Text } = Typography;
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//     // message.error(intl.formatMessage({id: 'message.error.uploadOnlyJPGPNG'}));
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return isJpgOrPng && isLt2M;
// }

export default function Certifications({ form }) {
  const intl = useIntl();
  const [certificationsData, setCertificationsData] = useState(null);
  const [selectedCertifications, setSelectedCertifications] = useState(['']);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);
  const [logo, setLogo] = useState(null);
  const [progress, setProgress] = useState(0);
  // api call for certifications
  useEffect(() => {
    API_KEY.URL.get(API_KEY.path.certifications)
      .then((res) => {
        setCertificationsData(res.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // set selected certifications in form field
  useEffect(() => {
    form.setFieldsValue({
      certifications: selectedCertifications,
      logo: logo,
    });
  }, [selectedCertifications, logo, form]);

  // handle selected certification tag
  const handleChange = (tag, checked) => {
    console.log(tag, 'selected tag');
    const nextSelectedTags = checked
      ? [...selectedCertifications, tag]
      : selectedCertifications.filter((t) => t !== tag);
    setSelectedCertifications(nextSelectedTags);
  };

  //handle image upload
  const handleChangeUpload = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImageUrl(imageUrl),
        setLoading(false)
      );
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append('image', file);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_DIRECTUS}files`,
        fmData,
        config
      );

      onSuccess('Ok');
      console.log('server res: ', res);
      setLogo(res.data?.data?.id);
    } catch (err) {
      setError('Something went wrong');
      onError({ err });
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      // message.error("You can only upload JPG/PNG file!");
      message.error(
        intl.formatMessage({ id: 'message.error.uploadOnlyJPGPNG' })
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error("Image must smaller than 2MB!");
      message.error(
        intl.formatMessage({ id: 'message.error.imageMustbeSmaller' })
      );
    }
    return isJpgOrPng && isLt2M;
  }

  return (
    <>
      {error && <Alert type="error" message={error} banner />}
      <Row>
        <Title level={4} style={{ fontFamily: 'SatoshiBold' }}>
          Make it authentic
        </Title>
      </Row>
      <Row>
        <Text style={{ color: Colors.gray }}>
          Select the product certifications. Upload your company logo too
        </Text>
      </Row>

      <Row>
        <span
          style={{ color: 'black', fontWeight: 'bolder', marginTop: '2rem' }}>
          Certifications (optional)
        </span>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col>
          {certificationsData &&
            certificationsData.map((item) => (
              <Tooltip key={item?.id} title={item?.meta}>
                <CheckableTag
                  style={{
                    padding: '0.5rem',
                    marginRight: '1rem',
                    marginBottom: '1rem',
                    border: '1px solid lightgray',
                    borderRadius: 4,
                  }}
                  key={item?.id}
                  checked={selectedCertifications.indexOf(item) > -1}
                  onChange={(checked) => handleChange(item, checked)}>
                  {item?.name}
                </CheckableTag>
              </Tooltip>
            ))}
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col>
          <span
            style={{
              color: 'black',
              fontWeight: 'bolder',
              marginTop: '2rem',
            }}>
            Company Logo (optional)
          </span>
        </Col>
      </Row>
      <Row style={{ marginTop: '2rem' }}>
        <Col>
          {/* upload image component */}
          <Upload
            name="logo"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action={`${process.env.REACT_APP_DIRECTUS}files`}
            customRequest={uploadImage}
            beforeUpload={beforeUpload}
            onChange={handleChangeUpload}
            // headers={{
            //   "Access-Control-Allow-Origin": "*",
            //   "Access-Control-Allow-Methods": "POST",
            //   "Access-Control-Allow-Headers":
            //     "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            //   "Access-Control-Allow-Credentials": "true",
            // }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </Col>
      </Row>

      <div hidden>
        <Form.Item label="certifications" name="certifications">
          <Input />
        </Form.Item>
      </div>

      <div hidden>
        <Form.Item label="logo" name="logo">
          <Input />
        </Form.Item>
      </div>
    </>
  );
}
