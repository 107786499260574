export const FilterActions = Object.freeze({
  TOGGLE_DRAWER: 'filterSettingAction/TOGGLE_DRAWER',
  SHOW_STEP_INCREMENT: 'filterSettingAction/SHOW_STEP_INCREMENT',
  GET_MAIN_FILTERS: 'filterSettingAction/GET_MAIN_FILTERS',
  FILTER_DATA: 'filterSettingAction/FILTER_DATA',
  UPDATE_FILTER: 'filterSettingAction/UPDATE_FILTER',
  FETCH_DATA: 'filterSettingAction/FETCH_DATA',
});

export const toggleDrawer = (data) => ({
  type: FilterActions.TOGGLE_DRAWER,
  payload: data,
});

export const getMainFilters = (data) => ({
  type: FilterActions.GET_MAIN_FILTERS,
  payload: data,
});

export const getFilterData = (data) => ({
  type: FilterActions.FILTER_DATA,
  payload: data,
});

export const toggleStepIncrementField = (data) => ({
  type: FilterActions.SHOW_STEP_INCREMENT,
  payload: data,
});

export const updateFilterdata = (data) => ({
  type: FilterActions.UPDATE_FILTER,
  payload: data,
});

export const fetchData = () => ({
  type: FilterActions.FETCH_DATA,
});

const ACTION_HANDLERS = {
  toggleDrawer,
  toggleStepIncrementField,
  getMainFilters,
  getFilterData,
  updateFilterdata,
  fetchData,
};

export default ACTION_HANDLERS;
