import PYTHON_KEY from 'api/pythonService';
import API_KEY from 'api';
import { QUOTATION_KEY } from './quotationApis';
import { PYTHON_API_KEY } from './customApis';

const pythonBaseUrl = process.env.REACT_APP_API_SERVICE;

export const getRewardSetupStatus = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}rewardsv2/get_setup_status`, {
    headers: { 'access-token': `${token}` },
  });
};

export const rewardSetupStatusUpdate = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}rewardsv2/setup_done`,
    {},
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const updateUserPoints = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}rewardsv2/update_user_points`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const updateClaimStatus = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}rewardsv2/update_claim_status`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

const is_rewards_url = (response) => {
  if (response?.status !== 200) {
    return { status: false };
  }

  const filtered = Object.fromEntries(
    Object.entries(rewards_update_hash).filter(([key, v]) =>
      response.config.url.includes(key)
    )
  );
  if (Object.keys(filtered).length === 0) {
    return { status: false };
  }

  var key = Object.keys(filtered)[0];
  if (!(response?.config?.method in rewards_update_hash[key])) {
    return { status: false };
  }

  if (
    Object.keys(rewards_update_hash[key][response?.config?.method]).includes(
      'validation'
    ) &&
    !rewards_update_hash[key][response?.config?.method].validation(response)
  ) {
    return { status: false };
  }

  var count = rewards_update_hash[key][response?.config?.method].count;
  if (typeof count === 'function') {
    count = count(response);
  }

  var info = {
    action_name: rewards_update_hash[key][response?.config?.method].action,
    count: count,
  };

  return { status: true, payload: info };
};

const updateReward = (is_valid) => {
  updateUserPoints(is_valid?.payload)
    .then((res) => {
      console.log('Updated user reward points');
    })
    .catch((error) => {
      console.error(error);
    });
};

//product edit
QUOTATION_KEY.URL.interceptors.response.use(
  async (response) => {
    const is_valid = is_rewards_url(response);
    if (is_valid?.status) {
      updateReward(is_valid);
    }
    return response;
  },
  (error) => {
    console.error(error);
  }
);

PYTHON_KEY.URL.interceptors.response.use(
  async (response) => {
    const is_valid = is_rewards_url(response);
    if (is_valid?.status) {
      updateReward(is_valid);
    }
    return response;
  },
  (error) => {
    console.error(error);
  }
);

PYTHON_API_KEY.URL.interceptors.response.use(
  async (response) => {
    const is_valid = is_rewards_url(response);
    if (is_valid?.status) {
      updateReward(is_valid);
    }
    return response;
  },
  (error) => {
    console.error(error);
  }
);

API_KEY.URL.interceptors.response.use(
  async (response) => {
    const is_valid = is_rewards_url(response);
    if (is_valid?.status) {
      updateReward(is_valid);
    }
    return response;
  },
  (error) => {
    console.error(error);
  }
);

const checkIfCatalogueCreate = (response) => {
  if (response?.data?.data?.status === 'published') {
    return true;
  }
  return false;
};
const checkIfQuotationCreate = (response) => {
  const parsedData = JSON.parse(response?.config?.data);
  if (parsedData.status === 'published') {
    return true;
  }
  return false;
};

// handle rewards action and the points triggering
const rewards_update_hash = {};
rewards_update_hash[API_KEY.path.addProduct] = {
  post: {
    action: 'product_add',
    count: 1,
  },
  patch: {
    action: 'product_edit',
    count: 1,
  },
};

rewards_update_hash[API_KEY.path.collections] = {
  patch: {
    action: 'catalogue_create',
    count: 1,
    validation: checkIfCatalogueCreate,
  },
};

rewards_update_hash[PYTHON_API_KEY.path.createQuotation] = {
  patch: {
    action: 'quotation_create',
    count: 1,
    validation: checkIfQuotationCreate,
  },
};
