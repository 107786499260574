import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import '../BulkUpdate/index.less';
import { MdDelete, MdDragIndicator } from 'react-icons/md';

const { Text } = Typography;

const SortableItem = SortableElement(
  ({ id, name, deleteAttribute, index, isDisabled, isBulkUpload }) => (
    <Row
      key={index}
      tabIndex={index}
      className={
        isBulkUpload
          ? 'bulk-upload-sortable-item-container'
          : 'sortable-item-container'
      }>
      <Col span={2}>
        <MdDragIndicator fontSize="20px" />
      </Col>
      <Col span={20}>
        <Text className="sortable-item-text">{name.trim()}</Text>
      </Col>
      <Col span={2} className="sortable-item-button-container">
        <Button
          isDisabled={isDisabled}
          onClick={() => (isDisabled ? {} : deleteAttribute(id, name))}
          className="sortable-item-button">
          {isDisabled ? <></> : <MdDelete fontSize="20px" color="#9B9F9E" />}
        </Button>
      </Col>
    </Row>
  )
);

const SortableList = SortableContainer(
  ({ isCheck, handleCheckBoxClick, isBulkUpload = false }) => {
    const deleteAttribute = (id, name) => {
      const e = {
        target: {
          id: id,
          checked: false,
          name: name,
          isDisabled: false,
        },
      };
      handleCheckBoxClick(e);
    };

    return (
      <div className="excel-container-width">
        {isCheck.map(({ name, id, isDisabled = false }, index) => (
          <SortableItem
            key={index}
            id={id}
            name={name}
            index={index}
            isDisabled={isDisabled}
            deleteAttribute={deleteAttribute}
            isBulkUpload={isBulkUpload}
          />
        ))}
      </div>
    );
  }
);

const SortableComponent = ({
  isCheck,
  setIsCheck,
  handleCheckBoxClick,
  isBulkUpload = false,
  compulsoryAttributes = [],
}) => {
  const arrayMove = (array, fromIndex, toIndex) => {
    if (fromIndex === toIndex) return array;
    const updatedArray = [...array];
    const target = updatedArray[fromIndex];
    const incrementValue = toIndex < fromIndex ? -1 : 1;
    for (let i = fromIndex; i !== toIndex; i += incrementValue) {
      updatedArray[i] = updatedArray[i + incrementValue];
    }
    updatedArray[toIndex] = target;
    return updatedArray;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsCheck(arrayMove(isCheck, oldIndex, newIndex));
  };
  return (
    <Row className="excel-container-width height">
      <Row className="sortable-container-head">
        <Text>{isCheck.length} attributes selected</Text>
      </Row>
      <Row className="sortable-list-container">
        <SortableList
          isCheck={isCheck}
          // isCheck={compulsoryAttributes}
          onSortEnd={onSortEnd}
          handleCheckBoxClick={handleCheckBoxClick}
          isBulkUpload={isBulkUpload}
          compulsoryAttributes={compulsoryAttributes || []}
        />
      </Row>
    </Row>
  );
};

export default SortableComponent;
