import { useState, useEffect } from 'react';
import API_KEY from 'api';
import { getCountryCodes } from 'api/customApis';
const useSelect = (datasource, url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    switch (datasource) {
      case 'users':
        return users();

      case 'countries':
        return countries();
      default:
        return [];
    }
  }, [url, datasource]);

  const countries = async () => {
    // setData(COUNTRIES);
    // setLoading(false);
    try {
      const res = await getCountryCodes();
      setData(
        res?.data.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.nicename,
          };
        })
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const users = async () => {
    setLoading(true);
    const orgDetails = localStorage.getItem('org');
    const orgDetailsJson = JSON.parse(orgDetails);
    const orgId = orgDetailsJson?.id;
    try {
      const users = await API_KEY.URL.get(API_KEY.path.users, {
        params: {
          filter: { org: { _eq: orgId } },
        },
      });
      setLoading(false);
      return setData(
        users.data.data.map((user) => ({
          value: user.id,
          label: user.first_name + ' ' + user.last_name,
        }))
      );
    } catch (error) {
      setLoading(false);
      setError('error', error);
    }
  };

  return [data, loading, error];
};

export default useSelect;

const COUNTRIES = [
  {
    value: 'US',
    label: 'United States',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'MX',
    label: 'Mexico',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'BR',
    label: 'Brazil',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'IND',
    label: 'India',
  },
  {
    value: 'BAN',
    label: 'Bangladesh',
  },
];
