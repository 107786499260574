import React, { useState, useEffect } from 'react';
import QrLabelListing from '../ProductV2/Product/Components/QrLabelListing';
import SWDrawer from 'react-common-components/SWDrawer';
import { MODULE_LIST } from 'routes/SourceWiz/Guardrails/Heimdall/ModuleList';
import { getProductAttributes } from 'api/customApis';
import { useGlobal } from '../../../store/index';
import { QUOTATION_KEY } from 'api/quotationApis';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Selectors from '../ManageProducts/selectors';
import QRDownloaded from 'routes/SourceWiz/labelQR/QRDownloaded';

function QrDrawer() {
  const [show, setShow] = useState(false);
  const [attributeEnable, setAttributeEnable] = useState(false);
  const [qrData, setQrData] = useState([]);
  const [defaultTemp, checkDefaultTemp] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultSelectedTemplates, setDefaultSelectTemplates] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  const [attributesData, setAttributesData] = useState([]);
  const [isGuardrails, setIsGuardrails] = useState(false);
  const [qrDataGuardrails, setQrDataGuardrails] = useState({});
  const userData = localStorage.getItem('user');
  const user = userData && JSON.parse(userData);
  const [global] = useGlobal();
  const [globalState, globalActions] = useGlobal();
  const { qrEnable, productIdForQr } = globalState;
  const { productData, productAttributes, selectedtemp } = globalState;
  const { usage } = global;
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(false);
  const [showQRDownloadedModal, setShowQRDownloadedModal] = useState(false);

  useEffect(() => {
    if (Array.isArray(usage) && usage.length > 0) {
      setQrDataGuardrails(
        usage.filter(
          (item) => item?.module_name === MODULE_LIST.qr_or_label_generator.id
        )?.[0]
      );
    }
  }, [usage]);

  const selectedProducts = useSelector((state) =>
    Selectors.selectTempCheckedProducts()(state)
  );

  const getAttributes = () => {
    getProductAttributes()
      .then((res) => {
        if (res?.status === 200) {
          setAttributesData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQRData();
  }, []);

  useEffect(() => {
    getAttributes();
  }, []);

  const getQRData = () => {
    setLoading(true);
    QUOTATION_KEY.URL.get(`${QUOTATION_KEY.path.getQrTemplates}`, {
      headers: {
        'access-token': user?.access_token,
      },
    }).then((res) => {
      // console.log(res);
      if (res?.status === 200) {
        setLoading(false);
        setQrData(res?.data?.data?.all_template_json);
        setDefaultSelectTemplates(res?.data?.data?.default_selected_attributes);
        setIsDefault(res?.data?.data?.default_template);
        if (res?.data?.data?.default_template?.id) {
          checkDefaultTemp(true);
          setIsGuardrails(true);
        } else {
          checkDefaultTemp(false);
        }
      }
    });
  };

  const isBulk = location.pathname.includes('manage-products');

  const productInfoData = {
    product_id: productData?.product_id || '',
    variants: productData?.variants || [],
  };

  const splitIDs = Object.values(selectedProducts).reduce(
    (combinedAggregate, currentItem) => {
      const { variants = {} } = currentItem;
      const { parent_id, variant_ids } = variants;

      combinedAggregate['mainProducts'].push(parent_id);
      combinedAggregate['variantProducts'].push(
        ...variant_ids.filter((variant) => variant !== parent_id)
      );

      return combinedAggregate;
    },
    { mainProducts: [], variantProducts: [] }
  );

  return (
    <>
      <QRDownloaded
        visible={showQRDownloadedModal}
        setVisible={setShowQRDownloadedModal}
        bulk={isBulk}
        productDict={isBulk ? splitIDs : productInfoData}
      />
      <SWDrawer
        success="Success"
        dismiss="Cancel"
        // title="Select QR label"
        width={attributeEnable ? 980 : 480}
        show={qrEnable}
        closable
        setShow={false}
        onClose={() => {
          globalActions.qrEnable.setQrLabelEnable(false);
          globalActions.qrEnable.setSelectedTemp({});
        }}
        // confirmShow={confirmShow}
        // setConfirmShow={setConfirmShow}
        BottomActionBar={<></>}
        hideButtons={true}>
        <QrLabelListing
          qrData={qrData}
          id={
            //   (productIdForQr && productIdForQr !== '' ||  productIdForQr && productIdForQr[0] !== null) ? productIdForQr :
            isBulk
              ? selectedProducts
              : productData?.id
              ? productData?.id
              : productIdForQr
          }
          productData={
            //   (productIdForQr && productIdForQr !== '' || productIdForQr && productIdForQr[0] !== null) ? productIdForQr :
            isBulk
              ? selectedProducts
              : productData
              ? productData
              : productIdForQr
          }
          setAttributeEnable={setAttributeEnable}
          attributeEnable={attributeEnable}
          setShow={setShow}
          selectedItem={selectedItem}
          show={show}
          bulk={isBulk}
          getQRData={getQRData}
          setLoading={setLoading}
          loading={loading}
          isGuardrails={isGuardrails}
          setIsGuardrails={setIsGuardrails}
          qrDataGuardrails={qrDataGuardrails}
          isProfile={
            location.pathname.includes('profile-settings') ? true : false
          }
          setDefaultSelectTemplates={setDefaultSelectTemplates}
          defaultSelectedTemplates={defaultSelectedTemplates}
          attributesData={attributesData}
          setSelectedItem={setSelectedItem}
          setShowQRDownloadedModal={setShowQRDownloadedModal}
        />
      </SWDrawer>
    </>
  );
}

export default QrDrawer;
