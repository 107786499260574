import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Input,
  Row,
  Col,
  Typography,
  Form,
  message,
  Skeleton,
  Checkbox,
} from 'antd';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'redux/actions/manageProductsActions';
import Selectors from './selectors';
import { ExclamationCircleFilled } from '@ant-design/icons';
import API_KEY from 'api';
import utils from './utils';
import bulkActions from 'api/bulkActionApis';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import { DELETE_PRODUCT_CONFIRM } from 'analytics/events';
import { useIntl, FormattedMessage } from 'react-intl';

const { Title, Text } = Typography;

export default function DeleteModal({ action, visible, closeModal }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [selectionCount, setSelectionCount] = useState(0);
  const [inputData, setInputData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [cataloguePrdCount, setCataloguePrdCount] = useState(0);

  const [loaderData, setLoaderData] = useState(false);
  const [checked, setChecked] = useState(false);
  const { trackEvent } = useTracking();
  const location = useLocation();

  const dispatch = useDispatch();
  const actions = bindActionCreators(
    {
      moveProductsFromTemoToProcessing:
        Actions.moveProductsFromTemoToProcessing,
    },
    dispatch
  );
  const tempData = useSelector((state) =>
    Selectors.selectTempCheckedProducts()(state)
  );

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    setLoaderData(true);

    let finalSelection = utils.getSelectionData(tempData);
    let selectedCount = utils.getSelectionCount(tempData);

    setSelectionCount((state) => selectedCount);

    const payload = Object.keys(finalSelection)
      ? Object.keys(finalSelection).map(Number)
      : [];
    setInputData(payload);

    setDeleteCount(payload.length);
    setCataloguePrdCount(0);
    setLoaderData(false);
  }, []);

  const handleChecked = () => {
    setChecked((state) => !state);
  };

  const handleLeftButton = () => {
    closeModal();
  };

  const handleRightButton = () => {
    trackEvent({
      name: DELETE_PRODUCT_CONFIRM,
      meta: {
        location: location.pathname,
        modules: 'Delete confirm product',
      },
    });
    const a = form.getFieldsValue(true);

    if (checked) {
      setLoading(true);
      bulkActions
        ?.bulkDeleteData(inputData)
        .then((res) => {
          if (res.status === 200) {
            actions.moveProductsFromTemoToProcessing({
              action: 'delete_products',
              task_id: res?.data?.task_id,
            });

            // message.success("Product Delete in Progress");
            message.success(
              `${intl.formatMessage({
                id: 'message.success.prodDeleteInProgress',
              })}`
            );
            setLoading(false);
            closeModal(true);
          }
        })
        .catch((err) => {
          message.error(
            // "Please wait for ongoing tasks to finish. Maximum 3 tasks are allowed"
            intl.formatMessage({ id: 'message.error.waitOngoingTasks' })
          );
          setLoading(false);
        });
    } else {
      message.warning(
        intl.formatMessage({ id: 'message.warning.pleaseConfirm' })
      );
    }
  };

  const renderForm = () => {
    return (
      <Row>
        <Col
          span={24}
          style={{
            background: '#F5F5F5',
            paddingTop: '1em',
            paddingRight: '1em',
            paddingLeft: '1em',
            borderRadius: '4px',
            marginTop: '1em',
          }}>
          <Form.Item
            name={'CHECK'}
            style={{ fontSize: 14 }}
            rules={[
              {
                required: true,
                // message: "Please Confirm",
                message: intl.formatMessage({
                  id: 'sourcewiz.button.pleaseConfirm',
                }),
              },
            ]}>
            <Row style={{ paddingLeft: '0.5em', paddingRight: '0.5em' }}>
              {/* {cataloguePrdCount > 0
                ? `These products that are part of catalogues cannot be deleted. Are you sure you want to continue?`
                : `These products will be deleted permanently and cannot be restored.
              Are you sure you want to continue?`} */}
              <Text
                style={{
                  paddingTop: '0.4em',
                  paddingLeft: '0.4em',
                  color: '#4F5655',
                }}>
                {/* These products will be deleted permanently and cannot be restored.
              Are you sure you want to continue? */}
                {intl.formatMessage({
                  id: 'sourcewiz.bulkActions.confirmDelete',
                })}
              </Text>
            </Row>
            <Row
              style={{
                paddingTop: '1em',
                paddingLeft: '0.5em',
                paddingRight: '0.5em',
              }}>
              <Checkbox onChange={handleChecked} />{' '}
              <Text style={{ paddingTop: '0.4em', paddingLeft: '0.4em' }}>
                {/* Yes, I am sure */}
                {intl.formatMessage({
                  id: 'sourcewiz.discardModal.checkboxText',
                })}
              </Text>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const catProductsSelectedNotify = () => {
    return (
      <Row
        style={{
          background: '#FFF1F1',
          padding: '1em',
          marginTop: '1em',
          borderRadius: '0.5em',
        }}>
        <Col>
          <Row>
            <Text
              style={{
                color: '#F34D4D',
                fontSize: '16px',
                fontWeight: 'bold',
              }}>
              {/* <ExclamationCircleFilled style={{ paddingRight: "0.2em" }} />{" "}
              {cataloguePrdCount} products cannot be deleted */}
              <ExclamationCircleFilled style={{ paddingRight: '0.2em' }} />{' '}
              {cataloguePrdCount}{' '}
              {intl.formatMessage({
                id: 'sourcewiz.bulkActions.cannotBeDeleted',
              })}
            </Text>
          </Row>

          <Row>
            <Text
              type="secondary"
              style={{
                color: '#4F5655',
                fontSize: '14px',
                paddingLeft: '1.5em',
              }}>
              {/* All these products are part of catalogues created */}
              {intl.formatMessage({
                id: 'sourcewiz.bulkActions.delete.message',
              })}
            </Text>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal
        closeIcon={() => null}
        visible={visible}
        footer={null}
        centered={true}
        width={450}
        destroyOnClose={true}>
        <Form form={form} name="delete_products" {...formItemLayout}>
          {loaderData ? (
            <Skeleton active />
          ) : (
            <>
              <Row>
                <Col>
                  <Title level={4}>
                    {/* {`Delete ${selectionCount} products`} */}
                    <FormattedMessage
                      id={'sourcewiz.bulkActions.deleteProds'}
                      values={{ numOfProds: selectionCount }}
                    />
                  </Title>
                </Col>
              </Row>

              {renderForm()}
            </>
          )}
          <Row justify="space-between" style={{ marginTop: '2em' }}>
            <Col span={11}>
              <Button
                size="large"
                style={{ width: '100%' }}
                onClick={handleLeftButton}>
                {/* Cancel */}
                {intl.formatMessage({ id: 'quotations.cancel' })}
              </Button>
            </Col>
            <Col span={11}>
              <Button
                // disabled={deleteCount === 0 ? true : false}
                htmlType="submit"
                loading={loading}
                size="large"
                onClick={handleRightButton}
                style={
                  deleteCount !== 0
                    ? {
                        color: 'white',
                        background: '#363E3D',
                        border: 'none',
                        marginLeft: 0,
                        width: '100%',
                      }
                    : {
                        width: '100%',
                        float: 'right',
                      }
                }>
                {/* Delete */}
                {intl.formatMessage({ id: 'sourcewiz.button.delete' })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
