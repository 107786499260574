import { useEffect, useState } from 'react';
import SourceWiz from '../routes/SourceWiz/index';
import Onboarding from '../routes/Onboarding/index';
import API_KEY from '../api';
import { useGlobal } from '../store/index';
import Loading from './Loading';
import ability from '../casl/ability';
import { AbilityBuilder } from '@casl/ability';
// import { updateRewardInfo } from "routes/SourceWiz/Dashboard/RewardCard";
import * as Sentry from '@sentry/react';
import { getCountryCodes } from 'api/customApis';
import { IntlProvider, FormattedMessage, FormattedNumber } from 'react-intl';
import eng from 'localisation/en-US.json';
import turk from 'localisation/tr.json';
import Tracker from '@openreplay/tracker';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const userData = localStorage.getItem('user');
const user = JSON.parse(userData);

Sentry.setUser({
  email: localStorage.getItem('email'),
  id: localStorage.getItem('userId'),
});

export default function MainApp() {
  const [globalState, globalActions] = useGlobal();
  const [spinning, setSpinning] = useState(false);
  const [locale, setLocale] = useState('en');
  const { permissions } = globalState;

  const languages = {
    en: eng,
    tr: turk,
  };

  useEffect(() => {
    const openClaimModalEvent = window.addEventListener(
      'open-claim-modal',
      function ({ detail }) {
        globalActions.milestoneInfoAction.setMilestoneInfo(detail);
        globalActions.showModalAction.setShowModal(true);
      }
    );
    return () => {
      window.removeEventListener(openClaimModalEvent, null);
    };
  }, [globalActions]);

  const defineRulesFor = (auth) => {
    const permissions = auth?.permissions;
    const { can, rules } = new AbilityBuilder();

    // This logic depends on how the
    // server sends you the permissions array
    if (permissions) {
      permissions.forEach((p) => {
        if (p.toggleValue) {
          can(p?.slug, p?.permissionType);
        }
        //
      });
    }
    //  window.location.reload();
    return rules;
  };

  useEffect(() => {
    if (permissions) {
      ability.update(defineRulesFor(permissions));
    }
  }, [permissions]);

  useEffect(() => {
    let isMounted = true;
    const userEmail = localStorage.getItem('email');
    setSpinning(true);
    API_KEY.URL.get(API_KEY.path.users, {
      params: {
        'filter[email][_eq]': JSON.parse(userEmail),
      },
    })
      .then((res) => {
        if (!isMounted) {
          return;
        }
        const userId = res.data?.data[0]?.id;
        const is_admin = res.data?.data[0]?.is_admin;
        const is_system_user = res.data?.data[0]?.is_system_user;
        const userData = res?.data?.data[0];
        // const mobile = res?.data?.data[0]?.mobile;
        const user_data = {
          mobile: res?.data?.data[0]?.mobile,
          email: res?.data?.data[0]?.email,
          country: res?.data?.data[0]?.country,
        };
        const getCountryCodesCall = async () => {
          try {
            const res3 = await getCountryCodes();
            user_data.country = res3.data.data[user_data?.country - 1];
            user_data.countryCodes = res3.data.data;
            globalActions.countriesList.setCountriesList(res3.data.data);
          } catch (e) {}
        };
        getCountryCodesCall();

        globalActions.userSetAction.updateUserInfo(user_data);
        localStorage.setItem('userId', JSON.stringify(userId));
        localStorage.setItem('isAdmin', is_admin);
        localStorage.setItem('is_system_user', is_system_user);
        //get permissions for CASL to work
        globalActions.permissions.getPermissions(userId);
        API_KEY.URL.get(API_KEY.path.manufacturer, {
          params: {
            // access_token: JSON.parse(user)?.access_token,
          },
        })
          .then((res) => {
            if (res.data.data.length > 0 && isMounted) {
              localStorage.setItem(
                'pref',
                JSON.stringify(res.data?.data?.[0]?.recommendation_preferences)
              );
              if (res.data.data[0].status === 'published') {
                globalActions.authActions.setOnboarding(false);
              }
            } else if (res.data.data.length === 0) {
              globalActions.authActions.setOnboarding(true);
            }

            const locale = res?.data?.data?.[0]?.locale || 'en';

            setLocale(locale);
            setSpinning(false);

            // openrelay integration

            const tracker = new Tracker({
              projectKey: 'QSGTOaMj5AZXQMcwjR3g',
            });

            if (process.env.REACT_APP_ENV === 'prod') {
              tracker
                .start({
                  userID: userData?.first_name + ' ' + userData?.last_name,
                  metadata: {
                    email: userData?.email,
                    mobile: userData?.mobile,
                    country: userData?.country,
                    isAdmin: userData?.is_admin,
                    userId: userData?.id,
                  },
                })
                .then((startedSession) => {
                  if (startedSession.success) {
                    console.log('session started:', startedSession);
                  }
                });
              // // Userguiding integration
              // const mid = res?.data?.data[0]?.id.toString();
              // window.userGuiding.identify(userId, {
              //   id: userId,
              //   email: userEmail,
              //   manufacturer: mid,
              // });
            }
          })
          .catch((err) => {
            console.log(err);
            setSpinning(false);
          });
      })
      .catch((err) => {
        setSpinning(false);
        if (err?.response?.status > 399) {
          clearLocalStorageAndCookie();
          window.location.reload();
        }
      });
    return () => {
      isMounted = false;
    };
  }, [globalActions]);

  if (spinning) {
    return <Loading />;
  }

  return (
    <IntlProvider
      messages={languages[locale]}
      locale={locale}
      defaultLocale={locale}>
      {permissions ? <SourceWiz /> : <Loading />}
    </IntlProvider>
  );

  // isOnboarding ? <Onboarding /> : <SourceWiz />
}
