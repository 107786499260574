export const guardrails = {
  setUsage: (store, payload) => {
    store.setState({ usage: payload });
  },
  setModuleData: (store, payload) => {
    store.setState({ moduleData: payload });
  },
  setPlanData: (store, payload) => {
    store.setState({ planData: payload });
  },
  setReloadUsageData: (store, payload) => {
    store.setState({ reloadUsageData: payload });
  },
  setShowTrialReminder: (store, payload) => {
    store.setState({ showTrialReminder: payload });
  },
};
