import { Modal, Row, Col, Typography, Button } from 'antd';
import { useState } from 'react';
import style from 'styled-components';
import qrSvg from '../../../../../assets/images/qrreminder.svg';
import { ModuleUpgradeModal } from '../ModuleUpgrade/ModuleUpgradeModal';

const { Title, Text } = Typography;
const PlanDiv = style.div`
  margin-top: 1.3rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background: linear-gradient(269.31deg, rgb(187, 211, 247, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
  mix-blend-mode: normal;`;
const ButtonContainer = style.div`
  .qr-reminder-btn-upgrade{
    background: black;
    color: white;
    border: none;
    margin-top: 0.5rem;
  }
`;
export const QRTrialReminder = ({
  data,
  trialReminderModal,
  setTrialReminderModal,
}) => {
  const [upgradeModal, setUpgradeModal] = useState(false);
  return (
    <>
      <Modal
        footer={null}
        visible={trialReminderModal}
        onCancel={() => setTrialReminderModal(false)}>
        <br />
        <Row align="center">
          <img src={qrSvg} alt="img" />
        </Row>
        <Row align="center" className="qr-reminder-title-row" style={{}}>
          <Title level={4}>QR label downloaded successfully</Title>
        </Row>
        <PlanDiv>
          <Row justify="space-between">
            <Col>
              <Row>
                <Title level={5}>
                  {data?.remaining_days} days left in your free trial
                </Title>
              </Row>
              <Row>
                <Text>Upgrade to continue using QR code labelling</Text>
              </Row>
            </Col>
            <Col>
              <ButtonContainer>
                <Button
                  size="large"
                  className="qr-reminder-btn-upgrade"
                  onClick={() => {
                    setTrialReminderModal(false);
                    setUpgradeModal(true);
                  }}>
                  Upgrade
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
        </PlanDiv>
      </Modal>
      <ModuleUpgradeModal
        forAddOn={false}
        moduleId={data?.module_id}
        moduleData={data}
        isModalVisible={upgradeModal}
        setIsModalVisible={setUpgradeModal}
      />
    </>
  );
};
