const ATTRIBUTE_DTYPE_CONSTANTS = [
  'packaging',
  'packaging_items',
  'html',
  'loadability',
  'file',
  'currency_secondary_unit',
];

const ATTRIBUTE_TABNAME_CONSTANTS = ['packaging', 'costing'];

const ATTRIBUTE_INTERNAL_NAME_CONSTANTS = ['buyer_note'];
const ATTRIBUTE_DATE_FORMAT = 'DD/MM/YYYY';

const BULK_UPLOAD_DFAULT_ATTR = [
  {
    id: 'product_id',
    name: 'Product ID',
    isDisabled: true,
  },
  {
    id: 'variant_id',
    name: 'Variant ID',
    isDisabled: true,
  },
  {
    id: 'secondary_category',
    name: 'Category',
    isDisabled: true,
  },
  {
    id: 'sub_category',
    name: 'Sub Category',
    isDisabled: true,
  },
];

const CUSTOM_ATTR_SUBCATEGORY = [
  { id: 'product_id', name: 'Product ID' },
  { id: 'variant_id', name: 'Variant ID' },
  { id: 'secondary_category', name: 'Category' },
  { id: 'sub_category', name: 'Sub Category' },
];

const CUSTOM_ATTR_SECONDARY = [
  { id: 'variant_id', name: 'Variant ID' },
  { id: 'product_id', name: 'Product ID' },
  { id: 'secondary_category', name: 'Category' },
];

export {
  ATTRIBUTE_DTYPE_CONSTANTS,
  ATTRIBUTE_TABNAME_CONSTANTS,
  ATTRIBUTE_INTERNAL_NAME_CONSTANTS,
  ATTRIBUTE_DATE_FORMAT,
  BULK_UPLOAD_DFAULT_ATTR,
  CUSTOM_ATTR_SUBCATEGORY,
  CUSTOM_ATTR_SECONDARY,
};
