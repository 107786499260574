import React, { useState } from 'react';
import { Row, Col, Typography, Card, Button, Tag } from 'antd';

import Prospects from '../../../assets/images/Prospects.svg';

import { useHistory } from 'react-router-dom';
import TitleComponent from './TitleComponent';
import styled from 'styled-components';
import ReccomendationCard from './Recommendations';
import Can from '../../../casl/Can';
import { PERMISSIONS } from 'constants/permissions';
import CatalogueCard from './CatalogueCard';
import ProductCard from './ProductCard';
import HistoryCard from './HistoryCard';
import ProspectCard from './ProspectCard';
import QuotationCard from './QuotationCard';
import ImageRouter from 'react-common-components/ImageRouter';
const AllProductsCard = styled.div`
  .card {
    margin-right: 1rem;
    min-height: 2em;
    margin-top: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    &:hover {
      background: #f5fcfb;
      cursor: pointer;
    }
    @media (max-width: 400px) {
      padding: 0rem;
    }
  }
  cursor: pointer;
  .icon {
    /* margin-top:1em; */
  }
`;
const CreateCatalougueCard = styled.div`
  .card {
    margin-right: 1rem;
    min-height: 22em;
    margin-top: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    &:hover {
      background: #fffcf7;
      cursor: pointer;
    }
    @media (max-width: 400px) {
      padding: 0rem;
    }
  }
  cursor: pointer;
  .icon {
    /* margin-top:1em; */
  }
`;
const SeeCatalougueCard = styled.div`
  .card {
    margin-right: 1rem;
    min-height: 22em;
    margin-top: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    &:hover {
      background: #fafcf5;
      cursor: pointer;
    }
    @media (max-width: 400px) {
      padding: 0rem;
    }
  }
  cursor: pointer;
  .icon {
    /* margin-top:1em; */
  }
`;

//Recommendations for you
const { Title, Text } = Typography;
export default function Dashboard() {
  const history = useHistory();

  return (
    <div>
      {/* <TitleComponent /> */}
      <Row>
        <Col span={24}>
          <Row>
            <Col
              xs={8}
              sm={8}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              {/* casl starts here */}
              <Can
                I={PERMISSIONS.view_products.slug}
                a={PERMISSIONS.view_products.permissionType}
                passThrough>
                {(allowed) => <ProductCard allowed={allowed} />}
              </Can>
              {/* casl ends here */}
            </Col>

            <Col
              xs={8}
              sm={8}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              {/* casl starts here */}
              <Can
                I={PERMISSIONS.create_catalogues.slug}
                a={PERMISSIONS.create_catalogues.permissionType}
                passThrough>
                {(allowed) => <CatalogueCard allowed={allowed} />}
              </Can>
              {/* casl ends here */}
            </Col>
            <Col
              xs={8}
              sm={8}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              <QuotationCard allowed={true} />
            </Col>
            <Col
              xs={8}
              sm={8}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              {/* casl starts here */}
              <Can
                I={PERMISSIONS.view_catalogues.slug}
                a={PERMISSIONS.view_catalogues.permissionType}
                passThrough>
                {(allowed) => <HistoryCard allowed={allowed} />}
              </Can>
              {/* casl ends here */}
            </Col>

            {/* removed Prospects and Buyer Recommendations */}

            {/* <Col
              xs={8}
              sm={8}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              <ProspectCard allowed={true} />
            </Col>
            <Col
              xs={8}
              sm={8}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}>
              <ReccomendationCard allowed={true} />
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
