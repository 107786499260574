import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Logo from 'assets/images/logo64.png';
import LogoContainer from 'components/LogoContainer';
import { Colors } from 'theme';
import * as Sentry from '@sentry/react';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info }, () => {
      Sentry.captureMessage(error?.message || error);
    });
  };

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <SessionExpired />;
    }
    return children;
  }
}

const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = () => (
  <div style={{ marginBottom: '2rem', marginTop: '3rem' }}>
    <img src={Logo} alt="sourcewiz" width={'100px'} height={'100px'} />
  </div>
);
const HeaderText = () => (
  <div style={{ marginBottom: '1rem' }}>
    <span
      style={{
        fontSize: '24px',
        color: Colors.black,
        fontFamily: 'SatoshiBold',
      }}>
      Session Expired
    </span>
  </div>
);

const DiscriptionText = () => (
  <div style={{ marginBottom: '2rem' }}>
    <span
      style={{
        fontSize: ' 16px',
        color: '#686E6D',
        display: 'flex',
        textAlign: 'center',
      }}>
      Looks like your session was expired. Please login again to continue.
    </span>
  </div>
);

const onLogin = () => {
  clearLocalStorageAndCookie();
  history.push(`/`);
  window.location.reload();
};

const LoginButton = () => {
  const history = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        paddingTop: '12px',
        paddingBottom: '12px',
        flex: 'display',
        justifyContent: 'center',
        width: '100%',
        background: 'black',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
      onClick={onLogin}>
      <span
        style={{
          flex: 'display',
          justifyContent: 'center',
          color: Colors.white,
          fontWeight: 'bold',
          fontSize: '16px',
        }}>
        Login
      </span>
    </div>
  );
};

export const history = createBrowserHistory();

export function SessionExpired() {
  const getWidth = (width) => {
    if (width >= 1000) return '33vw';
    if (width < 1000 && width > 800) return '40vw';
    return '60vh';
  };

  return (
    <div style={{ background: 'lightgrey', height: window.innerHeight }}>
      <CenterContainer>
        <div
          style={{
            flex: 'display',
            padding: 40,
            background: 'white',
            borderRadius: '12px',
            width: getWidth(window.innerWidth),
            marginTop: '3rem',
          }}>
          <LogoContainer fontSize={'28px'} logoSize={'32px'} />
          <CenterContainer>
            <ImgContainer />
          </CenterContainer>
          <CenterContainer>
            <HeaderText />
          </CenterContainer>
          <CenterContainer>
            <DiscriptionText />
          </CenterContainer>
          <LoginButton />
        </div>
      </CenterContainer>
    </div>
  );
}
