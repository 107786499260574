import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Collapse,
  Tag,
  Typography,
  Modal,
  Input,
  Button,
  Form,
} from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Colors } from '../../theme';
import API_KEY from '../../api/index';
import { useIntl } from 'react-intl';
const { Panel } = Collapse;
const { CheckableTag } = Tag;
const { Title, Text } = Typography;

export default function ProductSelection({ form }) {
  const intl = useIntl();
  const [categories, setCategories] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newCategory, setNewCategoryName] = useState('');
  const [addedCategory, setAddedCategory] = useState([]);
  // const selectedCategoriesRef = useRef([]);
  // const [currentCategory, setCurrentCategory] = useState("");
  const [errorText, setErrorText] = useState(null);
  const showModal = (parentCategory) => {
    // setCurrentCategory(parentCategory);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!newCategory) {
      setErrorText((state) => 'Please enter a valid category name');
    } else {
      const data = {
        status: 'published',
        primary_category: 4,
        name: newCategory,
      };

      API_KEY.URL.post(API_KEY.path.secondaryCategory, data)
        .then((res) => {
          setSelectedCategories([
            ...selectedCategories,
            { id: res?.data?.data?.id, name: res?.data?.data?.name },
          ]);
          setAddedCategory((state) => [
            ...state,
            { id: res?.data?.data?.id, name: res?.data?.data?.name },
          ]);
          // fetchCategories();
        })
        .catch((err) => console.log(err));
      setIsModalVisible(false);
      setErrorText((state) => null);
    }
  };

  const handleCancel = () => {
    setErrorText((state) => null);
    // setCurrentCategory("");
    setNewCategoryName('');
    setIsModalVisible(false);
  };
  //   [
  //     {
  //       "secondary_category_id": 1
  //     },
  //     {
  //       "secondary_category_id": 2
  //     }
  //   ]

  const fetchCategories = () => {
    API_KEY.URL.get(API_KEY.path.queryCategories)
      .then((res) => {
        var dictionary = [];
        res.data?.data.forEach((element) => {
          let key = element.primary_category.name;
          let value = element.name;
          let secondary_id = element.id;
          let primary_id = element.primary_category.id;
          dictionary.push({
            primary_key: { name: key, id: primary_id },
            secondary_key: { secondary_name: value, secondary_id },
          });
        });
        dictionary.push({
          primary_key: { name: 'Others', id: 4 },
          secondary_key: { secondary_name: null, secondary_id: null },
        });

        const result = Object.values(
          dictionary.reduce(
            (
              acc,
              {
                primary_key: { name },
                primary_key: { id },
                secondary_key: { secondary_name },
                secondary_key: { secondary_id },
              }
            ) => {
              acc[name] = acc[name] || { name, values: [], id };
              acc[name].values.push({ name: secondary_name, id: secondary_id });
              return acc;
            },
            {}
          )
        );

        setCategories(result);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleChange = (tag, checked) => {
    // }
    console.log(selectedCategories.find((x) => x.id === tag?.id));

    if (selectedCategories.find((x) => x.id === tag?.id)) {
      setSelectedCategories((state) => state.filter((x) => x.id !== tag?.id));
    } else {
      const nextSelectedTags = checked
        ? [...selectedCategories, tag]
        : selectedCategories.filter((t) => t !== tag);
      setSelectedCategories(nextSelectedTags);
      console.log(nextSelectedTags, 'nextSelectedTags');
    }
  };
  useEffect(() => {
    console.log(selectedCategories, 'selected categories');
    form.setFieldsValue({
      secondary_categories: selectedCategories,
    });
  }, [form, selectedCategories]);
  return (
    <>
      <Modal
        centered
        visible={isModalVisible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        footer={null}
        destroyOnClose={true}>
        <Row>
          <Title level={5} style={{ fontFamily: 'SatoshiBold' }}>
            Name the product
          </Title>{' '}
        </Row>
        <Row justify="space-between">
          <Col span={18}>
            <Input
              onChange={(e) => setNewCategoryName(e.target.value)}
              type="text"
              placeholder="Enter product name"></Input>
          </Col>
          <Col span={4}>
            <Button type="primary" onClick={() => handleOk()}>
              {/* Done */}
              {intl.formatMessage({ id: 'sourcewiz.button.done' })}
            </Button>
          </Col>
        </Row>
        {errorText && (
          <Row style={{ marginTop: '0.5rem' }}>
            {' '}
            <Text type="danger">{errorText}</Text>
          </Row>
        )}
      </Modal>
      <Row>
        <Title level={4} style={{ fontFamily: 'SatoshiBold' }}>
          Select products
        </Title>
      </Row>
      <Row>
        <Text style={{ color: Colors.gray }}>
          Let us know what kind of goods you deal for easily setting up your
          catalogues
        </Text>
      </Row>
      <Row
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
          overflowY: 'scroll',
        }}>
        <Col span={24}>
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse">
            {categories &&
              categories.map((item) => (
                <Panel
                  header={
                    item?.id === 4 ? (
                      <Text>
                        {item.name}{' '}
                        <Text
                          type="secondary"
                          style={{ marginLeft: '2rem', color: 'grey' }}>
                          (Add a new category)
                        </Text>
                      </Text>
                    ) : (
                      item?.name
                    )
                  }
                  key={item.id}
                  className="site-collapse-custom-panel">
                  {item.values.map((tag, index) =>
                    tag?.name ? (
                      <CheckableTag
                        style={{
                          padding: '0.4rem',
                          margin: '0.3rem',
                          border: '1px solid lightgray',
                          borderRadius: 4,
                        }}
                        key={index}
                        checked={selectedCategories.indexOf(tag) > -1}
                        onChange={(checked) => handleChange(tag, checked)}>
                        {tag?.name}
                      </CheckableTag>
                    ) : null
                  )}

                  {item?.id === 4 &&
                    addedCategory.length > 0 &&
                    addedCategory.map((cat, index) => (
                      <CheckableTag
                        style={{
                          padding: '0.4rem',
                          margin: '0.3rem',
                          border: '1px solid lightgray',
                          borderRadius: 4,
                        }}
                        key={index}
                        checked={addedCategory.indexOf(cat) > -1}
                        onChange={(checked) => handleChange(cat, checked)}>
                        {cat?.name}
                      </CheckableTag>
                    ))}

                  {item?.id === 4 && (
                    <CheckableTag
                      style={{
                        padding: '0.4rem',
                        margin: '0.3rem',
                        border: '1px solid lightgray',
                        borderRadius: 4,
                        color: Colors.primaryColor,
                      }}
                      onClick={() => showModal(item.id)}>
                      + Add new category
                    </CheckableTag>
                  )}
                </Panel>
              ))}
            {/* <Panel
              header={
                <Text>
                  Others
                  <Text
                    type="secondary"
                    style={{ marginLeft: "2rem", color: "grey" }}
                  >
                    (Add a new category)
                  </Text>
                </Text>
              }
            >
              <CheckableTag
                style={{
                  padding: "0.4rem",
                  margin: "0.3rem",
                  border: "1px solid lightgray",
                  borderRadius: 4,
                  color: Colors.primaryColor,
                }}
                 onClick={() => showModal()}
              >
                + Add new category
              </CheckableTag>
            </Panel> */}
          </Collapse>
        </Col>
      </Row>
      <div hidden>
        <Form.Item label="secondary_categories" name="secondary_categories">
          <Input />
        </Form.Item>
      </div>
    </>
  );
}
