import { SET_MANUFACTURER_SETTINGS } from 'redux/actions/manufacturerSettingsActions';

const initialState = {
  settingsData: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_MANUFACTURER_SETTINGS: {
      return { ...state, settingsData: payload };
    }
    default:
      return state;
  }
}
