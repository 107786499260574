import ProductActions from 'redux/actions/productActions';
import ProductQuotationActions from 'redux/actions/productQuotationActions';
import ProductDocumentActions from 'redux/actions/productDocumentActions';
import {
  sendDataRedis,
  deleteDataRedis,
  deleteQuotationDataRedis,
  sendQuotationDataRedis,
  sendDocumentDataRedis,
  deleteDocumentDataRedis,
} from 'store/redis/methods';
import {
  getRemoveMultipleProductsPayload,
  getRemoveProductPayload,
} from 'utils/RedisPayloadFunctions';

const { ActionTypes } = ProductActions;
const { QuotationActionTypes } = ProductQuotationActions;
const { DOCUMENT_ACTION_TYPES } = ProductDocumentActions;
const initialState = {
  data: [],

  quotationProducts: [],
  documentProducts: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ADD_PRODUCT: {
      const { product, collectionId } = action.payload;
      if (product) {
        const existingProducts = state.data.map((pro) => parseInt(pro?.id));
        const tobeAdded = product;
        var newProducts = [];
        if (existingProducts.includes(parseInt(tobeAdded?.id))) {
          let current = state.data?.find(
            (x) => x?.id?.toString() === tobeAdded?.id?.toString()
          );
          current.meta.selected_variants = tobeAdded?.meta?.selected_variants;
          newProducts = state.data;
        } else {
          // need to refactor
          product.id = product?.id?.toString();
          newProducts = [...state.data, product];
        }
        sendDataRedis(newProducts, collectionId)
          .then((res) => {})
          .catch((err) => console.error(err));

        return {
          ...state,
          data: newProducts,
        };
      } else {
        return state;
      }
    }
    case ActionTypes.REMOVE_PRODUCT: {
      const { product, collectionId } = action.payload;
      if (product) {
        const existingProducts = state.data;
        const tobeRemoved = parseInt(product?.id);
        const newProducts = existingProducts.filter(
          (pro) => parseInt(pro?.id) !== tobeRemoved
        );
        // deleteDataRedis([product], collectionId);

        return {
          ...state,
          data: newProducts,
        };
      } else {
        return state;
      }
    }
    case ActionTypes.SET_PRODUCTS: {
      const { products, collectionId } = action.payload;
      if (Array.isArray(products)) {
        return {
          ...state,
          data: products,
        };
      } else {
        return state;
      }
    }
    case ActionTypes.RESET_PRODUCT: {
      const { id, isReset } = action.payload;
      return {
        ...state,
        resetData: isReset,
      };
    }

    case ActionTypes.ADD_MULTIPLE_PRODUCTS: {
      const { products, collectionId } = action.payload;
      if (Array.isArray(products)) {
        const existingProducts = state.data.map((pro) => parseInt(pro?.id));
        const tobeAdded = products;
        const nonExistingProducts = tobeAdded.filter(
          (pro) => !existingProducts.includes(parseInt(pro?.id))
        );

        const updatedProductsId = tobeAdded
          ?.filter((item) => existingProducts?.includes(parseInt(item?.id)))
          ?.map((el) => el?.id?.toString());

        if (updatedProductsId?.length) {
          state.data
            .filter((item) => updatedProductsId?.includes(item?.id))
            .forEach((prod) => {
              const updatedSelection = tobeAdded?.find(
                (e) => e?.id?.toString() === prod?.id
              );
              prod.meta.selected_variants =
                updatedSelection?.meta?.selected_variants;
            });
        }

        const newProducts = [...state.data, ...nonExistingProducts];
        sendDataRedis(newProducts, collectionId)
          .then((res) => {})
          .catch((err) => console.error(err));

        return {
          ...state,
          data: newProducts,
        };
      } else {
        return state;
      }
    }
    case ActionTypes.REMOVE_MULTIPLE_PRODUCTS: {
      const { products, collectionId } = action.payload;
      if (Array.isArray(products)) {
        const existingProducts = state.data;
        const tobeRemoved = products.map((product) => parseInt(product?.id));
        const newProducts = existingProducts.filter(
          (product) => !tobeRemoved.includes(parseInt(product?.id))
        );
        return {
          ...state,
          data: newProducts,
        };
      } else {
        return state;
      }
    }

    case QuotationActionTypes.QUOTATION_ADD_PRODUCT: {
      const { product, quotationId } = action.payload;
      if (product) {
        // This payload structure has been wrapped into a function and moved to utils folder in order to remove latency in selecting products while creating a quotation as earlier we used to store the selected products data in redis after dispatching the action from redux. Leaving it commented if need to revert things in future.

        // const existingProducts = state?.quotationProducts?.map((product) =>
        //   parseInt(product?.id)
        // );
        // const tobeAdded = product;
        // var newProducts = [];
        // if (existingProducts?.includes(parseInt(tobeAdded?.id))) {
        //   let current = state?.quotationProducts?.find(
        //     (item) => item?.id?.toString() === tobeAdded?.id?.toString()
        //   );
        //   current.meta.selected_variants = tobeAdded?.meta?.selected_variants;
        //   newProducts = state.quotationProducts;
        // } else {
        //   // need to refactor
        //   product.id = product?.id?.toString();
        //   newProducts = [...state.quotationProducts, product];
        // }
        // sendQuotationDataRedis(newProducts, quotationId)
        //   .then((res) => {})
        //   .catch((err) => {
        //     console.log('Error: ', err);
        //   });
        return {
          ...state,
          quotationProducts: product,
        };
      } else {
        return state;
      }
    }
    case QuotationActionTypes.QUOTATION_REMOVE_PRODUCT: {
      const { product, quotationId } = action.payload;
      if (product) {
        const existingProducts = state?.quotationProducts;
        //wrapped the payload into a function and moved to utils folder
        const payload = getRemoveProductPayload(existingProducts, product);
        return {
          ...state,
          quotationProducts: payload,
        };
      } else {
        return state;
      }
    }
    case QuotationActionTypes.QUOTATION_SET_PRODUCTS: {
      const { products, quotationId } = action.payload;
      if (Array.isArray(products)) {
        return {
          ...state,
          quotationProducts: products,
        };
      } else {
        return state;
      }
    }
    case QuotationActionTypes.QUOTATION_ADD_MULTIPLE_PRODUCTS: {
      const { products, quotationId } = action.payload;
      if (Array.isArray(products)) {
        // This payload structure has been wrapped into a function and moved to utils folder in order to remove latency in selecting products while creating a quotation as earlier we used to store the selected products data in redis after dispatching the action from redux. Leaving it commented if need to revert things in future.

        // const existingProducts = state?.quotationProducts?.map((product) =>
        //   parseInt(product?.id)
        // );
        // const tobeAdded = products;
        // const nonExistingProducts = tobeAdded?.filter(
        //   (product) => !existingProducts.includes(parseInt(product?.id))
        // );

        // const updatedProductsId = tobeAdded
        //   ?.filter((item) => existingProducts?.includes(parseInt(item?.id)))
        //   ?.map((element) => element?.id?.toString());

        // if (updatedProductsId?.length) {
        //   state?.quotationProducts
        //     ?.filter((item) => updatedProductsId?.includes(item?.id))
        //     ?.forEach((product) => {
        //       const updatedSelection = tobeAdded?.find(
        //         (item) => item?.id?.toString() === product?.id
        //       );
        //       product.meta.selected_variants =
        //         updatedSelection?.meta?.selected_variants;
        //     });
        // }

        // const newProducts = [
        //   ...state.quotationProducts,
        //   ...nonExistingProducts,
        // ];
        // sendQuotationDataRedis(newProducts, quotationId)
        //   .then((res) => {})
        //   .catch((err) => console.error(err));

        return {
          ...state,
          quotationProducts: products,
        };
      } else {
        return state;
      }
    }
    case QuotationActionTypes.QUOTATION_REMOVE_MULTIPLE_PRODUCTS: {
      const { products, quotationId } = action.payload;
      if (Array.isArray(products)) {
        const existingProducts = state?.quotationProducts;
        const payload = getRemoveMultipleProductsPayload(
          existingProducts,
          products
        );

        return {
          ...state,
          quotationProducts: payload,
        };
      } else {
        return state;
      }
    }

    case DOCUMENT_ACTION_TYPES.DOCUMENT_ADD_PRODUCT: {
      const { product, documentId } = action.payload;
      if (product) {
        const existingProducts = state.documentProducts.map((prods) =>
          parseInt(prods?.id)
        );
        const tobeAdded = product;
        let newProducts = [];
        if (existingProducts.includes(parseInt(tobeAdded?.id))) {
          newProducts = state.documentProducts;
        } else {
          newProducts = [...state.documentProducts, product];
        }

        sendDocumentDataRedis(newProducts, documentId)
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
        return {
          ...state,
          documentProducts: newProducts,
        };
      } else {
        return state;
      }
    }

    case DOCUMENT_ACTION_TYPES.DOCUMENT_REMOVE_PRODUCT: {
      const { product, documentId } = action.payload;
      if (product) {
        const existingProducts = state.documentProducts;
        const tobeRemoved = parseInt(product?.id);
        const newProducts = existingProducts.filter(
          (prod) => parseInt(prod?.id) !== tobeRemoved
        );

        deleteDocumentDataRedis([product], documentId)
          .then((res) => {})
          .catch((err) => {
            console.log('Error: ', err);
          });

        return {
          ...state,
          documentProducts: newProducts,
        };
      } else {
        return state;
      }
    }

    case DOCUMENT_ACTION_TYPES.DOCUMENT_SET_PRODUCTS: {
      const { products, documentId } = action.payload;
      if (Array.isArray(products)) {
        return {
          ...state,
          documentProducts: products,
        };
      } else {
        return state;
      }
    }

    case DOCUMENT_ACTION_TYPES.DOCUMENT_ADD_MULTIPLE_PRODUCTS: {
      const { products, documentId } = action.payload;
      if (Array.isArray(products)) {
        const existingProducts = state.documentProducts.map((prod) =>
          parseInt(prod?.id)
        );
        const tobeAdded = products;
        const nonExistingProducts = tobeAdded.filter(
          (prod) => !existingProducts.includes(parseInt(prod?.id))
        );
        const newProducts = [...state.documentProducts, ...nonExistingProducts];

        sendDocumentDataRedis(newProducts, documentId)
          .then((res) => {})
          .catch((err) => {
            console.log('Error: ', err);
          });

        return {
          ...state,
          documentProducts: newProducts,
        };
      } else {
        return state;
      }
    }

    case DOCUMENT_ACTION_TYPES.DOCUMENT_REMOVE_MULTIPLE_PRODUCTS: {
      const { products, documentId } = action.payload;
      if (Array.isArray(products)) {
        const existingProducts = state.documentProducts;
        const tobeRemoved = products.map((prod) => parseInt(prod?.id));
        const newProducts = existingProducts.filter(
          (prod) => !tobeRemoved.includes(parseInt(prod?.id))
        );

        deleteDocumentDataRedis(products, documentId)
          .then((res) => {})
          .catch((err) => {
            console.log('Error: ', err);
          });

        return {
          ...state,
          documentProducts: newProducts,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
