import React, { useState } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import './index.less';
import { useIntl } from 'react-intl';

const TermsAndConditions = (props) => {
  const intl = useIntl();
  const [checked, setChecked] = useState(false);
  const { visible, onNext } = props;

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const openTerms = () => {
    window.open('https://www.sourcewiz.co/terms-of-use');
  };

  const openPrivacy = () => {
    window.open('https://www.sourcewiz.co/privacy-policy-of-sourcewiz');
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {}}
      closable={false}
      closeIcon={null}
      footer={null}
      centered
      width={'528px'}
      bodyStyle={{ padding: '2em' }}
      destroyOnClose={true}>
      <div className="agr-label">AGREEMENT</div>
      <div className="agr-header">Our Terms & Services</div>
      <div className="agr-subheader">
        Find the details of the Terms of Use & Privacy policy below
      </div>
      <div className="policy-container">
        The Sourcewiz{' '}
        <span onClick={openTerms} style={{ color: '#0B7763' }}>
          {' '}
          Terms of Use
        </span>{' '}
        and{' '}
        <span onClick={openPrivacy} style={{ color: '#0B7763' }}>
          {' '}
          Privacy Policy
        </span>{' '}
        (together the "Agreement") contain the terms and conditions that govern
        your access to and use of the Services (as defined in the Terms of Use)
        and is an agreement between Oritur Technologies Pvt. Ltd. (“Sourcewiz”,
        “we”, “us”, or “our”), having its registered office at House No. 20,
        Road No. 24, Punjabi Bagh Extension, Near Shiv Durga Mandir, Delhi West,
        Delhi - 110026, and you or the entity you represent (“you” or “your”).
      </div>
      <div className="ack-section">
        <Checkbox
          style={{ alignItems: 'center', marginTop: '1rem' }}
          checked={checked}
          onChange={handleCheck}>
          <div className="ack-statement">
            I have read and agree to the{' '}
            <span
              onClick={openTerms}
              style={{
                color: '#0B7763',
                textDecoration: 'underline',
                margin: '0 4px',
              }}>
              {' '}
              Terms of Use
            </span>{' '}
            and{' '}
            <span
              onClick={openPrivacy}
              style={{
                color: '#0B7763',
                textDecoration: 'underline',
                margin: '0 4px',
              }}>
              {' '}
              Privacy Policy
            </span>
          </div>
        </Checkbox>
      </div>
      <div className="cta-container">
        <Button
          size="large"
          onClick={onNext}
          disabled={!checked}
          style={{
            background: '#1D2624',
            width: '171px',
            opacity: checked ? 1 : 0.4,
            padding: '8px 16px',
            fontSize: '14px',
            color: 'white',
            border: 0,
          }}>
          {/* Next */}
          {intl.formatMessage({ id: 'sourcewiz.addproducts.next' })}
        </Button>
      </div>
    </Modal>
  );
};

export default TermsAndConditions;
