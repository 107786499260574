import { useState } from 'react';
import { Steps, Button, Row, Col, Form } from 'antd';
import BasicDetails from './BasicDetails';
import ProductSelection from './ProductSelection';
import Certifications from './Certifications';
import API_KEY from '../../api';
import { useGlobal } from '../../store/index';
import { useIntl } from 'react-intl';

const { Step } = Steps;

export default function StepsComponent() {
  const intl = useIntl();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [globalState, globalActions] = useGlobal();
  const next = () => {
    // const values = form.getFieldsValue();
    // if (values?.name) {
    //   setCurrent(current + 1);
    // }

    if (current === 0) {
      form
        .validateFields(['name'])
        .then((value) => {
          setCurrent(current + 1);
        })
        .catch((errorInfo) => {
          if (errorInfo.errorFields.length === 0) {
            setCurrent(current + 1);
          }
        });
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onFinish = (values) => {
    const user_id = localStorage.getItem('userId');

    let secondary_categories = values.secondary_categories.map((key) => {
      return key.id ? { secondary_category_id: key.id } : null;
    });

    let certifications = values.certifications.map((key) => {
      return key.id ? { certifications_id: key.id } : null;
    });

    //remove null if any
    values.secondary_categories = secondary_categories.filter((el) => el);
    // values.secondary_categories = [...new Set(secondary_categories)];
    values.certifications = certifications.filter((el) => el);
    values.user_id = JSON.parse(user_id);
    values.status = 'published';
    console.log(values, 'values');
    API_KEY.URL.post(API_KEY.path.manufacturer, values)
      .then((res) => {
        console.log(res.data, 'manufacturer');
        globalActions.authActions.setOnboarding(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(globalState);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const steps = [
    {
      title: 'Basic Details',
      content: <BasicDetails />,
    },
    {
      title: 'Product Selection',
      content: <ProductSelection />,
    },
    {
      title: 'Certifications',
      content: <Certifications />,
    },
  ];

  return (
    <>
      <Row>
        <Col
          span={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#F0F8F6',
            width: '80%',
          }}>
          <div style={{ marginTop: '2rem', width: '80%' }}>
            <Steps direction="vertical" current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        </Col>

        <Col span={16} offset={2}>
          <Form
            form={form}
            name="onboarding"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <div style={{ minHeight: '30rem' }} className="steps-content">
              <span hidden={current === 0 ? false : true}>
                <BasicDetails form={form} />
              </span>
              <span hidden={current === 1 ? false : true}>
                <ProductSelection form={form} />
              </span>

              <span hidden={current === 2 ? false : true}>
                <Certifications form={form} />
              </span>
            </div>
            <Row
              style={{ justifyContent: 'space-between' }}
              className="steps-action">
              {current > 0 && (
                <Button
                  style={{ margin: '0 8px', width: '25%' }}
                  onClick={() => prev()}>
                  {/* Previous */}
                  {intl.formatMessage({
                    id: 'sourcewiz.resourceLibrary.previous',
                  })}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  style={{ width: '25%' }}
                  htmlType="submit">
                  {/* Done */}
                  {intl.formatMessage({ id: 'sourcewiz.button.done' })}
                </Button>
              )}
              {current < steps.length - 1 &&
                (current === 0 ? (
                  <Col span={6} offset={14}>
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      onClick={() => next()}>
                      {/* Next */}
                      {intl.formatMessage({ id: 'sourcewiz.addproducts.next' })}
                    </Button>
                  </Col>
                ) : (
                  <Col span={6}>
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      onClick={() => next()}>
                      {/* Next */}
                      {intl.formatMessage({ id: 'sourcewiz.addproducts.next' })}
                    </Button>
                  </Col>
                ))}
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
