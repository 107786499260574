import { Alert, Typography } from 'antd';

const { Text } = Typography;
// customise alert based on percentage of usage
const getType = (percent) => {
  if (percent > 90) {
    return {
      type: 'error',
      textColor: '#AA3636',
      backgroundColor: '#FDDBDB',
      closable: false,
      showUpgrade: true,
    };
  } else if (percent > 70) {
    return {
      type: 'warning',
      textColor: '#B37D36',
      backgroundColor: '#FFF0DB',
      closable: false,
      showUpgrade: true,
    };
  } else {
    return {
      type: 'info',
      textColor: '#1B6BE4',
      backgroundColor: '#F1F7FF',
      closable: true,
      showUpgrade: false,
    };
  }
};
const SWAlert = ({ percent, message }) => (
  <Alert
    showIcon={false}
    closable={getType(percent)?.closable}
    style={{
      textAlign: 'center',
      color: getType(percent)?.textColor,
      backgroundColor: getType(percent)?.backgroundColor,
      borderRadius: 8,
    }}
    banner
    message={message}
    type={getType(percent)?.type}
  />
);

const ProductAlert = ({ percent, handleUpgrade }) =>
  percent >= 50 && (
    <SWAlert
      message={
        <>
          <Text>
            You have reached {percent}% of total SKU available for your selected
            plan.
          </Text>
          {getType(percent)?.showUpgrade && (
            <Text
              style={{
                textDecoration: 'underline',
                marginLeft: '0.7rem',
                cursor: 'pointer',
              }}
              onClick={handleUpgrade}>
              Upgrade Plan
            </Text>
          )}
        </>
      }
      percent={percent}
    />
  );

const UsersAlert = ({ percent }) =>
  percent >= 50 && (
    <SWAlert
      message={` You have reached ${percent}% of total users available for your selected plan. ${
        getType(percent)?.showUpgrade ? 'Upgrade plan.' : ''
      }`}
      percent={percent}
    />
  );

const CatalogueAlert = ({ percent }) =>
  percent >= 50 && (
    <SWAlert
      message={` You have reached ${percent}% of total catalogues available for your selected plan. ${
        getType(percent)?.showUpgrade ? 'Upgrade plan.' : ''
      }`}
      percent={percent}
    />
  );

const QuotationAlert = ({ percent }) =>
  percent >= 50 && (
    <SWAlert
      message={` You have reached ${percent}% of total catalogues available for your selected plan. ${
        getType(percent)?.showUpgrade ? 'Upgrade plan.' : ''
      }`}
      percent={percent}
    />
  );

export { QuotationAlert, CatalogueAlert, UsersAlert, ProductAlert };
