import Actions from '../actions/manageProductsActions';

const { ActionTypes } = Actions;

const initialState = {
  tempSelectedProducts: {},
  productsCurrentlyProcessing: {},
  pendingTaskCount: 0,
  allTasks: [],
};

const getDataFromTemp = (tempData, { action, task_id }) => {
  const finalData = {};
  for (let id in tempData) {
    if (tempData[id]?.value) {
      finalData[id] = {
        id: id,
        action,
        task_id,
        status: 'pending',
      };
    }
  }
  return finalData;
};

const getUpdatedDataOnMessage = (data) => {
  const finalData = {};
  data.forEach((task) => {
    if (task?.action === 'delete_products' && task?.status === 'success') {
      task?.done_product_ids?.forEach((id) => {
        finalData[id] = {
          id: id,
          action: task.action,
          task_id: task.task_id,
          status: task.status,
          isDeleted: true,
        };
      });
    } else if (
      task?.action === 'delete_products' &&
      task?.status === 'pending'
    ) {
      task?.done_product_ids?.forEach((id) => {
        finalData[id] = {
          id: id,
          action: task.action,
          task_id: task.task_id,
          status: task.status,
          isDeleted: true,
        };
      });
      task?.remaining_product_ids?.forEach((id) => {
        finalData[id] = {
          id: id,
          action: task.action,
          task_id: task.task_id,
          status: task.status,
          isDeleted: false,
        };
      });
    } else if (task?.status === 'pending' || task?.status === 'failed') {
      task?.remaining_product_ids?.forEach((id) => {
        finalData[id] = {
          id: id,
          action: task.action,
          task_id: task.task_id,
          status: task.status,
          isDeleted: false,
        };
      });
    } else if (task?.status === 'draft') {
      task?.remaining_product_ids?.forEach((id) => {
        finalData[id] = {
          id: id,
          action: task.action,
          task_id: task.task_id,
          status: task.status,
          isDeleted: false,
        };
      });
    }
  });

  return finalData;
};

const getDataForRetry = ({ action, task_id, remaining_product_ids }) => {
  const data = remaining_product_ids?.map((id) => {
    return {
      action: action,
      id: id,
      task_id: task_id,
      status: 'pending',
    };
  });
  const finalData = data?.reduce((a, v) => ({ ...a, [v.id]: v }), {});
  return finalData;
};

const getDataForIgnore = (data, remaining_product_ids = []) => {
  const finalData = { ...data };
  remaining_product_ids?.forEach((id) => {
    delete finalData[id];
  });
  return finalData;
};

const getDataForDismissCompletedTasks = (data, taskIds) => {
  const finalData = { ...data };
  const taskIdsMap = taskIds?.reduce((a, v) => ({ ...a, [v]: v }), {});
  for (let id in data) {
    const taskId = data[id]?.task_id;
    if (taskIdsMap[taskId]) {
      delete finalData[id];
    }
  }
  return finalData;
};

const getTempItem = (item, value) => {
  return {
    value,
    variants: {
      parent_id: item?.parent_id || item?.id,
      variant_ids: item?.variants?.map((curr) => curr?.variant_id?.id),
    },
  };
};

const getDataForSelectAll = (data, value) => {
  const finalData = {};
  data?.forEach((item) => {
    finalData[item.id] = getTempItem(item, value);
  });
  return finalData;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TOGGLE_CHECKBOX: {
      const { value, item } = action.payload;
      return {
        ...state,
        tempSelectedProducts: {
          ...state.tempSelectedProducts,
          [item.id]: {
            ...getTempItem(item, value),
          },
        },
      };
    }

    case ActionTypes.BULK_ACTION_ON_MESSAGE: {
      const { data, pendingTaskCount } = action.payload;
      return {
        ...state,
        productsCurrentlyProcessing: getUpdatedDataOnMessage(data),
        allTasks: data,
        pendingTaskCount,
      };
    }

    case ActionTypes.TEMP_TO_PROCESSING: {
      const data = action.payload;
      return {
        ...state,
        tempSelectedProducts: {},
        productsCurrentlyProcessing: {
          ...state.productsCurrentlyProcessing,
          ...getDataFromTemp(state.tempSelectedProducts, data),
        },
      };
    }

    case ActionTypes.CLEAR_SELECTION: {
      return {
        ...state,
        tempSelectedProducts: {},
      };
    }

    case ActionTypes.RETRY_TASK: {
      const { actionType, task_id, remaining_product_ids } = action.payload;
      return {
        ...state,
        productsCurrentlyProcessing: {
          ...state.productsCurrentlyProcessing,
          ...getDataForRetry({
            action: actionType,
            task_id,
            remaining_product_ids,
          }),
        },
      };
    }

    case ActionTypes.IGNORE_TASK: {
      const { actionType, task_id, remaining_product_ids } = action.payload;
      return {
        ...state,
        productsCurrentlyProcessing: {
          ...getDataForIgnore(
            state.productsCurrentlyProcessing,
            remaining_product_ids
          ),
        },
      };
    }

    case ActionTypes.SET_INITIAL_DATA: {
      const data = action.payload;
      return {
        ...state,
        productsCurrentlyProcessing: {
          ...state.productsCurrentlyProcessing,
          ...data,
        },
      };
    }

    case ActionTypes.TOGGLE_ALL_DATA: {
      const { data, value } = action.payload;
      return {
        ...state,
        tempSelectedProducts: {
          ...state.tempSelectedProducts,
          ...getDataForSelectAll(data, value),
        },
      };
    }

    case ActionTypes.DISMISS_COMPLETED_TASKS: {
      const { taskIds } = action.payload;
      return {
        ...state,
        productsCurrentlyProcessing: {
          ...state.productsCurrentlyProcessing,
          ...getDataForDismissCompletedTasks(
            state.productsCurrentlyProcessing,
            taskIds
          ),
        },
      };
    }

    default:
      return state;
  }
}
