export const ACTION_TYPES = Object.freeze({
  UPDATE_TOTAL: 'documentActions/UPDATE_TOTAL',
  UPDATE_PRODUCT_COUNT: 'documentActions/UPDATE_PRODUCT_COUNT',
  SET_TAX_LIST: 'documentActions/SET_TAX_LIST',
  UPDATE_TAX_PROPERTY: 'documentActions/UPDATE_TAX_PROPERTY',
  UPDATE_TAX_AMOUNT: 'documentActions/UPDATE_TAX_AMOUNT',
  NEW_TAX_ITEM: 'documentActions/NEW_TAX_ITEM',
  DELETE_TAX: 'documentActions/DELETE_TAX',
  TOGGLE_IS_TABLE_SAVING: 'documentActions/TOGGLE_IS_TABLE_SAVING',
  SET_FACETS_DATA: 'documentActions/SET_FACETS_DATA',
  SET_BASE_CURRENCY: 'documentActions/SET_BASE_CURRENCY',
  UPDATE_DOCUMENT: 'documentActions/UPDATE_DOCUMENT',
  RESET_STATE: 'documentActions/RESET_STATE',
  SET_TAX_VALUE: 'documentActions/SET_TAX_VALUE',
  RECOMPUTE_TAXES: 'documentActions/RECOMPUTE_TAXES',
  RECOMPUTE_TOTAL: 'documentActions/RECOMPUTE_TOTAL',
  RECOMPUTE_BASE_TOTAL: 'documentActions/RECOMPUTE_BASE_TOTAL',
  SET_DATASOURCE: 'documentActions/SET_DATASOURCE',
  UPDATE_PRODUCT_LIST: 'documentActions/UPDATE_PRODUCT_LIST',
  SET_DOCUMENT_DETAILS_LOADING: 'documentActions/SET_DOCUMENT_DETAILS_LOADING',
  SET_CURRENCY_DATA: 'documentActions/SET_CURRENCY_DATA',
  SET_ROW_UNIT: 'documentActions/SET_ROW_UNIT',
  SET_ROW_UNIT_UPDATE_COUNT: 'documentActions/SET_ROW_UNIT_UPDATE_COUNT',
  UPDATE_DOCUMENT_META_COUNT: 'documentActions/UPDATE_DOCUMENT_META_COUNT',
});

export function updateDocumentTotal(data) {
  return {
    type: ACTION_TYPES.UPDATE_TOTAL,
    payload: data,
  };
}

export function updateProductCount(data) {
  return {
    type: ACTION_TYPES.UPDATE_PRODUCT_COUNT,
    payload: data,
  };
}

export function setTaxList(data) {
  return {
    type: ACTION_TYPES.SET_TAX_LIST,
    payload: data,
  };
}

export function updateTaxProperty(data) {
  return {
    type: ACTION_TYPES.UPDATE_TAX_PROPERTY,
    payload: data,
  };
}

export function updateTaxAmount(data) {
  return {
    type: ACTION_TYPES.UPDATE_TAX_AMOUNT,
    payload: data,
  };
}

export function newTaxItem(data) {
  return {
    type: ACTION_TYPES.NEW_TAX_ITEM,
    payload: data,
  };
}
export function deleteTax(data) {
  return {
    type: ACTION_TYPES.DELETE_TAX,
    payload: data,
  };
}
export function tableSaving(data) {
  return {
    type: ACTION_TYPES.TOGGLE_IS_TABLE_SAVING,
    payload: data,
  };
}
export function setFacetsData(data) {
  return {
    type: ACTION_TYPES.SET_FACETS_DATA,
    payload: data,
  };
}
export function setBaseCurrency(data) {
  return {
    type: ACTION_TYPES.SET_BASE_CURRENCY,
    payload: data,
  };
}

export function updateDocument(data) {
  return {
    type: ACTION_TYPES.UPDATE_DOCUMENT,
    payload: data,
  };
}

export function resetState() {
  return {
    type: ACTION_TYPES.RESET_STATE,
    payload: null,
  };
}

export function setTaxValue(data) {
  return {
    type: ACTION_TYPES.SET_TAX_VALUE,
    payload: data,
  };
}

export function recomputeTaxes() {
  return {
    type: ACTION_TYPES.RECOMPUTE_TAXES,
    payload: null,
  };
}

export function recomputeTotal() {
  return {
    type: ACTION_TYPES.RECOMPUTE_TOTAL,
    payload: null,
  };
}
export function recomputeBaseTotal(data) {
  return {
    type: ACTION_TYPES.RECOMPUTE_BASE_TOTAL,
    payload: data,
  };
}
export function updateProductList(data) {
  return {
    type: ACTION_TYPES.UPDATE_PRODUCT_LIST,
    payload: data,
  };
}

export function setDataSource(data) {
  return {
    type: ACTION_TYPES.SET_DATASOURCE,
    payload: data,
  };
}

export function setCurrencyData(data) {
  return {
    type: ACTION_TYPES.SET_CURRENCY_DATA,
    payload: data,
  };
}

export function setDocumentDetailsLoading(data) {
  return {
    type: ACTION_TYPES.SET_DOCUMENT_DETAILS_LOADING,
    payload: data,
  };
}
export function updatePriceUnit(data) {
  return {
    type: ACTION_TYPES.SET_ROW_UNIT,
    payload: data,
  };
}

export function updateDocumentMetaCount(data) {
  return {
    type: ACTION_TYPES.UPDATE_DOCUMENT_META_COUNT,
    payload: data,
  };
}
export function updatePriceUnitUpdateCount(data) {
  return {
    type: ACTION_TYPES.SET_ROW_UNIT_UPDATE_COUNT,
    payload: data,
  };
}

const DocumentActions = {
  updateDocumentTotal,
  updateProductCount,
  setTaxList,
  updateTaxProperty,
  updateTaxAmount,
  newTaxItem,
  deleteTax,
  tableSaving,
  setFacetsData,
  setBaseCurrency,
  updateDocument,
  resetState,
  setTaxValue,
  recomputeTaxes,
  recomputeTotal,
  recomputeBaseTotal,
  setDocumentDetailsLoading,
  setDataSource,
  updateProductList,
  setCurrencyData,
  updatePriceUnit,
  updateDocumentMetaCount,
  updatePriceUnitUpdateCount,
  ACTION_TYPES,
};

export default DocumentActions;
