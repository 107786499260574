import { Typography, Row, Col, Button } from 'antd';
import helpDeskImage from '../../../../../assets/images/helpdesk.svg';
import { Subdiv } from '../CommonFunction/PlanDetailsDiv';
import './HelpDesk.css';

const { Title, Text } = Typography;
export const HelpDesk = ({ forExpiredModal }) => {
  return (
    <Subdiv
      width={forExpiredModal ? '100%' : '71.5%'}
      padding={'1.5rem'}
      background={'#F5F5F5'}>
      <Row justify="space-between">
        <Col>
          <Row>
            <Title level={5}>Enterprise plans also available</Title>
          </Row>
          <Row>
            <Text style={{ color: '#363E3D' }}>
              Call <b>+919215777736</b> to get in touch with sales or send us a
              <br />
              message with your custom requirements
            </Text>
          </Row>
          <br />
          <Row>
            <a href="mailto:support@sourcewiz.co">
              <Button>Contact us</Button>
            </a>
          </Row>
        </Col>
        <Col>
          <Row>
            <img src={helpDeskImage} alt="img" height="130" width="140" />
          </Row>
        </Col>
      </Row>
    </Subdiv>
  );
};
