import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PYTHON_KEY from 'api/pythonService';
import { useGlobal } from '../../../../store/index';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import { MODULE_LIST } from './ModuleList';
import {
  QuotationAlert,
  CatalogueAlert,
  UsersAlert,
  ProductAlert,
} from './Alerts';
import { AddOnSuccess } from '../Components/AddOnSuccess/AddOnSuccess';
import { PlanUpgradeSuccess } from '../Components/PlanUpgradeSuccess';
import { ModuleUpgradeModal } from '../Components/ModuleUpgrade/ModuleUpgradeModal';
import { DemoExpiredModal } from '../Components/DemoExpiredModal';
import { PlanExpiredModal } from '../Components/PlanExpired/PlanExpiredModal';
import { EarlyBirdNotification } from '../Components/EarlyBirdNotification/EarlyBirdNotification';
export default function Heimdall() {
  const [globalState, globalAction] = useGlobal();
  const { usage, reloadUsageData } = globalState;
  const location = useLocation();
  const [activeModule, setActiveModule] = useState(null);
  const [moduleData, setModuleData] = useState(null);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [notificationsData, setNotificationsData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCurrentPlanExpired, setIsCurrentPlanExpired] = useState(false);
  const [isDemoExpired, setIsDemoExpired] = useState(false);
  // get usage data and plan data
  const getUsage = useCallback(() => {
    PYTHON_KEY.URL.get(PYTHON_KEY.path.usage)
      .then((res) => {
        if (res.status === 200) {
          const module_usage = res?.data?.module_limits;
          const planUsage = res?.data?.plan;
          globalAction.guardrails.setUsage(module_usage);
          globalAction.guardrails.setPlanData(planUsage);
          if (
            planUsage?.core_plan?.name === 'Trial' &&
            planUsage?.status === 'expired'
          ) {
            setIsDemoExpired(true);
          }
          if (
            planUsage?.core_plan?.name !== 'Trial' &&
            planUsage?.status === 'expired'
          ) {
            setIsCurrentPlanExpired(true);
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [globalAction]);

  // get usage on mount
  useEffect(() => {
    getUsage();
  }, [getUsage]);

  useEffect(() => {
    if (reloadUsageData) {
      getUsage();
      //reload data state false
      globalAction.guardrails.setReloadUsageData(false);
    }
  }, [getUsage, reloadUsageData]);

  // find current moduole from location path
  const getCurrentModule = useCallback(() => {
    const path = location?.pathname;
    if (path === '/') {
      return '';
    } else if (
      path.includes('/master-catalogue') ||
      path.includes('/manage-products')
    ) {
      return MODULE_LIST.product_library.id;
    }
    // else if (
    //   path.includes("/create-collection") ||
    //   path.includes("/catalogue-table") ||
    //   path.includes("/select-products") ||
    //   path.includes("/catalogue-history")
    // ) {
    //   return MODULE_LIST.quick_catalogue_generator.id;
    // }
    // else if (
    //   path.includes("/enquiries") ||
    //   path.includes("/quotations") ||
    //   path.includes("/quotation-table")
    // ) {
    //   return MODULE_LIST.quotation.id;
    // } else if (path.includes("/profile-settings")) {
    //   return MODULE_LIST.users.id;
    // }
  }, [location]);

  // when usage data is updated or location is changed find current module and set it as active
  useEffect(() => {
    if (Array.isArray(Object.keys(usage))) {
      const currentModule = getCurrentModule();
      setActiveModule(currentModule);

      if (currentModule && Object.keys(usage).length > 0) {
        const moduleUsage = usage.find(
          (mod) => mod.module_name === currentModule
        );
        setModuleData(moduleUsage);
        globalAction.guardrails.setModuleData(moduleUsage);
      }
    }
  }, [usage, getCurrentModule]);

  // // set active module data
  // useEffect(() => {
  //   if (activeModule && Object.keys(usage).length > 0) {
  //     const moduleUsage = usage.find((mod) => mod.module_name === activeModule);
  //     setModuleData(moduleUsage);
  //     globalAction.guardrails.setModuleData(moduleUsage);
  //   }

  // }, [activeModule, usage]);

  // conditionally render alert based on module
  const handleUpgrade = () => {
    setUpgradeModal(true);
  };
  const renderBody = useCallback(() => {
    if (moduleData && Object.keys(moduleData).length > 0) {
      const percent = moduleData?.percent_used;
      const componentObj = {
        [MODULE_LIST.product_library.id]: (
          <ProductAlert percent={percent} handleUpgrade={handleUpgrade} />
        ),
        [MODULE_LIST.quick_catalogue_generator.id]: (
          <CatalogueAlert percent={percent} />
        ),
        [MODULE_LIST.quotation.id]: <QuotationAlert percent={percent} />,
        // [MODULE_LIST.users.id]: (
        //   <UsersAlert  percent={percent} />
        // ),
      };
      return <>{componentObj[activeModule]}</> || <></>;
    }
  }, [moduleData, activeModule]);

  // notifications data api call
  const getNotificationsData = useCallback(() => {
    const userData = localStorage.getItem('user');
    const user = userData && JSON.parse(userData);
    const access_token = user?.access_token;
    PYTHON_KEY.URL.get(PYTHON_KEY.path.notifications, {
      headers: { 'access-token': `${access_token}` },
    })
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data;
          if (data && Object.keys(data).length > 0) {
            setIsModalVisible(true);
            setNotificationsData(data);
          }
        }
      })
      .catch((err) => {
        console.log(err, 'error');
      });
    // setTimeout(() => {
    //   setShowNotifications(false);
    // }, 1000);
  }, []);

  // fetch notifications data
  useEffect(() => {
    getNotificationsData();
  }, [getNotificationsData]);

  // conditionally render notifications
  const renderNotifications = () => {
    if (notificationsData && Object.keys(notificationsData).length > 0) {
      //  setIsModalVisible(true);
      const { type } = notificationsData;
      const componentObj = {
        plan_upgrade_success: (
          <PlanUpgradeSuccess
            data={notificationsData}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        ),
        addon_activated_success: (
          <AddOnSuccess
            addOnData={notificationsData}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
          />
        ),
        no_sku_usage: (
          <EarlyBirdNotification
            data={notificationsData}
            type={'no_sku_usage'}
            earlyBirdModal={isModalVisible}
            setEarlyBirdModal={setIsModalVisible}
          />
        ),
        sku_usage: (
          <EarlyBirdNotification
            data={notificationsData}
            type={'sku_usage'}
            earlyBirdModal={isModalVisible}
            setEarlyBirdModal={setIsModalVisible}
          />
        ),
      };
      return componentObj[type] || <></>;
    }
  };

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>{renderBody()}</Col>

      {renderNotifications()}
      <ModuleUpgradeModal
        forAddOn={true}
        moduleData={moduleData}
        moduleId={moduleData?.module_id}
        isModalVisible={upgradeModal}
        setIsModalVisible={setUpgradeModal}
      />
      <DemoExpiredModal
        isModalVisible={isDemoExpired}
        setIsModalVisible={setIsDemoExpired}
      />
      <PlanExpiredModal
        isPlanExpired={isCurrentPlanExpired}
        setIsPlanExpired={setIsCurrentPlanExpired}
      />
    </Row>
  );
}
