export const QuotationActionTypes = Object.freeze({
  QUOTATION_ADD_PRODUCT: 'productsActions/QUOTATION_ADD_PRODUCT',
  QUOTATION_REMOVE_PRODUCT: 'productsActions/QUOTATION_REMOVE_PRODUCT',
  QUOTATION_SET_PRODUCTS: 'productsActions/QUOTATION_SET_PRODUCTS',
  QUOTATION_ADD_MULTIPLE_PRODUCTS:
    'productsActions/QUOTATION_ADD_MULTIPLE_PRODUCTS',
  QUOTATION_REMOVE_MULTIPLE_PRODUCTS:
    'productsActions/QUOTATION_REMOVE_MULTIPLE_PRODUCTS',
});

export function addProduct(product, quotationId) {
  return {
    type: QuotationActionTypes.QUOTATION_ADD_PRODUCT,
    payload: {
      product,
      quotationId,
    },
  };
}

export function removeProduct(product, quotationId) {
  return {
    type: QuotationActionTypes.QUOTATION_REMOVE_PRODUCT,
    payload: {
      product,
      quotationId,
    },
  };
}

export function setProducts(products, quotationId) {
  return {
    type: QuotationActionTypes.QUOTATION_SET_PRODUCTS,
    payload: {
      products,
      quotationId,
    },
  };
}

export function addMultipleProducts(products, quotationId) {
  return {
    type: QuotationActionTypes.QUOTATION_ADD_MULTIPLE_PRODUCTS,
    payload: {
      products,
      quotationId,
    },
  };
}

export function removeMultipleProducts(products, quotationId) {
  return {
    type: QuotationActionTypes.QUOTATION_REMOVE_MULTIPLE_PRODUCTS,
    payload: {
      products,
      quotationId,
    },
  };
}

const ACTION_HANDLERS = {
  QuotationActionTypes,
  addProduct,
  removeProduct,
  setProducts,
  addMultipleProducts,
  removeMultipleProducts,
};

export default ACTION_HANDLERS;
