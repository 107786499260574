export const portCodes = [
  {
    country: 'United Arab Emirates',
    code: 'AEAUH',
    name: 'Abu Dhabi',
  },
  {
    country: 'United Arab Emirates',
    code: 'AERUW',
    name: 'Ar Ruways',
  },
  {
    country: 'United Arab Emirates',
    code: 'AEKLF',
    name: 'Khor al Fakkan',
  },
  {
    country: 'United Arab Emirates',
    code: 'AEMKH',
    name: 'Mina Khalid',
  },
  {
    country: 'United Arab Emirates',
    code: 'AEKHL',
    name: 'Mina Khalifa/Abu Dhabi',
  },
  {
    country: 'United Arab Emirates',
    code: 'AEQIW',
    name: 'Umm al Qaiwain',
  },
  {
    country: 'Antigua and Barbuda',
    code: 'AGSJO',
    name: "Saint John's",
  },
  {
    country: 'Argentina',
    code: 'ARBUE',
    name: 'Buenos Aires',
  },
  {
    country: 'Argentina',
    code: 'ARMDQ',
    name: 'Mar del Plata',
  },
  {
    country: 'Argentina',
    code: 'ARPUD',
    name: 'Puerto Deseado',
  },
  {
    country: 'Argentina',
    code: 'ARPMY',
    name: 'Puerto Madryn',
  },
  {
    country: 'Argentina',
    code: 'ARROS',
    name: 'Rosario',
  },
  {
    country: 'Argentina',
    code: 'ARSAE',
    name: 'San Antonio Este',
  },
  {
    country: 'Argentina',
    code: 'ARUSH',
    name: 'Ushuaia',
  },
  {
    country: 'American Samoa',
    code: 'ASPPG',
    name: 'Pago Pago',
  },
  {
    country: 'Australia',
    code: 'AUADL',
    name: 'Adelaide',
  },
  {
    country: 'Australia',
    code: 'AUBNE',
    name: 'Brisbane',
  },
  {
    country: 'Australia',
    code: 'AUDRW',
    name: 'Darwin',
  },
  {
    country: 'Australia',
    code: 'AUMEL',
    name: 'Melbourne',
  },
  {
    country: 'Australia',
    code: 'AUSYD',
    name: 'Sydney',
  },
  {
    country: 'Aruba',
    code: 'AWBAR',
    name: 'Barcadera',
  },
  {
    country: 'Bangladesh',
    code: 'BDCGP',
    name: 'Chattogram',
  },
  {
    country: 'Belgium',
    code: 'BEANR',
    name: 'Antwerpen',
  },
  {
    country: 'Belgium',
    code: 'BEGNE',
    name: 'Gent (Ghent)',
  },
  {
    country: 'Belgium',
    code: 'BEZEE',
    name: 'Zeebrugge',
  },
  {
    country: 'Bahrain',
    code: 'BHMIN',
    name: 'Mina Sulman Port',
  },
  {
    country: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQKRA',
    name: 'Kralendijk',
  },
  {
    country: 'Brazil',
    code: 'BRNVT',
    name: 'Navegantes',
  },
  {
    country: 'Brazil',
    code: 'BRRIO',
    name: 'Rio de Janeiro',
  },
  {
    country: 'Brazil',
    code: 'BRSSZ',
    name: 'Santos',
  },
  {
    country: 'Bahamas',
    code: 'BSNAS',
    name: 'Nassau',
  },
  {
    country: 'Belize',
    code: 'BZBGK',
    name: 'Big Creek',
  },
  {
    country: 'Canada',
    code: 'CANWP',
    name: 'Argentia',
  },
  {
    country: 'Canada',
    code: 'CASJB',
    name: 'Saint-John',
  },
  {
    country: 'Canada',
    code: 'CASJF',
    name: "Saint-John's",
  },
  {
    country: 'Congo, The Democratic Republic of the',
    code: 'CDBNW',
    name: 'Banana',
  },
  {
    country: 'Congo',
    code: 'CGPNR',
    name: 'Pointe Noire',
  },
  {
    country: 'Chile',
    code: 'CLANF',
    name: 'Antofagasta',
  },
  {
    country: 'Chile',
    code: 'CLARI',
    name: 'Arica',
  },
  {
    country: 'Chile',
    code: 'CLCNL',
    name: 'Coronel',
  },
  {
    country: 'Chile',
    code: 'CLIQQ',
    name: 'Iquique',
  },
  {
    country: 'Chile',
    code: 'CLMJS',
    name: 'Mejillones',
  },
  {
    country: 'Chile',
    code: 'CLPAG',
    name: 'Puerto Angamos',
  },
  {
    country: 'Chile',
    code: 'CLPUQ',
    name: 'Punta Arenas',
  },
  {
    country: 'Chile',
    code: 'CLSAI',
    name: 'San Antonio',
  },
  {
    country: 'Chile',
    code: 'CLSVE',
    name: 'San Vicente',
  },
  {
    country: 'Chile',
    code: 'CLVAP',
    name: 'Valparaiso',
  },
  {
    country: 'China',
    code: 'CNCFD',
    name: 'Caofeidian Pt',
  },
  {
    country: 'China',
    code: 'CNDCB',
    name: 'DA CHAN BAY',
  },
  {
    country: 'China',
    code: 'CNDGG',
    name: 'Dongguan Pt',
  },
  {
    country: 'China',
    code: 'CNDJK',
    name: 'Dongjiangkou',
  },
  {
    country: 'China',
    code: 'CNFQG',
    name: 'Fuqing',
  },
  {
    country: 'China',
    code: 'CNHUI',
    name: 'Huizhou Pt',
  },
  {
    country: 'China',
    code: 'CNHMN',
    name: 'Humen Pt',
  },
  {
    country: 'China',
    code: 'CNJGY',
    name: 'Jiangyin',
  },
  {
    country: 'China',
    code: 'CNJNZ',
    name: 'Jinzhou Pt',
  },
  {
    country: 'China',
    code: 'CNLYG',
    name: 'Lianyungang',
  },
  {
    country: 'China',
    code: 'CNNSA',
    name: 'Nansha Pt',
  },
  {
    country: 'China',
    code: 'CNNTG',
    name: 'Nantong Pt',
  },
  {
    country: 'China',
    code: 'CNSHP',
    name: 'Qinhuangdao Pt',
  },
  {
    country: 'China',
    code: 'CNQZJ',
    name: 'Quanzhou Pt',
  },
  {
    country: 'China',
    code: 'CNRZH',
    name: 'Rizhao Pt',
  },
  {
    country: 'China',
    code: 'CNSHK',
    name: 'Shekou Pt',
  },
  {
    country: 'China',
    code: 'CNSHD',
    name: 'Shidao Pt',
  },
  {
    country: 'China',
    code: 'CNWIH',
    name: 'Waihai',
  },
  {
    country: 'China',
    code: 'CNWEF',
    name: 'Weifang Pt',
  },
  {
    country: 'China',
    code: 'CNWEI',
    name: 'Weihai',
  },
  {
    country: 'China',
    code: 'CNYPG',
    name: 'Yangpu Pt',
  },
  {
    country: 'China',
    code: 'CNYTN',
    name: 'Yantian Pt',
  },
  {
    country: 'China',
    code: 'CNYIK',
    name: 'Yingkou Pt',
  },
  {
    country: 'China',
    code: 'CNZJG',
    name: 'Zhangjiagang',
  },
  {
    country: 'China',
    code: 'CNZZU',
    name: 'Zhangzhou Pt',
  },
  {
    country: 'China',
    code: 'CNZUH',
    name: 'Zhuhai Pt',
  },
  {
    country: 'Colombia',
    code: 'COLET',
    name: 'Leticia',
  },
  {
    country: 'Colombia',
    code: 'COTRB',
    name: 'Turbo',
  },
  {
    country: 'Costa Rica',
    code: 'CRCAL',
    name: 'Caldera',
  },
  {
    country: 'Cuba',
    code: 'CUMAR',
    name: 'Mariel',
  },
  {
    country: 'Cabo Verde',
    code: 'CVMIN',
    name: 'Mindelo',
  },
  {
    country: 'Christmas Island',
    code: 'CXFFC',
    name: 'Flying Fish Cove',
  },
  {
    country: 'Cyprus',
    code: 'CYLMS',
    name: 'Limassol',
  },
  {
    country: 'Germany',
    code: 'DEBKE',
    name: 'Brake',
  },
  {
    country: 'Germany',
    code: 'DECUX',
    name: 'Cuxhaven',
  },
  {
    country: 'Germany',
    code: 'DEEME',
    name: 'Emden',
  },
  {
    country: 'Germany',
    code: 'DEKEL',
    name: 'Kiel',
  },
  {
    country: 'Germany',
    code: 'DERSK',
    name: 'Rostock',
  },
  {
    country: 'Germany',
    code: 'DEWVN',
    name: 'Wilhelmshaven',
  },
  {
    country: 'Denmark',
    code: 'DKAAR',
    name: 'Aarhus',
  },
  {
    country: 'Dominican Republic',
    code: 'DOCAU',
    name: 'Caucedo',
  },
  {
    country: 'Dominican Republic',
    code: 'DOMAN',
    name: 'Manzanillo',
  },
  {
    country: 'Algeria',
    code: 'DZALG',
    name: 'Alger (Algiers)',
  },
  {
    country: 'Algeria',
    code: 'DZAZW',
    name: 'Arzew',
  },
  {
    country: 'Algeria',
    code: 'DZGHZ',
    name: 'Ghazaouet',
  },
  {
    country: 'Ecuador',
    code: 'ECESM',
    name: 'Esmeraldas',
  },
  {
    country: 'Ecuador',
    code: 'ECGYE',
    name: 'Guayaquil',
  },
  {
    country: 'Ecuador',
    code: 'ECPSJ',
    name: 'Posorja',
  },
  {
    country: 'Estonia',
    code: 'EEKND',
    name: 'Kunda',
  },
  {
    country: 'Estonia',
    code: 'EEMUG',
    name: 'Muuga',
  },
  {
    country: 'Estonia',
    code: 'EEPLA',
    name: 'Paldiski',
  },
  {
    country: 'Estonia',
    code: 'EETLL',
    name: 'Tallinn',
  },
  {
    country: 'Egypt',
    code: 'EGDAM',
    name: 'Dumyat (Damietta)',
  },
  {
    country: 'Egypt',
    code: 'EGALY',
    name: 'El Iskandariya (Alexandria)',
  },
  {
    country: 'Egypt',
    code: 'EGSOK',
    name: 'Sokhna Port',
  },
  {
    country: 'Eritrea',
    code: 'ERMSW',
    name: 'Massawa (Mitsiwa)',
  },
  {
    country: 'Spain',
    code: 'ESBIO',
    name: 'Bilbao',
  },
  {
    country: 'Spain',
    code: 'ESCAR',
    name: 'Cartagena',
  },
  {
    country: 'Spain',
    code: 'ESFRO',
    name: 'Ferrol',
  },
  {
    country: 'Spain',
    code: 'ESACE',
    name: 'Lanzarote',
  },
  {
    country: 'Spain',
    code: 'ESLPA',
    name: 'Las Palmas de Gran Canaria',
  },
  {
    country: 'Spain',
    code: 'ESMLN',
    name: 'Melilla',
  },
  {
    country: 'Spain',
    code: 'ESSAG',
    name: 'Sagunto',
  },
  {
    country: 'Spain',
    code: 'ESSPC',
    name: 'Santa Cruz de La Palma',
  },
  {
    country: 'Finland',
    code: 'FIHMN',
    name: 'Hamina (Fredrikshamn)',
  },
  {
    country: 'Finland',
    code: 'FIPRS',
    name: 'Pietarsaari (Jakobstad)',
  },
  {
    country: 'Finland',
    code: 'FIKOK',
    name: 'Kokkola (Karleby)',
  },
  {
    country: 'Finland',
    code: 'FIKTK',
    name: 'Kotka',
  },
  {
    country: 'Finland',
    code: 'FIRAU',
    name: 'Raumo (Rauma)',
  },
  {
    country: 'Fiji',
    code: 'FJLTK',
    name: 'Lautoka',
  },
  {
    country: 'Fiji',
    code: 'FJSUV',
    name: 'Suva',
  },
  {
    country: 'Faroe Islands',
    code: 'FOTHO',
    name: 'Thorshavn',
  },
  {
    country: 'France',
    code: 'FRBAS',
    name: 'Bassens',
  },
  {
    country: 'France',
    code: 'FRBES',
    name: 'Brest',
  },
  {
    country: 'France',
    code: 'FRDKK',
    name: 'Dunkerque',
  },
  {
    country: 'France',
    code: 'FRFOS',
    name: 'Fos-sur-Mer',
  },
  {
    country: 'France',
    code: 'FRLPE',
    name: 'la Pallice',
  },
  {
    country: 'France',
    code: 'FRLRH',
    name: 'La Rochelle',
  },
  {
    country: 'France',
    code: 'FRLEH',
    name: 'Le Havre',
  },
  {
    country: 'France',
    code: 'FRMRS',
    name: 'Marseille',
  },
  {
    country: 'France',
    code: 'FRMTX',
    name: 'Montoir-de-Bretagne',
  },
  {
    country: 'France',
    code: 'FRPOV',
    name: 'Port-Vendres',
  },
  {
    country: 'France',
    code: 'FRRAD',
    name: 'Radicatel',
  },
  {
    country: 'France',
    code: 'FRURO',
    name: 'Rouen',
  },
  {
    country: 'France',
    code: 'FRTLN',
    name: 'Toulon',
  },
  {
    country: 'United Kingdom',
    code: 'GBABD',
    name: 'Aberdeen',
  },
  {
    country: 'United Kingdom',
    code: 'GBBEL',
    name: 'Belfast',
  },
  {
    country: 'United Kingdom',
    code: 'GBGRG',
    name: 'Grangemouth',
  },
  {
    country: 'United Kingdom',
    code: 'GBGRK',
    name: 'Greenock',
  },
  {
    country: 'United Kingdom',
    code: 'GBHUL',
    name: 'Hull',
  },
  {
    country: 'United Kingdom',
    code: 'GBIMM',
    name: 'Immingham',
  },
  {
    country: 'United Kingdom',
    code: 'GBLIV',
    name: 'Liverpool',
  },
  {
    country: 'United Kingdom',
    code: 'GBLGP',
    name: 'London Gateway Port',
  },
  {
    country: 'United Kingdom',
    code: 'GBTEE',
    name: 'Teesport',
  },
  {
    country: 'United Kingdom',
    code: 'GBTYN',
    name: 'Tyne',
  },
  {
    country: 'Greenland',
    code: 'GLGOH',
    name: 'Nuuk (Godthaab)',
  },
  {
    country: 'Greece',
    code: 'GRHER',
    name: 'Iraklion (Heraklion)',
  },
  {
    country: 'Greece',
    code: 'GRPIR',
    name: 'Piraeus',
  },
  {
    country: 'Guam',
    code: 'GUPIT',
    name: 'Piti',
  },
  {
    country: 'Guinea-Bissau',
    code: 'GWOXB',
    name: 'Bissau',
  },
  {
    country: 'Croatia',
    code: 'HRPLE',
    name: 'Ploce',
  },
  {
    country: 'Croatia',
    code: 'HRRJK',
    name: 'Rijeka',
  },
  {
    country: 'Croatia',
    code: 'HRSPU',
    name: 'Split',
  },
  {
    country: 'Haiti',
    code: 'HTLFF',
    name: 'Lafiteau',
  },
  {
    country: 'Indonesia',
    code: 'IDBPN',
    name: 'Balikpapan',
  },
  {
    country: 'Indonesia',
    code: 'IDBTM',
    name: 'Batam Island',
  },
  {
    country: 'Indonesia',
    code: 'IDBEN',
    name: 'Benete',
  },
  {
    country: 'Indonesia',
    code: 'IDBOA',
    name: 'Benoa',
  },
  {
    country: 'Indonesia',
    code: 'IDJKT',
    name: 'Jakarta, Java',
  },
  {
    country: 'Indonesia',
    code: 'IDDJJ',
    name: 'Jayapura, Irian Jaya',
  },
  {
    country: 'Indonesia',
    code: 'IDKUM',
    name: 'Kumai',
  },
  {
    country: 'Indonesia',
    code: 'IDMAK',
    name: 'Makassar',
  },
  {
    country: 'Indonesia',
    code: 'IDPDG',
    name: 'Padang',
  },
  {
    country: 'Indonesia',
    code: 'IDPER',
    name: 'Perawang',
  },
  {
    country: 'Indonesia',
    code: 'IDSRG',
    name: 'Semarang',
  },
  {
    country: 'Indonesia',
    code: 'IDSUB',
    name: 'Surabaya',
  },
  {
    country: 'Indonesia',
    code: 'IDTNJ',
    name: 'Tanjungpinang',
  },
  {
    country: 'Indonesia',
    code: 'IDTMK',
    name: 'Timika',
  },
  {
    country: 'Indonesia',
    code: 'IDTUA',
    name: 'Tual',
  },
  {
    country: 'Ireland',
    code: 'IEWAT',
    name: 'Waterford',
  },
  {
    country: 'Israel',
    code: 'ILHFA',
    name: 'Haifa',
  },
  {
    country: 'India',
    code: 'INMAA',
    name: 'Chennai (ex Madras)',
  },
  {
    country: 'India',
    code: 'INCOK',
    name: 'Cochin',
  },
  {
    country: 'India',
    code: 'INDEL4',
    name: 'New Delhi',
  },
  {
    country: 'India',
    code: 'INHZR',
    name: 'Hazira',
  },
  {
    country: 'India',
    code: 'INIXY',
    name: 'Kandla',
  },
  {
    country: 'India',
    code: 'INKTP',
    name: 'Kattupalli Port',
  },
  {
    country: 'India',
    code: 'INCCU',
    name: 'Kolkata (ex Calcutta)',
  },
  {
    country: 'India',
    code: 'INMRM',
    name: 'Marmugao (Marmagao)',
  },
  {
    country: 'India',
    code: 'INBOM',
    name: 'Mumbai (ex Bombay)',
  },
  {
    country: 'India',
    code: 'INPRT',
    name: 'Paradip Garh',
  },
  {
    country: 'India',
    code: 'INPAV',
    name: 'Pipavav (Victor) Port',
  },
  {
    country: 'India',
    code: 'INTUT',
    name: 'Tuticorin',
  },
  {
    country: 'India',
    code: 'INVTZ',
    name: 'Visakhapatnam',
  },
  {
    country: 'Iraq',
    code: 'IQUQR',
    name: 'Umm Qasr Port',
  },
  {
    country: 'Iran, Islamic Republic of',
    code: 'IRASA',
    name: 'Asaluyeh',
  },
  {
    country: 'Iran, Islamic Republic of',
    code: 'IRBND',
    name: 'Bandar Abbas',
  },
  {
    country: 'Iran, Islamic Republic of',
    code: 'IRBKM',
    name: 'Bandar Khomeini',
  },
  {
    country: 'Iran, Islamic Republic of',
    code: 'IRBUZ',
    name: 'Bushehr',
  },
  {
    country: 'Italy',
    code: 'ITAOI',
    name: 'Ancona',
  },
  {
    country: 'Italy',
    code: 'ITBRI',
    name: 'Bari',
  },
  {
    country: 'Italy',
    code: 'ITCAG',
    name: 'Cagliari',
  },
  {
    country: 'Italy',
    code: 'ITCTA',
    name: 'Catania',
  },
  {
    country: 'Italy',
    code: 'ITCVV',
    name: 'Civitavecchia',
  },
  {
    country: 'Italy',
    code: 'ITGOA',
    name: 'Genova',
  },
  {
    country: 'Italy',
    code: 'ITGIT',
    name: 'Gioia Tauro',
  },
  {
    country: 'Italy',
    code: 'ITSPE',
    name: 'La Spezia',
  },
  {
    country: 'Italy',
    code: 'ITLIV',
    name: 'Livorno',
  },
  {
    country: 'Italy',
    code: 'ITNAP',
    name: 'Napoli',
  },
  {
    country: 'Italy',
    code: 'ITOLB',
    name: 'Olbia',
  },
  {
    country: 'Italy',
    code: 'ITPMO',
    name: 'Palermo',
  },
  {
    country: 'Italy',
    code: 'ITSVN',
    name: 'Savona',
  },
  {
    country: 'Italy',
    code: 'ITTPS',
    name: 'Trapani',
  },
  {
    country: 'Italy',
    code: 'ITVDL',
    name: 'Vado Ligure',
  },
  {
    country: 'Italy',
    code: 'ITVCE',
    name: 'Venezia',
  },
  {
    country: 'Jordan',
    code: 'JOAQJ',
    name: "Al 'Aqabah",
  },
  {
    country: 'Japan',
    code: 'JPHTD',
    name: 'Hakata',
  },
  {
    country: 'Japan',
    code: 'JPISS',
    name: 'Isa',
  },
  {
    country: 'Japan',
    code: 'JPKKJ',
    name: 'Kitakyushu',
  },
  {
    country: 'Japan',
    code: 'JPUKB',
    name: 'Kobe',
  },
  {
    country: 'Japan',
    code: 'JPMJR',
    name: 'Mitajiri',
  },
  {
    country: 'Japan',
    code: 'JPMOJ',
    name: 'Moji/Kitakyushu',
  },
  {
    country: 'Japan',
    code: 'JPOSA',
    name: 'Osaka',
  },
  {
    country: 'Japan',
    code: 'JPSEN',
    name: 'Satsumasendai',
  },
  {
    country: 'Japan',
    code: 'JPTYO',
    name: 'Tokyo',
  },
  {
    country: 'Japan',
    code: 'JPYOK',
    name: 'Yokohama',
  },
  {
    country: 'Comoros',
    code: 'KMMUT',
    name: 'Mutsamudu, Anjouan',
  },
  {
    country: 'Saint Kitts and Nevis',
    code: 'KNCHA',
    name: 'Charlestown',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRPUS',
    name: 'Busan',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRTSN',
    name: 'Daesan/Seosan',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRTJI',
    name: 'Dangjin',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRKUV',
    name: 'Gunsan',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRKAN',
    name: 'Gwangyang',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRINC',
    name: 'Incheon',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRMAS',
    name: 'Masan',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRMOK',
    name: 'Mokpo',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRKPO',
    name: 'Pohang',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRPTK',
    name: 'Pyeongtaek',
  },
  {
    country: 'Korea, Republic of',
    code: 'KRUSN',
    name: 'Ulsan',
  },
  {
    country: 'Kuwait',
    code: 'KWSAA',
    name: 'Shuaiba',
  },
  {
    country: 'Kuwait',
    code: 'KWSWK',
    name: 'Shuwaikh',
  },
  {
    country: 'Cayman Islands',
    code: 'KYGEC',
    name: 'Georgetown, Grand Cayman',
  },
  {
    country: 'Lebanon',
    code: 'LBBEY',
    name: 'Beirut',
  },
  {
    country: 'Saint Lucia',
    code: 'LCCAS',
    name: 'Castries',
  },
  {
    country: 'Sri Lanka',
    code: 'LKCMB',
    name: 'Colombo',
  },
  {
    country: 'Liberia',
    code: 'LRMLW',
    name: 'Monrovia',
  },
  {
    country: 'Libya',
    code: 'LYKHO',
    name: 'Al Khums',
  },
  {
    country: 'Morocco',
    code: 'MACAS',
    name: 'Casablanca',
  },
  {
    country: 'Moldova, Republic of',
    code: 'MDGIU',
    name: 'Giurgiulesti',
  },
  {
    country: 'Montenegro',
    code: 'MEBAR',
    name: 'Bar',
  },
  {
    country: 'Madagascar',
    code: 'MGEHL',
    name: 'Ehoala',
  },
  {
    country: 'Madagascar',
    code: 'MGNOS',
    name: 'Nosy-Be',
  },
  {
    country: 'Marshall Islands',
    code: 'MHMAJ',
    name: 'Majuro',
  },
  {
    country: 'Myanmar',
    code: 'MMTLA',
    name: 'Thilawa',
  },
  {
    country: 'Malta',
    code: 'MTMAR',
    name: 'Marsaxlokk',
  },
  {
    country: 'Mauritius',
    code: 'MUPMA',
    name: 'Port Mathurin',
  },
  {
    country: 'Maldives',
    code: 'MVMLE',
    name: 'Male',
  },
  {
    country: 'Mexico',
    code: 'MXATM',
    name: 'Altamira',
  },
  {
    country: 'Mexico',
    code: 'MXCOA',
    name: 'Coatzacoalcos',
  },
  {
    country: 'Mexico',
    code: 'MXESE',
    name: 'Ensenada',
  },
  {
    country: 'Mexico',
    code: 'MXGYM',
    name: 'Guaymas',
  },
  {
    country: 'Mexico',
    code: 'MXZLO',
    name: 'Manzanillo',
  },
  {
    country: 'Mexico',
    code: 'MXMZT',
    name: 'Mazatlan',
  },
  {
    country: 'Mexico',
    code: 'MXPRO',
    name: 'Paraiso',
  },
  {
    country: 'Mexico',
    code: 'MXPMD',
    name: 'Puerto Madero',
  },
  {
    country: 'Mexico',
    code: 'MXPMS',
    name: 'Puerto Morelos',
  },
  {
    country: 'Mexico',
    code: 'MXTAM',
    name: 'Tampico',
  },
  {
    country: 'Mexico',
    code: 'MXTUX',
    name: 'Tuxpan',
  },
  {
    country: 'Mexico',
    code: 'MXVER',
    name: 'Veracruz',
  },
  {
    country: 'Malaysia',
    code: 'MYPEN',
    name: 'Penang (Georgetown)',
  },
  {
    country: 'Malaysia',
    code: 'MYPKG',
    name: 'Port Klang (Pelabuhan Klang)',
  },
  {
    country: 'Malaysia',
    code: 'MYTPP',
    name: 'Tanjung Pelepas',
  },
  {
    country: 'Nigeria',
    code: 'NGLKK',
    name: 'Lekki',
  },
  {
    country: 'Nigeria',
    code: 'NGONN',
    name: 'Onne',
  },
  {
    country: 'Nicaragua',
    code: 'NIRAM',
    name: 'Rama',
  },
  {
    country: 'Netherlands',
    code: 'NLAMS',
    name: 'Amsterdam',
  },
  {
    country: 'Netherlands',
    code: 'NLBOT',
    name: 'Botlek',
  },
  {
    country: 'Netherlands',
    code: 'NLIJM',
    name: 'IJmuiden/Velsen',
  },
  {
    country: 'Netherlands',
    code: 'NLMOE',
    name: 'Moerdijk',
  },
  {
    country: 'Netherlands',
    code: 'NLRTM',
    name: 'Rotterdam',
  },
  {
    country: 'Netherlands',
    code: 'NLTNZ',
    name: 'Terneuzen',
  },
  {
    country: 'Netherlands',
    code: 'NLVLI',
    name: 'Vlissingen',
  },
  {
    country: 'Norway',
    code: 'NOBGO',
    name: 'Bergen',
  },
  {
    country: 'Norway',
    code: 'NOBVK',
    name: 'Brevik',
  },
  {
    country: 'Norway',
    code: 'NODRM',
    name: 'Drammen',
  },
  {
    country: 'Norway',
    code: 'NOEGE',
    name: 'Egersund',
  },
  {
    country: 'Norway',
    code: 'NOFRK',
    name: 'Fredrikstad',
  },
  {
    country: 'Norway',
    code: 'NOFUS',
    name: 'Fusa',
  },
  {
    country: 'Norway',
    code: 'NOGLO',
    name: 'Glomfjord',
  },
  {
    country: 'Norway',
    code: 'NOHFT',
    name: 'Hammerfest',
  },
  {
    country: 'Norway',
    code: 'NOHRD',
    name: 'Harstad',
  },
  {
    country: 'Norway',
    code: 'NOHAU',
    name: 'Haugesund',
  },
  {
    country: 'Norway',
    code: 'NOHLA',
    name: 'Holla',
  },
  {
    country: 'Norway',
    code: 'NOIKR',
    name: 'Ikornnes',
  },
  {
    country: 'Norway',
    code: 'NOKRS',
    name: 'Kristiansand',
  },
  {
    country: 'Norway',
    code: 'NOLAR',
    name: 'Larvik',
  },
  {
    country: 'Norway',
    code: 'NOMOL',
    name: 'Molde',
  },
  {
    country: 'Norway',
    code: 'NOMSS',
    name: 'Moss',
  },
  {
    country: 'Norway',
    code: 'NOODD',
    name: 'Odda',
  },
  {
    country: 'Norway',
    code: 'NOORK',
    name: 'Orkanger',
  },
  {
    country: 'Norway',
    code: 'NOOSL',
    name: 'Oslo',
  },
  {
    country: 'Norway',
    code: 'NOSAT',
    name: 'Salten',
  },
  {
    country: 'Norway',
    code: 'NOSVG',
    name: 'Stavanger',
  },
  {
    country: 'Norway',
    code: 'NOSKN',
    name: 'Stokmarknes',
  },
  {
    country: 'Norway',
    code: 'NOSVE',
    name: 'Svelgen',
  },
  {
    country: 'Norway',
    code: 'NOTAE',
    name: 'Tananger',
  },
  {
    country: 'Norway',
    code: 'NOTRD',
    name: 'Trondheim',
  },
  {
    country: 'Nauru',
    code: 'NRINU',
    name: 'Nauru Island',
  },
  {
    country: 'New Zealand',
    code: 'NZAKL',
    name: 'Auckland',
  },
  {
    country: 'New Zealand',
    code: 'NZBLU',
    name: 'Bluff',
  },
  {
    country: 'New Zealand',
    code: 'NZLYT',
    name: 'Lyttelton',
  },
  {
    country: 'New Zealand',
    code: 'NZMAP',
    name: 'Marsden Point',
  },
  {
    country: 'New Zealand',
    code: 'NZNPE',
    name: 'Napier',
  },
  {
    country: 'New Zealand',
    code: 'NZNSN',
    name: 'Nelson',
  },
  {
    country: 'New Zealand',
    code: 'NZPOE',
    name: 'Port Chalmers',
  },
  {
    country: 'New Zealand',
    code: 'NZTRG',
    name: 'Tauranga',
  },
  {
    country: 'New Zealand',
    code: 'NZTIU',
    name: 'Timaru',
  },
  {
    country: 'New Zealand',
    code: 'NZWLG',
    name: 'Wellington',
  },
  {
    country: 'Panama',
    code: 'PAROD',
    name: 'Rodman',
  },
  {
    country: 'French Polynesia',
    code: 'PFBOB',
    name: 'Bora Bora',
  },
  {
    country: 'French Polynesia',
    code: 'PFPPT',
    name: 'Papeete',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGMAG',
    name: 'Madang',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGMTK',
    name: 'Motukea Island',
  },
  {
    country: 'Philippines',
    code: 'PHBTG',
    name: 'Batangas/Luzon',
  },
  {
    country: 'Philippines',
    code: 'PHGES',
    name: 'General Santos',
  },
  {
    country: 'Philippines',
    code: 'PHTGO',
    name: 'Tagoloan',
  },
  {
    country: 'Poland',
    code: 'PLGDY',
    name: 'Gdynia',
  },
  {
    country: 'Poland',
    code: 'PLSWI',
    name: 'Swinoujscie',
  },
  {
    country: 'Puerto Rico',
    code: 'PRSJU',
    name: 'San Juan',
  },
  {
    country: 'Portugal',
    code: 'PTFDF',
    name: 'Figueira da Foz',
  },
  {
    country: 'Portugal',
    code: 'PTHOR',
    name: 'Horta',
  },
  {
    country: 'Portugal',
    code: 'PTLIS',
    name: 'Lisboa',
  },
  {
    country: 'Portugal',
    code: 'PTPIC',
    name: 'Pico',
  },
  {
    country: 'Portugal',
    code: 'PTPDL',
    name: 'Ponta Delgada',
  },
  {
    country: 'Portugal',
    code: 'PTPXO',
    name: 'Porto Santo Island',
  },
  {
    country: 'Portugal',
    code: 'PTPRG',
    name: 'Praia da Graciosa',
  },
  {
    country: 'Portugal',
    code: 'PTSCF',
    name: 'Santa Cruz das Flores',
  },
  {
    country: 'Portugal',
    code: 'PTSIE',
    name: 'Sines',
  },
  {
    country: 'Portugal',
    code: 'PTVEL',
    name: 'Velas',
  },
  {
    country: 'Portugal',
    code: 'PTVDP',
    name: 'Vila do Porto',
  },
  {
    country: 'Qatar',
    code: 'QAHMD',
    name: 'BGN/PCGN1956 - HAMAD',
  },
  {
    country: 'Qatar',
    code: 'QAMES',
    name: 'Mesaieed',
  },
  {
    country: 'Romania',
    code: 'ROCND',
    name: 'Constanta',
  },
  {
    country: 'Russian Federation',
    code: 'RUBRK',
    name: 'Lomonosov',
  },
  {
    country: 'Russian Federation',
    code: 'RUNVS',
    name: 'Novorossiysk',
  },
  {
    country: 'Russian Federation',
    code: 'RULED',
    name: 'Saint Petersburg (ex Leningrad)',
  },
  {
    country: 'Russian Federation',
    code: 'RUULU',
    name: "Ust'-Luga",
  },
  {
    country: 'Russian Federation',
    code: 'RUVYP',
    name: 'Vostochnyy Port',
  },
  {
    country: 'Russian Federation',
    code: 'RUZAR',
    name: 'Zarubino',
  },
  {
    country: 'Saudi Arabia',
    code: 'SADMM',
    name: 'Ad Dammam',
  },
  {
    country: 'Saudi Arabia',
    code: 'SAJED',
    name: 'Jeddah',
  },
  {
    country: 'Saudi Arabia',
    code: 'SAKAC',
    name: 'King Abdullah City',
  },
  {
    country: 'Saudi Arabia',
    code: 'SAYNB',
    name: 'Yanbu al-Bahr',
  },
  {
    country: 'Sweden',
    code: 'SEHAD',
    name: 'Halmstad',
  },
  {
    country: 'Sweden',
    code: 'SEKSD',
    name: 'Karlstad',
  },
  {
    country: 'Sweden',
    code: 'SEOSK',
    name: 'Oskarshamn',
  },
  {
    country: 'Sweden',
    code: 'SESTO',
    name: 'Stockholm',
  },
  {
    country: 'Sweden',
    code: 'SESDL',
    name: 'Sundsvall',
  },
  {
    country: 'Syrian Arab Republic',
    code: 'SYLTK',
    name: 'Latakia',
  },
  {
    country: 'Syrian Arab Republic',
    code: 'SYTTS',
    name: 'Tartus',
  },
  {
    country: 'Thailand',
    code: 'THBKK',
    name: 'Bangkok',
  },
  {
    country: 'Thailand',
    code: 'THLCH',
    name: 'Laem Chabang',
  },
  {
    country: 'Thailand',
    code: 'THHKT',
    name: 'Phuket International Apt',
  },
  {
    country: 'Thailand',
    code: 'THSCS',
    name: 'Sahathai Coastal Seaport',
  },
  {
    country: 'Timor-Leste',
    code: 'TLDIL',
    name: 'Dili',
  },
  {
    country: 'Tonga',
    code: 'TOTBU',
    name: "Nuku'alofa",
  },
  {
    country: 'Turkey',
    code: 'TRPAM',
    name: 'Ambarli',
  },
  {
    country: 'Turkey',
    code: 'TRAYT',
    name: 'Antalya',
  },
  {
    country: 'Turkey',
    code: 'TRBDM',
    name: 'Bandirma',
  },
  {
    country: 'Turkey',
    code: 'TRELI',
    name: 'Eregli',
  },
  {
    country: 'Turkey',
    code: 'TREYP',
    name: 'Evyap Port /Kocaeli',
  },
  {
    country: 'Turkey',
    code: 'TRGEB',
    name: 'Gebze',
  },
  {
    country: 'Turkey',
    code: 'TRGEM',
    name: 'Gemlik',
  },
  {
    country: 'Turkey',
    code: 'TRISK',
    name: 'Iskenderun',
  },
  {
    country: 'Turkey',
    code: 'TRIZM',
    name: 'Izmir',
  },
  {
    country: 'Turkey',
    code: 'TRIZT',
    name: 'Izmit',
  },
  {
    country: 'Turkey',
    code: 'TRKMX',
    name: 'Kumport',
  },
  {
    country: 'Turkey',
    code: 'TRLMA',
    name: 'Limas',
  },
  {
    country: 'Turkey',
    code: 'TRMAD',
    name: 'Mardas',
  },
  {
    country: 'Turkey',
    code: 'TRMER',
    name: 'Mersin',
  },
  {
    country: 'Turkey',
    code: 'TRSSX',
    name: 'Samsun',
  },
  {
    country: 'Turkey',
    code: 'TRTEK',
    name: 'Tekirdag',
  },
  {
    country: 'Turkey',
    code: 'TRTZX',
    name: 'Trabzon',
  },
  {
    country: 'Turkey',
    code: 'TRYPO',
    name: 'Yilport',
  },
  {
    country: 'Taiwan, Province of China',
    code: 'TWTPE',
    name: 'Taipei',
  },
  {
    country: 'Tanzania, United Republic of',
    code: 'TZDAR',
    name: 'Dar es Salaam',
  },
  {
    country: 'Ukraine',
    code: 'UAILK',
    name: 'Chornomorsk',
  },
  {
    country: 'Ukraine',
    code: 'UAODS',
    name: 'Odesa',
  },
  {
    country: 'Ukraine',
    code: 'UAYUZ',
    name: 'Yuzhnyi',
  },
  {
    country: 'United States',
    code: 'USBAL',
    name: 'Baltimore',
  },
  {
    country: 'United States',
    code: 'USACL',
    name: 'Chester',
  },
  {
    country: 'United States',
    code: 'USEPI',
    name: 'Eastport',
  },
  {
    country: 'United States',
    code: 'USFEB',
    name: 'Fernandina Beach',
  },
  {
    country: 'United States',
    code: 'USGFP',
    name: 'Gulfport',
  },
  {
    country: 'United States',
    code: 'USHNL',
    name: 'Honolulu',
  },
  {
    country: 'United States',
    code: 'USHKA',
    name: 'Houston',
  },
  {
    country: 'United States',
    code: 'USLGB',
    name: 'Long Beach',
  },
  {
    country: 'United States',
    code: 'USNYC',
    name: 'New York',
  },
  {
    country: 'United States',
    code: 'USNFF',
    name: 'Norfolk',
  },
  {
    country: 'United States',
    code: 'USKND',
    name: 'Oakland',
  },
  {
    country: 'United States',
    code: 'USPDP',
    name: 'Philadelphia',
  },
  {
    country: 'United States',
    code: 'USPEF',
    name: 'Port Everglades',
  },
  {
    country: 'United States',
    code: 'USNTD',
    name: 'Port Hueneme',
  },
  {
    country: 'United States',
    code: 'USPME',
    name: 'Port Manatee',
  },
  {
    country: 'United States',
    code: 'USSAN',
    name: 'San Diego',
  },
  {
    country: 'United States',
    code: 'USSCK',
    name: 'Stockton',
  },
  {
    country: 'United States',
    code: 'USILM',
    name: 'Wilmington',
  },
  {
    country: 'Uruguay',
    code: 'UYPTP',
    name: 'Punta Pereyra',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    code: 'VCKTN',
    name: 'Kingstown',
  },
  {
    country: 'Virgin Islands, U.S.',
    code: 'VICTD',
    name: 'Christiansted, Saint Croix',
  },
  {
    country: 'Viet Nam',
    code: 'VNC8Q',
    name: 'Chu Lai',
  },
  {
    country: 'Viet Nam',
    code: 'VNDAD',
    name: 'Da Nang',
  },
  {
    country: 'Viet Nam',
    code: 'VNSGN',
    name: 'Ho Chi Minh City',
  },
  {
    country: 'Viet Nam',
    code: 'VNNGH',
    name: 'Nghi Son',
  },
  {
    country: 'Viet Nam',
    code: 'VNUIH',
    name: 'Qui Nhon',
  },
  {
    country: 'Viet Nam',
    code: 'VNVUT',
    name: 'Vung Tau',
  },
  {
    country: 'Wallis and Futuna',
    code: 'WFFUT',
    name: 'Vele Futuna I. Apt',
  },
  {
    country: 'Mayotte',
    code: 'YTLON',
    name: 'Longoni',
  },
  {
    country: 'South Africa',
    code: 'ZACPT',
    name: 'Cape Town',
  },
  {
    country: 'South Africa',
    code: 'ZAZBA',
    name: 'Coega',
  },
  {
    country: 'South Africa',
    code: 'ZADUR',
    name: 'Durban',
  },
  {
    country: 'United Arab Emirates',
    code: 'AEAJM',
    name: 'Ajman',
  },
  {
    country: 'United Arab Emirates',
    code: 'AEJEA',
    name: 'Jebel Ali',
  },
  {
    country: 'Anguilla',
    code: 'AIAXA',
    name: 'Anguilla',
  },
  {
    country: 'Angola',
    code: 'AOCAB',
    name: 'Cabinda',
  },
  {
    country: 'Angola',
    code: 'AOLOB',
    name: 'Lobito',
  },
  {
    country: 'Angola',
    code: 'AOLAD',
    name: 'Luanda',
  },
  {
    country: 'Angola',
    code: 'AOMAL',
    name: 'Malongo',
  },
  {
    country: 'Angola',
    code: 'AOMSZ',
    name: 'Namibe',
  },
  {
    country: 'Angola',
    code: 'AOSZA',
    name: 'Soyo',
  },
  {
    country: 'Australia',
    code: 'AUBEL',
    name: 'Bell Bay',
  },
  {
    country: 'Australia',
    code: 'AUBWT',
    name: 'Burnie',
  },
  {
    country: 'Australia',
    code: 'AUCNS',
    name: 'Cairns',
  },
  {
    country: 'Australia',
    code: 'AUDAM',
    name: 'Dampier',
  },
  {
    country: 'Australia',
    code: 'AUDPO',
    name: 'Devonport',
  },
  {
    country: 'Australia',
    code: 'AUFRE',
    name: 'Fremantle',
  },
  {
    country: 'Australia',
    code: 'AUGEX',
    name: 'Geelong',
  },
  {
    country: 'Australia',
    code: 'AUNTL',
    name: 'Newcastle',
  },
  {
    country: 'Australia',
    code: 'AUPBT',
    name: 'Port Botany',
  },
  {
    country: 'Australia',
    code: 'AUPKL',
    name: 'Port Kembla',
  },
  {
    country: 'Australia',
    code: 'AUTSV',
    name: 'Townsville',
  },
  {
    country: 'Australia',
    code: 'AUWEI',
    name: 'Weipa',
  },
  {
    country: 'Aruba',
    code: 'AWORJ',
    name: 'Oranjestad',
  },
  {
    country: 'Barbados',
    code: 'BBBGI',
    name: 'Bridgetown',
  },
  {
    country: 'Bangladesh',
    code: 'BDMGL',
    name: 'Mongla',
  },
  {
    country: 'Bulgaria',
    code: 'BGBOJ',
    name: 'Burgas',
  },
  {
    country: 'Bulgaria',
    code: 'BGVAR',
    name: 'Varna',
  },
  {
    country: 'Benin',
    code: 'BJCOO',
    name: 'Cotonou',
  },
  {
    country: 'Bermuda',
    code: 'BMBDA',
    name: 'Hamilton',
  },
  {
    country: 'Brazil',
    code: 'BRFOR',
    name: 'Fortaleza',
  },
  {
    country: 'Brazil',
    code: 'BRIOS',
    name: 'Ilheus',
  },
  {
    country: 'Brazil',
    code: 'BRIBB',
    name: 'Imbituba',
  },
  {
    country: 'Brazil',
    code: 'BRITJ',
    name: 'Itajai',
  },
  {
    country: 'Brazil',
    code: 'BRMAO',
    name: 'Manaus',
  },
  {
    country: 'Brazil',
    code: 'BRNAT',
    name: 'Natal',
  },
  {
    country: 'Brazil',
    code: 'BRRIG',
    name: 'Rio Grande',
  },
  {
    country: 'Brazil',
    code: 'BRSSA',
    name: 'Salvador',
  },
  {
    country: 'Brazil',
    code: 'BRSUA',
    name: 'Suape',
  },
  {
    country: 'Belize',
    code: 'BZBZE',
    name: 'Belize City',
  },
  {
    country: 'Canada',
    code: 'CAHAL',
    name: 'Halifax',
  },
  {
    country: 'Canada',
    code: 'CAMTR',
    name: 'Montreal',
  },
  {
    country: 'Canada',
    code: 'CANWE',
    name: 'New Westminster',
  },
  {
    country: 'Canada',
    code: 'CAPRR',
    name: 'Prince Rupert',
  },
  {
    country: 'Canada',
    code: 'CASQA',
    name: 'Squamish',
  },
  {
    country: 'Canada',
    code: 'CATOR',
    name: 'Toronto',
  },
  {
    country: 'Canada',
    code: 'CAVAN',
    name: 'Vancouver',
  },
  {
    country: 'Cook Islands',
    code: 'CKAIT',
    name: 'Aitutaki',
  },
  {
    country: 'Cook Islands',
    code: 'CKRAR',
    name: 'Rarotonga',
  },
  {
    country: 'Cameroon',
    code: 'CMDLA',
    name: 'Douala',
  },
  {
    country: 'Cameroon',
    code: 'CMKBI',
    name: 'Kribi',
  },
  {
    country: 'Colombia',
    code: 'COBAQ',
    name: 'Barranquilla',
  },
  {
    country: 'Colombia',
    code: 'COBUN',
    name: 'Buenaventura',
  },
  {
    country: 'Colombia',
    code: 'COCTG',
    name: 'Cartagena',
  },
  {
    country: 'Colombia',
    code: 'COSMR',
    name: 'Santa Marta',
  },
  {
    country: 'Cuba',
    code: 'CUMOA',
    name: 'Moa',
  },
  {
    country: 'Cuba',
    code: 'CUSCU',
    name: 'Santiago de Cuba',
  },
  {
    country: 'Cabo Verde',
    code: 'CVPAL',
    name: 'Palmeira',
  },
  {
    country: 'Cabo Verde',
    code: 'CVRAI',
    name: 'Praia',
  },
  {
    country: 'Cabo Verde',
    code: 'CVSAR',
    name: 'Sal Rei',
  },
  {
    country: 'Germany',
    code: 'DEBRE',
    name: 'Bremen',
  },
  {
    country: 'Germany',
    code: 'DEBRV',
    name: 'Bremerhaven',
  },
  {
    country: 'Germany',
    code: 'DEHAM',
    name: 'Hamburg',
  },
  {
    country: 'Djibouti',
    code: 'DJJIB',
    name: 'Djibouti',
  },
  {
    country: 'Denmark',
    code: 'DKAAL',
    name: 'Aalborg',
  },
  {
    country: 'Denmark',
    code: 'DKCPH',
    name: 'Copenhagen',
  },
  {
    country: 'Denmark',
    code: 'DKEBJ',
    name: 'Esbjerg',
  },
  {
    country: 'Denmark',
    code: 'DKFRC',
    name: 'Fredericia',
  },
  {
    country: 'Denmark',
    code: 'DKGRE',
    name: 'Grenaa',
  },
  {
    country: 'Denmark',
    code: 'DKHUN',
    name: 'Hundested',
  },
  {
    country: 'Denmark',
    code: 'DKSKA',
    name: 'Skagen',
  },
  {
    country: 'Dominica',
    code: 'DMRSU',
    name: 'Roseau',
  },
  {
    country: 'Dominican Republic',
    code: 'DOBCC',
    name: 'Boca Chica',
  },
  {
    country: 'Dominican Republic',
    code: 'DOPOP',
    name: 'Puerto Plata',
  },
  {
    country: 'Algeria',
    code: 'DZMOS',
    name: 'Mostaganem',
  },
  {
    country: 'Algeria',
    code: 'DZORN',
    name: 'Oran',
  },
  {
    country: 'Egypt',
    code: 'EGEDK',
    name: 'El Dekheila',
  },
  {
    country: 'Egypt',
    code: 'EGPSD',
    name: 'Port Said',
  },
  {
    country: 'Spain',
    code: 'ESALG',
    name: 'Algeciras',
  },
  {
    country: 'Spain',
    code: 'ESALC',
    name: 'Alicante',
  },
  {
    country: 'Spain',
    code: 'ESBCN',
    name: 'Barcelona',
  },
  {
    country: 'Spain',
    code: 'ESCAD',
    name: 'Cadiz',
  },
  {
    country: 'Spain',
    code: 'ESCEU',
    name: 'Ceuta',
  },
  {
    country: 'Spain',
    code: 'ESHUV',
    name: 'Huelva',
  },
  {
    country: 'Spain',
    code: 'ESFUE',
    name: 'Puerto del Rosario-Fuerteventura',
  },
  {
    country: 'Spain',
    code: 'ESSCT',
    name: 'Santa Cruz de Tenerife',
  },
  {
    country: 'Spain',
    code: 'ESSDR',
    name: 'Santander',
  },
  {
    country: 'Spain',
    code: 'ESSVQ',
    name: 'Sevilla',
  },
  {
    country: 'Spain',
    code: 'ESTAR',
    name: 'Tarragona',
  },
  {
    country: 'Spain',
    code: 'ESVLC',
    name: 'Valencia',
  },
  {
    country: 'Spain',
    code: 'ESVGO',
    name: 'Vigo',
  },
  {
    country: 'Finland',
    code: 'FIHEL',
    name: 'Helsinki (Helsingfors)',
  },
  {
    country: 'Finland',
    code: 'FIVKO',
    name: 'Valkom (Valko)',
  },
  {
    country: 'Gabon',
    code: 'GALBV',
    name: 'Libreville',
  },
  {
    country: 'Gabon',
    code: 'GAPOG',
    name: 'Port Gentil',
  },
  {
    country: 'United Kingdom',
    code: 'GBBLY',
    name: 'Blyth',
  },
  {
    country: 'United Kingdom',
    code: 'GBBRS',
    name: 'Bristol',
  },
  {
    country: 'United Kingdom',
    code: 'GBDVR',
    name: 'Dover',
  },
  {
    country: 'United Kingdom',
    code: 'GBFXT',
    name: 'Felixstowe',
  },
  {
    country: 'United Kingdom',
    code: 'GBFOY',
    name: 'Fowey',
  },
  {
    country: 'United Kingdom',
    code: 'GBHRW',
    name: 'Harwich',
  },
  {
    country: 'United Kingdom',
    code: 'GBPOO',
    name: 'Poole',
  },
  {
    country: 'United Kingdom',
    code: 'GBPME',
    name: 'Portsmouth',
  },
  {
    country: 'United Kingdom',
    code: 'GBSCR',
    name: 'Scrabster',
  },
  {
    country: 'United Kingdom',
    code: 'GBSHS',
    name: 'Sheerness',
  },
  {
    country: 'United Kingdom',
    code: 'GBSOU',
    name: 'Southampton',
  },
  {
    country: 'United Kingdom',
    code: 'GBTIL',
    name: 'Tilbury',
  },
  {
    country: 'Grenada',
    code: 'GDSTG',
    name: "Saint George's",
  },
  {
    country: 'Georgia',
    code: 'GEBUS',
    name: 'Batumi',
  },
  {
    country: 'Georgia',
    code: 'GEPTI',
    name: 'Poti',
  },
  {
    country: 'Ghana',
    code: 'GHTKD',
    name: 'Takoradi',
  },
  {
    country: 'Ghana',
    code: 'GHTEM',
    name: 'Tema',
  },
  {
    country: 'Gibraltar',
    code: 'GIGIB',
    name: 'Gibraltar',
  },
  {
    country: 'Greenland',
    code: 'GLJHS',
    name: 'Sisimiut (Holsteinsborg)',
  },
  {
    country: 'Gambia',
    code: 'GMBJL',
    name: 'Banjul',
  },
  {
    country: 'Guinea',
    code: 'GNCKY',
    name: 'Conakry',
  },
  {
    country: 'Equatorial Guinea',
    code: 'GQBSG',
    name: 'Bata',
  },
  {
    country: 'Equatorial Guinea',
    code: 'GQSSG',
    name: 'Malabo',
  },
  {
    country: 'Guatemala',
    code: 'GTPBR',
    name: 'Puerto Barrios',
  },
  {
    country: 'Guyana',
    code: 'GYGEO',
    name: 'Georgetown',
  },
  {
    country: 'Hong Kong',
    code: 'HKHKG',
    name: 'Hong Kong',
  },
  {
    country: 'Honduras',
    code: 'HNPCA',
    name: 'Puerto Castilla',
  },
  {
    country: 'Honduras',
    code: 'HNSLO',
    name: 'San Lorenzo',
  },
  {
    country: 'Haiti',
    code: 'HTPAP',
    name: 'Port-au-Prince',
  },
  {
    country: 'Indonesia',
    code: 'IDBDJ',
    name: 'Banjarmasin',
  },
  {
    country: 'Indonesia',
    code: 'IDBLW',
    name: 'Belawan, Sumatra',
  },
  {
    country: 'Indonesia',
    code: 'IDBIT',
    name: 'Bitung, Sulawesi',
  },
  {
    country: 'Indonesia',
    code: 'IDGTO',
    name: 'Gorontalo, Sulawesi',
  },
  {
    country: 'Indonesia',
    code: 'IDKDI',
    name: 'Kendari, Sulawesi',
  },
  {
    country: 'Indonesia',
    code: 'IDLUW',
    name: 'Luwuk',
  },
  {
    country: 'Indonesia',
    code: 'IDMES',
    name: 'Medan, Sumatra',
  },
  {
    country: 'Indonesia',
    code: 'IDPLM',
    name: 'Palembang, Sumatra',
  },
  {
    country: 'Indonesia',
    code: 'IDPNJ',
    name: 'Panjang',
  },
  {
    country: 'Indonesia',
    code: 'IDPNK',
    name: 'Pontianak, Kalimantan',
  },
  {
    country: 'Indonesia',
    code: 'IDSRI',
    name: 'Samarinda, Kalimantan',
  },
  {
    country: 'Indonesia',
    code: 'IDSOQ',
    name: 'Sorong',
  },
  {
    country: 'Indonesia',
    code: 'IDTRK',
    name: 'Tarakan, Kalimantan',
  },
  {
    country: 'Indonesia',
    code: 'IDTLI',
    name: 'Tolitoli',
  },
  {
    country: 'Ireland',
    code: 'IEORK',
    name: 'Cork',
  },
  {
    country: 'Ireland',
    code: 'IEDUB',
    name: 'Dublin',
  },
  {
    country: 'Israel',
    code: 'ILASH',
    name: 'Ashdod',
  },
  {
    country: 'India',
    code: 'INENR',
    name: 'Ennore',
  },
  {
    country: 'India',
    code: 'INHAL',
    name: 'Haldia',
  },
  {
    country: 'India',
    code: 'INKRI',
    name: 'Krishnapatnam',
  },
  {
    country: 'India',
    code: 'INIXE',
    name: 'Mangalore',
  },
  {
    country: 'India',
    code: 'INMUN',
    name: 'Mundra',
  },
  {
    country: 'India',
    code: 'INIXZ',
    name: 'Port Blair',
  },
  {
    country: 'Iraq',
    code: 'IQALF',
    name: 'Abu Al Fulus',
  },
  {
    country: 'Iraq',
    code: 'IQBSR',
    name: 'Basra',
  },
  {
    country: 'Italy',
    code: 'ITMDC',
    name: 'Marina di Carrara',
  },
  {
    country: 'Italy',
    code: 'ITMNF',
    name: 'Monfalcone',
  },
  {
    country: 'Italy',
    code: 'ITPOZ',
    name: 'Pozzuoli',
  },
  {
    country: 'Italy',
    code: 'ITRAN',
    name: 'Ravenna',
  },
  {
    country: 'Italy',
    code: 'ITSAL',
    name: 'Salerno',
  },
  {
    country: 'Italy',
    code: 'ITTRS',
    name: 'Trieste',
  },
  {
    country: 'Jamaica',
    code: 'JMKIN',
    name: 'Kingston',
  },
  {
    country: 'Jamaica',
    code: 'JMMBJ',
    name: 'Montego Bay',
  },
  {
    country: 'Japan',
    code: 'JPABU',
    name: 'Aburatsu',
  },
  {
    country: 'Japan',
    code: 'JPAXT',
    name: 'Akita',
  },
  {
    country: 'Japan',
    code: 'JPCHB',
    name: 'Chiba',
  },
  {
    country: 'Japan',
    code: 'JPFKY',
    name: 'Fukuyama, Hiroshima',
  },
  {
    country: 'Japan',
    code: 'JPHHE',
    name: 'Hachinohe',
  },
  {
    country: 'Japan',
    code: 'JPHIJ',
    name: 'Hiroshima',
  },
  {
    country: 'Japan',
    code: 'JPHTC',
    name: 'Hitachi',
  },
  {
    country: 'Japan',
    code: 'JPHSM',
    name: 'Hososhima',
  },
  {
    country: 'Japan',
    code: 'JPIMB',
    name: 'Imabari',
  },
  {
    country: 'Japan',
    code: 'JPIMI',
    name: 'Imari',
  },
  {
    country: 'Japan',
    code: 'JPIWK',
    name: 'Iwakuni',
  },
  {
    country: 'Japan',
    code: 'JPKNZ',
    name: 'Kanazawa',
  },
  {
    country: 'Japan',
    code: 'JPKSM',
    name: 'Kashima, Ibaraki',
  },
  {
    country: 'Japan',
    code: 'JPKWS',
    name: 'Kawasaki',
  },
  {
    country: 'Japan',
    code: 'JPKCZ',
    name: 'Kochi',
  },
  {
    country: 'Japan',
    code: 'JPKMJ',
    name: 'Kumamoto',
  },
  {
    country: 'Japan',
    code: 'JPMAI',
    name: 'Maizuru',
  },
  {
    country: 'Japan',
    code: 'JPMYJ',
    name: 'Matsuyama',
  },
  {
    country: 'Japan',
    code: 'JPMII',
    name: 'Miike, Fukuoka',
  },
  {
    country: 'Japan',
    code: 'JPMIZ',
    name: 'Mizushima',
  },
  {
    country: 'Japan',
    code: 'JPMUR',
    name: 'Muroran',
  },
  {
    country: 'Japan',
    code: 'JPNGS',
    name: 'Nagasaki',
  },
  {
    country: 'Japan',
    code: 'JPNGO',
    name: 'Nagoya, Aichi',
  },
  {
    country: 'Japan',
    code: 'JPNAH',
    name: 'Naha, Okinawa',
  },
  {
    country: 'Japan',
    code: 'JPNAO',
    name: 'Naoetsu',
  },
  {
    country: 'Japan',
    code: 'JPKIJ',
    name: 'Niigata',
  },
  {
    country: 'Japan',
    code: 'JPOIT',
    name: 'Oita',
  },
  {
    country: 'Japan',
    code: 'JPOMZ',
    name: 'Omaezaki',
  },
  {
    country: 'Japan',
    code: 'JPONA',
    name: 'Onahama',
  },
  {
    country: 'Japan',
    code: 'JPOTK',
    name: 'Otake',
  },
  {
    country: 'Japan',
    code: 'JPOTR',
    name: 'Otaru',
  },
  {
    country: 'Japan',
    code: 'JPSMN',
    name: 'Sakaiminato',
  },
  {
    country: 'Japan',
    code: 'JPSKT',
    name: 'Sakata',
  },
  {
    country: 'Japan',
    code: 'JPSBS',
    name: 'Shibushi',
  },
  {
    country: 'Japan',
    code: 'JPSMZ',
    name: 'Shimizu',
  },
  {
    country: 'Japan',
    code: 'JPTAK',
    name: 'Takamatsu',
  },
  {
    country: 'Japan',
    code: 'JPTKS',
    name: 'Tokushima',
  },
  {
    country: 'Japan',
    code: 'JPTKY',
    name: 'Tokuyama',
  },
  {
    country: 'Japan',
    code: 'JPTMK',
    name: 'Tomakomai',
  },
  {
    country: 'Japan',
    code: 'JPTHS',
    name: 'Toyohashi',
  },
  {
    country: 'Japan',
    code: 'JPTRG',
    name: 'Tsuruga',
  },
  {
    country: 'Japan',
    code: 'JPUBJ',
    name: 'Ube',
  },
  {
    country: 'Japan',
    code: 'JPWAK',
    name: 'Wakayama',
  },
  {
    country: 'Japan',
    code: 'JPYAT',
    name: 'Yatsushiro',
  },
  {
    country: 'Japan',
    code: 'JPYKK',
    name: 'Yokkaichi',
  },
  {
    country: 'Kenya',
    code: 'KEMBA',
    name: 'Mombasa',
  },
  {
    country: 'Kiribati',
    code: 'KITRW',
    name: 'Tarawa',
  },
  {
    country: 'Comoros',
    code: 'KMYVA',
    name: 'Moroni',
  },
  {
    country: 'Lebanon',
    code: 'LBKYE',
    name: 'Tripoli',
  },
  {
    country: 'Lithuania',
    code: 'LTKLJ',
    name: 'Klaipeda',
  },
  {
    country: 'Latvia',
    code: 'LVRIX',
    name: 'Riga',
  },
  {
    country: 'Libya',
    code: 'LYBEN',
    name: 'Bingazi (Benghazi)',
  },
  {
    country: 'Libya',
    code: 'LYMRA',
    name: 'Misurata',
  },
  {
    country: 'Libya',
    code: 'LYTIP',
    name: 'Tripoli',
  },
  {
    country: 'Morocco',
    code: 'MAAGA',
    name: 'Agadir',
  },
  {
    country: 'Morocco',
    code: 'MANDR',
    name: 'Nador',
  },
  {
    country: 'Morocco',
    code: 'MAPTM',
    name: 'Tanger Med',
  },
  {
    country: 'Madagascar',
    code: 'MGDIE',
    name: 'Antsiranana',
  },
  {
    country: 'Madagascar',
    code: 'MGMJN',
    name: 'Majunga (Mahajanga)',
  },
  {
    country: 'Madagascar',
    code: 'MGTMM',
    name: 'Tamatave (Toamasina)',
  },
  {
    country: 'Madagascar',
    code: 'MGTLE',
    name: 'Tulear (Toliara)',
  },
  {
    country: 'Marshall Islands',
    code: 'MHKWA',
    name: 'Kwajalein',
  },
  {
    country: 'Myanmar',
    code: 'MMRGN',
    name: 'Yangon',
  },
  {
    country: 'Martinique',
    code: 'MQFDF',
    name: 'Fort-de-France',
  },
  {
    country: 'Mauritania',
    code: 'MRNDB',
    name: 'Nouadhibou',
  },
  {
    country: 'Mauritania',
    code: 'MRNKC',
    name: 'Nouakchott',
  },
  {
    country: 'Montserrat',
    code: 'MSPLY',
    name: 'Plymouth',
  },
  {
    country: 'Mauritius',
    code: 'MUPLU',
    name: 'Port Louis',
  },
  {
    country: 'Malaysia',
    code: 'MYBTU',
    name: 'Bintulu, Sarawak',
  },
  {
    country: 'Malaysia',
    code: 'MYBKI',
    name: 'Kota Kinabalu, Sabah',
  },
  {
    country: 'Malaysia',
    code: 'MYKCH',
    name: 'Kuching, Sarawak',
  },
  {
    country: 'Malaysia',
    code: 'MYLBU',
    name: 'Labuan, Sabah',
  },
  {
    country: 'Malaysia',
    code: 'MYMKZ',
    name: 'Malacca',
  },
  {
    country: 'Malaysia',
    code: 'MYPGU',
    name: 'Pasir Gudang, Johor',
  },
  {
    country: 'Malaysia',
    code: 'MYSDK',
    name: 'Sandakan, Sabah',
  },
  {
    country: 'Malaysia',
    code: 'MYSBW',
    name: 'Sibu, Sarawak',
  },
  {
    country: 'Malaysia',
    code: 'MYTWU',
    name: 'Tawau, Sabah',
  },
  {
    country: 'Mozambique',
    code: 'MZBEW',
    name: 'Beira',
  },
  {
    country: 'Mozambique',
    code: 'MZMPM',
    name: 'Maputo',
  },
  {
    country: 'Mozambique',
    code: 'MZMNC',
    name: 'Nacala',
  },
  {
    country: 'Mozambique',
    code: 'MZUEL',
    name: 'Quelimane',
  },
  {
    country: 'Namibia',
    code: 'NAWVB',
    name: 'Walvis Bay',
  },
  {
    country: 'Nigeria',
    code: 'NGAPP',
    name: 'Apapa',
  },
  {
    country: 'Nigeria',
    code: 'NGCBQ',
    name: 'Calabar',
  },
  {
    country: 'Nigeria',
    code: 'NGLOS',
    name: 'Lagos',
  },
  {
    country: 'Nigeria',
    code: 'NGPHC',
    name: 'Port Harcourt',
  },
  {
    country: 'Nicaragua',
    code: 'NICIO',
    name: 'Corinto',
  },
  {
    country: 'Norway',
    code: 'NOGJM',
    name: 'Gjemnes',
  },
  {
    country: 'Oman',
    code: 'OMSLL',
    name: 'Salalah',
  },
  {
    country: 'Oman',
    code: 'OMSOH',
    name: 'Sohar',
  },
  {
    country: 'Panama',
    code: 'PAPAM',
    name: 'Almirante',
  },
  {
    country: 'Panama',
    code: 'PABLB',
    name: 'Balboa',
  },
  {
    country: 'Panama',
    code: 'PACSO',
    name: 'Coco Solo',
  },
  {
    country: 'Panama',
    code: 'PAMIT',
    name: 'Manzanillo',
  },
  {
    country: 'Peru',
    code: 'PECLL',
    name: 'Callao',
  },
  {
    country: 'Peru',
    code: 'PEILQ',
    name: 'Ilo',
  },
  {
    country: 'Peru',
    code: 'PEIQT',
    name: 'Iquitos',
  },
  {
    country: 'Peru',
    code: 'PEMRI',
    name: 'Matarani',
  },
  {
    country: 'Peru',
    code: 'PEPAI',
    name: 'Paita',
  },
  {
    country: 'Peru',
    code: 'PEPIO',
    name: 'Pisco',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGGUR',
    name: 'Alotau',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGBUA',
    name: 'Buka',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGKIM',
    name: 'Kimbe',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGLAE',
    name: 'Lae',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGPOM',
    name: 'Port Moresby',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGRAB',
    name: 'Rabaul',
  },
  {
    country: 'Papua New Guinea',
    code: 'PGWWK',
    name: 'Wewak',
  },
  {
    country: 'Philippines',
    code: 'PHBCD',
    name: 'Bacolod, Negros',
  },
  {
    country: 'Philippines',
    code: 'PHCGY',
    name: 'Cagayan de Oro, Mindanao',
  },
  {
    country: 'Philippines',
    code: 'PHCEB',
    name: 'Cebu',
  },
  {
    country: 'Philippines',
    code: 'PHCBO',
    name: 'Cotabato, Mindanao',
  },
  {
    country: 'Philippines',
    code: 'PHDGT',
    name: 'Dumaguete',
  },
  {
    country: 'Philippines',
    code: 'PHMNL',
    name: 'Manila',
  },
  {
    country: 'Philippines',
    code: 'PHOZC',
    name: 'Ozamis, Mindanao',
  },
  {
    country: 'Philippines',
    code: 'PHPLC',
    name: 'Polloc',
  },
  {
    country: 'Philippines',
    code: 'PHPPS',
    name: 'Puerto Princesa, Palawan',
  },
  {
    country: 'Philippines',
    code: 'PHSFS',
    name: 'Subic Bay',
  },
  {
    country: 'Philippines',
    code: 'PHTAG',
    name: 'Tagbilaran, Bohol',
  },
  {
    country: 'Philippines',
    code: 'PHZAM',
    name: 'Zamboanga',
  },
  {
    country: 'Pakistan',
    code: 'PKGWD',
    name: 'Gwadar',
  },
  {
    country: 'Pakistan',
    code: 'PKKHI',
    name: 'Karachi',
  },
  {
    country: 'Poland',
    code: 'PLGDN',
    name: 'Gdansk',
  },
  {
    country: 'Poland',
    code: 'PLSZZ',
    name: 'Szczecin',
  },
  {
    country: 'Palau',
    code: 'PWROR',
    name: 'Koror',
  },
  {
    country: 'Russian Federation',
    code: 'RUARH',
    name: 'Arkhangelsk',
  },
  {
    country: 'Russian Federation',
    code: 'RUDUD',
    name: 'Dudinka',
  },
  {
    country: 'Russian Federation',
    code: 'RUKGD',
    name: 'Kaliningrad',
  },
  {
    country: 'Russian Federation',
    code: 'RUKOR',
    name: 'Korsakov',
  },
  {
    country: 'Russian Federation',
    code: 'RUGDX',
    name: 'Magadan',
  },
  {
    country: 'Russian Federation',
    code: 'RUPKC',
    name: 'Petropavlovsk-Kamchatskiy',
  },
  {
    country: 'Russian Federation',
    code: 'RUVVO',
    name: 'Vladivostok',
  },
  {
    country: 'Saudi Arabia',
    code: 'SAJUB',
    name: 'Jubail',
  },
  {
    country: 'Seychelles',
    code: 'SCPOV',
    name: 'Port Victoria',
  },
  {
    country: 'Sudan',
    code: 'SDPZU',
    name: 'Port Sudan',
  },
  {
    country: 'Sweden',
    code: 'SEHEL',
    name: 'Helsingborg',
  },
  {
    country: 'Sweden',
    code: 'SEVAG',
    name: 'Varberg',
  },
  {
    country: 'Singapore',
    code: 'SGSIN',
    name: 'Singapore',
  },
  {
    country: 'Slovenia',
    code: 'SIKOP',
    name: 'Koper',
  },
  {
    country: 'Sierra Leone',
    code: 'SLFNA',
    name: 'Freetown',
  },
  {
    country: 'Senegal',
    code: 'SNDKR',
    name: 'Dakar',
  },
  {
    country: 'Somalia',
    code: 'SOBBO',
    name: 'Berbera',
  },
  {
    country: 'Somalia',
    code: 'SOKMU',
    name: 'Kismayu',
  },
  {
    country: 'Somalia',
    code: 'SOMGQ',
    name: 'Mogadishu',
  },
  {
    country: 'Suriname',
    code: 'SRPBM',
    name: 'Paramaribo',
  },
  {
    country: 'El Salvador',
    code: 'SVAQJ',
    name: 'Acajutla',
  },
  {
    country: 'El Salvador',
    code: 'SVSAL',
    name: 'San Salvador',
  },
  {
    country: 'Togo',
    code: 'TGLFW',
    name: 'Lome',
  },
  {
    country: 'Thailand',
    code: 'THSRI',
    name: 'Sriracha',
  },
  {
    country: 'Tunisia',
    code: 'TNBIZ',
    name: 'Bizerte',
  },
  {
    country: 'Tunisia',
    code: 'TNSFA',
    name: 'Sfax',
  },
  {
    country: 'Tunisia',
    code: 'TNSUS',
    name: 'Sousse',
  },
  {
    country: 'Tunisia',
    code: 'TNTUN',
    name: 'Tunis',
  },
  {
    country: 'Turkey',
    code: 'TRALI',
    name: 'Aliaga',
  },
  {
    country: 'Turkey',
    code: 'TRDRC',
    name: 'Derince',
  },
  {
    country: 'Turkey',
    code: 'TRHAY',
    name: 'Haydarpasa',
  },
  {
    country: 'Turkey',
    code: 'TRYAR',
    name: 'Yarimca',
  },
  {
    country: 'United States',
    code: 'USANC',
    name: 'Anchorage',
  },
  {
    country: 'United States',
    code: 'USBPT',
    name: 'Beaumont',
  },
  {
    country: 'United States',
    code: 'USBOS',
    name: 'Boston',
  },
  {
    country: 'United States',
    code: 'USBCK',
    name: 'Brunswick',
  },
  {
    country: 'United States',
    code: 'USCAT',
    name: 'Camden',
  },
  {
    country: 'United States',
    code: 'USCHS',
    name: 'Charleston',
  },
  {
    country: 'United States',
    code: 'USCAV',
    name: 'Cleveland',
  },
  {
    country: 'United States',
    code: 'USCRP',
    name: 'Corpus Christi',
  },
  {
    country: 'United States',
    code: 'USDUT',
    name: 'Dutch Harbor',
  },
  {
    country: 'United States',
    code: 'USPAE',
    name: 'Everett',
  },
  {
    country: 'United States',
    code: 'USFEP',
    name: 'Freeport',
  },
  {
    country: 'United States',
    code: 'USGLS',
    name: 'Galveston',
  },
  {
    country: 'United States',
    code: 'USGLC',
    name: 'Gloucester City',
  },
  {
    country: 'United States',
    code: 'USIJX',
    name: 'Jacksonville',
  },
  {
    country: 'United States',
    code: 'USADQ',
    name: 'Kodiak',
  },
  {
    country: 'United States',
    code: 'USLAX',
    name: 'Los Angeles',
  },
  {
    country: 'United States',
    code: 'USMIA',
    name: 'Miami',
  },
  {
    country: 'United States',
    code: 'USMOB',
    name: 'Mobile',
  },
  {
    country: 'United States',
    code: 'USMRH',
    name: 'Morehead City',
  },
  {
    country: 'United States',
    code: 'USMSY',
    name: 'New Orleans',
  },
  {
    country: 'United States',
    code: 'USPFN',
    name: 'Panama City',
  },
  {
    country: 'United States',
    code: 'USPWM',
    name: 'Portland',
  },
  {
    country: 'United States',
    code: 'USPDX',
    name: 'Portland',
  },
  {
    country: 'United States',
    code: 'USSAV',
    name: 'Savannah',
  },
  {
    country: 'United States',
    code: 'USSEA',
    name: 'Seattle',
  },
  {
    country: 'United States',
    code: 'USTIW',
    name: 'Tacoma',
  },
  {
    country: 'United States',
    code: 'USTPA',
    name: 'Tampa',
  },
  {
    country: 'United States',
    code: 'USVAN',
    name: 'Vancouver',
  },
  {
    country: 'United States',
    code: 'USPBI',
    name: 'West Palm Beach',
  },
  {
    country: 'United States',
    code: 'USILG',
    name: 'Wilmington',
  },
  {
    country: 'Uruguay',
    code: 'UYMVD',
    name: 'Montevideo',
  },
  {
    country: 'Virgin Islands, U.S.',
    code: 'VICHA',
    name: 'Charlotte Amalie, Saint Thomas',
  },
  {
    country: 'Viet Nam',
    code: 'VNHPH',
    name: 'Haiphong',
  },
  {
    country: 'Vanuatu',
    code: 'VUVLI',
    name: 'Port Vila',
  },
  {
    country: 'Vanuatu',
    code: 'VUSAN',
    name: 'Santo',
  },
  {
    country: 'Samoa',
    code: 'WSAPW',
    name: 'Apia',
  },
  {
    country: 'Yemen',
    code: 'YEADE',
    name: 'Aden',
  },
  {
    country: 'Yemen',
    code: 'YEMKX',
    name: 'Mukalla',
  },
  {
    country: 'South Africa',
    code: 'ZAELS',
    name: 'East London',
  },
  {
    country: 'South Africa',
    code: 'ZAPLZ',
    name: 'Port Elizabeth',
  },
  {
    country: 'South Africa',
    code: 'ZARCB',
    name: 'Richards Bay',
  },
];
