export const numberWithCommas = (x) => {
  // function to format amount with commas
  // if x is not a number, return x
  if (isNaN(x)) return x;
  return x.toLocaleString();
};
export const daysCalculator = (date) => {
  if (date) {
    const expiry_date = new Date(date);
    const current_date = new Date();
    let difference = expiry_date?.getTime() - current_date?.getTime();
    let no_of_days = Math.ceil(difference / (1000 * 3600 * 24));
    return no_of_days;
  }
};
