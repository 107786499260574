import { getSessionId } from './analytics.api';
import { parseJson } from '../utils/commonFunctions';

const SESSION_KEY = 'user-session';
const SESSION_EXPIRE_TIME = 10 * 60 * 1000; // 10 mins

const isSessionValid = () => {
  try {
    const userSession = localStorage.getItem(SESSION_KEY);
    if (!userSession) return false;

    const session = JSON.parse(userSession);
    if (!session?.expires) return false;
    if (Date.now() > session.expires) return false;

    return true;
  } catch (e) {
    return false;
  }
};

export const getSession = async () => {
  if (!isSessionValid()) {
    const userId = parseJson(localStorage.getItem('userId'));
    if (!userId) return;

    const org = parseJson(localStorage.getItem('org'));
    if (!org || !org?.id) return;

    try {
      const res = await getSessionId(userId, org?.id);
      const sessionId = res.data.sessionId;
      localStorage.setItem(
        SESSION_KEY,
        JSON.stringify({
          sessionId: sessionId,
          expires: Date.now() + SESSION_EXPIRE_TIME,
        })
      );
    } catch (e) {
      console.log('Error calling the getSessionId ', e);
      return;
    }
  }
  return parseJson(localStorage.getItem(SESSION_KEY));
};
