import React, { useState } from 'react';
import './index.less';
import { Row, Typography, message } from 'antd';
import Importbulkexcel from 'assets/images/ImportExcel.svg';
import SWDrawer from 'react-common-components/SWDrawer';
import { useGlobal } from 'store/index';
import { uploadImportBulk } from 'api/customApis';
import ImportCard from './ImportCard';
import Footer from './Footer';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import ExportExcelCard from '../BulkExcelExport/ExportExcelCard';
import BulkUpdateImage from './BulkUpdateImage';
const { Text } = Typography;
function ImportExportDrawer({
  isExport,
  setIsExport,
  setIsCreateTemplate,
  setIsChangeTemplate,
  templateList,
  setTemplateList,
  setIsBulkExcelDownload,
  defaultBulkExcelTemplate,
  setIsDiscard,
  setIsTemplateInValid,
  setTaskDetail,
  excelLimitThreshold,
}) {
  const [globalState, globalActions] = useGlobal();
  const [loading, setLoading] = useState(false);
  const { isImportExportDrawerActive, importSocketData } = globalState;
  const { trackEvent } = useTracking();
  const location = useLocation();

  const uploadExcel = async (options, taskid) => {
    trackEvent({
      name: 'bulk_import_upload',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel import',
      },
    });
    setLoading(true);
    const { onSuccess, file, onProgress } = options;
    const fmData = new FormData();
    fmData.append('excel_file', file);
    uploadImportBulk(fmData)
      .then((res) => {
        if (res?.status === 200) {
          setTaskDetail(res?.data?.data?.task_id);
          globalActions.bulkUpdateActions.setIsUploadOpen(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 409) {
          setIsTemplateInValid(true);
        } else {
          message.error({
            content: 'Failed to upload. Please try again',
            style: {
              right: '95px',
              position: 'absolute',
              textAlign: 'center',
            },
          });
        }
        setLoading(false);
        console.log(err);
      });
  };

  const viewDetails = (importTasks) => {
    trackEvent({
      name: 'bulk_import_viewDetails',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel import',
      },
    });
    globalActions.bulkUpdateActions.setViewSocketData(importTasks);
    globalActions.bulkUpdateActions.setIsUploadOpen(true);
  };

  const onBack = () => {
    trackEvent({
      name: 'bulk_import_export_close',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel import',
      },
    });
    globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
  };

  const hideDrawer = () => {
    globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
  };

  return (
    <>
      <SWDrawer
        success="Success"
        dismiss="Cancel"
        width={459}
        show={isImportExportDrawerActive}
        closable
        setShow={false}
        onClose={() => {
          globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
        }}
        BottomActionBar={<></>}
        hideButtons={true}>
        <div className="import-export-wrapper">
          <Row>
            <Text className="import-export-wrapper_head">
              Export/Import download excel
            </Text>
          </Row>
          <Row>
            <Text className="import-export-wrapper_sub">
              Export your product data and import to bulk update details
            </Text>
          </Row>
          <Row className="import-export-wrapper_cards-wrapper">
            {/* Import card */}
            <ImportCard
              {...{
                importSocketData,
                uploadExcel,
                loading,
                Importbulkexcel,
                viewDetails,
              }}
            />

            {/* Export card */}
            <ExportExcelCard
              isExport={isExport}
              setIsExport={setIsExport}
              setIsCreateTemplate={setIsCreateTemplate}
              setIsChangeTemplate={setIsChangeTemplate}
              templateList={templateList}
              setTemplateList={setTemplateList}
              setIsBulkExcelDownload={setIsBulkExcelDownload}
              defaultBulkExcelTemplate={defaultBulkExcelTemplate}
              setIsDiscard={setIsDiscard}
              excelLimitThreshold={excelLimitThreshold}
            />
            <BulkUpdateImage
              hideDrawer={hideDrawer}
              importSocketData={importSocketData}
            />
          </Row>
          <Row className="footer-gap" />
          <Footer onBack={onBack} btnLabel="Close" />
        </div>
      </SWDrawer>
    </>
  );
}

export default ImportExportDrawer;
