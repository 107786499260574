export const settings = {
  showFiltersAction: (store, payload) => {
    store.setState({ showFilters: payload });
  },
  setShortlistedProducts: (store, payload) => {
    store.setState({ shortlistedProducts: payload });
  },
  setCollection_link: (store, payload) => {
    store.setState({ collection_link: payload });
  },
};
