import {
  Typography,
  Row,
  Col,
  Button,
  Card,
  Divider,
  Badge,
  Skeleton,
} from 'antd';
import { useEffect, useState } from 'react';
import style from 'styled-components';
import PYTHON_KEY from 'api/pythonService';
import { UpgradePlanModal } from 'routes/SourceWiz/Guardrails/Components/PlanUpgrade/UpgradePlanModal';
import { numberWithCommas } from '../CommonFunction/commonFunctions';
const { Title, Text } = Typography;

const Subdiv = style.div`
box-sizing: border-box;
margin : 1.2rem 0 1rem 0;
opacity: 1;
mix-blend-mode: normal;
border-radius: 0.5rem;
`;
export const AvailablePlans = ({ forExpiredModal }) => {
  const [availablePlans, setAvailablePlans] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // need to refactor
    getCurrentPlanDetails();
  }, []);
  const getCurrentPlanDetails = () => {
    setLoading(true);
    const userData = localStorage.getItem('user');
    const user = userData && JSON.parse(userData);
    const access_token = user?.access_token;
    PYTHON_KEY.URL.get(PYTHON_KEY.path.availableplans, {
      headers: { 'access-token': `${access_token}` },
    })
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setAvailablePlans(res?.data?.plan);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleUpgradePlan = () => {
    setIsModalVisible(true);
  };
  return (
    <>
      {!forExpiredModal && availablePlans?.length > 0 ? (
        <Title level={5}>Available plans</Title>
      ) : (
        <></>
      )}
      <Subdiv style={{ width: `${forExpiredModal ? '100%' : '55.5%'}` }}>
        {loading ? (
          <Col>
            <Skeleton />
          </Col>
        ) : (
          <Row justify="space-between">
            {availablePlans?.map((e) => {
              return (
                <Badge
                  count={'Popular'}
                  style={{
                    visibility: `${
                      e?.name?.toLowerCase() === 'gold' ? 'visible' : 'hidden'
                    }`,
                  }}
                  offset={[-78, 2]}
                  color="linear-gradient(92.76deg, #694CBB -4.7%, #FFA42B 150.67%)">
                  <Card
                    align="center"
                    style={{
                      borderRadius: '0.3rem',
                      border: '1px solid #CDCFCE',
                    }}>
                    <Text>{e?.name?.toUpperCase()}</Text>
                    <Divider style={{ margin: '0.7rem 0 0.8rem 0' }} />
                    <Title level={5} style={{ marginBottom: '0' }}>
                      ₹ {numberWithCommas(e?.amount)}
                    </Title>
                    <Text style={{ fontSize: '0.75rem' }}>per year</Text>
                    <br />
                    <Button
                      style={{ marginTop: '1.2rem' }}
                      onClick={handleUpgradePlan}>
                      View details
                    </Button>
                  </Card>
                </Badge>
              );
            })}
          </Row>
        )}
      </Subdiv>
      <UpgradePlanModal
        forDemo={true}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};
