// When buyer downloads ppt
export const PPT_DOWNLOADED = 'download_ppt';
// When buyer downloads excel
export const EXCEL_DOWNLOADED = 'download_excel';
// This event is triggered when buyer clicks on “View Catalogue” button
export const LINK_OPEN = 'link_open';
// When buyer shortlists a product
export const SHORTLIST_PRODUCTS = 'shortlist_product';
// When buyer removes a shortlisted product
export const REMOVE_FROM_SHORTLIST = 'remove_from_shortlist';
// When buyer clicks on product modal
export const OPEN_PRODUCT_MODE = 'open_product_modal';
// When buyer downloads ppt for shortlisted products
export const DOWNLOAD_SHORTLISTED_PPT = 'download_shortlisted_ppt';
// When buyer sends a query
export const SEND_QUERY = 'send_query';
// view all buyers button
export const VIEW_ALL_BUYERS = 'view_all_buyers_btn';
// search keyword for buyers
export const SEARCH_KEYWORD_BUYERS = 'search_keyword_buyers';
// view buyer details
export const VIEW_BUYER_DETAILS = 'view_buyer_details';
// filter country select event for buyers
export const FILTER_SELECT_COUNTRY = 'filter_select_country';
// filter country deselect event for buyers
export const FILTER_DESELECT_COUNTRY = 'filter_deselect_country';
// filter hscode select event for buyers
export const FILTER_SELECT_HS_CODE = 'filter_select_hs_code';
// filter hscode deselect event for buyers
export const FILTER_DESELECT_HS_CODE = 'filter_deselect_hs_code';
// download qr code
export const DOWNLOAD_QR_CODE = 'download_qr_code';
// view all prospects
export const VIEW_ALL_PROSPECTS = 'view_all_prospects';
// smart search text
export const SMART_SEARCH = 'smart_search';
// quotations text
export const VIEW_ALL_ENQUIRIES = 'view_all_enquirirs';

export const PRODUCT_MODAL_OPEN = 'product_modal_open';

export const PROFILE_SETTINGS = 'view_profile';

export const CREATE_CATALOGUE = 'create_catalogue';

export const PROSPECTS = 'prospects';

export const MANAGE_PRODUCTS = 'manage_products';

export const BUYER_RECOMMENDATION = 'buyer_recommendation';

export const CATALOGUES = 'catalogues';

export const PRODUCTS = 'products';

export const LOGOUT = 'logout';

export const FILTER_APPLIED = 'filter_applied';

export const PRODUCTS_SELECTED = 'products_selected';

export const CHANGE_CATEGORY = 'change_category';

export const EDIT_ATTRIBUTES = 'edit_attributes';

export const DOWNLOAD_QR = 'download_qr_click';

export const MODIFY_ON_TABLE = 'modify_on_table';

export const DELETE = 'delete';

export const BULK_ACTIONS = 'bulk_actions';

export const SEARCH_ENTER = 'search_enter';

export const SEARCH_CONFIRM = 'search_confirm';

export const FILTERS = 'filters';

export const COPY_LINK = 'copy_link';

export const PREVIEW = 'preview';

export const EDIT = 'edit';

export const DOWNLOAD_PPT = 'download_ppt_click';

export const DOWNLOAD_EXCEL = 'download_excel_click';

export const DEACTIVATE_LINK = 'deactivate_link';

export const UPDATE_LINK_SETTINGS = 'update_link_settings';

export const CREATE_A_COPY = 'create_a_copy';

export const ACTIVATE_LINK = 'activate_link';

export const NEXT_BUTTON = 'next_button';

export const PRODUCT_SELECTION = 'product_selection';

export const SELECT_ALL = 'select_all';

export const SELECT_ALL_CLEAR = 'select_all_clear';

export const SORT_BY = 'sort_by';

export const FILTER = 'filter';

export const PAGES = 'pages';

export const ATTRIBUTE_SELECTION = 'attribute_selection';

export const DONE_BUTTON = 'done_button';

export const CELL_UPDATE = 'cell_update';

export const SEND_FOR_APPROVAL = 'send_for_approval';

export const GENERATE_CATALOGUE = 'generate_catalogue';

export const SHOW_PRODUCT_COST = 'show_product_cost';

export const ADD_PRODUCTS = 'add_products';

export const CUSTOMISE_TABLE = 'customise_table';

export const BUYER_FORM = 'buyer_form';

export const PPT_UPDATE = 'ppt_update';

export const LINK_UPDATE = 'link_update';

export const PREVIEW_CATALOGUE = 'preview_catalogue';

export const COPY_LINK_BUTTON = 'copy_link_button';

export const DOWNLOAD_EXCEL_BUTTON = 'download_excel_button';

export const DOWNLOAD_PPT_BUTTON = 'download_ppt_button';

export const EDIT_IN_STUDIO_BUTTON = 'edit_in_studio_button';

export const CREATE_CATALOGUE_CLICK = 'create_collection_click';

export const ALL_CATALOGUE_CLICK = 'all_catalogue_click';

export const BUYER_RECOMMENDATION_CLICK = 'buyer_recommendation_click';

export const DOWNLOAD_IMAGES = 'download_images';

export const ALL_PRODUCTS = 'all_products';

export const CATEGORIES = 'categories';

export const ADD_PRODUCT = 'add_product';

export const ADD_PRODUCT_SINGLE_UPLOAD = 'add_product_single_upload';

export const ADD_PRODUCT_BULK_UPLOAD = 'add_product_bulk_upload_click';

export const BULK_IMAGES_UPLOAD_SUCCESS = 'bulk_images_uploaded_successfull';

export const BULK_UPLOAD_GROUPING = 'bulk_upload_create_groups';

export const DOWLOAD_IMAGE_URL = 'download_image_url';

export const DOWLOAD_UPLOAD_EXCEL_TEMPLATE = 'download_excel_upload_template';

export const BULK_UPLOAD_GROUP_REORDER = 'bulk_upload_group_reorder';

export const BULK_UPLOAD_IMGE_REORDER_SAME_GROUP =
  'bulk_upload_reorder_same_group';

export const BULK_UPLOAD_MOVE_IMAGE = 'bulk_upload_group_move_image';

export const BULK_UPLOAD_GROUPING_CONFIRM = 'bulk_upload_grouping_confirm';

export const BULK_UPLOAD_EXCEL_UPLOAD = 'bulk_upload_excel_upload';

export const BULK_UPLOAD_DOWNLOAD_TEMPLATE_START =
  'bulk_upload_download_template_start';

export const BULK_UPLOAD_INVALID_TEMPLATE = 'bulk_upload_invalid_template';

export const BULK_UPLOAD_EXCEL_FAILED = 'bulk_upload_excel_failed';

export const BULK_UPLOAD_EXCEL_SUCCESS = 'bulk_upload_excel_success';

export const BULK_UPLOAD_IMPORT_MORE = 'bulk_upload_import_more';

export const BULK_UPLOAD_CUSTOMISE_TABLE = 'bulk_upload_customise_table';

export const BULK_UPLOAD_CHECK_DUPLICATE = 'bulk_upload_check_duplicate';

export const BULK_UPLOAD_SAVE = 'bulk_upload_save';

export const BULK_UPLOAD_SAVE_SUCCESS = 'bulk_upload_save_success';

export const COUNTRY_FILTER = 'country_filter';

export const HS_CODE_FILTER = 'hs_code_filter';

export const BUYER_VIEW_DETAILS = 'buyer_view_details';

export const SHORTLIST_Y = 'buyer_shortlist_y';

export const SHORTLIST_N = 'buyer_shortlist_n';

export const VIEW_MORE = 'buyer_view_more';

export const UNLOCK_CONTACT = 'unlock_contact';

export const COPY_EMAIL = 'buyer_copy_email';

export const COPY_LINKEDIN = 'buyer_copy_linkedin';

export const VIEW_LESS = 'view_less';

export const REMOVE_PROSPECT = 'remove_prospect';

export const VIEW_MORE_DETAILS = 'view_more_details';

export const VIEW_LESS_DETAILS = 'view_less_details';

export const PROSPECT_SELECT = 'prospect_select';

export const LOGINS = 'logins';

export const ALL_PRODUCTS_CLICK = 'all_products_click';

export const TASK_HISTORY = 'task_history';

export const ANALYTICS_BUTTON = 'view_analytics_button';

export const DOWNLOAD_REPORT = 'analytics_download_report';

export const VIEW_DETAILS = 'buyer_session_view_details';

export const EDIT_BUTTON = 'edit_button';

export const VIEW_BUTTON = 'view_button';

export const QUOTATION_VIEW_BUTTON = 'quotation_view_button';

export const CATEGORY_CARD_CLICK = 'category_card_click';

export const BULK_UPLOAD_IMAGES = 'bulk_upload_image';

export const BULK_UPLOAD_MORE_IMAGES = 'bulk_upload_more_image';

export const BULK_UPLOAD_DOWNLOAD_URL = 'bulk_upload_download_url';

export const BULK_SUCCESS_UPLOAD_IMAGES = 'bulk_success_upload_images';

export const DELETE_BULK_ITEM = 'Delete_Bulk_Image';

export const ADD_MORE_IMAGES = 'add_more_image';

export const BULK_NEXT = 'bulk_next';

export const BULK_NEXT_STAGE_1 = 'bulk_next_stage_1_pass';

export const BULK_NEXT_STAGE_2 = 'bulk_next_stage_2_click';

export const BULK_NEXT_STAGE_2_CONFIRM = 'bulk_next_stage_2_confirm_click';

export const BULK_NEXT_STAGE_3 = 'bulk_next_stage_3_click';

export const UPLOAD_ALL = 'upload_all_pass';

export const CUSTOMIZE_BULK = 'customize_Bulk';

export const SINGLE_IMAGE_UPLOAD_DONE = 'single_image_upload_done';

export const SINGLE_MORE_IMAGE_UPLOAD_DONE = 'single_more_image_upload_done';

export const SINGLE_UPLOAD_HIT_NEXT = 'single_upload_hit_next';

export const SINGLE_UPLOAD_HIT_FINISH = 'single_upload_hit_finish';

export const SINGLE_UPLOAD_HIT_EDIT_FINISH = 'single_upload_hit_edit_finish';

export const DELETE_PRODUCT = 'delete_product';

export const CHANGE_CATEGORY_CONFIRM = 'change_category_confirm';

export const CHANGE_CATEGORY_CANCEL = 'change_category_cancel';

export const EDIT_ATTRIBUTES_CONFIRM = 'edit_attributes_confirm';

export const EDIT_ATTRIBUTES_DONE = 'edit_attributes_done';

export const DOWNLOAD_QR_BULK = 'download qr bulk';

export const EDIT_BULK_DONE = 'edit_bulk_done';

export const MODIFY_ON_TABLE_SAVE_CHANGES = 'Modify on table save changes';

export const DELETE_PRODUCT_CONFIRM = 'delete_product_confirm';

export const DEACTIVATE_LINK_CONFIRM = 'deactivate_link_confirm';

export const DELETE_CATALOGUE_LINK_CONFIRM = 'delete_catalogue_link_confirm';

export const UPDATE_CATALOGUE_SETTINGS = 'update_catalogue_settings';

export const EDIT_MODE = 'edit_mode';

export const SEND_FOR_APPROVAL_CONFIRM = 'send_for_approval_confirm';

export const ADD_MATERIAL = 'add_material';

export const ADD_MATERIAL_CONFIRM = 'resource_confirm';

export const EDIT_MATERIAL = 'edit_material';

export const ARCHIVE_MATERIAL = 'archive_material';

export const ADD_PROCESS = 'add_process';

export const EDIT_PROCESS = 'edit_process';

export const EDIT_PROFILE = 'edit_profile';

export const ADD_NEW_USER = 'add_new_user';

export const CONFIRM_USER = 'confirm_user';

export const EDIT_USER_PROFILE = 'edit_user_profile';

export const UPDATE_QR = 'update_qr';

export const EDIT_PREFERENCES = 'edit_preferences';

export const CREATE_NEW_QUOTATION = 'create_new_quotation';

export const CREATE_NEW_QUOTATION_CONFIRM = 'create_new_quotation_confirm';

export const QUOTATION_SEARCH = 'quotation_search';

export const QUOTATION_SORT = 'quotation_sort';

export const ENQUIRY_VIEW = 'enquiry_view';

export const CREATE_NEW_QUOTATION_HOVER = 'create_new_quotation_hover';

export const CREATE_QUOTATION = 'create_quotation';

export const UPLOAD_QUOTATION = 'upload_quotation';

export const REVISION_EXPAND = 'revision_expand';

export const DOWNLOAD_PDF_QUOTATION = 'download_pdf_quotation';

export const DOWNLOAD_EXCEL_QUOTATION = 'download_excel_quotation';

export const UPLOAD_QUOTATION_CONFIRM = 'upload_quotation_confirm';

export const ADD_NEW_PRODUCT_MODAL = 'add_new_product_modal';

export const UPLOAD_IMAGE_QUOTAITON = 'upload_product_image_quotation';

export const NEW_PRODUCT_ADD_CONFIRM_QUOTATION =
  'new_product_add_confirm_quotation';

export const ADD_PRODUCTS_FOR_QUOTATION_DONE =
  'add_products_for_quotation_done';

export const REMARK_QUOTATION = 'remark_quotation';

export const ADD_MORE_PRODUCTS_SHOW = 'add_more_products_show';

export const CUSTOMIZE_TABLE_QUOTATION = 'customize_table_quotation';

export const CUSTOMIZE_TABLE_QUOTATION_DONE = 'customize_table_quotation_done';

export const DELETE_PRODUCT_QUOTATION = 'delete_product_quotation';

export const VIEW_DASHBOARD = 'view_dashboard';

export const VIEW_QUOTATION = 'view_quotation';

export const COPY_QUOTATION = 'copy_quotation';

export const ADD_A_PRODUCT = 'add_a_product';

export const REMOVE_A_PRODUCT = 'remove_a_product';

//Quotation Events

export const CREATE_NEW_QUOTATION_CLICK = 'create_new_quotation_click';

export const QUOTATION_BUYER_FORM_CONFIRM = 'quotation_buyer_form_confirm';

export const QUOTATION_DELETE_CONFIRM = 'quotation_delete_confirm';

export const QUOTATION_UPLOAD_CUSTOM = 'quotation_upload_custom';

export const QUOTATION_CREATE_CLICK = 'quotation_create_click';

export const QUOTATION_UPLOAD_CONFIRM = 'quotation_upload_confirm';

export const QUOTATION_UPLOAD_CANCEL = 'quotation_upload_cancel';

export const QUOTATION_ADD_NEW_PRODUCT = 'quotation_add_new_product';

export const SAVE_QUOTATION_CLICK = 'save_quotation_click';

export const SMART_SEARCH_ENTRY = 'smart_search_entry';

export const PROFORMA_CREATE_INVOICE_BUTTON = 'proforma_create';

export const PROFORMA_BUYER_PROCEED = 'proforma_buyer_proceed';

export const PROFORMA_BUYER_CANCEL = 'proforma_buyer_cancel';

export const PROFORMA_ADD_DETAILS_NEXT = 'proforma_add_details_next';

export const PROFORMA_SELECT_PRODUCTS_VIEW = 'proforma_select_products_view';

export const PROFORMA_ADD_NEW_PRODUCT = 'proforma_add_new_product';

export const PROFORMA_SHOW_CUSTOMIZE_TABLE = 'proforma_customize_table';

export const PROFORMA_CUSTOMIZE_MODAL_DONE = 'proforma_custumize_modal_done';

export const PROFORMA_CUSTOMIZE_MODAL_CANCEL =
  'proforma_custumize_modal_cancel';

export const PROFORMA_TABLE_ADD_PRODUCTS = 'proforma_table_add_products';

export const PROFOMORMA_GENERATE_PDF = 'proforma_generate_pdf';

export const PROFORMA_CANCEL_INVOICE = 'proforma_cancel_invoice';

export const PROFORMA_CREATE_COPY = 'proforma_create_copy';

export const PROFORMA_DOWNLOAD = 'proforma_download';

export const PROFORMA_DOCUMENT_LIST_COPY = 'proforma_document_list_copy';

export const PROFORMA_DOCUMENT_LIST_CANCEL = 'proforma_document_list_cancel';

export const PROFORMA_DOCUMENT_LIST_DELETE = 'proforma_document_list_delete';

export const PROFORMA_DOCUMENT_LIST_DOWNLOAD =
  'proforma_document_list_download';

export const PROFORMA_DOCUMENT_LIST_EDIT = 'proforma_document_list_edit';

export const ADVANCED_IMAGE_EDIT_DONE = 'advanced_image_edit_done';

export const IMAGE_EDITOR_RESET = 'image_editor_reset';

export const IMAGE_EDITOR_REMOVE_BG = 'image_editor_remove_bg';

export const REMOVE_IMAGE_FROM_EDITOR = 'remove_image_from_editor';

export const RESOURCE_LIBRARY_PRODUCTS_COSTING_SKIP =
  'resource_library_products_costing_skip';

export const RESOURCE_LIBRARY_PRODUCTS_COSTING_UPDATE =
  'resource_library_products_costing_update';

//when user downloads excel sheet of a product costing
export const COSTING_EXCEL_DOWNLOAD = 'costing_excel_download';

//when user clicks on change button while creating costing of a product
export const COSTING_UNIT_CHANGE_BTN_CLICK = 'costing_unit_change_click';

//when user clicks on done button after changin the unit
export const COSTING_UNIT_CHANGE_DONE = 'costing_unit_change_done';

//when user clicks on add packing materials in packaging while doing costing of a product
export const ADD_PACKAGING_MATERIALS_BUTTON = 'add_packaging_materials';

//when user selects packging materials and clicks on add
export const ADD_PACKAGING_MATERIALS = 'add_packaging_materials';

//when user saves the costing of a product
export const SAVE_CHANGES_COSTING = 'save_changes_costing';
export const VARIANT_TAB_CLICK = 'variant_tab_click';
export const VARIANTS_INDEXED = 'variants_indexed';
export const ADD_VARIANT_CLICK = 'add_variant_clicked';
export const ADD_VARIANT_FINISH = 'add_variant_finish';
export const PUBLISH_VARIANT = 'publish_variant';
export const OPEN_VARIANT_SIDE_PANEL = 'open_variant_side_panel';
export const SAVE_VARIANT_SIDE_PANEL = 'save_variant_side_panel';

//Attachments tab events
export const ATTACHMENTS_TAB_CLICK = 'attachments_tab_click';
export const ADD_ATTACHMENT_CLICK = 'add_attachment_click';
export const ADD_ATTACHMENT_FINISH = 'add_attachment_finish';
export const EDIT_ATTACHMENT_CLICK = 'edit_attachment_click';
export const DELETE_ATTACHMENT_CLICK = 'delete_attachment_click';
export const DOWNLOAD_ATTACHMENT_CLICK = 'download_attachment_click';

//central notes
export const VIEW_CENTRAL_NOTE = 'view_central_note';

//event for clicking add images of attribute d-type image_list
export const ADD_IMAGES_LIST_CLICK = 'add_images_list_click';

//events for adding new fields in customize table in quotation
export const QUOTATION_CUSTOMIZE_TABLE_ADD_NEW_FIELD =
  'quotation_customize_table_add_new_field';
export const QUOTATION_CUSTOMIZE_TABLE_ADD_NEW_FIELD_DONE =
  'quotation_customize_table_add_new_field_done';
export const QUOTATION_CUSTOMIZE_TABLE_CANCEL_NEW_FIELD =
  'quotation_customize_table_cancel_new_field';
//Reference image events
export const REFERENCE_IMAGE_DRAWER_CANCEL_CLICK =
  'reference_image_drawer_cancel_click';
export const REFERENCE_IMAGE_DRAWER_DONE_CLICK =
  'reference_image_drawer_done_click';
export const REFERENCE_IMAGE_SELECT_IMAGES_CLICK =
  'reference_image_select_images_click';
export const REFERENCE_IMAGE_UPLOAD_IMAGES_CLICK =
  'reference_image_upload_images_click';
export const REFERENCE_IMAGE_SELECTION_PAGE_CONFIRM =
  'reference_image_selection_page_confirm';
export const REFERENCE_IMAGE_SELECT_MAIN_IMAGE_CLICK =
  'reference_image_select_main_image_click';
export const REFERENCE_IMAGE_CUSTOMIZE_SELECTION_CLICK =
  'reference_image_customize_selection_click';
export const REFERENCE_IMAGE_CUSTOMIZE_SELECTION_DONE =
  'reference_image_customize_selection_done';
//attribute settings
export const CREATE_ATTRIBUTE_CLICK = 'create_attribute_click';
export const CREATE_ATTRIBUTE_NEXT_TAB = 'create_attribute_next_tab';
export const CREATE_ATTRIBUTE_SAVE = 'create_attribute_save';
export const DELETE_ATTRIBUTE_CLICK = 'delete_attribute_click';
export const DELETE_ATTRIBUTE_CONFIRM = 'delete_attribute_confirm';
export const EDIT_ATTRIBUTE_CLICK = 'edit_attribute_click';
export const EDIT_ATTRIBUTE_NEXT_TAB = 'edit_attribute_next_tab';
export const EDIT_ATTRIBUTE_SAVE = 'edit_attribute_save';
export const ATTRIBUTE_REORDERING = 'attribute_reordering';

//filter settings
export const FILTER_REORDERING = 'filter_reordering';
export const EDIT_FILTER_CLICK = 'edit_filter_click';
export const SAVE_FILTER = 'save_filter';

// next and previous buttons on pdp and modals in all selection flows
export const NEXT_PRODUCT_BUTTON_CLICK = 'next_product_button_click';
export const PREV_PRODUCT_BUTTON_CLICK = 'prev_product_button_click';

// bulk image update
export const BULK_IMAGE_UPDATE_START = 'bulk_image_update_start';
export const BULK_IMAGE_UPLOAD_IMAGES = 'bulk_image-update-upload-images';

// bulk image update -> Linking table Page
export const BULK_IMAGE_UPDATE_DELETE_GROUP = 'bulk_image_update_delete_group';
export const BULK_IMAGE_UPDATE_UNLINK_ALL = 'bulk_image_update_unlink_all';
export const BULK_IMAGE_UPDATE_LINK_GROUP_CLICK =
  'bulk_image_update_link_group_click';
export const BULK_IMAGE_UPDATE_PRODUCT_CHIP_CLICK =
  'bulk_image_update_product_chip_click';
export const BULK_IMAGE_UPDATE_PRODUCT_UNLINK_CLICK =
  'bulk_image_update_product_unlink_click';
export const BULK_IMAGE_UPDATE_EDIT_IMAGES_CLICK =
  'bulk_image_update_edit_images_click';
export const BULK_IMAGE_UPDATE_REPLACE_IMAGES_CLICK =
  'bulk_image_update_replace_images_click';
export const BULK_IMAGE_UPDATE_CONFIRM_MODAL_CLICK =
  'bulk_image_update_confirm_modal_click';

export const BULK_IMAGE_UPDATE_REMOVE_ALL_IMAGE =
  'bulk_image_update_remove_all_image';

export const BULK_IMAGE_UPDATE_NEXT = 'bulk_image_update_next';

export const BULK_IMAGE_UPDATE_AUTO_LINK = 'bulk_image_update_auto_link';

export const BULK_IMAGE_UPDATE_PRODUCT_CHECKBOX_CLICK =
  'bulk_image_update_product_checkbox_click';

export const BULK_IMAGE_UPDATE_PRODUCT_SEARCH =
  'bulk_image_update_product_search';

export const BULK_IMAGE_UPDATE_LINK = 'bulk_image_update_link';

export const BULK_IMAGE_UPDATE_PAGINATION = 'bulk_image_update_pagination';
