import API_KEY from '../../api';
import axios from 'axios';
import { history } from '../../containers/SourceApp';
import emailList from '../../utils/userManagementHack.json';
import { useIntl, FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';
import {
  clearLocalStorageAndCookie,
  getRootDomain,
} from 'utils/logoutUsingCookies';

export const authActions = {
  otpVerify: async (store, payload) => {
    try {
      var user = localStorage.getItem('temp');
      store.setState({ isloggedIn: JSON.parse(user) });
      localStorage.setItem('user', user);
      localStorage.removeItem('temp');
      const userData = JSON.parse(user);

      Cookies.set('access_token', userData?.access_token, {
        domain: getRootDomain(window.location.href), // Replace with env variable
        expires: 15, // Set an expiration, for example, 7 days
        secure: true, // Recommended: Use HTTPS for secure cookies
      });

      axios
        .get(`${process.env.REACT_APP_DIRECTUS}users/me`, {
          params: {
            access_token: userData?.access_token,
            fields: '*,org.status,org.id',
          },
        })
        .then((res) => {
          console.log('res:', res.data.data);
          const data = res?.data?.data?.org;
          localStorage.setItem('logout-event', 'logout' + Math.random());

          localStorage.setItem('org', JSON.stringify(data));
          if (sessionStorage.getItem('routing-path')) {
            history.push(sessionStorage.getItem('routing-path'));
          } else {
            history.push('/');
          }
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      store.setState({
        error: error?.response?.data?.errors[0].message ? (
          error?.response?.data?.errors[0].message
        ) : (
          <FormattedMessage id={'sourcewiz.message.somethingWentWrong'} />
        ),
        loading: false,
      });
    }
  },
  signinAction: async (store, payload) => {
    store.setState({ loading: true });

    try {
      const authData = await axios.post(
        `${process.env.REACT_APP_DIRECTUS}${API_KEY.path.login}`,
        payload
      );

      Cookies.set('access_token', authData?.data?.data?.access_token, {
        domain: getRootDomain(window.location.href), // Replace with env variable
        expires: 15, // Set an expiration, for example, 7 days
        secure: true, // Recommended: Use HTTPS for secure cookies
      });

      localStorage.setItem('user', JSON.stringify(authData?.data?.data));
      localStorage.setItem(
        'email',
        JSON.stringify(payload?.email.toLowerCase())
      );
      var user = localStorage.getItem('user');

      const userData = JSON.parse(user);
      axios
        .get(`${process.env.REACT_APP_DIRECTUS}users/me`, {
          params: {
            access_token: userData?.access_token,
            fields: '*,org.status,org.id',
          },
        })
        .then((res) => {
          console.log('res:', res.data.data);
          const data = res?.data?.data?.org;

          localStorage.setItem('org', JSON.stringify(data));
          if (sessionStorage.getItem('routing-path')) {
            history.push(sessionStorage.getItem('routing-path'));
          } else {
            history.push('/');
          }
          store.setState({ isloggedIn: authData?.data?.data, loading: false });
          localStorage.setItem('logout-event', 'logout' + Math.random());
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      store.setState({
        error: 'Something went wrong. Try again',
        // error: <FormattedMessage id={'sourcewiz.message.somethingWentWrong'} />,
        loading: false,
      });
    }
  },
  signoutAction: async (store, payload) => {
    store.setState({ loading: true });
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);

    const authData = await API_KEY.URL.post(API_KEY.path.logout, {
      refresh_token: user?.refresh_token,
    });

    clearLocalStorageAndCookie();
    store.setState({ isloggedIn: false, loading: false });
    if (sessionStorage.getItem('routing-path')) {
      history.push(sessionStorage.getItem('routing-path'));
    } else {
      history.push('/');
    }
    window.location.reload();
    // if (authData?.status === 200) {
    //   localStorage.clear();
    //   history.push("/");
    //   store.setState({ isloggedIn: false, loading: false });
    // } else {
    //   localStorage.clear();
    //   history.push("/");
    //   store.setState({
    //     error: "Someting went wrong, Try again!",
    //     loading: false,
    //   });
    // }
  },
  setOnboarding: (store, payload) => {
    store.setState({ isOnboarding: payload });
  },
  signup: async (store, payload) => {
    store.setState({ loading: true });

    try {
      const authData = await API_KEY.URL.post(API_KEY.path.users, payload, {
        params: {
          access_token: null,
        },
      });

      store.setState({
        signupNew: authData.data?.data ? true : false,
        loading: false,
      });
      localStorage.setItem(
        'email',
        JSON.stringify(payload?.email.toLowerCase())
      );
    } catch (error) {
      store.setState({
        error: error?.response?.data?.errors[0].message
          ? error?.response?.data?.errors[0].message
          : 'Try again with another email',
        loading: false,
      });
    }
  },
};

// javascript sdk for directus

// import API_KEY from "../../api";
// import directus from "../../api/directus";
// export const authActions = {
//   signinAction: async (store, payload) => {
//     store.setState({ loading: true });

//     try {
//       const authData = await directus.auth.login({
//         refresh: {
//           auto: true,
//         },
//         ...payload,
//       });
//       console.log(authData);
//       store.setState({ isloggedIn: authData, loading: false });
//       localStorage.setItem("user", JSON.stringify(authData));
//     } catch (error) {
//       store.setState({
//         error: "Invalid user credentials or Try again!",
//         loading: false,
//       });
//     }
//   },
//   signoutAction: async (store, payload) => {
//     store.setState({ loading: true });
//     const userData = localStorage.getItem("user");
//     const user = JSON.parse(userData);

//     const authData = await API_KEY.URL.post(API_KEY.path.logout, {
//       refresh_token: user?.refresh_token,
//     });
//     if (authData?.status === 200) {
//       localStorage.clear();
//       store.setState({ isloggedIn: false, loading: false });
//     } else {
//       store.setState({
//         error: "Someting went wrong, Try again!",
//         loading: false,
//       });
//     }
//   },
// };
