import Pusher from 'pusher-js';
import {
  ChannelScope,
  MAX_CHANNEL_NAME_SIZE,
  NotificationProvider,
} from 'constants/notifications';

function pusherSubscribe(channelName, event, callbackFunc) {
  var pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
    cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
    encrypted: true,
  });
  let channel = pusher.subscribe(channelName);
  channel.bind(event, callbackFunc);
}

function pubNubSubscribe(channelName, event, callbackFunc) {}

function validate_and_create_channel_name(
  orgId,
  channel,
  channelScope = ChannelScope.ORGANIZATION,
  userId = null
) {
  if (!channel) {
    console.error('channel can not be empty!');
    return null;
  }
  let channelName = `${ChannelScope.ORGANIZATION}.${orgId}`;
  if (channelScope === ChannelScope.USER) {
    if (!userId) {
      console.error('userId can not be empty!');
      return null;
    }
    channelName += `_${channelScope}.${userId}`;
  }
  channelName += `_${channel}`;
  if (channelName.length >= MAX_CHANNEL_NAME_SIZE) {
    console.error(
      `Channel name size can not exceed ${MAX_CHANNEL_NAME_SIZE} characters`
    );
    return null;
  }
  return channelName;
}

export function subscribe(
  orgId,
  channel,
  eventType,
  callbackFunc,
  channelScope = ChannelScope.ORGANIZATION,
  userId = null
) {
  var channelName = validate_and_create_channel_name(
    orgId,
    channel,
    channelScope,
    userId
  );
  if (!!channelName) {
    switch (process.env.REACT_APP_NOTIFICATIONS_PROVIDER?.toLowerCase()) {
      case NotificationProvider.PUSHER:
        pusherSubscribe(channelName, eventType, callbackFunc);
        break;
      case NotificationProvider.PUBNUB:
        pubNubSubscribe(channelName, eventType, callbackFunc);
        break;
      default:
        pusherSubscribe(channelName, eventType, callbackFunc);
    }
  }
}
