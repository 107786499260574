import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Colors } from '../../theme';
import SignIn from './SignIn';
import SignUp from './SignUp';
import HomeSVG from './Home.svg';
import MobileView from './MobileView.svg';
import Logo from '../../assets/images/logo64.png';
import { Link, useLocation } from 'react-router-dom';
import './index.css';
import AppStoreButton from 'assets/images/AppStoreButton.svg';
import PlayStoreButton from 'assets/images/PlayStoreButton.svg';
import DownloadAppIllustration from 'assets/images/DownloadAppIllustration.svg';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

export default function Home() {
  const [createAccount, setCreateAccount] = useState(false);
  const location = useLocation();
  useEffect(() => {
    clearLocalStorageAndCookie();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('view-collection')) {
      sessionStorage.setItem('routing-path', location.pathname);
    }
  }, [location]);
  const changeState = (state) => {
    setCreateAccount(state);
  };
  return (
    <div
      style={{
        height: '100vh',
        background: '#E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Row
        className="hideOnSmall"
        style={{
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "center",
          // alignItems: "center",
          //  height: "75vh",
          width: '90%',
          background: 'white',
          padding: '4rem',
          borderRadius: 12,
        }}>
        <Col span={12}>
          <Row align="middle">
            <img src={Logo} alt="sourcewiz" width="40px" height="40px" />
            <span
              style={{
                fontSize: '32px',
                // fontWeight: "bolder",
                color: Colors.black,
                fontFamily: 'SatoshiBold',
                // marginBottom:'2rem'
                marginLeft: '0.5rem',
              }}>
              Sourcewiz
            </span>
          </Row>
          <Row style={{ marginTop: '1.5rem' }}>
            <Col span={22}>
              {createAccount ? (
                <SignUp changeState={changeState} />
              ) : (
                <SignIn changeState={changeState} />
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img src={HomeSVG} alt="sourcewiz" />
        </Col>
      </Row>

      {/* Mobile View starts ------------ */}
      <Row
        className="displayOnSmall"
        style={{
          width: '95%',
          background: 'white',
          padding: '2rem 1rem',
          borderRadius: 12,
          boxShadow: '0px 4px 40px 0 rgba(0,0,0,0.2)',
        }}>
        <Col span={24} className="alignCenter">
          <Row align="middle" className="">
            <img src={Logo} alt="sourcewiz" width="28px" height="28px" />
            <span
              style={{
                fontSize: '24px',
                textAlign: 'center',
                color: Colors.black,
                fontFamily: 'SatoshiBold',
                marginLeft: '0.5rem',
              }}>
              Sourcewiz
            </span>
          </Row>

          <Row
            className="alignCenter"
            style={{
              margin: '2.5em 0 1.5rem 0',
            }}>
            <img src={DownloadAppIllustration} alt="sourcewiz" width="100%" />
          </Row>

          <Row
            align="middle"
            style={{
              margin: '0 0 1.5rem 0',
            }}>
            <h1
              style={{
                fontSize: '14px',
                color: Colors.black,
                textAlign: 'center',
                fontWeight: 'bold',
                margin: 'auto',
              }}>
              Sourcewiz is now available on mobile
            </h1>
            <span
              style={{
                fontSize: '14px',
                color: Colors.black,
                textAlign: 'center',
                margin: 'auto',
                paddingLeft: 6,
              }}>
              Download the official app now
            </span>
          </Row>
          <Row justify="space-between" gutter={10}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.sourcewizmobileapp">
              <img alt="" src={PlayStoreButton} style={{ width: '90%' }} />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/in/app/sourcewiz/id1631787469">
              <img alt="" src={AppStoreButton} style={{ width: '90%' }} />
            </a>
          </Row>
        </Col>
        {/* Mobile View ends ------------ */}
      </Row>
    </div>
  );
}
