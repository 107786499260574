import API_KEY from '../../api';

export const requirements = {
  postRequirements: async (store, payload) => {
    store.setState({ loading: true });
    const data = await API_KEY.URL.post(
      API_KEY.path.requirements,
      payload
      //   {
      //   headers: { "Content-Type": "multipart/form-data" },
      // }
    );
    store.setState({ postRequirements: data, loading: false });
  },
};
