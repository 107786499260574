import { Modal, Row, Col, Typography } from 'antd';
import style from 'styled-components';
import { useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import PYTHON_KEY from 'api/pythonService';
const { Title, Text } = Typography;
const PlanDiv = style.div`
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    padding: 1.2rem 0rem 1.4rem 2.5rem;
    border-radius: 8px;
    background: linear-gradient(269.39deg, rgb(230, 223, 251, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
    mix-blend-mode: normal;
`;
export const PlanUpgradeSuccess = ({
  data,
  isModalVisible,
  setIsModalVisible,
}) => {
  const [benefits, setBenefits] = useState([]);
  useEffect(() => {
    if (Array.isArray(data?.meta?.others)) {
      setBenefits([...data?.meta?.benefits, ...data?.meta?.others]);
    } else {
      if (Array.isArray(data?.meta?.benefits)) {
        setBenefits([...data?.meta?.benefits]);
      }
    }
  }, [data]);
  const handleDismiss = () => {
    const userData = localStorage.getItem('user');
    const user = userData && JSON.parse(userData);
    const access_token = user?.access_token;
    const payload = {
      id: data?.id,
    };
    PYTHON_KEY.URL.post(PYTHON_KEY.path.dismiss_notification, payload, {
      headers: { 'access-token': `${access_token}` },
    })
      .then((res) => {
        if (res?.status === 200) {
          setIsModalVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal
        width={590}
        visible={isModalVisible}
        footer={null}
        onCancel={handleDismiss}>
        <Row align="center">
          <img src={data?.meta?.url} alt="img" />
        </Row>
        <br />
        <Row align="center">
          <Title level={4}>{data?.title}</Title>
        </Row>
        <Row align="center">
          <Text style={{ color: '#4F5655' }}>{data?.description}</Text>
        </Row>
        {benefits && (
          <PlanDiv>
            <Row justify="space-between">
              {benefits?.map((e) => {
                return (
                  <Col span={12} style={{ marginTop: '0.3rem' }}>
                    <CheckCircleFilled
                      style={{ color: '#E6A145', marginRight: '0.3rem' }}
                    />{' '}
                    <Text>{e}</Text>
                  </Col>
                );
              })}
            </Row>
          </PlanDiv>
        )}
      </Modal>
    </>
  );
};
