import React from 'react';
import { Modal, Row, Col, Typography } from 'antd';
import './index.less';
import Button from 'react-common-components/Button';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';

const { Text } = Typography;

const DiscardTemplate = ({
  setIsDiscardTemplate,
  isDiscardTemplate,
  setIsCreateTemplate,
  setIsDiscard,
}) => {
  const { trackEvent } = useTracking();
  const location = useLocation();

  const discardTemplate = () => {
    trackEvent({
      name: 'export_discard_template',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setIsDiscard(true);
    setIsDiscardTemplate(false);
    setIsCreateTemplate(false);
  };

  const handleCreateTemplate = () => {
    trackEvent({
      name: 'export_discard_continue',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setIsDiscardTemplate(false);
  };

  return (
    <Modal
      className="donwloadExecelModal"
      title=""
      centered
      visible={isDiscardTemplate}
      width={516}
      footer={null}
      onOk={() => setIsDiscardTemplate(false)}
      onCancel={() => setIsDiscardTemplate(false)}
      style={{ padding: '0px' }}>
      <Row className="discard-modal">
        <Row>
          <Text className="modal-template-header">Discard template?</Text>
        </Row>
        <Row>
          <Text className="discard-modal-text">
            You have not saved the template. Are you sure to discard and leave?
          </Text>
        </Row>
        <Row justify="space-between" className="discard-modal-footer">
          <Col>
            <Button
              onClick={discardTemplate}
              variant="outline_red"
              size="large"
              className="standard-button">
              Discard
            </Button>
          </Col>
          <Col>
            <Button
              onClick={handleCreateTemplate}
              variant="dark"
              size="large"
              className="standard-button">
              Continue
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default DiscardTemplate;
