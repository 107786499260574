export const productActions = {
  setProductPage: (store, payload) => {
    const existingProductPage = store?.state?.productPage;
    store.setState({ productPage: { ...existingProductPage, ...payload } });
  },
  setParts: (store, payload) => {
    store.setState({ parts: payload });
  },
  setNewResourceId: (store, payload) => {
    store.setState({ newResouceId: payload });
  },
  // below actions are used from feat-product-costing => overheads and margins calculations
  setOverhead: (store, payload) => {
    store.setState({ overhead: payload });
  },
  setMarginOrProfit: (store, payload) => {
    store.setState({ margin: payload });
  },

  setTax: (store, payload) => {
    store.setState({ tax: payload });
  },
  setSellingPrice: (store, payload) => {
    store.setState({ sellingPrice: payload });
  },
  refreshData: (store, payload) => {
    store.setState({
      productPage: { ...store?.state?.productPage, refreshData: payload },
    });
  },
  shouldReindexProduct: (store, payload) => {
    store.setState({
      productPage: {
        ...store?.state?.productPage,
        shouldReindexProduct: payload,
      },
    });
  },
};
