import moment from 'moment';
import * as DOMPurify from 'dompurify';
import { ATTRIBUTE_DATE_FORMAT } from 'constants/attributeConstants';
import { v4 as uuid } from 'uuid';
import { parsePreciseNumericValue } from 'react-common-components/utils';

export const getComputedValue = (fieldsArray = []) => {
  if (!Array.isArray(fieldsArray)) fieldsArray = [];
  const materialCompValues = fieldsArray.map((field) => {
    if (field?.material) {
      return `${field.material} ${field.value} %`;
    }
    return '';
  });
  return materialCompValues.join(', ') || '';
};

export const getBooleanValue = (value) => {
  switch (value) {
    case 'True':
    case 'true':
    case true:
      return true;
    default:
      return false;
  }
};

export const getAttributeValueIDMap = (attributeData) => {
  const map = {};
  for (let i = 0; i < attributeData.length; i++) {
    const item = attributeData[i];
    if (item && item.attribute_id) {
      map[item.attribute_id] = item.attribute_value_id;
    }
  }
  return map;
};

export const getFormFieldValue = (
  type,
  value,
  composite,
  compositeKey,
  defaults
) => {
  switch (type) {
    case 'csv':
    case 'select':
    case 'multi_select':
      return typeof value === 'string'
        ? value.split(',').filter(Boolean)
        : value || [];
    case 'date':
      const parsedValue =
        value && moment(value, ATTRIBUTE_DATE_FORMAT).isValid()
          ? moment(value, ATTRIBUTE_DATE_FORMAT)
          : '';
      return parsedValue;
    case 'composition':
      return composite;
    case 'dropdown_percent':
    case 'size':
    case 'uom':
    case 'price':
      return compositeKey
        ? composite?.[compositeKey] || undefined
        : parsePreciseNumericValue(defaults, value);
    case 'decimal':
      return parsePreciseNumericValue(defaults, value);
    case 'html':
      return composite?.content || '';
    case 'boolean':
      return getBooleanValue(value);
    default:
      return value;
  }
};

export const getFormFields = (currentField) => {
  const currentAttributeValue =
    currentField?.attribute_value_id?.attribute_values_id || {};
  const type = currentAttributeValue?.attribute_id?.dtype;

  const defaults = currentAttributeValue?.attribute_id?.default;

  const { attribute_id = {}, values, composite = {} } = currentAttributeValue;

  switch (type) {
    case 'size':
      const sizeKeys = ['length', 'width', 'height', 'unit'];
      const sizeFormFields = sizeKeys.reduce((agg, item) => {
        return {
          ...agg,
          [`${currentField.name}:${currentField.id}:${item}`]:
            getFormFieldValue(
              attribute_id.dtype,
              values,
              composite,
              item,
              defaults
            ),
        };
      }, {});
      return sizeFormFields;
    case 'price':
      const priceKeys = ['currency', 'unit'];
      const priceFormFields = {
        [`${currentField.name}:${currentField.id}`]: getFormFieldValue(
          attribute_id.dtype,
          values,
          composite,
          defaults
        ),
        ...priceKeys.reduce((agg, item) => {
          return {
            ...agg,
            [`${currentField.name}:${currentField.id}:${item}`]:
              getFormFieldValue(
                attribute_id.dtype,
                values,
                composite,
                item,
                defaults
              ),
          };
        }, {}),
      };

      return priceFormFields;
    case 'uom':
      const uomKeys = ['value', 'unit'];
      const uomFields = {
        [`${currentField.name}:${currentField.id}`]: getFormFieldValue(
          attribute_id.dtype,
          values,
          composite,
          defaults
        ),
        ...uomKeys.reduce((agg, item) => {
          return {
            ...agg,
            [`${currentField.name}:${currentField.id}:${item}`]:
              getFormFieldValue(
                attribute_id.dtype,
                values,
                composite,
                item,
                defaults
              ),
          };
        }, {}),
      };

      return uomFields;
    case 'dropdown_percent':
      const dpKeys = ['value', 'option'];
      const dpFields = {
        [`${currentField.name}:${currentField.id}`]: getFormFieldValue(
          attribute_id.dtype,
          values,
          composite,
          defaults
        ),
        ...dpKeys.reduce((agg, item) => {
          return {
            ...agg,
            [`${currentField.name}:${currentField.id}:${item}`]:
              getFormFieldValue(
                attribute_id.dtype,
                values,
                composite,
                item,
                defaults
              ),
          };
        }, {}),
      };

      return dpFields;
    case 'date':
      return {
        [`${currentField.name}:${currentField.id}`]: getFormFieldValue(
          attribute_id.dtype,
          values,
          composite,
          defaults
        ),
      };
    default:
      return {
        [`${currentField.name}:${currentField.id}`]: getFormFieldValue(
          attribute_id.dtype,
          values,
          composite,
          defaults
        ),
      };
  }
};

export const renderReadOnlyValues = (field) => {
  switch (field?.dtype) {
    case 'price':
      const value =
        (field?.composite?.currency || '') +
        ' ' +
        (field?.values || '') +
        ' ' +
        (field?.composite?.unit || '');
      field.values = value || '';
      return field;
    default:
      return field;
  }
};

export const getPIFormItemValue = (key, currentValue, item) => {
  switch (key?.dtype) {
    case 'packaging':
      switch (key.compositeKey) {
        case 'unit':
          return currentValue.split('--')?.length > 1
            ? currentValue.split('--')[1]
            : currentValue || 'cm';
        case 'no_of_pieces':
          return currentValue || 0;
        default:
          return currentValue || '';
      }
    case 'date':
      return moment(currentValue, ATTRIBUTE_DATE_FORMAT).isValid()
        ? moment(currentValue, ATTRIBUTE_DATE_FORMAT)
        : null;
    case 'boolean':
      return getBooleanValue(currentValue);
    case 'composition':
      if (JSON.stringify(currentValue) === '{}') return [];
      return currentValue;
    case 'csv':
    case 'select':
    case 'multi_select':
      return typeof currentValue === 'string'
        ? currentValue
          ? currentValue.split(',')
          : []
        : currentValue;

    case 'decimal':
      return parsePreciseNumericValue(item?.default, currentValue);
    default:
      return currentValue || '';
  }
};

/**
 *
 * @formKey {optional}
 * @value {required - primitive or object or array}
 * @updatedAttribute {optional}
 * @product_id {optional}
 * @isMainProduct {optional}
 * @type {required}
 */
//called from bulk upload, bulk update, variants table, quotation table
export const getPayloadFromFormKey = (
  formKey,
  value,
  updatedAttribute,
  product_id,
  type
) => {
  const attributeId = updatedAttribute?.id || updatedAttribute?.attribute_id;
  switch (type) {
    case 'product_id':
      return {
        product_ids: [product_id],
        variant_id: value,
      };
    case 'price':
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: value[formKey],
          composite: {
            unit: value[formKey]
              ? value[formKey.replace('values', 'unit')]
              : '',
            currency: value[formKey]
              ? value[formKey.replace('values', 'currency')]
              : '',
          },
        },
      };
    case 'size':
      const compositeObj = {
        length: value[formKey.replace('values', 'length')],
        width: value[formKey.replace('values', 'width')],
        height: value[formKey.replace('values', 'height')],
      };
      const unit = value[formKey.replace('values', 'unit')];
      const parsedValue = `${Object.values(compositeObj)
        .filter(Boolean)
        .join('x')} ${unit || ''}`;
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: parsedValue,
          composite: { ...compositeObj, unit },
        },
      };
    case 'packaging':
      const packagingObj = {
        length: value[formKey.replace('values', 'length')],
        width: value[formKey.replace('values', 'width')],
        height: value[formKey.replace('values', 'height')],
        unit: value[formKey.replace('values', 'unit')],
        no_of_pieces: value[formKey.replace('values', 'no_of_pieces')],
        CBM: value[formKey.replace('values', 'CBM')],
      };
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: '',
          id: updatedAttribute.attribute_value_id,
          collections_products_id: product_id,
          composite: { ...packagingObj },
        },
      };
    case 'image':
      return {
        product_ids: [product_id],
        images: value.map((item) => item.directus_files_id),
        type: 'variant_edit',
      };
    case 'coo':
      return {
        country_of_origin: value,
        product_ids: [product_id],
      };
    case 'dropdown_percent':
      const compositeValue = {
        option: value[formKey.replace('values', 'option')] || '',
        value: value[formKey.replace('values', 'value')] || '',
      };
      // const { option = '', value : thisValue = '' } = compositeValue;
      const validValues =
        [compositeValue.option, compositeValue.value].filter(Boolean) || [];
      const computedValue = validValues.length
        ? `${validValues.join(' ')}%`
        : '';
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: computedValue,
          composite: compositeValue,
        },
      };
    case 'uom':
      const uomCompositeObj = {
        unit: value[formKey.replace('values', 'unit')] || '',
        value: value[formKey.replace('values', 'value')] || '',
      };
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: uomCompositeObj?.value
            ? `${uomCompositeObj?.value} ${uomCompositeObj?.unit}`
            : '',
          composite: {
            unit: uomCompositeObj?.value ? uomCompositeObj?.unit : '',
            value: uomCompositeObj?.value,
          },
        },
      };
    case 'composition':
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: getComputedValue(value),
          composite: value,
        },
      };
    case 'html':
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: 'html',
          composite: {
            content: value,
          },
        },
      };
    case 'variant_id':
      return {
        product_ids: [product_id],
        variant_id: value,
      };
    default:
      return {
        product_ids: [product_id],
        attribute_values_id: {
          attribute_id: attributeId,
          values: value,
          composite: {},
        },
      };
  }
};

// Returns payload for ProductV2 call for either add or edit product
export const getAttributePayloadFromFormKey = ({
  value,
  attribute_id,
  composite,
  product_id,
  attribute_value_id,
}) => {
  return {
    attribute_id: attribute_id,
    id: attribute_value_id,
    collections_products_id: product_id,
    values: value,
    composite: composite,
  };
};

export const getCompositeBulkUpdatePayload = (
  dataIndex,
  type,
  attributeID,
  productIDs,
  values,
  selectedRows
) => {
  let updatePayload = {};
  switch (type) {
    case 'size':
      const length = dataIndex.replace('unit', 'length');
      const width = dataIndex.replace('unit', 'width');
      const unit = values[dataIndex] || '';
      const height = dataIndex.replace('unit', 'height');
      const updatedValues = selectedRows?.map((item) => {
        return {
          length: item[length],
          width: item[width],
          height: item[height],
          unit: unit,
        };
      });
      updatePayload = productIDs?.reduce((agg, item, index) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: {
              length: updatedValues[index].length || '',
              width: updatedValues[index].width || '',
              height: updatedValues[index].height || '',
              unit: updatedValues[index].unit,
            },
            values: `${[
              updatedValues[index].length,
              updatedValues[index].width,
              updatedValues[index].height,
            ]
              .filter(Boolean)
              .join('x')} ${updatedValues[index].unit || ''}`,
          },
        };
      }, {});
      break;

    case 'uom':
      const value = dataIndex.replace('unit', 'value');
      const uomUnit = values[dataIndex] || '';
      const updatedUOM = selectedRows?.map((item) => {
        return {
          value: item[value],
          unit: uomUnit,
        };
      });
      updatePayload = productIDs?.reduce((agg, item, index) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: {
              value: updatedUOM[index].value || '',
              unit: updatedUOM[index].unit || '',
            },
            values: `${[updatedUOM[index].value, updatedUOM[index].unit]
              .filter(Boolean)
              .join(' ')}`,
          },
        };
      }, {});
      break;

    case 'price':
      const updatedUnit = dataIndex.includes('currency')
        ? dataIndex.replace('currency', 'unit')
        : dataIndex.replace('unit', 'currency');
      const price = dataIndex.includes('currency')
        ? dataIndex.replace('currency', 'values')
        : dataIndex.replace('unit', 'values');

      const updatedAttribute = values[dataIndex] || '';
      const updatedVal = selectedRows?.map((item) => {
        return {
          unit: dataIndex.includes('currency')
            ? item[updatedUnit]
            : updatedAttribute,
          currency: dataIndex.includes('currency')
            ? updatedAttribute
            : item[updatedUnit],
          value: item[price],
        };
      });
      updatePayload = productIDs?.reduce((agg, item, index) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: {
              unit: updatedVal[index]?.unit,
              currency: updatedVal[index]?.currency,
            },
            values: updatedVal[index]?.value,
          },
        };
      }, {});
      break;

    default:
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            collections_products_ids: productIDs,
            values:
              typeof values[dataIndex] === 'object'
                ? values[dataIndex].join(',')
                : values[dataIndex],
          },
        };
      }, {});
  }
  return updatePayload;
};

export const getSingleBulkUpdatePayload = (
  attributeID,
  dataIndex,
  productIDs,
  values,
  type,
  isBulkPriceChanged = false
) => {
  let updatePayload = {};
  switch (type) {
    case 'csv':
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            values:
              typeof values[dataIndex] === 'object'
                ? values[dataIndex].join(',')
                : values[dataIndex],
          },
        };
      }, {});
      break;

    case 'price':
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            values: isBulkPriceChanged ? values?.[item] : values?.[dataIndex],
            composite: {
              unit: values[dataIndex.replace('values', 'unit')],
              currency: values[dataIndex.replace('values', 'currency')],
            },
          },
        };
      }, {});
      break;
    case 'size':
      const length = values[dataIndex.replace('values', 'length')];
      const height = values[dataIndex.replace('values', 'height')];
      const width = values[dataIndex.replace('values', 'width')];
      const unit = values[dataIndex.replace('values', 'unit')];
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            values: `${[length, height, width]
              .filter(Boolean)
              .join('x')} ${unit}`,
            composite: {
              length: length,
              width: width,
              height: height,
              unit: unit,
            },
          },
        };
      }, {});
      break;

    case 'date':
      const dateValue = values[dataIndex]?._d || '';
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: {},
            values: !dateValue
              ? ''
              : moment(dateValue, ATTRIBUTE_DATE_FORMAT)
                  ?.format(ATTRIBUTE_DATE_FORMAT)
                  .split('-')
                  .join('/'),
          },
        };
      }, {});
      break;

    case 'sub_category':
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            sub_category:
              typeof values[dataIndex] === 'object'
                ? values[dataIndex].join(',')
                : values[dataIndex],
          },
        };
      }, {});
      break;

    case 'dropdown_percent':
      const percent = values[dataIndex.replace('values', 'value')];
      const option = values[dataIndex.replace('values', 'option')];
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: { option: option, value: percent },
            values: `${option} ${percent} %`,
          },
        };
      }, {});
      break;

    case 'uom':
      const uomUnit = values[dataIndex.replace('values', 'unit')] || '';
      const uomValue = values[dataIndex.replace('values', 'value')] || '';
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: { value: uomValue, unit: uomValue ? uomUnit : '' },
            values: uomValue ? `${uomValue} ${uomUnit}` : '',
          },
        };
      }, {});
      break;
    case 'html':
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: { content: values },
            values: 'html',
          },
        };
      }, {});
      break;

    case 'composition':
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            composite: values,
            values: getComputedValue(values),
          },
        };
      }, {});
      break;
    default:
      updatePayload = productIDs?.reduce((agg, item) => {
        return {
          ...agg,
          [item]: {
            attribute_id: attributeID,
            values:
              typeof values[dataIndex] === 'object'
                ? values[dataIndex].join(',')
                : values[dataIndex],
          },
        };
      }, {});
      break;
  }
  return updatePayload;
};
export const getAllFormFields = (attributeData, defaultsMap) => {
  const formFields = attributeData.reduce((agg, attribute) => {
    let obj = {};

    const {
      name,
      attribute_id,
      value,
      type,
      attribute_value_id,
      composite,
      sub_columns,
      default: defaultProps = {},
    } = attribute;

    const formKey = `${name}__${attribute_id || name}__values__${type}`;

    const parsedType = type || name.toLowerCase();

    switch (parsedType) {
      case 'csv':
      case 'select':
      case 'multi_select':
      case 'sub_category':
        obj = {
          [formKey]:
            value && typeof value === 'string'
              ? value?.replaceAll(', ', ',').split(',')
              : value || [],
        };
        break;
      case 'date':
        const tempValue =
          value && moment(value, ATTRIBUTE_DATE_FORMAT).isValid()
            ? moment(value, ATTRIBUTE_DATE_FORMAT)
            : '';
        obj = {
          [`${name}__${attribute_id}__values__${type}`]: tempValue,
        };
        break;
      case 'price':
        let compositeKeys = ['unit', 'currency'];
        const compositeFormData = compositeKeys.reduce((agg, item) => {
          return {
            ...agg,
            [formKey.replace('values', item)]:
              (sub_columns || {}).find((comp) => comp.name === item)?.value ||
              '',
          };
        }, {});
        obj = {
          [formKey]: value || '',
          ...compositeFormData,
        };
        break;
      case 'packaging':
        const packagingKeys = [
          'length',
          'width',
          'height',
          'unit',
          'no_of_pieces',
          'cbm_per_piece',
          'CBM',
        ];
        const packagingData = packagingKeys.reduce((agg, item) => {
          return {
            ...agg,
            [formKey.replace('values', item)]:
              (sub_columns || []).find((comp) => comp.name === item)?.value ||
              '',
          };
        }, {});
        obj = {
          [formKey]: value || '',
          ...packagingData,
        };
        break;
      case 'size':
        const sizeKeys = ['unit', 'length', 'width', 'height'];
        const sizeData = sizeKeys.reduce((agg, item) => {
          return {
            ...agg,
            [formKey.replace('values', item)]:
              attribute[name]?.[item] || composite?.[item] || '',
          };
        }, {});
        obj = {
          [formKey]: value || '',
          ...sizeData,
        };
        break;
      case 'image':
        obj = {
          [parsedType]: value.file
            ? [
                {
                  ...value,
                  directus_files_id: value.file,
                },
              ]
            : value?.map((item) => ({
                ...item,
                directus_files_id: item.file,
              })) || [],
        };
        break;
      case 'dropdown_percent':
        const dropdownKeys = ['value', 'option'];
        const dropdownData = dropdownKeys.reduce((agg, item) => {
          return {
            ...agg,
            [item]: attribute[name]?.[item] || composite?.[item] || '',
          };
        }, {});
        obj = {
          [formKey]: [dropdownData],
        };
        break;
      case 'product_id':
        obj = {
          has_variant: attribute?.has_variant || false,
          parent_id: attribute?.parent_id || '',
          [formKey]: value || '',
        };
        break;
      case 'boolean':
        obj = {
          [formKey]: getBooleanValue(value),
        };
        break;
      case 'uom':
        const uomKeys = ['value', 'unit'];
        const uomData = uomKeys.reduce((agg, item) => {
          return {
            ...agg,
            [formKey.replace('values', item)]:
              attribute['composite']?.[item] || '',
          };
        }, {});
        obj = {
          [formKey]: value || '',
          ...uomData,
        };
        break;
      case 'html':
        obj = {
          [formKey]: composite?.content ? composite.content : '',
        };
        break;
      case 'composition': {
        obj = {
          [formKey]:
            JSON.stringify(composite) === '{}'
              ? []
              : composite?.map((item) => ({ ...item, id: uuid() })) || [],
        };
        break;
      }
      case 'image_list': {
        obj = {
          [formKey]: composite || {},
        };
        break;
      }
      default:
        obj = {
          [formKey]: value || '',
        };
        break;
    }
    return { ...agg, ...obj };
  }, {});
  return formFields;
};

export const getProductPatchAttributePayload = (attribute, fieldValues) => {
  const { dtype, id, name, default: defaultProps = {} } = attribute;
  const formKey = `${name}:${id}`;
  let composite = {};
  let values = '';
  switch (dtype) {
    case 'csv':
      return {
        values:
          typeof (fieldValues[formKey] || []) === 'object'
            ? (fieldValues[formKey] || []).join(',')
            : fieldValues[formKey] || '',
        composite: {},
      };
    case 'size':
      const sizeKeys = ['length', 'width', 'height', 'unit'];
      const sizeFields = sizeKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: parsePreciseNumericValue(
            defaultProps,
            fieldValues[`${formKey}:${key}`]
          ),
        };
      }, {});
      const { length, width, height, unit } = sizeFields;
      const validSizes = [length, width, height].filter(Boolean) || [];
      values = validSizes.length
        ? `${validSizes.join('x')} ${unit || ''}`.trim()
        : '';
      return {
        values: values,
        composite: sizeFields,
      };
    case 'dropdown_percent':
      const dpKeys = ['option', 'value'];
      const dpFields = dpKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]:
            key === 'value'
              ? parsePreciseNumericValue(
                  defaultProps,
                  fieldValues[`${formKey}:${key}`]
                )
              : fieldValues[`${formKey}:${key}`],
        };
      }, {});
      const { option = '', value = '' } = dpFields;
      const validValues = [option, value].filter(Boolean) || [];
      values = validValues.length ? `${validValues.join(' ')}%` : '';
      return {
        ...{
          values,
          composite: dpFields,
        },
      };
    case 'uom':
      const uomKeys = ['unit', 'value'];
      const uomFields = uomKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]:
            key === 'value'
              ? parsePreciseNumericValue(
                  defaultProps,
                  fieldValues[`${formKey}:${key}`]
                )
              : fieldValues[`${formKey}:${key}`],
        };
      }, {});
      values = uomFields.value
        ? `${uomFields.value} ${uomFields.unit || ''}`
        : '';
      return {
        ...{
          values,
          composite: {
            value: uomFields?.value,
            unit: uomFields?.value ? uomFields?.unit : '',
          },
        },
      };
    case 'date':
      const dateValue = fieldValues[formKey]?._d || '';
      return {
        values: !dateValue
          ? dateValue
          : moment(dateValue, ATTRIBUTE_DATE_FORMAT).format(
              ATTRIBUTE_DATE_FORMAT
            ),
        composite: composite,
      };

    case 'price':
      const priceKeys = ['unit', 'currency'];
      const priceFields = priceKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: fieldValues[`${formKey}:${key}`],
        };
      }, {});
      return {
        ...{
          values: parsePreciseNumericValue(defaultProps, fieldValues[formKey]),
          composite: priceFields,
        },
      };
    case 'file':
      return {
        values: '',
        composite: fieldValues[formKey],
      };
    case 'html':
      const htmlValue = DOMPurify.sanitize(fieldValues[formKey]);
      return {
        values: 'html',
        composite: {
          content: htmlValue,
        },
      };

    case 'boolean':
      return {
        values: fieldValues[formKey],
      };
    case 'hscode':
      return {
        values: fieldValues[formKey],
        composite: {},
      };
    case 'composition':
      return {
        values: getComputedValue(fieldValues[formKey]),
        composite: fieldValues[formKey],
      };
    default:
      return {
        values:
          fieldValues[formKey] && typeof fieldValues[formKey] === 'object'
            ? (fieldValues[formKey] || []).join(',')
            : fieldValues[formKey] || '',
        composite: {},
      };
  }
};

export const validateFields = (type, product_id, attribute_id, formObject) => {
  const valuesKey = Object.keys(formObject)
    ?.filter((item) => item?.includes('values'))
    ?.toString();

  const isValidSizeValue = [
    formObject[valuesKey?.replace('values', 'length')],
    formObject[valuesKey?.replace('values', 'width')],
    formObject[valuesKey?.replace('values', 'height')],
  ]?.filter(Boolean)?.length;
  switch (type) {
    case 'uom':
      if (
        formObject[valuesKey?.replace('values', 'value')] &&
        !formObject[valuesKey?.replace('values', 'unit')]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
          },
        };
      }
      break;
    case 'size':
      if (
        isValidSizeValue &&
        !formObject[valuesKey?.replace('values', 'unit')]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
          },
        };
      }
      break;
    case 'price':
      if (
        formObject[valuesKey] &&
        !formObject[valuesKey?.replace('values', 'unit')] &&
        !formObject[valuesKey?.replace('values', 'currency')]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
            currency: 'please enter currency',
          },
        };
      }
      if (
        formObject[valuesKey] &&
        formObject[valuesKey?.replace('values', 'unit')] &&
        !formObject[valuesKey?.replace('values', 'currency')]
      ) {
        return {
          [attribute_id]: {
            currency: 'please enter currency',
          },
        };
      }
      if (
        formObject[valuesKey] &&
        !formObject[valuesKey?.replace('values', 'unit')] &&
        formObject[valuesKey?.replace('values', 'currency')]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
          },
        };
      }

      break;
    default:
      break;
  }
};

export const validateFieldsOnBlur = (
  attributeName,
  type,
  product_id,
  attribute_id,
  formObject
) => {
  const isValidSizeValue = [
    formObject[`${attributeName}__${attribute_id}__length__${type}`],
    formObject[`${attributeName}__${attribute_id}__width__${type}`],
    formObject[`${attributeName}__${attribute_id}__height__${type}`],
  ]?.filter(Boolean)?.length;
  switch (type) {
    case 'uom':
      if (
        formObject[`${attributeName}__${attribute_id}__value__${type}`] &&
        !formObject[`${attributeName}__${attribute_id}__unit__${type}`]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
          },
        };
      }
      break;
    case 'size':
      if (
        isValidSizeValue &&
        !formObject[`${attributeName}__${attribute_id}__unit__${type}`]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
          },
        };
      }
      break;
    case 'price':
      if (
        formObject[`${attributeName}__${attribute_id}__values__${type}`] &&
        !formObject[`${attributeName}__${attribute_id}__unit__${type}`] &&
        !formObject[`${attributeName}__${attribute_id}__currency__${type}`]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
            currency: 'please enter currency',
          },
        };
      }
      if (
        formObject[`${attributeName}__${attribute_id}__values__${type}`] &&
        formObject[`${attributeName}__${attribute_id}__unit__${type}`] &&
        !formObject[`${attributeName}__${attribute_id}__currency__${type}`]
      ) {
        return {
          [attribute_id]: {
            currency: 'please enter currency',
          },
        };
      }
      if (
        formObject[`${attributeName}__${attribute_id}__values__${type}`] &&
        !formObject[`${attributeName}__${attribute_id}__unit__${type}`] &&
        formObject[`${attributeName}__${attribute_id}__currency__${type}`]
      ) {
        return {
          [attribute_id]: {
            unit: 'please enter unit',
          },
        };
      }

      break;
    default:
      break;
  }
};
