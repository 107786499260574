import { Modal, Typography, Row, Divider, Col, Button } from 'antd';
import PYTHON_KEY from 'api/pythonService';
import './AddOnSuccess.css';

const { Title, Text } = Typography;
export const AddOnSuccess = ({
  addOnData,
  isModalVisible,
  setIsModalVisible,
}) => {
  const handleDismiss = () => {
    const userData = localStorage.getItem('user');
    const user = userData && JSON.parse(userData);
    const access_token = user?.access_token;
    const payload = {
      id: addOnData?.id,
    };
    PYTHON_KEY.URL.post(PYTHON_KEY.path.dismiss_notification, payload, {
      headers: { 'access-token': `${access_token}` },
    })
      .then((res) => {
        if (res?.status === 200) {
          setIsModalVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal
        width={600}
        visible={isModalVisible}
        closable={false}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        className="notification-addon-modal"
        // bodyStyle={{ padding: '2rem' }}
      >
        <Row align="center">
          <img src={addOnData?.meta?.url} alt="img" />
        </Row>
        <br />
        <br />
        <Row align="center">
          <Title level={5}>{addOnData?.title}</Title>
        </Row>
        <Row align="center" className="notification-addon-antrow">
          <Button
            size="large"
            className="notification-btn-dismiss"
            onClick={handleDismiss}>
            Dismiss
          </Button>
        </Row>
      </Modal>
    </>
  );
};
