import { combineReducers } from 'redux';
import checkedProducts from './checkedProducts';
import manageProducts from './manageProducts';
import documentState from './document';
import referenceImagesState from './referenceImages';
import attributeSetting from './attributeSetting';
import filterSetting from './filterSetting';
import manufacturerSettings from './manufacturerSettings';

export default combineReducers({
  filterSetting,
  checkedProducts,
  manageProducts,
  documentState,
  referenceImagesState,
  attributeSetting,
  manufacturerSettings,
});
