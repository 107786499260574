import { useState } from 'react';
import {
  Modal,
  Typography,
  Row,
  Card,
  Col,
  Button,
  Divider,
  Form,
  Input,
} from 'antd';
import { RequestStatusModal } from './RequestStatusModal';
import { PYTHON_API_KEY } from 'api/customApis';
import style from 'styled-components';
const { Title, Text } = Typography;
const { TextArea } = Input;

const ui_texts = {
  title: 'Request sent successfully',
  text: 'Please wait our customer executive will get in touch with you shortly',
};
const ButtonContainer = style.div`
  .request-status-button-send{
    background: black; 
    color: white;
    border: none;
  }
`;
export const UpgradeRequestModal = ({
  requestPayload,
  upgradeRequestModal,
  setUpgradeRequestModal,
}) => {
  const [requestStatusModal, setRequestStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (requestPayload) {
      setLoading(true);
      const payload = {
        ...requestPayload,
        user_comments: values?.message || '',
        meta: `{"contact_no": ${values?.contact}}`,
      };
      PYTHON_API_KEY.URL.post(PYTHON_API_KEY.path.userUpgradeRequest, payload)
        .then((res) => {
          if (res?.status === 200) {
            setUpgradeRequestModal(false);
            setRequestStatusModal(true);
            setLoading(false);
            form.resetFields();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <Modal
        visible={upgradeRequestModal}
        footer={null}
        closable={false}
        width={620}>
        <Row>
          <Title level={5}>
            {requestPayload?.feature_type === 'addon'
              ? 'Request to buy addon'
              : 'Request to upgrade plan'}
          </Title>
        </Row>
        <Row>
          <Text>
            Share your requirements below and our team will reach out to you
            shortly
          </Text>
        </Row>
        <br />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name={'contact'}
            label={'Contact number'}
            rules={[
              {
                required: true,
                min: 10,
                max: 10,
                message: 'Please enter a valid phone number',
              },
            ]}>
            <Input size="large" type={'number'} />
          </Form.Item>
          <Form.Item name={'message'} label={'Message'}>
            <TextArea rows={5} />
          </Form.Item>
          <Divider />
          <Row justify="space-between">
            <Col>
              <Button
                size="large"
                onClick={() => {
                  form.resetFields();
                  setUpgradeRequestModal(false);
                }}>
                Cancel
              </Button>
            </Col>
            <Col>
              <ButtonContainer>
                <Button
                  size="large"
                  className="request-status-button-send"
                  style={{}}
                  htmlType="submit"
                  loading={loading}>
                  Send request
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
        </Form>
      </Modal>
      <RequestStatusModal
        data={ui_texts}
        requestStatusModal={requestStatusModal}
        setRequestStatusModal={setRequestStatusModal}
      />
    </>
  );
};
