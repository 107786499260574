export const filters = {
  onChangeFilters: (store, payload) => {
    store.setState({ filters: payload });
  },
  setAppliedFilters: (store, payload) => {
    store.setState({ appliedFilters: payload });
  },
  setMoreFilters: (store, payload) => {
    store.setState({ moreFilters: payload });
  },
  setAppliedMoreFilterList: (store, payload) => {
    store.setState({ appliedMoreFilterList: payload });
  },
  setAppliedPrimaryFilterList: (store, payload) => {
    store.setState({ appliedPrimaryFilterList: payload });
  },
};
