export const qrEnable = {
  setQrLabelEnable: (store, payload) => {
    store.setState({ qrEnable: payload });
  },
  setProductIdForQr: (store, payload) => {
    store.setState({ productIdForQr: payload });
  },
  setSelectedTemp: (store, payload) =>
    store.setState({ selectedtemp: payload }),
};
