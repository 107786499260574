import React from 'react';
import { Tabs } from 'antd';
export default function Index({
  defaultActiveKey,
  activeKey,
  animated,
  type,
  children,
  onChange,
  tabBarStyle,
  tabPanes,
  ...props
}) {
  const tabs = tabPanes.map((tab) => (
    <>
      <Tabs.TabPane tab={tab?.tab} key={tab?.key}>
        {tab.content}
      </Tabs.TabPane>
    </>
  ));
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      type={type}
      onChange={onChange}
      activeKey={activeKey}
      animated={animated}
      tabBarStyle={tabBarStyle}
      {...props}>
      {tabs}
    </Tabs>
  );
}
