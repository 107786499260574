import { Button, Checkbox, Col, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react';
import './index.less';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;
function ImportCancelModal({
  setIsCancelImport,
  isCancelImport,
  cancelTask,
  setIsConfirm,
  isConfirm,
  cancelImportTask,
  cancelLoading,
  currentSlug,
}) {
  const intl = useIntl();

  const cancelStatus = () => {
    switch (currentSlug) {
      case 'product_update':
        return intl.formatMessage({
          id: 'sourcewiz.bulkImport.productUpdateCancel',
        });
      case 'data_backup':
        return intl.formatMessage({
          id: 'sourcewiz.bulkImport.dataBackupCancel',
        });
      default:
        return intl.formatMessage({
          id: 'sourcewiz.bulkImport.dataBackupCancel',
        });
    }
  };
  return (
    <Modal
      closeIcon={() => null}
      visible={isCancelImport}
      footer={null}
      onCancel={() => {
        setIsCancelImport(false);
        setIsConfirm(false);
      }}
      centered={true}
      width={'35%'}
      destroyOnClose={true}>
      <Row className="cancel-import">
        <Col span={24}>
          <Row>
            <Title level={4} className="cancel-import_head">
              {intl.formatMessage({
                id: 'sourcewiz.bulkImport.cancelUpdate',
              })}
            </Title>
          </Row>
        </Col>
        <Row className="cancel-import_desc">
          {cancelStatus()}
          <Col span={24} className="cancel-import_check">
            <Row
              justify="start"
              className="cancel-import_check_rw"
              onClick={() => setIsConfirm(!isConfirm)}>
              <Checkbox
                checked={isConfirm}
                value={isConfirm}
                className="cancel-import_check_cnfrm"
                onChange={() => setIsConfirm(!isConfirm)}></Checkbox>
              <Row className="cancel-import_check_txt">
                <Text>
                  {intl.formatMessage({
                    id: 'sourcewiz.bulkImport.confirmCancel',
                  })}
                </Text>
              </Row>
            </Row>
          </Col>
        </Row>
        <div
          className="cancel-import_footer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Button
            className="cancel-import_cancel"
            onClick={() => {
              setIsCancelImport(false);
              setIsConfirm(false);
            }}>
            No
          </Button>

          <Button
            disabled={!isConfirm}
            danger
            loading={cancelLoading}
            className="cancel-import_continue"
            onClick={() => {
              cancelImportTask(cancelTask);
            }}>
            Yes, cancel
          </Button>
          {/* </Col> */}
        </div>
      </Row>
    </Modal>
  );
}

export default ImportCancelModal;
