import { BrowserRouter as Router } from 'react-router-dom';
import { useGlobal } from '../store/index';
import PublicRoutes from './PublicRoutes';
import MainApp from './MainApp';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './ErrorBoundary';
export const history = createBrowserHistory();

export default function SourceApp() {
  const [globalState] = useGlobal();
  const { isloggedIn } = globalState;
  return (
    <Router history={history}>
      <ErrorBoundary>
        {isloggedIn ? <MainApp /> : <PublicRoutes />}
      </ErrorBoundary>
    </Router>
  );
}
