const getSelectionData = (tempData) => {
  const tempSelection = Object.entries(tempData);

  const filteredSelect = tempSelection.filter(([key, value]) => {
    if (value.value === true) {
      return value.value === true;
    }
  });

  return Object.fromEntries(filteredSelect);
};

const getSelectionCount = (tempData) => {
  let selectedCount = 0;

  const tempSelection = Object.entries(tempData);

  tempSelection.filter(([key, value]) => {
    if (value.value === true) {
      selectedCount++;
    }
  });

  return selectedCount;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderImages = (list, startIndex, endIndex, groupId) => {
  return list.map((l) => {
    if (l.id === groupId) {
      const result = Array.from(l.directus_file_ids);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      const _imgNames = Array.from(l.image_names);
      const [removedImage] = _imgNames.splice(startIndex, 1);
      _imgNames.splice(endIndex, 0, removedImage);
      return { ...l, directus_file_ids: result, image_names: _imgNames };
    } else {
      return l;
    }
  });
};

export const moveImages = (
  list,
  sourceId,
  sourceIndex,
  destinationIndex,
  destinationId,
  draggableId,
  sourceGroup
) => {
  return list.map((l) => {
    if (l.id === sourceId) {
      const result = Array.from(l.directus_file_ids);
      result.splice(sourceIndex, 1);
      const resultImg = Array.from(l.image_names);
      resultImg.splice(sourceIndex, 1);
      return { ...l, directus_file_ids: result, image_names: resultImg };
    }
    if (l.id === destinationId) {
      const _directus = [
        ...l.directus_file_ids.slice(0, destinationIndex),
        draggableId,
        ...l.directus_file_ids.slice(destinationIndex),
      ];
      const _imgNames = [
        ...l.image_names.slice(0, destinationIndex),
        sourceGroup.image_names[sourceIndex],
        ...l.image_names.slice(destinationIndex),
      ];
      return { ...l, directus_file_ids: _directus, image_names: _imgNames };
    } else {
      return l;
    }
  });
};

const getIds = (data) => {
  let ids = [];
  data.forEach((d) => {
    ids.push(d.id);
  });
  return ids;
};

const getNames = (data) => {
  let names = [];
  data.forEach((d) => {
    names.push(d.name);
  });
  return names;
};

export const mapGroups = (groups) => {
  if (!Array.isArray(groups) || groups?.length === 0) {
    return [];
  }
  const _tempGroups = JSON.parse(JSON.stringify(groups));
  const _temp = _tempGroups.map((group, index) => {
    const _tempId = Object.keys(group)?.[0];
    return {
      id: group?.id ? group.id : _tempId,
      directus_file_ids: group.directus_file_ids
        ? group.directus_file_ids
        : getIds(group[_tempId]?.data),
      image_names: group.image_names
        ? group.image_names
        : getNames(group[_tempId]?.data),
      groupName: group.groupName ? group.groupName : group[_tempId]?.group_name,
      order: index + 1,
      expanded: false,
    };
  });

  return _temp;
};

export default {
  getSelectionData,
  getSelectionCount,
  reorder,
};
