import { Select } from 'antd';
import qs from 'qs';
import {
  EMPTY_KEYWORD_SRV,
  EMPTY_KEYWORD_APPLY,
  SUB_CATEGORY_LABEL,
  SUB_CATEGORY_ID,
} from 'constants/filters';

export const getAccessToken = () => {
  const userData = localStorage.getItem('user');
  const user = userData && JSON.parse(userData);
  return user?.access_token;
};

export const createAlgoliaURL = (state) => `?${qs.stringify(state)}`;

export const urlToSearchState = (location) =>
  qs.parse(location.search.slice(1));

export const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createAlgoliaURL(searchState)}` : '';

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
}

export const transformItems = (items) => {
  let i = -1;
  items = items.map((item) => {
    if (item?.label === EMPTY_KEYWORD_SRV) {
      return {
        ...item,
        label: toTitleCase(EMPTY_KEYWORD_APPLY),
        label_to_sort: '-10000000',
      };
    } else {
      i = i + 1;
      return {
        ...item,
        label: toTitleCase(item?.label),
        label_to_sort: item?.label,
      };
    }
  });
  return items.sort((a, b) => {
    const labelA = a?.label_to_sort;
    const labelB = b?.label_to_sort;
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
};

export function cx(...classNames) {
  return classNames.filter(Boolean).join(' ');
}

export function getCurrentUser() {
  var org = localStorage.getItem('org');
  var userId = localStorage.getItem('userId');
  var isAdmin = localStorage.getItem('isAdmin');
  const orgId = JSON.parse(org)?.id;
  return { userId: userId, isAdmin: isAdmin, orgId: orgId };
}

export function compareByPriorityHigh(a, b) {
  if (a.priority < b.priority) {
    return 1;
  }
  if (a.priority > b.priority) {
    return -1;
  }
  return 0;
}

export function compareByPriorityLow(a, b) {
  if (a.priority < b.priority) {
    return -1;
  }
  if (a.priority > b.priority) {
    return 1;
  }
  return 0;
}

export const preventMinus = (e) => {
  if (
    e.code === 'Minus' ||
    e.code.toString() === '109' ||
    e.code === 'NumpadSubtract' ||
    e.code === 'NumpadAdd' ||
    (e.shiftKey && e.code === 'Equal')
  ) {
    e.preventDefault();
  }
};

export const preventDecimal = (e) => {
  if (e.key === '.' || e.charCode === 46) {
    e.preventDefault();
  }
};

export const parsePreciseNumericValue = (defaults = {}, value) => {
  const { precision } = defaults;
  const shouldParse = precision && value && !isNaN(value);
  const parsedValue = shouldParse
    ? parseFloat(value).toFixed(precision)
    : value;
  return parsedValue;
};

export const filterAttribute = (val) => {
  let type = 'dtype';
  if (!('dtype' in val)) {
    type = 'type';
  }
  return (
    val[type] !== 'packaging' &&
    val[type] !== 'packaging_items' &&
    val[type] !== 'loadability' &&
    val[type] !== 'currency_secondary_unit' &&
    val[type] !== 'uom_secondary_unit' &&
    val[type] !== 'calculated_price'
  );
};

export const generateUOMOptions = (uomData) => {
  var unitOptions = [];
  for (const key in uomData) {
    if (key !== 'Quality') {
      const tempUnitOption = (
        <Select.OptGroup key={key} label={key}>
          {uomData[key].map((val) => (
            <Select.Option value={`${val.id}--${val.short_name}`} key={val.id}>
              per {val.short_name}
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
      unitOptions.push(tempUnitOption);
    }
  }
  return unitOptions;
};

export const getFilterItemLabel = (item) => {
  const suffix = EMPTY_KEYWORD_APPLY;
  if (item?.id === SUB_CATEGORY_ID) {
    return `${SUB_CATEGORY_LABEL} ${suffix}`;
  }
  const label =
    item.label
      .replace('transformedAttributes.', '')
      .replace('computedAttributes.', '')
      .replace(':', '') +
    ' ' +
    suffix;
  return label;
};
