import { portCodes } from './portCodes';

const defaultCurrency = 'USD';
const defaultUnit = 'per-piece';
const defaultLocaleStringIN = 'INR';

const DOCUMENT_GENERATE = 'Generate';

const defaultFilter =
  'NOT status:archived AND NOT status:draft  AND type:product AND NOT type:material AND NOT type:process';
const portOptions = portCodes;

const DOCUMENT_CONSTANTS = {
  defaultLocaleStringIN,
  defaultCurrency,
  defaultUnit,
  portOptions,
  defaultFilter,
  DOCUMENT_GENERATE,
};

export default DOCUMENT_CONSTANTS;
