export const userSetAction = {
  updateUserInfo: async (store, payload) => {
    store.setState({ userInfo: payload });
  },
};

export const showDrawerAction = {
  setShowDrawer: async (store, payload) => {
    store.setState({ showDrawer: payload });
  },
};

export const showModalAction = {
  setShowModal: async (store, payload) => {
    store.setState({ showModal: payload });
  },
};

export const milestoneInfoAction = {
  setMilestoneInfo: async (store, payload) => {
    store.setState({
      milestoneInfo: payload,
    });
  },
  setRewardsData: async (store, payload) => {
    store.setState({ rewardsData: payload });
  },
};
