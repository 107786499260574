import style from 'styled-components';

export const PlanDiv = style.div`
	width: ${(props) => props.width || '100%'};
	margin: ${(props) => !props.width && 'auto'};
	margin-top: 1rem;
	padding: 1.7rem;
	border-radius: 8px;
	background: linear-gradient(90.61deg, rgb(230, 223, 251, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
	mix-blend-mode: normal;
`;
export const Subdiv = style.div`
	flexDirection: "row";
	border: 1px solid #E6E7E7;
	box-sizing: border-box;
	margin : 1rem 0 1rem 0;
	padding: ${(props) => props.padding || '1.3rem'};
    width: ${(props) => props.width || '70%'};
	opacity: 1;
  	mix-blend-mode: normal;
  	border-radius: 0.5rem;
	background: ${(props) => props.background && props.background}
`;

export const ReminderBannerDiv = style.div`
	width: ${(props) => props.width};
	display: ${(props) => props.display};
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: ${(props) => props.padding || '1.7rem'};
	border-radius: 0.5rem;
	background: linear-gradient(269.39deg, rgb(230, 223, 251, 0.3) 3.37%, rgb(255, 217, 166, 0.3) 121.27%);
	mix-blend-mode: normal;
`;
