import React, { useRef, useState } from 'react';
import { Button, Form, Input, Select, Skeleton, message } from 'antd';
import { AiFillTags } from 'react-icons/ai';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const ICON_STYLE = {
  fontSize: 24,
  padding: 1,
  background: '#f5f5f5',
  borderRadius: 4,
  color: ' #9B9F9E',
  marginRight: 10,
};
const ICON_CONTAINER = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
};

function isEmpty(val) {
  if (val != null) {
    return val.trim().length === 0;
  } else {
    return val === undefined || val === null || val.length <= 0 ? true : false;
  }
}

const capitalize = (name) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export default function SelectAdd({ field, rest, addId }) {
  const [showAddOption, setShowAddOption] = useState(false);
  const [newOptionBottom, setNewOptionBottom] = useState(null);
  const [newOption, setNewOption] = useState(null);
  const [showAddNewForm, setShowAddNewForm] = useState(true);
  const [onLoadOptionCreate, setOnLoadOptionCreate] = useState(false);
  const selectRef = useRef();
  const displayAdd = newOption ? 'block' : 'none';

  const AddNewOption = () => {
    setShowAddOption(true);
  };

  const onSuccessfulNewAdd = () => {
    message.success(`${field.label} added.`);
    setShowAddOption(false);
    setOnLoadOptionCreate(false);
    selectRef?.current?.blur();
  };
  const handleDone = (newOptionValue) => {
    setOnLoadOptionCreate(true);
    if (!newOptionValue || isEmpty(newOptionValue)) {
      message.warning(`Please enter a ${field.label.toLowerCase()}`);
      setOnLoadOptionCreate(false);
    } else {
      let elementIndex = -1;
      if (field.options) {
        elementIndex = field.options?.findIndex(
          (option) =>
            option.label.toLowerCase() === newOptionValue.toLowerCase()
        );
      }
      if (elementIndex === -1) {
        if (field.createNewOption) {
          field.createNewOption(capitalize(newOptionValue), onSuccessfulNewAdd);
        } else {
          message.warning('No method exist for adding new option');
          setOnLoadOptionCreate(false);
        }
      } else {
        message.warning(`Please enter a ${field.label.toLowerCase()}`);
        setOnLoadOptionCreate(false);
      }
    }
  };
  const removeAddOption = () => {
    setShowAddOption(false);
  };

  const onNewOptionInputChange = (event) => {
    setNewOptionBottom(event.target.value);
  };
  let idString = '';
  if (addId) {
    idString = `:${field.id ? field.id : 0}`;
  }
  return (
    <Form.Item
      label={field.label}
      name={field.name + idString}
      rules={field.rules ? field.rules : []}>
      {field.options ? (
        <Select
          showSearch
          disabled={field.disabled ? true : false}
          optionFilterProp="children"
          ref={selectRef}
          onSearch={(value) => {
            const foundItems = field.options?.filter((option) => {
              return option.label.toLowerCase().includes(value.toLowerCase());
            });
            if (foundItems.length > 0) {
              setShowAddNewForm(true);
            } else {
              setShowAddNewForm(false);
            }
          }}
          filterOption={(input, option) => {
            setNewOption(input);

            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          filterSort={(optionA, optionB) => {
            if (optionA.children) {
              return optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase());
            } else if (!optionA.label) {
              return false;
            } else {
              return optionA.label
                .toLowerCase()
                .localeCompare(optionB.label.toLowerCase());
            }
          }}
          placeholder={
            field.placeholder ? (
              field.placeholder
            ) : (
              <span style={ICON_CONTAINER}>
                <AiFillTags style={ICON_STYLE} />
                Select Category
              </span>
            )
          }
          size="large"
          notFoundContent={
            <Button
              loading={onLoadOptionCreate}
              onClick={() => handleDone(newOption)}
              style={{
                cursor: 'pointer',
                color: '#0B7764',
                display: displayAdd,
                fontWeight: 'bold',
                border: 'none',
                padding: 0,
                margin: 0,
              }}>
              Add {newOption}
            </Button>
          }
          dropdownRender={(menu) => (
            <div>
              {menu}
              {!showAddOption && showAddNewForm && (
                <span
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer',
                    color: '#0B7764',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                  onClick={AddNewOption}>
                  {field.addNewLabel ? field.AddNewLabel : 'Add a Category'}
                </span>
              )}

              {showAddOption ? (
                <div
                  style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                  <Input
                    style={{ width: '18em' }}
                    value={newOptionBottom}
                    onChange={onNewOptionInputChange}
                  />
                  <Button
                    style={{
                      flex: 'none',
                      padding: '8px',
                      display: 'block',
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    loading={onLoadOptionCreate}
                    onClick={() => {
                      handleDone(newOptionBottom);
                    }}>
                    Done
                    <CheckCircleFilled
                      style={{ color: '#0B7764', fontSize: '18px' }}
                    />
                  </Button>

                  <span
                    style={{
                      flex: 'none',
                      padding: '8px',
                      display: 'block',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      removeAddOption();
                    }}>
                    Cancel
                    <CloseCircleFilled
                      style={{ fontSize: '18px', color: '#9B9F9E' }}
                    />
                  </span>
                </div>
              ) : null}
            </div>
          )}>
          {field.options?.map((option, index) => (
            <Select.Option key={`option_${index}`} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Skeleton.Input active />
      )}
    </Form.Item>
  );
}
