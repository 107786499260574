import axios from 'axios';

const API_KEY = {
  URL: axios.create({
    baseURL: 'https://brec.sourcerer.tech/',
  }),
  path: {
    recommend: 'recommend',
    buyer: 'buyer',
    getAllCountries: 'recommend/countries',
    getByCountry: 'search-by-country',
    getByProduct: 'search-by-product',
    contact: 'contacts/contacts',
  },
};

export default API_KEY;
