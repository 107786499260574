import React from 'react';
import { Row, Col, Typography } from 'antd';
import Logo from '../../assets/images/logo64.png';
import { Colors } from '../../theme';
import ResetLinkSentSVG from './ResetLinkSent.svg';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

export default function ResetLinkSent() {
  const params = useParams();
  return (
    <div
      style={{
        height: '100vh',
        background: '#E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Row
        style={{
          // display: "flex",
          // flexDirection: "row",
          // justifyContent: "center",
          // alignItems: "center",
          //  height: "75vh",
          width: '80%',
          background: 'white',
          padding: '4rem',
          borderRadius: 12,
        }}>
        <Col span={24}>
          <Row align="middle">
            <img src={Logo} alt="sourcewiz" width="40px" height="40px" />
            <span
              style={{
                fontSize: '32px',
                // fontWeight: "bolder",
                color: Colors.black,
                fontFamily: 'SatoshiBold',
                // marginBottom:'2rem'
                marginLeft: '0.5rem',
              }}>
              Sourcewiz
            </span>
          </Row>
          <Row justify="center" style={{ marginTop: '3rem' }}>
            <img src={ResetLinkSentSVG} alt="ResetLinkSentSVG" width="50%" />
          </Row>
          <Row justify="center">
            <Col>
              <Title level={4}>
                A link to reset your password has been shared with you at{' '}
                {params?.email}
              </Title>
            </Col>
          </Row>

          <Row justify="center" style={{ marginTop: '8rem' }}>
            <Col>
              <Text type="secondary">
                If you are still facing issues to reset your password reach out
                to{' '}
                <span style={{ color: Colors.primaryColor }}>
                  support@sourcewiz.co
                </span>
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
