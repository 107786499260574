import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Checkbox,
  Form,
  Upload,
} from 'antd';
import { useIntl } from 'react-intl';

const { Title, Text } = Typography;

export default function ConfirmPopup({
  setShowConfirmModal,
  showConfirmModal,
  products,
  handleNext,
  continueLoading,
  forwardText,
  title,
  content,
  confirmText,
}) {
  const intl = useIntl();
  const [checked, setChecked] = useState(false);

  // Reset 'checked' state everytime modal opens.
  useEffect(() => {
    if (showConfirmModal) {
      setChecked(false);
    }
  }, [showConfirmModal]);

  const renderButton = () => {
    return (
      <Row style={{ marginTop: '1.5em' }}>
        <Col span={16}>
          <Button
            type="ghost"
            size="large"
            onClick={() => {
              setShowConfirmModal(false);
            }}>
            {/* Cancel */}
            {intl.formatMessage({ id: 'quotations.cancel' })}
          </Button>
        </Col>
        <Col span={6}>
          <Button
            primary
            size="large"
            style={{
              width: '200px',
              background: checked ? '#1D2624' : 'gray',
              border: checked ? '1px solid #1D2624' : 'none',
              color: 'white',
            }}
            disabled={!checked}
            loading={continueLoading}
            onClick={handleNext}>
            {forwardText}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Modal
        visible={showConfirmModal}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        closable={false}
        closeIcon={null}
        footer={null}
        centered
        width={'48em'}
        bodyStyle={{ padding: '2em' }}
        destroyOnClose={true}>
        <div>
          <Row>
            <Col span={24}>
              <Title level={4}>{title}</Title>
              <Text>{content}</Text>
            </Col>
            <Divider style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
          </Row>
          <Col
            style={{
              background: '#F5F5F5',
              borderRadius: '4px',
              padding: '1em',
              marginTop: '1em',
              maxHeight: '9em',
            }}>
            <Row style={{ paddingLeft: '1.5em' }}>{confirmText}</Row>
            <Row style={{ paddingTop: '1em', paddingLeft: '1.5em' }}>
              <Form.Item
                name={'CHECK'}
                style={{ fontSize: 14, width: '100%' }}
                rules={[
                  {
                    required: true,
                    // message: "Please Confirm",
                    message: intl.formatMessage({
                      id: 'sourcewiz.button.pleaseConfirm',
                    }),
                  },
                ]}>
                <Row>
                  <Col>
                    <Checkbox
                      onChange={() => {
                        setChecked((state) => !state);
                      }}
                      checked={checked}
                    />
                  </Col>
                  <Col>
                    <Text
                      onClick={() => {
                        setChecked((state) => !state);
                      }}
                      style={{
                        paddingLeft: '0.4em',
                        position: 'relative',
                        padding: '5px',
                        top: '5px',
                        cursor: 'pointer',
                      }}>
                      {intl.formatMessage({
                        id: 'sourcewiz.discardModal.checkboxText',
                      })}
                    </Text>
                  </Col>
                </Row>
              </Form.Item>
            </Row>
          </Col>

          <Divider style={{ margin: '1em' }} />

          {renderButton()}
        </div>
      </Modal>
    </div>
  );
}
