export const EMPTY_KEYWORD_SRV = 'EMPTY_KEYWORD';
export const EMPTY_KEYWORD_APPLY = 'is Blank';
export const PREFERRED_ATTRIBUTES = ['Size', 'Theme', 'Color', 'Finish'];
export const TASKS_FILTERS = [
  'ppt_download',
  'excel_export_bulk_update',
  'bulk_upload_image_template_download',
  'catalogue_pdf',
];
export const BULK_TASKS = [
  'excel_import_bulk_update',
  'excel_restore_products',
];

export const RESTORE_TASK = ['excel_restore_products'];
export const ACTIVITY_BULK_TASK = ['excel_import_bulk_update'];
export const EXCEL_TASKS = [
  'excel_import_bulk_update',
  'excel_export_bulk_update',
  'excel_restore_products',
];
export const NOTIFICATION_TYPE_FILE_DOWNLOAD = [
  'ppt_download',
  'excel_export_bulk_update',
  'bulk_products_upload',
  'catalogue_pdf',
];
export const CUSTOM_NOTIFICATION_TYPES = [
  'excel_import_bulk_update',
  'bulk_products_upload',
];
export const RESTORE_NOTIFICATIONS = ['excel_restore_products'];

export const DATE_OPTIONS = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'This Week',
    value: 'this_week',
  },
  {
    label: 'Last Week',
    value: 'last_week',
  },
  {
    label: 'This Month',
    value: 'this_month',
  },
  {
    label: 'Last Month',
    value: 'last_month',
  },
  {
    label: 'Last 3 Months',
    value: 'last_3_months',
  },
  {
    label: 'Last 6 Months',
    value: 'last_6_months',
  },
];

export const DEFAULT_FILTER =
  'NOT status:archived AND NOT status:draft  AND type:product AND NOT type:material AND NOT type:process';

export const DEFAULT_SORT =
  process?.env?.REACT_APP_ENV === 'prod' ? 'products_id_desc' : 'products';

export const SORT_OPTIONS = [
  {
    value: 'products',
    label: 'Best match',
  },
  {
    value: 'products_desc',
    label: 'Recent first',
  },
  {
    value: 'products_asc',
    label: 'Oldest first',
  },
  {
    value: 'products_id_asc',
    label: 'Product ID Asc',
  },
  {
    value: 'products_id_desc',
    label: 'Product ID Desc',
  },
];

export const FILTER_OPERATORS = {
  betweenSameFilterType: 'OR',
  betweenDiffFilterType: 'AND',
};

export const INDEX_PROVIDER = {
  smart_search: 'elastic',
  default: 'algolia',
};

export const SUB_CATEGORY_ID = 'sub_category';
export const SUB_CATEGORY_LABEL = 'Sub Category';
