import React from 'react';
import { Row, Col, Upload, Typography, Button } from 'antd';
import './index.less';
const { Text } = Typography;
function ImportCard({
  importSocketData,
  uploadExcel,
  loading,
  Importbulkexcel,
  viewDetails,
}) {
  let importTasks = Object.values(importSocketData)?.filter((item) =>
    item?.action?.includes('excel_import_bulk_update')
  );
  importTasks = importTasks[importTasks?.length - 1];

  return (
    <>
      <Row className="import-excel-wrap" justify="space-between" align="center">
        <Col span={2} className="cardIcon">
          <img
            src={Importbulkexcel}
            className="import-excel-wrap_img"
            alt="importExcel"
          />
        </Col>
        <Col span={2} />
        <Col className="import-excel-wrap_import-text-area" span={20}>
          <Row justify="start">
            <Col>
              <Row>
                <Text className="import-excel-wrap_import-text-area_head">
                  Import Excel
                </Text>
              </Row>
            </Col>
            {importTasks?.status && importTasks?.status !== 'success' && (
              <Col>
                <Row>
                  <Text className="import-excel-wrap_status-in-progress">
                    IN PROGRESS
                  </Text>
                </Row>
              </Col>
            )}
          </Row>

          <Row>
            <Text className="import-excel-wrap_import-text-area_sub">
              {importTasks?.status && importTasks?.status !== 'success'
                ? 'Import blocked. One import task in progress.'
                : 'Upload excel sheet to update product data'}
            </Text>
          </Row>
          {importTasks?.status && importTasks?.status !== 'success' ? (
            <Button
              onClick={() => {
                viewDetails(importTasks);
              }}
              className="import-excel-wrap_view-details">
              View details
            </Button>
          ) : (
            <Upload
              name="file"
              showUploadList={false}
              customRequest={uploadExcel}
              onClick={() => {}}
              accept=".xlsx">
              <Button
                htmlFor="file-input"
                loading={loading}
                className="import-excel-wrap_upload">
                {loading ? 'Uploading' : 'Upload'}
              </Button>
            </Upload>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ImportCard;
