import axios from 'axios';

const OPEN_AUTH = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
  }),
  path: {
    country_codes: `auth/country_codes/`,
  },
};

export const getOpenAuthCountryCodes = () => {
  return OPEN_AUTH.URL.get(OPEN_AUTH.path.country_codes);
};
