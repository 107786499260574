import PYTHON_KEY from './pythonService';
import API_KEY from 'api';

const user = localStorage.getItem('user');
const token = JSON.parse(user)?.access_token;
const orgDetails = JSON.parse(localStorage.getItem('org'));

export const updateCollection = (collection_id, payload) => {
  return PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}items/v2/collections/add_products?collections_id=${collection_id}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const copyCollection = (payload) => {
  return PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}items/v2/collections/copy`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const fetchCollectionTable = (id) => {
  return PYTHON_KEY.URL.get(
    `${process.env.REACT_APP_API_SERVICE}items/v2/table/collection_products/`,
    {
      params: {
        collection_id: id,
        access_token: token,
      },
    }
    // {
    //   headers: { 'access-token': `${token}` },// TODO: token for this api is sent as param. Refactor to send in header
    // }
  );
};

export const removeProductFromCatalogue = (payload) => {
  return PYTHON_KEY.URL.delete(
    `${process.env.REACT_APP_API_SERVICE}items/collections/delete_product`,
    {
      headers: { 'access-token': `${token}` },
      data: payload,
    }
  );
};

export const updateCatalogueTable = (payload) => {
  return PYTHON_KEY.URL.patch(`${PYTHON_KEY.path.bulkUpdate}/`, payload, {
    headers: { 'access-token': `${token}` },
  });
};

export const getCatalogueTableCategory = () => {
  return API_KEY.URL.get(`${API_KEY.path.manufacturer_secondary_category}`, {
    params: {
      fields: 'secondary_category_id.*',
      limit: -1,
    },
  });
};

export const updateCollections = (id, data) => {
  return PYTHON_KEY.URL.patch(
    `${process.env.REACT_APP_API_SERVICE}items/collections/${id}`,
    data,
    {
      headers: { 'access-token': `${token}` },
    }
    // {
    // 	headers: { Authorization: `Bearer ${token}` },
    // }
  );
};

export const downloadBulkExcel = (payload) => {
  return PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}bulk/export/excel/download`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const downloadBulkUploadExcel = async (
  payload,
  setIsBulkUploadExcelDownloading
) => {
  await PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}bulk/upload/template/download`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  )
    .then((res) => {
      window.open(res?.data?.data?.download_link);
      setIsBulkUploadExcelDownloading(false);
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getTemplateList = () => {
  return PYTHON_KEY.URL.get(
    `${process.env.REACT_APP_API_SERVICE}file/templates/excel/all`,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const createNewTemplate = (payload) => {
  return PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}file/templates`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const updateTemplate = (id, payload) => {
  return PYTHON_KEY.URL.patch(
    `${process.env.REACT_APP_API_SERVICE}file/templates/${id}`,
    payload,
    {
      headers: { 'access-token': `${token}` },
    }
  );
};

export const getCataloguePDF = (payload) => {
  return PYTHON_KEY.URL.post(
    `${PYTHON_KEY.path.cataloguePDF}?manufacturer_id=${orgDetails?.id}`,
    payload
  );
};
