import { useRef, useEffect, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Progress, message, Badge, Popover } from 'antd';
import styled from 'styled-components';
import { MdError, MdCheckCircle } from 'react-icons/md';

import { parseJson } from 'utils/commonFunctions';
import Actions from 'redux/actions/manageProductsActions';
import ActivityCard, { DownloadsActivityCard } from './ActivityCard';
import bulkActionApis from 'api/bulkActionApis';
import { fetchCachedData } from 'api/utilApis';
import moment from 'moment';
import { useGlobal } from 'store/index';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { TASK_HISTORY } from 'analytics/events';
import { Tabs } from 'antd';
import DownloadNotification from '../downloadNotificationModal';
import { TASKS_FILTERS } from 'constants/filters';
import * as equal from 'deep-equal';
import { subscribe } from 'utils/notificationUtils';
import Selectors from 'routes/SourceWiz/ManageProducts/selectors';
import { ChannelScope, ChannelType, EventType } from 'constants/notifications';
const { TabPane } = Tabs;

const NotifierButtonStyle = styled.div`
  .notify-btn-container {
    display: flex;
    align-items: center;
    border-radius: 74px;
    line-height: normal;
    font-size: 0.9rem;
    gap: 0.5em;
    padding: 0.3em 0.9em 0.3em 0.5em;
    cursor: pointer;
    margin-left: 1rem;

    .notifier-text {
      font-weight: 600;
    }
    .text-progress {
      color: #505756;
    }
  }
  .notify-error {
    background: linear-gradient(
      269.07deg,
      #e5e5e5 -1.25%,
      rgba(255, 234, 234, 0.65) 109.51%
    );
  }

  .notify-pending {
    background: linear-gradient(
      269.07deg,
      #e8f0fc -1.25%,
      rgba(201, 228, 223, 0.48) 109.51%
    );
    .ant-progress {
      line-height: 0;

      .ant-progress-circle-path {
        stroke-linecap: square;
      }
    }
  }
  .notify-completed {
    background: linear-gradient(91.86deg, #f1ecff 3.73%, #f1f7ff 70.53%);
  }
`;

const getSortWeight = (task) => {
  switch (task?.status) {
    case 'failed':
      return 1;
    case 'pending':
      return 2;
    case 'draft':
      return 3;
    case 'success':
      return 4;
    default:
      return 5;
  }
};

const sortTaskFun = (task1, task2) => {
  return (
    getSortWeight(task1) - getSortWeight(task2) ||
    moment(task2?.created_at) - moment(task1?.created_at)
  );
};

export const DropdownMenu = ({ data, totalRemainingItems, totalItems }) => {
  const pptTasksData = data?.filter((item) => item?.action === 'ppt_download');
  var pptTaskObj = {};

  pptTasksData?.map((e, i) => {
    pptTaskObj[e?.id] = false;
  });

  const getData = () => {
    return (
      <div
        style={{
          background: 'white',
          padding: '10px',
          width: '500px',
          'border-radius': '8px',
          'box-shadow': '0px 4px 34px rgba(0, 0, 0, 0.12)',
        }}>
        <Tabs defaultActiveKey="tasks">
          <TabPane tab="Tasks" key="tasks">
            {data
              ?.filter((item) => !TASKS_FILTERS.includes(item?.action))
              .slice(0, 5)
              .map((item) => {
                return <ActivityCard key={item.task_id} data={item} />;
              })}
          </TabPane>
          <TabPane tab="Downloads" key="downloads">
            {data
              ?.filter((item) => TASKS_FILTERS.includes(item?.action))
              ?.slice(0, 5)
              .map((item) => {
                return (
                  <DownloadsActivityCard
                    key={item.task_id}
                    data={item}
                    // notificationData={notificationData}
                    // pptTasksData={pptTaskObj}
                    // setSeconds={setSeconds}
                    // seconds={seconds}
                    setDone={true}
                  />
                );
              })}
          </TabPane>
        </Tabs>
      </div>
    );
  };

  // const notificationData = () => {

  //   return (
  //     <DownloadNotification fileType='ppt'
  //         user="vishal@sourcewiz.co"
  //         catalogueName="test"
  //         showDownload={true}
  //         ></DownloadNotification>
  //   )
  // }

  // const contentData = () => {
  //   return (

  //     <Popover content={getData()}>

  //     </Popover>
  //     // <StyledDropdownMenu>
  //     //   <Tabs defaultActiveKey="tasks"  >
  //     //   {data?.filter((item) => item?.action !== 'ppt_download') && (
  //     //     <TabPane tab="Tasks" key="tasks">
  //     //       {/* <RecentCard /> */}
  //     //       {totalRemainingItems > 0 && (
  //     //         <div className="top-progress">
  //     //           <div className="progress-text">
  //     //             {totalItems - totalRemainingItems}/{totalItems}
  //     //           </div>
  //     //           <Progress
  //     //             percent={(1 - totalRemainingItems / totalItems) * 100}
  //     //             showInfo={false}
  //     //             strokeColor={'#9AC421'}
  //     //             strokeWidth={10}
  //     //           />
  //     //         </div>
  //     //       )}
  //     //       {data
  //     //         ?.filter((item) => item?.action !== 'ppt_download')
  //     //         .slice(0, 5)
  //     //         .map((item) => {
  //     //           return <ActivityCard key={item.task_id} data={item} />;
  //     //         })}
  //     //     </TabPane>
  //     //   )}

  //     //   <TabPane tab="Downloads" key="downloads" style={{ width: '350px' }}>
  //     //     {data
  //     //       ?.filter((item) => item?.action === 'ppt_download')
  //     //       ?.slice(0, 5)
  //     //       .map((item) => {
  //     //         console.log(item);
  //     //         return <DownloadsActivityCard key={item.task_id} data={item} setSeconds={setSeconds} seconds={seconds} setDone={true} />;
  //     //       })}

  //     //     {/* <Row justify="space-between">
  //     //                 <Col>
  //     //                   yes
  //     //                 </Col>
  //     //                  no
  //     //                 <Col>
  //     //                 </Col>

  //     //               </Row>
  //     //               <Row>
  //     //               <Progress
  //     //                     percent={seconds}
  //     //                     showInfo={false}
  //     //                     strokeColor={"#9AC421"}
  //     //                     strokeWidth={10}
  //     //                   />
  //     //               </Row> */}
  //     //   </TabPane>
  //     // </Tabs>
  //     // </StyledDropdownMenu>
  //   )

  // }

  return (
    <Popover placement="bottomLeft" trigger="click">
      {getData()}
    </Popover>
  );
};

const NotifierButton = ({
  totalRemainingItems,
  totalItems,
  pendingTasks,
  failedTasks,
  completedTasks,
  draftTasks,
}) => {
  const location = useLocation();
  const { trackEvent } = useTracking();
  if (failedTasks?.length) {
    return (
      <div
        className="notify-btn-container notify-error"
        onClick={() =>
          trackEvent({
            name: TASK_HISTORY,
            meta: {
              location: location.pathname,
              modules: 'Overall',
              property: 'Overall',
            },
          })
        }>
        <MdError size={20} color={'#F34D4D'} />
        <div className="notifier-text">
          {failedTasks.length}{' '}
          {failedTasks.length > 1 ? (
            <FormattedMessage id={'sourcewiz.taskButton.tasksFailed'} />
          ) : (
            <FormattedMessage id={'sourcewiz.taskButton.failed'} />
          )}
        </div>
      </div>
    );
  }

  if (pendingTasks?.length) {
    return (
      <div
        className="notify-btn-container notify-pending"
        onClick={() =>
          trackEvent({
            name: TASK_HISTORY,
            meta: {
              location: location.pathname,
              modules: 'Overall',
              property: 'Overall',
            },
          })
        }>
        <Progress
          type="circle"
          percent={(1 - totalRemainingItems / totalItems) * 100}
          width={20}
          strokeWidth={22}
          showInfo={false}
          strokeColor={'#14453C'}
          trailColor={'#7DB8AD'}
        />
        <div className="notifier-text">
          {pendingTasks.length}{' '}
          {draftTasks.length > 1 ? (
            <FormattedMessage id={'sourcewiz.taskButton.tasksInProgress'} />
          ) : (
            <FormattedMessage id={'sourcewiz.taskButton.taskInProgress'} />
          )}
        </div>
      </div>
    );
  }
  if (draftTasks?.length) {
    return (
      <Badge size="default" count={draftTasks.length}>
        <div
          className="notify-btn-container notify-pending"
          onClick={() =>
            trackEvent({
              name: TASK_HISTORY,
              meta: {
                location: location.pathname,
                modules: 'Overall',
                property: 'Overall',
              },
            })
          }>
          <Progress
            type="circle"
            percent={
              (1 -
                draftTasks.length /
                  (draftTasks.length + completedTasks.length)) *
              100
            }
            width={20}
            strokeWidth={22}
            showInfo={false}
            strokeColor={'#14453C'}
            trailColor={'#7DB8AD'}
          />
          <div className="notifier-text">
            {draftTasks.length > 1 ? (
              <FormattedMessage id={'sourcewiz.taskButton.tasksInProgress'} />
            ) : (
              <FormattedMessage id={'sourcewiz.taskButton.taskInProgress'} />
            )}
          </div>
        </div>
      </Badge>
    );
  }

  if (completedTasks?.length) {
    return (
      <div
        className="notify-btn-container notify-completed"
        onClick={() =>
          trackEvent({
            name: TASK_HISTORY,
            meta: {
              location: location.pathname,
              modules: 'Overall',
              property: 'Overall',
            },
          })
        }>
        <MdCheckCircle size={20} color={'#363E3D'} />
        <div className="notifier-text">
          <FormattedMessage id={'sourcewiz.taskButton.taskHistory'} />
        </div>
      </div>
    );
  }

  return <></>;
};

const HeaderActivity = ({ visible, setVisible }) => {
  const ws = useRef(null);
  const [taskProgress, setTaskProgress] = useState([]);
  const [wsIsOpen, setWsIsOpen] = useState(false);
  const location = useLocation();
  const [globalState, globalActions] = useGlobal();
  const [isMessage, setIsMessage] = useState({});
  const [newTask, setNewTask] = useState(null);
  const [deletedTask, setDeletedTask] = useState(null);
  let interval = null;
  const {
    isImportSocketEqual,
    isUploadOpen,
    isImportExportDrawerActive,
    importSocketData,
    viewSocketData,
  } = globalState;
  const dispatch = useDispatch();

  const actions = bindActionCreators(
    {
      bulkActionOnMessage: Actions.bulkActionOnMessage,
    },
    dispatch
  );
  const allTasks = useSelector((state) => Selectors.selectAllTasks()(state));

  // const connectWebsocket = () => {
  //   const userId = parseJson(localStorage.getItem('userId'));
  //   const mID = parseJson(localStorage.getItem('org'))?.id;
  //   ws.current = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}/${mID}/ws`);
  //   ws.current.onopen = () => {
  //     setWsIsOpen(true);
  //   };
  //   ws.current.onclose = () => {
  //     setWsIsOpen(false);
  //     interval = setInterval(function () {
  //       console.log('checking ws');
  //       connectWebsocket();
  //     }, 5000);
  //   };
  //   ws.current.onmessage = onMessage;

  //   const wsCurrent = ws.current;

  //   return () => {
  //     wsCurrent.close();
  //   };
  // };

  // useEffect(() => {
  //   connectWebsocket();
  // }, []);

  // useEffect(() => {
  //   if (wsIsOpen && interval) {
  //     clearInterval(interval);
  //   }
  // }, [wsIsOpen]);
  const is_system_user = localStorage.getItem('is_system_user');

  const processAfterFetchingTask = (data) => {
    const filterUserTasks = data.filter((item) =>
      is_system_user === 'true'
        ? item?.status
        : is_system_user === 'false' && !item?.is_system_user && item?.status
    );

    setTaskProgress(filterUserTasks);
    const pendingTaskCount = filterUserTasks.filter(
      (t) => t?.status === 'pending'
    )?.length;
    actions.bulkActionOnMessage({
      data,
      pendingTaskCount,
    });
  };

  const setTasks = (tasks) => {
    const importData = tasks?.filter(
      (item) =>
        item?.action === 'excel_import_bulk_update' ||
        item?.action === 'bulk_products_upload' ||
        item?.action === 'bulk_update_images'
    );

    setIsMessage(importData);

    const filteredBulkActionTasks = tasks
      .filter((value) => value.type !== 'BULK_ACTION')
      .sort(sortTaskFun);

    processAfterFetchingTask(filteredBulkActionTasks);
  };

  const updateTasks = (oldTasks, newTask) => {
    let tempTasks = oldTasks;
    if (Array.isArray(tempTasks)) {
      tempTasks = tempTasks.filter((task) => {
        return task?.task_id !== newTask?.task_id;
      });
      tempTasks = [newTask, ...tempTasks];
      setTasks(tempTasks);
    }
  };

  const removeTask = (oldTasks, deleteTask) => {
    let tempTasks = oldTasks;
    if (Array.isArray(tempTasks)) {
      tempTasks = tempTasks.filter((task) => {
        return task?.task_id !== deleteTask?.task_id;
      });
      setTasks(tempTasks);
    }
  };

  const onTaskUpdated = (updatedTask) => {
    fetchCachedData(updatedTask?.redis_key)
      .then((res) => {
        const cachedUpdatedTask = {
          ...updatedTask,
          ...res?.data?.data,
        };
        setNewTask(cachedUpdatedTask);
      })
      .catch((e) => {
        setNewTask(updatedTask);
        console.error(e);
      });
  };

  const onTaskDismissed = (taskData) => {
    setDeletedTask(taskData);
  };

  const processCachedTasks = (ev) => {
    const newData = Object.values(ev || {});
    if (newData) {
      setTasks(newData);
    }
  };

  useEffect(() => {
    if (isMessage !== null) {
      if (viewSocketData?.task_id) {
        globalActions.bulkUpdateActions.setImportSocketData(isMessage);
      } else if (!equal(isMessage, importSocketData)) {
        globalActions.bulkUpdateActions.setImportSocketData(isMessage);
      }
    }
  }, [isMessage]);

  useEffect(() => {
    bulkActionApis
      .fetchTasks()
      .then((res) => {
        const tasks = (res?.data?.data ?? []).sort(sortTaskFun);
        bulkActionApis
          .fetchCachedTasks()
          .then((res) => {
            const cachedTasks = res?.data?.data;
            if (cachedTasks && Object.keys(cachedTasks)?.length) {
              processCachedTasks(cachedTasks);
            } else {
              processAfterFetchingTask(tasks);
            }
          })
          .catch((e) => {
            processAfterFetchingTask(tasks);
          });
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
      });
    const orgId = parseJson(localStorage.getItem('org'))?.id;
    subscribe(
      orgId,
      ChannelType.TASKS,
      EventType.TASK_UPDATED,
      onTaskUpdated,
      ChannelScope.ORGANIZATION
    );
    subscribe(
      orgId,
      ChannelType.TASKS,
      EventType.TASK_DISMISSED,
      onTaskDismissed,
      ChannelScope.ORGANIZATION
    );
  }, []);

  useEffect(() => {
    if (newTask) {
      updateTasks(allTasks, newTask);
      setNewTask(null);
    }
  }, [newTask]);

  useEffect(() => {
    if (deletedTask) {
      removeTask(allTasks, deletedTask);
      setDeletedTask(null);
    }
  }, [deletedTask]);

  // const [globalState, globalActions] = useGlobal();

  const pendingTasks = taskProgress.filter((t) => t?.status === 'pending');
  const failedTasks = taskProgress.filter((t) => t?.status === 'failed');
  const completedTasks = taskProgress.filter((t) => t?.status === 'success');
  const draftTasks = taskProgress.filter((t) => t?.status === 'draft');

  if (draftTasks.length) {
    let currentTask = draftTasks[0];
    const { unique_id = '', task_id = '' } = currentTask;
    localStorage.setItem(
      'editIdentifier',
      JSON.stringify({ editIdentifier: unique_id, task_id: task_id })
    );
  }

  const [totalItems, totalRemainingItems] = useMemo(() => {
    const total = pendingTasks.reduce(
      (acc, value) => acc + (value?.total_count ?? 0),
      0
    );
    const remaining = pendingTasks.reduce(
      (acc, value) => acc + (value?.remaining_count ?? 0),
      0
    );
    return [total, remaining];
  }, [pendingTasks]);

  if (!taskProgress.length) return null;

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Dropdown
        placement="bottom"
        trigger="click"
        overlay={
          <DropdownMenu
            data={taskProgress}
            totalRemainingItems={totalRemainingItems}
            totalItems={totalItems}
          />
        }
        getPopupContainer={(trigger) => trigger.parentNode}>
        <NotifierButtonStyle>
          <NotifierButton
            {...{
              totalRemainingItems,
              totalItems,
              pendingTasks,
              failedTasks,
              completedTasks,
              draftTasks,
            }}
          />
        </NotifierButtonStyle>
      </Dropdown>

      {/* 
      <Drawer
        // title={`${size} Drawer`}
        placement="right"
        width={500}
        onClose={handleClose}
        visible={visible}
      >
        <NotifierButtonStyle>
          <NotifierButton
            {...{
              totalRemainingItems,
              totalItems,
              pendingTasks,
              failedTasks,
              completedTasks,
            }}
          />
        </NotifierButtonStyle>
        <DropdownMenu
          data={taskProgress}
          totalRemainingItems={totalRemainingItems}
          totalItems={totalItems}
        />
      </Drawer> */}
    </>
  );
};

export default HeaderActivity;
