import { Card, Row, Col, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Title, Text } = Typography;
const DashboardCard = ({ onClick, icon, title, subTitle, cta, allowed }) => {
  return (
    <Card bordered onClick={onClick} className="card">
      <Row className="icon">{icon}</Row>
      <Row style={{ marginTop: '2em' }}>
        <Col>
          <Title level={4} type={!allowed && 'secondary'}>
            <FormattedMessage id={title} />
            {/* {title} */}
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text type={!allowed && 'secondary'}>
            <FormattedMessage id={subTitle} />
          </Text>
        </Col>
      </Row>

      <Row style={{ marginTop: '2em' }}>
        <Col>{cta}</Col>
      </Row>
    </Card>
  );
};

export default DashboardCard;
