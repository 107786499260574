import React, { useState } from 'react';
import { Modal, Row, Col, Typography, Checkbox } from 'antd';
import { Button } from 'components/index';
import './index.less';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
const { Text } = Typography;

const DeleteTemplateModal = ({
  deleteTemplate,
  setShowDeleteModal,
  showDeleteModal,
  template,
  setViewDetailsActive,
}) => {
  const [isSure, setIsSure] = useState(false);
  const { trackEvent } = useTracking();
  const location = useLocation();

  const handleTemplateDeletion = () => {
    trackEvent({
      name: 'export_delete_template_confirm',
      meta: {
        data: template,
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setShowDeleteModal(false);
    deleteTemplate(template);
    setViewDetailsActive(false);
  };

  const onChange = (e) => {
    setIsSure(e.target.checked);
  };

  return (
    <Modal
      className="donwloadExecelModal"
      title=""
      centered
      visible={showDeleteModal}
      width={516}
      footer={null}
      onOk={() => setShowDeleteModal(false)}
      onCancel={() => setShowDeleteModal(false)}
      style={{ padding: '0px' }}>
      <Row className="delete-template-modal">
        <Row>
          <Text className="modal-template-header">Delete template?</Text>
        </Row>
        <Row className="delete-template-container">
          <Text className="delete-template-text">
            Deleting the template will remove this template forever and you
            cannot undo this. Are you sure you want to delete this?
          </Text>
        </Row>
        <Row>
          <Checkbox onChange={onChange}>
            <Text className="delete-template-confirmation">Yes, I am sure</Text>
          </Checkbox>
        </Row>
        <Row justify="space-between" className="delete-template-footer">
          <Col>
            <Button
              onClick={() => setShowDeleteModal(false)}
              size="large"
              className="delete-template-button standard-button">
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              disabled={isSure ? false : true}
              onClick={handleTemplateDeletion}
              size="large"
              className={
                isSure
                  ? 'delete-template-active standard-button'
                  : 'delete-template-inactive standard-button'
              }>
              Delete
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default DeleteTemplateModal;
