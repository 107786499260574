import ReferenceImageActions from 'redux/actions/referenceImagesActions';

const { ReferenceImgActionTypes } = ReferenceImageActions;

const initialState = {
  quotationReferenceImages: {
    uploadedList: [],
    selectedList: {},
  },
  showReferenceImageDrawer: false,
  currRowData: {},
  hasSelectedImages: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ReferenceImgActionTypes.ADD_MAIN_PRODUCT_IMAGE: {
      const { productData = {} } = payload;
      const existingData = state?.quotationReferenceImages?.selectedList || {};
      const productMainImg = productData?.product_images[0]?.directus_files_id;
      const newData = {
        [productData?.id]: {
          selectedImages: [productMainImg],
          productData,
        },
      };
      if (Object.keys(existingData)?.includes(productData?.id?.toString())) {
        delete existingData[productData?.id];
      }
      return {
        ...state,
        quotationReferenceImages: {
          ...state.quotationReferenceImages,
          selectedList: {
            ...newData,
            ...existingData,
          },
        },
      };
    }

    case ReferenceImgActionTypes.SET_REFERENCE_IMAGES: {
      const { data = {} } = payload;
      return {
        ...state,
        quotationReferenceImages: {
          ...state.quotationReferenceImages,
          selectedList: data,
        },
      };
    }

    case ReferenceImgActionTypes.ADD_MULTIPLE_PRODUCT_IMAGES: {
      const { imgIds = [], productData = {} } = payload;
      const existingData = state?.quotationReferenceImages?.selectedList || {};
      const newImages = {
        [productData?.id]: {
          selectedImages: imgIds,
          productData,
        },
      };
      if (Object.keys(existingData)?.includes(productData?.id?.toString())) {
        delete existingData[productData?.id];
      }
      return {
        ...state,
        quotationReferenceImages: {
          ...state.quotationReferenceImages,
          selectedList: {
            ...newImages,
            ...existingData,
          },
        },
      };
    }

    case ReferenceImgActionTypes.REMOVE_SINGLE_PRODUCT_IMAGE: {
      const { imgId = '', productData = {} } = payload;
      const { quotationReferenceImages = {} } = state;
      const { selectedList = {} } = quotationReferenceImages;
      const selectedImgData = selectedList?.[productData?.id] || {};
      const newImgList = selectedImgData?.selectedImages?.filter(
        (item) => item !== imgId
      );
      const newImgData = {
        [productData?.id]: { ...selectedImgData, selectedImages: newImgList },
      };
      if (Object.keys(selectedList)?.includes(productData?.id?.toString())) {
        delete selectedList[productData?.id];
      }

      return {
        ...state,
        quotationReferenceImages: {
          ...state.quotationReferenceImages,
          selectedList: {
            ...state.quotationReferenceImages.selectedList,
            ...newImgData,
          },
        },
      };
    }

    case ReferenceImgActionTypes.REMOVE_MULTIPLE_PRODUCT_IMAGES: {
      const {
        productData: { id },
      } = payload;
      const {
        [id]: {},
        ...existingImages
      } = state?.quotationReferenceImages?.selectedList || {};
      return {
        ...state,
        quotationReferenceImages: {
          ...state?.quotationReferenceImages,
          selectedList: existingImages,
        },
      };
    }

    case ReferenceImgActionTypes.SHOW_REFERENCE_IMAGE_DRAWER: {
      const { value = false } = payload;
      return {
        ...state,
        showReferenceImageDrawer: value,
      };
    }

    case ReferenceImgActionTypes.SET_CURR_ROW_DATA: {
      const { data = {} } = payload;
      return {
        ...state,
        currRowData: data,
      };
    }

    case ReferenceImgActionTypes.SET_UPLOADED_IMAGES: {
      const { data = [] } = payload;
      const newData = [...data]?.map((item) => item?.directus_file_id);
      const uniqueData = [...new Set(newData)];
      const newPayload = uniqueData?.map((item) => {
        const fileName =
          data?.find((element) => element?.directus_file_id === item)?.name ||
          '';
        return {
          directus_file_id: item,
          name: fileName,
        };
      });
      return {
        ...state,
        quotationReferenceImages: {
          ...state.quotationReferenceImages,
          uploadedList: newPayload,
        },
      };
    }

    case ReferenceImgActionTypes.HAS_SELECTED_IMAGES: {
      const { value = false } = payload;
      return {
        ...state,
        hasSelectedImages: value,
      };
    }

    default:
      return state;
  }
}
