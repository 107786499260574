import { useState, useRef, useEffect, useMemo } from 'react';
import {
  Button,
  message,
  Space,
  Tag,
  Tabs,
  Statistic,
  Row,
  Col,
  Progress,
  notification,
} from 'antd';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import './styles.less';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import ConfirmPopup from '../ManageProducts/BulkUpload/ConfirmPopup';
import Actions from 'redux/actions/manageProductsActions';
import bulkActionApis from 'api/bulkActionApis';
import { utcToLocal } from 'utils/commonFunctions';
import { MdError, MdCheck, MdCheckCircle } from 'react-icons/md';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import PYTHON_KEY from 'api/pythonService';
import exceltaskimg from 'assets/images/exceltask.svg';
import ppttaskimg from 'assets/images/ppttask.svg';
import taskcataloguepdfimg from 'assets/images/taskcataloguepdf.svg';
import { useGlobal } from 'store/index';
import taskfailed from 'assets/images/taskfailed.svg';
import {
  ACTIVITY_BULK_TASK,
  BULK_TASKS,
  CUSTOM_NOTIFICATION_TYPES,
  RESTORE_TASK,
} from 'constants/filters';

const getFormattedDate = (date) => {
  if (!date) return 'Invalid Date';
  return moment.utc(date).local().calendar();
};

const ActivityCardStyle = styled.div`
  padding-top: 5px;
  margin: 10px;
  background: white;
  min-width: 350px;
  border-bottom: 1px solid #e6e7e7;

  .card-date {
    font-size: 10px;
    color: #686e6d;
  }

  .card-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    .card-title {
      flex-grow: 1;
      flex-shrink: 0;
      color: #4f5655;
      width: 250px;
    }
    .recent-title {
      flex-grow: 1;
      flex-shrink: 0;
      color: #040e0c;
      font-weight: 700;
    }
    .card-shrink-space {
      width: 3rem;
    }
    .card-actions {
      flex-shrink: 0;

      .card-failed-buttons {
        display: flex;
        gap: 7px;
      }
    }
  }
`;

const uploadActions = [
  'excel_import_bulk_update',
  'bulk_products_upload',
  'bulk_update_images',
];

const ButtonTag = ({
  label,
  triggerAction,
  data,
  styles = {},
  onClick,
  isProcessing,
  disabled,
  setIsProcessing,
}) => {
  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;

  const location = useLocation();

  const actions = bindActionCreators(
    {
      retryTask: Actions.retryTask,
      ignoreTask: Actions.ignoreTask,
      dismissTask: Actions.dimissCompletedTasks,
    },
    dispatch
  );

  const onClickButton = () => {
    setLoading(true);
    if (
      data?.actionType === 'bulk_upload_image_template_download' &&
      triggerAction === 'download'
    ) {
      globalActions.sidebar.confirmCheck(false);
    }
    bulkActionApis
      .triggerAction({ task_id: data.task_id, trigger_action: triggerAction })
      .then((res) => {
        const newData = {
          task_id: data.task_id,
          remaining_product_ids: data.remaining_product_ids,
          actionType: data.action,
        };
        if (triggerAction === 'retry') {
          if (newData?.actionType === 'ppt_download') {
            PYTHON_KEY.URL.get(
              `${process.env.REACT_APP_API_SERVICE}pptservice/?collection_link=${data?.collection_link}&access_token=${token}&is_exporter=true&action_type=retry&task_id=${data?.task_id}`
            ).then((res) => {
              setLoading(false);
            });
          } else {
            actions.retryTask(newData);
            setLoading(false);
          }
        } else if (triggerAction === 'ignore') {
          actions.ignoreTask(newData);
          if (data.action === 'bulk_products_table') {
            localStorage.removeItem('editIdentifier');
          }
        } else if (triggerAction === 'dismiss') {
          actions.dismissTask({ taskIds: [data.task_ids] });
        } else if (triggerAction === 'download') {
          const collection_download_link =
            newData?.actionType === 'bulk_upload_image_template_download'
              ? data?.image_template_download_link
              : data?.download_link;
          setTimeout(() => {
            window.open(collection_download_link, '_self');
            setTimeout(() => {
              if (
                newData?.actionType === 'bulk_upload_image_template_download'
              ) {
                globalActions.sidebar.confirmCheck(true);
              }
            }, 100);
          }, 0);
          setLoading(false);
          actions.dismissTask({ taskIds: [data?.task_id] });
        }
        if (
          data.action === 'bulk_products_upload' &&
          location.pathname.includes(`bulk-upload-group/${data?.task_id}`)
        ) {
          history.push('/bulk-upload');
        }
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
      });
  };

  return (
    <Button
      onClick={onClick ? onClick : onClickButton}
      loading={isProcessing || loading}
      disabled={disabled}
      className={`${disabled && 'taskButtonDisable'}`}
      style={{
        color: '#4F5655',
        background: 'white',
        border: '1px solid #CDCFCE',
        ...styles,
      }}>
      {label}
    </Button>
  );
};

const ActionTag = ({ action }) => {
  const getLabel = () => {
    if (action === 'delete_products')
      return <FormattedMessage id={'sourcewiz.message.deleting'} />;
    if (action === 'change_products_category')
      return <FormattedMessage id={'sourcewiz.message.moving'} />;
    if (action === 'change_products_attribute')
      return <FormattedMessage id={'sourcewiz.message.updating'} />;
    if (action === 'bulk_products_table')
      return <FormattedMessage id={'sourcewiz.message.updating'} />;
    if (action === 'bulk_products_upload')
      return <FormattedMessage id={'sourcewiz.message.adding'} />;
    return '';
  };

  return (
    <Button
      size="small"
      style={{
        color: '#4F5655',
        background: 'white',
        border: '1px solid #CDCFCE',
        borderRadius: 40,
      }}>
      {getLabel()}
    </Button>
  );
};

export const DownloadsActivityCard = ({
  data,
  notificationData,
  // pptTasksData
  // setSeconds,
  // seconds,
  setDone,
}) => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');
  const isAdmin = localStorage.getItem('isAdmin');
  const [percentage, setPercentage] = useState(0);
  const [customTimer, setCustomTimer] = useState(1000);

  var parsedUserId = userId ? JSON.parse(userId) : '';
  var parsedIsAdmin = isAdmin ? JSON.parse(isAdmin) : '';

  const intervalTick = useRef();
  function tick() {
    const newDate = new Date();
    const newTestDate = moment.utc(data?.created_at).toDate();
    var seconds = new Date(newDate).getTime() - newTestDate.getTime();
    seconds = seconds / 1000;
    setPercentage(seconds + 1);
  }
  useEffect(() => {
    if (
      data?.action === 'ppt_download' ||
      data?.action === 'bulk_upload_image_template_download' ||
      data?.action === 'catalogue_pdf'
    ) {
      intervalTick.current = setInterval(() => tick(), customTimer);
    }
  }, [customTimer]);

  useEffect(() => {
    if (data?.action === 'excel_export_bulk_update') {
      if (
        data?.status === 'pending' &&
        data?.done_product_ids?.length !== data?.total_count
      ) {
        let excelProgress =
          (data?.done_product_ids?.length / data?.total_count) * 100;
        setPercentage(excelProgress);
      }
      if (
        data?.status === 'pending' &&
        data?.done_product_ids?.length === data?.total_count
      ) {
        setPercentage(98);
      }
      if (data?.status === 'success') {
        setPercentage(100);
      }
    }
  }, [data]);

  useEffect(() => {
    if (percentage === 50) {
      setCustomTimer(2000);
      clearInterval(intervalTick.current);
    } else if (percentage === 99) {
      clearInterval(intervalTick.current);
    } else if (percentage > 70) {
      setCustomTimer((_timer) => _timer + 1000);
      clearInterval(intervalTick.current);
    }
  }, [percentage]);
  const actions = bindActionCreators(
    {
      retryTask: Actions.retryTask,
      ignoreTask: Actions.ignoreTask,
      dismissTask: Actions.dimissCompletedTasks,
    },
    dispatch
  );

  const handleDiscard = () => {
    setIsProcessing(true);
    bulkActionApis
      .triggerAction({ task_id: data.task_id, trigger_action: 'dismiss' })
      .then((res) => {
        // if (data.action === 'bulk_products_table')
        //   localStorage.removeItem('editIdentifier');
        actions.dismissTask({ taskIds: [data?.task_id] });
        setIsProcessing(false);
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
        setIsProcessing(false);
        // setLoading(false);
      });
  };

  const renderRightCol = () => {
    return (
      <div>
        {(data?.status === 'pending' || data?.status === 'in_progress') && (
          <Space>
            <ButtonTag
              triggerAction="cancel"
              label="Cancel"
              data={data}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              onClick={handleDiscard}
            />
          </Space>
        )}
        {data?.status === 'success' && (
          <Space>
            <div className="card-failed-buttons">
              <ButtonTag
                label={intl.formatMessage({
                  id: 'sourcewiz.addproducts.dismiss',
                })}
                triggerAction="dismiss"
                data={data}
              />
              <ButtonTag
                label="Download"
                triggerAction="download"
                data={data}
                styles={{ background: '#0B7764', color: 'white' }}
              />
            </div>
          </Space>
        )}

        {data?.status === 'failed' && (
          <Space>
            <div className="card-failed-buttons">
              <ButtonTag
                label={intl.formatMessage({ id: 'sourcewiz.button.ignore' })}
                triggerAction="ignore"
                data={data}
              />
              <ButtonTag
                label={intl.formatMessage({ id: 'sourcewiz.button.retry' })}
                triggerAction="retry"
                data={data}
                styles={{
                  border: '1px solid #7DB8AD',
                  color: '#0B7764',
                  background: '#F0F8F6',
                }}
              />
            </div>
          </Space>
        )}
      </div>
    );
  };

  return (
    <ActivityCardStyle>
      <div className="card-date">
        {getFormattedDate(data?.created_at)} By{' '}
        <Tag
          key={data?.created_by_email}
          style={{
            color: `#686e6d`,
            justifyContent: 'center',
            fontSize: '10px',
            borderRadius: '20px',
            backgroundColor: `#F3F4F3`,
            border: 'none',
          }}>
          {data?.created_by_email}
        </Tag>
      </div>

      <div className="card-details" style={{ padding: 'inherit' }}>
        <div
          className="card-title"
          style={{ marginTop: '6px', marginBottom: '2px' }}>
          {(data?.action === 'excel_export_bulk_update' ||
            data?.action === 'bulk_upload_image_template_download') && (
            <Row style={{ marginBottom: '6px' }}>
              <img src={exceltaskimg} />
              {data?.action === 'bulk_upload_image_template_download' &&
                `Download image URLs`}
            </Row>
          )}
          {data?.action === 'ppt_download' && (
            <Row style={{ marginBottom: '6px' }}>
              <img src={ppttaskimg} />
            </Row>
          )}
          {data?.action === 'catalogue_pdf' && (
            <Row style={{ marginBottom: '6px' }}>
              <img src={taskcataloguepdfimg} />
            </Row>
          )}
          {data?.collection_name}

          {data?.action === 'excel_export_bulk_update' &&
            `Download ${data?.template_name}`}

          {data?.status === 'failed' && (
            <div class="card-date" style={{ marginTop: '5px' }}>
              <Row>
                <Col>
                  <MdError size={18} color={'#F34D4D'} />
                </Col>
                <Col style={{ marginTop: '1px', marginLeft: '5px' }}>
                  Download Failed
                </Col>
              </Row>
            </div>
          )}
          {data?.status === 'success' && (
            <div class="card-date" style={{ marginTop: '5px' }}>
              <Row>
                <Col>
                  <BsFillCheckCircleFill size={15} color={'#9AC421'} />
                </Col>
                <Col style={{ marginTop: '1px', marginLeft: '5px' }}>
                  Ready for download
                </Col>
              </Row>
            </div>
          )}
          {data?.status === 'pending' && (
            <Progress
              percent={percentage}
              showInfo={false}
              strokeColor={{
                '0%': '#94C016',
                '100%': '#57978B',
              }}
              strokeWidth={5}
              width="120px"
            />
          )}
        </div>
        <div className="card-shrink-space"></div>
        <div className="card-actions">{renderRightCol()}</div>
      </div>
      <div></div>
    </ActivityCardStyle>
  );
};

const ActivityCard = ({ data }) => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = localStorage.getItem('userId');
  const isAdmin = localStorage.getItem('isAdmin');
  const [global, globalActions] = useGlobal();
  const [percentage, setPercentage] = useState(0);
  const location = useLocation();

  var parsedUserId = userId ? JSON.parse(userId) : '';
  var parsedIsAdmin = isAdmin ? JSON.parse(isAdmin) : '';

  const actions = bindActionCreators(
    {
      retryTask: Actions.retryTask,
      ignoreTask: Actions.ignoreTask,
      dismissTask: Actions.dimissCompletedTasks,
    },
    dispatch
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const taskItem = {
    excel_import_bulk_update: 'Bulk update of ',
    excel_restore_products: 'Restoring of ',
  };

  useEffect(() => {
    const {
      action = '',
      status = '',
      total_count = 0,
      done_product_ids = [],
    } = data || {};
    if (!BULK_TASKS.includes(action)) {
      if (status === 'pending' && done_product_ids?.length !== total_count) {
        const progressPercent = (done_product_ids?.length / total_count) * 100;
        setPercentage(progressPercent);
      }
    }
  }, [data]);

  const bulkImportExcel = () => {
    if (data?.action === 'excel_import_bulk_update') {
      let importProgress = 0;
      let stepProgress = data && data?.steps_status;
      if (stepProgress) {
        stepProgress = Object.values(stepProgress);
        stepProgress?.length > 0 &&
          stepProgress?.map((item) => {
            if (item !== 'product_update' && item === 'done') {
              importProgress = importProgress + 25;
            }
          });
        setPercentage(importProgress);
        if (
          stepProgress.product_update === 'pending' &&
          data?.done_product_ids?.length !== data?.total_count
        ) {
          importProgress =
            (data?.done_product_ids?.length / data?.total_count) * 100;
          setPercentage(importProgress);
        }
      }
    }
  };

  useEffect(() => {
    if (data?.action === 'excel_restore_products') {
      if (
        data?.status === 'pending' &&
        data?.done_product_ids?.length !== data?.total_count
      ) {
        let excelProgress =
          (data?.done_product_ids?.length / data?.total_count) * 100;
        setPercentage(excelProgress);
      }
      if (
        data?.status === 'pending' &&
        data?.done_product_ids?.length === data?.total_count
      ) {
        setPercentage(98);
      }
      if (data?.status === 'success') {
        setPercentage(100);
      }
    }
  }, [data]);

  useEffect(() => {
    bulkImportExcel();
  }, [data]);

  const getFirstWod = () => {
    if (data.action === 'delete_products')
      return `${intl.formatMessage({ id: 'sourcewiz.activityCard.deletion' })}`;
    if (data.action === 'change_products_category')
      return `${intl.formatMessage({ id: 'sourcewiz.activityCard.moving' })}`;
    if (data.action === 'change_products_attribute')
      return `${intl.formatMessage({
        id: 'sourcewiz.profile.qrSettings.update',
      })}`;
    if (data.action === 'bulk_products_table')
      return `${intl.formatMessage({
        id: 'sourcewiz.activityCard.tableEdit',
      })}`;
    if (data.action === 'bulk_products_upload')
      return `${intl.formatMessage({
        id: 'sourcewiz.allProducts.upload.bulkUpload',
      })}`;
    if (data.action === 'bulk_update_images')
      return `${intl.formatMessage({
        id: 'sourcewiz.manageProducts.bulkImageUpdate.bulkUpdateImages',
      })}`;
    if (data.action === 'costing_change')
      return `${intl.formatMessage({
        id: 'sourcewiz.activityCard.costingChange',
      })}`;
    if (data.action === 'excel_import_bulk_update') {
      return 'Bulk Update ';
    }
    if (data?.action === 'user_deletion') {
      return 'Migration ';
    }
    if (data.action === 'excel_restore_products') {
      return 'Restore ';
    }
  };

  const getTitle = () => {
    if (data.status === 'draft') {
      if (data?.action === 'bulk_update_images') {
        return <>Bulk update of images in progress</>;
      }
      return (
        <>
          {getFirstWod()} of{' '}
          <b>
            {data?.action === 'user_deletion'
              ? data?.deleted_user_email
              : data.total_count}
          </b>{' '}
          {intl.formatMessage({ id: 'sourcewiz.activityCard.inProgress' })}
        </>
      );
    }
    if (data.status === 'failed') {
      if (data?.action === 'user_deletion') {
        return `Migration of ${data?.deleted_user_email} data failed`;
      }
      return (
        <>
          <img src={taskfailed} className="task-failed" />
          {BULK_TASKS.includes(data?.action) ? (
            taskItem[data?.action] + data.total_count + ' products failed'
          ) : (
            <FormattedMessage
              id={'sourcewiz.activityCard.productsFailed'}
              values={{
                task: getFirstWod(),
                n: `${data.remaining_count}`,
                total: `${data.total_count}`,
              }}
            />
          )}
        </>
      );
    }
    if (data.status === 'success') {
      if (data?.action === 'user_deletion') {
        return `Migration of ${data?.deleted_user_email} data completed`;
      }
      return (
        <FormattedMessage
          id={'sourcewiz.activityCard.productsComplete'}
          values={{ task: getFirstWod(), n: `${data.total_count}` }}
        />
      );
    }
    if (data.status === 'pending') {
      if (data?.action === 'user_deletion') {
        return `Migration of ${data?.deleted_user_email} data in progress`;
      }
      return (
        <>
          {BULK_TASKS.includes(data?.action)
            ? taskItem[data?.action] +
              data.total_count +
              ' products in progress'
            : data.total_count +
              ' ' +
              intl.formatMessage({
                id: 'sourcewiz.sidebar.products',
              })}

          <Progress
            percent={percentage}
            showInfo={false}
            strokeColor={{
              '0%': '#94C016',
              '100%': '#57978B',
            }}
            strokeWidth={5}
            width="120px"
          />
        </>
      );
    }
  };

  const handleDiscard = () => {
    setShowConfirmModal(true);
  };

  const confirmedDiscard = () => {
    setIsProcessing(true);
    setShowConfirmModal(false);
    bulkActionApis
      .triggerAction({ task_id: data.task_id, trigger_action: 'dismiss' })
      .then((res) => {
        if (data.action === 'bulk_products_table')
          localStorage.removeItem('editIdentifier');
        actions.dismissTask({ taskIds: [data.task_ids] });
        if (location.pathname.includes(`bulk-upload-group/${data?.task_id}`)) {
          history.push('/bulk-upload');
        }
        if (
          location.pathname.includes(
            `/bulk-image-upload/grouping/${data.task_id}`
          ) ||
          location.pathname.includes(
            `/bulk-image-upload/linking/${data.task_id}`
          )
        ) {
          history.push('/manage-products');
        }
        setIsProcessing(false);
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
        setIsProcessing(false);
        // setLoading(false);
      });
  };

  const handleContinue = () => {
    switch (data.action) {
      case 'bulk_products_table':
        history.push(`/bulk-edit-table/${data?.unique_id}`);
        break;
      case 'bulk_products_upload':
        history.push(
          data.current_state && data.current_state === 'add_information_table'
            ? `/bulk-upload-table/${data.task_id}`
            : `/bulk-upload-group/${data.task_id}`
        );
        break;
      case 'bulk_update_images':
        history.push(
          data?.task_meta?.current_state === 'image_grouping'
            ? `/bulk-image-upload/grouping/${data.task_id}`
            : `/bulk-image-upload/linking/${data.task_id}`
        );
        break;
      default:
        break;
    }
  };

  const handleViewDetailBulkUpload = () => {
    if (location.pathname.includes(`bulk-upload-group/${data?.task_id}`)) {
      globalActions.bulkUpdateActions.setViewSocketData({});
      globalActions.bulkUpdateActions.setBulkUploadTaskId(data?.task_id);
      globalActions.bulkUpdateActions.setIsBulkUploadExcel(true);
      globalActions.bulkUpdateActions.setIsUploadOpen(true);
    } else {
      history.push(`/bulk-upload-group/${data?.task_id}`);
    }
  };

  const renderRightCol = () => {
    if (data.status === 'pending') {
      return (
        <div>
          {BULK_TASKS.includes(data?.action) ? (
            <div className="card-failed-buttons">
              <ButtonTag
                label={'Cancel'}
                disabled={data?.action.includes(RESTORE_TASK)}
                triggerAction="ignore"
                data={data}
              />
              {data?.action !== 'excel_restore_products' && (
                <Button
                  className="tasks-view-details"
                  onClick={() => {
                    globalActions.bulkUpdateActions.setViewSocketData(data);
                    globalActions.bulkUpdateActions.setIsUploadOpen(true);
                  }}>
                  View details
                </Button>
              )}
            </div>
          ) : (
            <ActionTag action={data.action} />
          )}
        </div>
      );
    }

    if (
      data.status === 'success' &&
      (data?.created_by === parsedUserId ||
        data?.user_created === parsedUserId ||
        parsedIsAdmin)
    ) {
      return (
        <div>
          <ButtonTag
            label={intl.formatMessage({ id: 'sourcewiz.addproducts.dismiss' })}
            triggerAction="dismiss"
            data={data}
          />
        </div>
      );
    }
    if (
      data.status === 'draft' &&
      (data?.created_by === parsedUserId ||
        data?.user_created === parsedUserId ||
        parsedIsAdmin)
    ) {
      return (
        <div>
          <ConfirmPopup
            handleNext={confirmedDiscard}
            // products={products}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            // continueLoading={continueLoading}
            forwardText={intl.formatMessage({
              id: 'sourcewiz.taskButton.discard',
            })}
            title={intl.formatMessage({ id: 'sourcewiz.discardModal.title' })}
            content={intl.formatMessage({
              id: 'sourcewiz.discardModal.subTitle',
            })}
            confirmText={intl.formatMessage({
              id: 'sourcewiz.discardModal.warning',
            })}
          />
          <Space>
            <ButtonTag
              triggerAction="ignore"
              label={intl.formatMessage({ id: 'sourcewiz.taskButton.discard' })}
              data={data}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              onClick={handleDiscard}
            />
            <ButtonTag
              data={data}
              styles={{
                border: '1px solid #7DB8AD',
                color: '#0B7764',
                background: '#F0F8F6',
              }}
              onClick={handleContinue}
              label={intl.formatMessage({
                id: 'sourcewiz.taskButton.continue',
              })}
            />
          </Space>
        </div>
      );
    }
    if (
      data.status === 'failed' &&
      (data?.created_by === parsedUserId ||
        data?.user_created === parsedUserId ||
        data?.user_created === parsedIsAdmin)
    ) {
      return (
        <div className="card-failed-buttons">
          <ButtonTag
            label={
              CUSTOM_NOTIFICATION_TYPES.includes(data?.action)
                ? 'Cancel'
                : intl.formatMessage({ id: 'sourcewiz.button.ignore' })
            }
            triggerAction="ignore"
            data={data}
          />
          {data.action === 'excel_import_bulk_update' && (
            <Button
              style={{
                background: '#F0F8F6',
                border: '1px solid #6DADA2',
                bordeRadius: '4px',
                color: '#0B7764',
                marginLeft: '4px',
              }}
              onClick={() => {
                globalActions.bulkUpdateActions.setViewSocketData(data);
                globalActions.bulkUpdateActions.setIsUploadOpen(true);
              }}>
              View details
            </Button>
          )}
          {data.action === 'bulk_products_upload' && (
            <Button
              style={{
                background: '#F0F8F6',
                border: '1px solid #6DADA2',
                bordeRadius: '4px',
                color: '#0B7764',
                marginLeft: '4px',
              }}
              onClick={() => {
                handleViewDetailBulkUpload();
              }}>
              View details
            </Button>
          )}
          {uploadActions.indexOf(data?.action) < 0 && (
            <ButtonTag
              label={intl.formatMessage({ id: 'sourcewiz.button.retry' })}
              triggerAction="retry"
              data={data}
              isProcessing={data?.status === 'pending'}
              styles={{
                border: '1px solid #7DB8AD',
                color: '#0B7764',
                background: '#F0F8F6',
              }}
            />
          )}
        </div>
      );
    }
  };

  return (
    <ActivityCardStyle>
      <div className="card-date">
        {getFormattedDate(data?.created_at)} By{' '}
        <Tag
          key={data?.created_by_email}
          style={{
            color: `#686e6d`,
            justifyContent: 'center',
            fontSize: '10px',
            borderRadius: '20px',
            backgroundColor: `#F3F4F3`,
            border: 'none',
          }}>
          {data?.created_by_email}
        </Tag>
      </div>
      <div className="card-details">
        <div className="card-title">{getTitle()}</div>
        <div className="card-shrink-space"></div>
        <div className="card-actions">{renderRightCol()}</div>
      </div>
    </ActivityCardStyle>
  );
};

export default ActivityCard;
