export const DOCUMENT_ACTION_TYPES = Object.freeze({
  DOCUMENT_ADD_PRODUCT: 'productActions/DOCUMENT_ADD_PRODUCT',
  DOCUMENT_REMOVE_PRODUCT: 'productActions/DOCUMENT_REMOVE_PRODUCT',
  DOCUMENT_SET_PRODUCTS: 'productActions/DOCUMENT_SET_PRODUCTS',
  DOCUMENT_ADD_MULTIPLE_PRODUCTS:
    'productActions/DOCUMENT_ADD_MULTIPLE_PRODUCTS',
  DOCUMENT_REMOVE_MULTIPLE_PRODUCTS:
    'productActions/DOCUMENT_REMOVE_MULTIPLE_PRODUCTS',
});

export function addProduct(product, documentId) {
  return {
    type: DOCUMENT_ACTION_TYPES.DOCUMENT_ADD_PRODUCT,
    payload: {
      product,
      documentId,
    },
  };
}

export function removeProduct(product, documentId) {
  return {
    type: DOCUMENT_ACTION_TYPES.DOCUMENT_REMOVE_PRODUCT,
    payload: {
      product,
      documentId,
    },
  };
}

export function setProducts(products, documentId) {
  return {
    type: DOCUMENT_ACTION_TYPES.DOCUMENT_SET_PRODUCTS,
    payload: {
      products,
      documentId,
    },
  };
}

export function addMultipleProducts(products, documentId) {
  return {
    type: DOCUMENT_ACTION_TYPES.DOCUMENT_ADD_MULTIPLE_PRODUCTS,
    payload: {
      products,
      documentId,
    },
  };
}

export function removeMultipleProducts(products, documentId) {
  return {
    type: DOCUMENT_ACTION_TYPES.DOCUMENT_REMOVE_MULTIPLE_PRODUCTS,
    payload: {
      products,
      documentId,
    },
  };
}

const ProductDocumentActions = {
  DOCUMENT_ACTION_TYPES,
  addProduct,
  setProducts,
  addMultipleProducts,
  removeProduct,
  removeMultipleProducts,
};

export default ProductDocumentActions;
