import React, { useState } from 'react';
import { Modal } from 'antd';
import './index.less';
import { useIntl } from 'react-intl';

const VerifySuccess = ({ onCancel, show }) => {
  const intl = useIntl();

  return (
    <Modal
      visible={show}
      onCancel={onCancel}
      closable={true}
      footer={null}
      centered
      width={'538px'}
      bodyStyle={{ padding: '2em' }}
      destroyOnClose={true}>
      <div className="success-container">
        <div className="circle">
          <div className="checkmark"></div>
        </div>
        <div className="success-title">
          {/* Whatsapp number verified */}
          {intl.formatMessage({ id: 'sourcewiz.whatsappModal.successTitle' })}
        </div>
        <div className="success-subtitle">
          {/* You will receive an NDA on verified number within 24 hours */}
          {intl.formatMessage({
            id: 'sourcewiz.whatsappModal.successSubTitle',
          })}
        </div>
      </div>
    </Modal>
  );
};

export default VerifySuccess;
