import React from 'react';
import { Colors } from '../theme';
import { Typography, Row } from 'antd';
// import Loader from "react-loader-spinner";
import LoadingGif from '../assets/images/loading.gif';
const { Title } = Typography;

export default function Loading({ hideTitle }) {
  return (
    <div>
      {hideTitle ? null : (
        <Title
          style={{
            color: Colors.primaryColor,
            margin: '1rem',
            fontFamily: 'SatoshiBold',
          }}>
          Sourcewiz
        </Title>
      )}
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          height: '80vh',
          alignItems: 'center',
        }}>
        {/* <Loader
          type="ThreeDots"
          color={Colors.primaryColor}
          height={200}
          width={200}
        /> */}

        <img src={LoadingGif} alt="loadingState" width={200} height={200} />
      </Row>
    </div>
  );
}
