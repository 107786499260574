import { Row, Image, Typography, Grid } from 'antd';
import Home from './LogoContainer';
import maintainanceLogo from '../assets/images/maintainanceLogo.svg';

const { Text, Link } = Typography;

const MaintainanceScreen = () => {
  const downtimeDate = process?.env?.REACT_APP_DOWNTIMEDATE || '';
  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: '#E5E5E5',
        padding: '8em 20em',
      }}>
      <div
        style={{ background: '#ffffff', padding: '2em', borderRadius: '1em' }}>
        <Row justify="center">
          <Home logoSize={'30rem'} fontSize={'2rem'} />
        </Row>
        <Row justify="center" style={{ marginTop: '2em' }}>
          <Image src={maintainanceLogo} preview={false} />
        </Row>
        <Row justify="center" style={{ marginTop: '2em', marginBottom: '1em' }}>
          <Text style={{ fontWeight: 'bold', fontSize: '2em' }}>
            Under maintenance {downtimeDate ? ` till ${downtimeDate}` : ''}
          </Text>
        </Row>
        <Row
          justify="center"
          wrap
          style={{ textAlign: 'center', fontSize: '1em', margin: '0% 15%' }}>
          <Text>
            The platform will be up running again in no time. Sorry for the
            inconvenience
          </Text>
        </Row>

        {/* <Row
          justify="center"
          wrap
          style={{ textAlign: 'center', fontSize: '1em' }}>
          <Text>
            {' '}
            The platform will be up and running by Wednesday, 26th Oct 06:00 AM
            IST.
          </Text>
        </Row> */}
        {/* 
        <Row
          justify="center"
          style={{ marginTop: '4em', fontSize: '1.2em', lineHeight: '1.6' }}>
          <Text>Need help? </Text>{' '}
          <span>
            <Link
              style={{
                fontWeight: 'bold',
                paddingLeft: '0.5em',
                cursor: 'pointer',
              }}>
              {' '}
              Contact us
            </Link>
          </span>
        </Row> */}
      </div>
    </div>
  );
};

export default MaintainanceScreen;
