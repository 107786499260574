import axios from 'axios';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const userData = localStorage.getItem('user');
const user = userData && JSON.parse(userData);

const API_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_DIRECTUS,
    params: {
      access_token: user?.access_token,
    },
  }),
  path: {
    passwordRequest: 'auth/password/request',
    passwordReset: 'auth/password/reset',
    collections: 'items/collections',
    collections_products: 'items/collections_products',
    quotations: 'quotation/product/products',
    users: 'users',
    files: 'files',
    assets: 'assets',
    manufacturer: 'items/manufacturer',
    primaryCategory: 'items/primary_category',
    secondaryCategory: 'items/secondary_category',
    certifications: 'items/certifications',
    policyStatus: 'policy/pending',
    policyUpdate: `policy/accept/:policyId`,
    products: 'items/products',
    colours: 'items/colours',
    queryCategories: `items/secondary_category/?fields=name,id,primary_category.name,primary_category.id`,
    queryProducts: `items/products?fields=name,description,fabric,size,price,product_images.image_url,secondary_category.category_name,secondary_category.primary_category.category_name`,
    requirements: 'items/requirements',
    login: 'auth/login',
    logout: 'auth/logout',
    manufacturerCategories:
      'items/manufacturer_secondary_category/?fields=secondary_category_id.name,secondary_category_id.status,secondary_category_id.id',
    manufacturer_secondary_category: 'items/manufacturer_secondary_category',
    collection_products: 'items/collections_products',
    catalogue_created: 'custom/email-service/catalogue_created',
    send_enquiry_copy: 'custom/email-service/send_enquiry_copy',
    prospects: 'items/prospects',
    attributes: 'items/attributes',
    secondary_category_attributes: 'items/secondary_category_attributes',
    table: 'custom/table/collectionproducts',
    attribute_values: 'items/attribute_values',
    collections_products_attribute_values:
      'items/collections_products_attribute_values',
    collections_variant_attribute_values:
      'items/collections_products_variant_attribute_values',
    variantModal: 'custom/table/product-variants-v2',
    addProduct: 'quotation/product/products/product',
    createCostingTemplate: 'items/manufacturer_costing_template',
  },
};

export default API_KEY;

API_KEY.URL.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response && error?.response?.status === 401) {
      setTimeout(() => {
        // localStorage.clear(); // <-- add your var
        window.location = '/'; // <-- add your path
        clearLocalStorageAndCookie();
        localStorage.setItem('logout-event', 'logout' + Math.random());
      }, 3000);
    } else return Promise.reject(error);
  }
);
