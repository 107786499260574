import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Typography,
  Select,
  Modal,
  message,
} from 'antd';
import OtpInput from 'react-otp-input';
import './styles.css';
import hooks from 'hooks';
import { generateOTP, verifyOTP } from 'api/authApis';
import { updateMobileNumber, updateWhatsappNumber } from 'api/customApis';
import { useGlobal } from '../../../store/index';
import { useIntl, FormattedMessage } from 'react-intl';

const { Option } = Select;

const { Title } = Typography;
export default function MobileField({
  label,
  editable,
  countryCodes,
  mobile,
  country,
  fetchData,
  whatsappVerified,
  email,
  isDetailComponent,
  forWhatsapp = false,
  setDisabled = () => {},
  showOTP,
  otpVerified,
  goBack,
  verifySuccess,
  setVerifyOTP,
}) {
  const intl = useIntl();
  const [editMode, setEditMode] = useState(false);
  const [mobileNum, setMobileNum] = useState(mobile);
  const [countryCode, setCountryCode] = useState(country?.id);
  const [currentCountry, setCurrentCountry] = useState(country);
  const [globalState, globalActions] = useGlobal();
  const user_data = globalState.userInfo;
  const { seconds, start, stop, reset } = hooks.useTimer({ seconds: 180 });

  const [labelValue, setLabelValue] = useState(
    mobile ? `+${country.phonecode}-${mobile}` : 'Not Added'
  );

  const [showOTPModal, setShowOTPModal] = useState(false);
  const [haserrorOTP, setHaserrorOTP] = useState(false);
  const [OtpValue, setOtpValue] = useState();

  const handleGenerateOTP = () => {
    if (mobileNum && mobileNum.length === 10 && countryCode) {
      generateOTP({ email, mobile: mobileNum, country: countryCode })
        .then(() => {
          start();
          if (!showOTPModal) setShowOTPModal(true);
        })
        .catch((e) => {
          message.error(
            `${intl.formatMessage({
              id: 'sourcewiz.login.failedToGenerateOTP',
            })}`
          );
        });
    } else {
      if (!mobileNum) {
        message.error(
          `${intl.formatMessage({
            id: 'sourcewiz.profileSettings.companyDetails.contactNumber',
          })}`
        );
      } else if (mobileNum.length !== 10) {
        message.error(
          `${intl.formatMessage({
            id: 'sourcewiz.profileSettings.companyDetails.validContactNumber',
          })}`
        );
      } else {
        message.error(
          `${intl.formatMessage({
            id: 'sourcewiz.profileSettings.companyDetails.countryode',
          })}`
        );
      }
    }
  };

  useEffect(() => {
    if (forWhatsapp) {
      setEditMode(true);
    }
  }, []);

  useEffect(() => {
    if (forWhatsapp && showOTP) {
      reset();
      handleGenerateOTP();
      setShowOTPModal(true);
      setHaserrorOTP(false);
      setOtpValue('');
    }

    if (forWhatsapp && !showOTP) {
      setShowOTPModal(false);
    }
  }, [showOTP]);

  useEffect(() => {
    if (countryCodes.length && forWhatsapp) {
      const indCountryData = countryCodes.find((item) => item.id === 99);
      setCurrentCountry(indCountryData);
      setCountryCode(indCountryData.id);
    }
  }, [countryCodes]);

  const handleverifyOTP = () => {
    verifyOTP({ otp: OtpValue })
      .then((res) => {
        if (res.data.verified) {
          stop();
          handleUpdate();
          if (forWhatsapp) {
            verifySuccess();
          }
          setEditMode(false);
          setShowOTPModal(false);
        } else {
          setHaserrorOTP(true);
          setVerifyOTP(false);
        }
      })
      .catch((e) => {
        setHaserrorOTP(true);
        setVerifyOTP(false);
      });
  };

  useEffect(() => {
    if (otpVerified && forWhatsapp) {
      handleverifyOTP();
    }
  }, [otpVerified]);

  useEffect(() => {
    if (mobileNum && mobileNum.length === 10) {
      setDisabled(false);
    } else if (!mobileNum || mobileNum.length !== 10) {
      setDisabled(true);
    }
  }, [mobileNum]);

  const formatSeconds = (countDown) => {
    const sec = seconds % 60;
    const secLabel = sec < 10 ? `0${sec}` : sec;
    const min = Math.floor(seconds / 60);
    if (min) {
      return `0${min}:${secLabel} minutes`;
    }
    return `00:${secLabel} seconds`;
  };

  const toggleMode = () => {
    setEditMode((state) => !state);
  };

  const renderReadMode = () => {
    return <div style={{ color: '#040E0C', fontSize: 16 }}>{labelValue}</div>;
  };

  const handleUpdate = () => {
    if (!forWhatsapp) {
      updateMobileNumber({ mobile: mobileNum, country: countryCode })
        .then((res) => {
          setLabelValue(`+${currentCountry?.phonecode}-${mobileNum}`);
          message.success(
            `${intl.formatMessage({
              id: 'sourcewiz.profileSettings.accountDetails.mobileNumberUpdateSuccess',
            })}`
          );
          fetchData();
        })
        .catch(() => {
          message.error(
            `${intl.formatMessage({
              id: 'sourcewiz.profileSettings.accountDetails.mobileNumberUpdateFailure',
            })}`
          );
        });
    } else {
      updateWhatsappNumber({ mobile: mobileNum, country: countryCode })
        .then((res) => {
          setLabelValue(`+${currentCountry?.phonecode}-${mobileNum}`);
          message.success(
            `${intl.formatMessage({
              id: 'sourcewiz.profileSettings.accountDetails.whatsappNumberUpdateSuccess',
            })}`
          );
          whatsappVerified();
          fetchData();
        })
        .catch(() => {
          message.error(
            `${intl.formatMessage({
              id: 'sourcewiz.profileSettings.accountDetails.whatsappNumberUpdateFailure',
            })}`
          );
        });
    }
  };

  const handleBack = () => {
    stop();
    setHaserrorOTP(false);
    setShowOTPModal(false);
    goBack();
  };

  const handleResendOTP = () => {
    reset();
    handleGenerateOTP();
    setHaserrorOTP(false);
    setOtpValue('');
  };

  const handleOtpModal = () => {
    return (
      <div>
        <Modal
          keyboard={true}
          closable={false}
          visible={showOTPModal}
          destroyOnClose={true}
          footer={null}
          style={{ minWidth: '600px' }}
          centered
          bodyStyle={{ top: '19em', borderRadius: '8px' }}>
          <Row style={{ fontSize: '18px', fontWeight: 'bold' }}>
            {/* {`Enter the OTP received on +${currentCountry?.phonecode}-${mobileNum}.`} */}
            <FormattedMessage
              id={'sourcewiz.login.enterOTPMob'}
              values={{ mob: `+${currentCountry?.phonecode}-${mobileNum}.` }}
            />
          </Row>
          <Row
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '1.5em',
              marginBottom: '0.8em',
            }}>
            {intl.formatMessage({ id: 'sourcewiz.login.otpValid' })}{' '}
            {formatSeconds(seconds)}
          </Row>
          <Row style={{ width: '100%', marginBottom: '0.8em' }}>
            <Col span={24}>
              <OtpInput
                isInputNum
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                value={OtpValue}
                onChange={(e) => {
                  setOtpValue(e);
                }}
                numInputs={6}
                inputStyle={{
                  width: '40px',
                  height: '3rem',
                  fontSize: '25px',
                  borderRadius: '8px',
                  border: '1px solid #C9E4DF',
                }}
                isInputSecure={true}
                hasErrored={haserrorOTP}
                errorStyle={{ border: '1px solid red' }}
              />
            </Col>
          </Row>
          {haserrorOTP ? (
            <Row
              style={{
                color: '#F34D4D',
                fontSize: '12px',
                fontWeight: '500',
                height: '2.5em',
                background: '#FDDBDB80',
                padding: '5px',
                marginBottom: '0.5em',
              }}>
              {/* The OTP you have entered is not valid */}
              {intl.formatMessage({ id: 'sourcewiz.login.wrongOTP' })}
            </Row>
          ) : null}
          <Row>
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'bolder',
                color: '#0B7764',
                cursor: 'pointer',
                paddingBottom: '7em',
              }}
              onClick={handleResendOTP}>
              {/* Resend OTP */}
              {intl.formatMessage({ id: 'sourcewiz.login.resendingOTP' })}
            </span>
          </Row>

          <Row>
            <Col span={11}>
              <Button
                type="Ghost"
                size="large"
                style={{
                  width: '100%',
                  // height: "3em",

                  border: '1px solid grey',
                  borderRadius: 4,
                  fontWeight: 'bold',
                  color: 'grey',
                }}
                onClick={handleBack}>
                {/* Go Back */}
                {intl.formatMessage({ id: 'sourcewiz.button.goBack' })}
              </Button>
            </Col>
            <Col span={11} offset={2}>
              <Button
                size="large"
                style={{
                  width: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  // width: "12em",
                  // height: "3em",
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: '#363E3D',
                  border: '1px solid white',
                  borderRadius: 4,
                  // left: "5.5em",
                }}
                onClick={handleverifyOTP}>
                {/* Verify */}
                {intl.formatMessage({ id: 'sourcewiz.login.verifyOTP' })}
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              color: '#4F5655',
              fontSize: '14px',
              forntWeight: 'normal',
              paddingTop: '0.8em',
            }}
            justify="space-around"
            align="middle">
            <Col>
              {intl.formatMessage({
                id: 'sourcewiz.profileSettings.accountDetails.contactSupport',
              })}{' '}
              support@sourcewiz.co
            </Col>
          </Row>
        </Modal>
      </div>
    );
  };

  const handleOtpWhatsapp = () => {
    return (
      <>
        <Row
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <div>
              {/* {`Enter the OTP received on +${currentCountry?.phonecode}-${mobileNum}.`} */}
              <FormattedMessage
                id={'sourcewiz.login.enterOTPMob'}
                values={{ mob: `+${currentCountry?.phonecode}-${mobileNum}.` }}
              />
            </div>
          </div>
        </Row>
        <Row
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: '1em',
            marginBottom: '1.6em',
          }}>
          {intl.formatMessage({ id: 'sourcewiz.login.otpValid' })}{' '}
          {formatSeconds(seconds)}
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <OtpInput
              isInputNum
              containerStyle={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              value={OtpValue}
              onChange={(e) => {
                setOtpValue(e);
              }}
              numInputs={6}
              inputStyle={{
                width: '47px',
                height: '60px',
                fontSize: '20px',
                borderRadius: '8px',
                border: '1px solid #C9E4DF',
              }}
              isInputSecure={true}
              hasErrored={haserrorOTP}
              errorStyle={{ border: '1px solid red' }}
            />
          </Col>
        </Row>
        <Row justify="start" style={{ paddingTop: '1em' }}>
          <Col span={24}>
            {haserrorOTP ? (
              <Row
                style={{
                  color: '#F34D4D',
                  fontSize: '12px',
                  fontWeight: '500',
                  height: '2.5em',
                  background: '#FDDBDB80',
                  padding: '5px',
                  marginBottom: '0.5em',
                }}>
                {/* The OTP you have entered is not valid */}
                {intl.formatMessage({ id: 'sourcewiz.login.wrongOTP' })}
              </Row>
            ) : null}
          </Col>
          <Col span={24}>
            <span
              type="Ghost"
              size="small"
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'grey',
                cursor: 'pointer',
                padding: '5px',
                marginTop: '0.5em',
              }}
              onClick={handleResendOTP}>
              {/* Resend OTP */}
              {intl.formatMessage({ id: 'sourcewiz.login.resendingOTP' })}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  const getButtonLabel = () => {
    if (forWhatsapp)
      return intl.formatMessage({
        id: 'sourcewiz.profileSettings.accountDetails.getOTP',
      });

    if (mobile)
      return intl.formatMessage({ id: 'sourcewiz.profile.qrSettings.update' });
    else return intl.formatMessage({ id: 'sourcewiz.button.submit' });
  };

  const renderButtons = () => {
    return (
      <Row align="bottom" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        {!forWhatsapp && (
          <Button
            size={forWhatsapp ? 'small' : 'large'}
            style={{
              color: 'white',
              backgroundColor: '#363E3D',
              border: '1px solid white',
              borderRadius: 4,
              // width: "85px",
              ...(forWhatsapp && { fontSize: '12px' }),
              ...(!isDetailComponent && { height: '41px' }),
            }}
            disabled={
              mobile === mobileNum &&
              !forWhatsapp &&
              countryCode === country?.id
            }
            onClick={handleGenerateOTP}>
            {getButtonLabel()}
          </Button>
        )}
        {!forWhatsapp && (
          <Button
            size={forWhatsapp ? 'small' : 'large'}
            style={{
              color: '#4F5655',
              backgroundColor: 'white',
              border: '1px solid #CDCFCE',
              borderRadius: 4,
              marginLeft: '1rem',
            }}
            onClick={() => {
              setEditMode(false);
              setMobileNum(mobile);
              setCountryCode(country?.id);
            }}>
            {/* Cancel */}
            {intl.formatMessage({ id: 'quotations.cancel' })}
          </Button>
        )}
      </Row>
    );
  };

  const handleNumberData = (e) => {
    const { value } = e.target;
    var reg = /\d+/;
    if (reg.test(value) || value === '') {
      setMobileNum(value);
    }
  };

  const renderEditMode = () => {
    return (
      <>
        {!showOTPModal ? (
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                dropdownAlign={{ points: ['bl', 'tl'] }}
                allowClear
                className="country_codes_select"
                showSearch
                style={{ width: 200, marginRight: '0.5rem' }}
                placeholder="code"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={(value, e) => {
                  setCurrentCountry(e?.data);
                  setCountryCode(value);
                }}
                required={true}
                defaultValue={forWhatsapp ? 99 : country?.id}>
                {countryCodes.map((item) => {
                  return (
                    <Option
                      data={item}
                      value={
                        item.id
                      }>{`${item.iso3}+(${item.phonecode})`}</Option>
                  );
                })}
              </Select>

              <Input
                onWheel={(e) => e.currentTarget.blur()}
                size="large"
                onChange={handleNumberData}
                value={mobileNum}
                type="number"
              />
            </div>
            <div>{renderButtons()}</div>
          </div>
        ) : isDetailComponent ? (
          <></>
        ) : (
          handleOtpWhatsapp()
        )}
      </>
    );
  };

  return (
    <div
      className="mobile_div"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '1rem',
        paddingBottom: forWhatsapp ? '0' : '1rem',
        borderBottom: forWhatsapp ? 'none' : '1px solid #E6E7E7',
        width: window.innerWidth > 900 && !forWhatsapp ? '70%' : '100%',
      }}>
      {!forWhatsapp && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              fontWeight: 'bold',
              color: '#4F5655',
              marginBottom: '0.5rem',
            }}>
            <Title level={5} type="secondary">
              {label}
            </Title>
          </div>
          {editable && !editMode && (
            <div
              onClick={toggleMode}
              style={{
                cursor: 'pointer',
                color: '#0B7764',
                paddingRight: '1rem',
              }}>
              {mobile
                ? `${intl.formatMessage({ id: 'quotations.view.edit' })}`
                : `${intl.formatMessage({ id: 'sourcewiz.button.add' })}`}
            </div>
          )}
        </div>
      )}
      {editMode ? renderEditMode() : renderReadMode()}
      {!forWhatsapp && <div>{handleOtpModal()}</div>}
    </div>
  );
}
