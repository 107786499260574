export const documentActions = {
  setDocumentProducts: (store, payload) => {
    store.setState({ documentProducts: payload });
  },
  setDocumentAttributes: (store, payload) => {
    store.setState({ documentAttributes: payload });
  },
  setSelectedDocumentAttributes: (store, payload) => {
    store.setState({ documentSelectedAttributes: payload });
  },
  setDocumentTableState: (store, payload) => {
    store.setState({ documentTableState: payload });
  },
  setDocumentId: (store, payload) => {
    store.setState({ documentId: payload });
  },
};
