import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Tag, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import data from '../../../data/data';
import styled, { keyframes } from 'styled-components';
import Can from '../../../casl/Can';
import { useHistory } from 'react-router-dom';
import './title.less';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import './dashboard.css';
import { VIEW_DASHBOARD } from 'analytics/events';
import RewardsDashboard from './RewardsIndex';
import { useGlobal } from 'store/index';
import { MODULE_LIST } from '../Guardrails/Heimdall/ModuleList';
import { StartTrial } from './StartTrialModal';
import { ModuleUpgradeModal } from '../Guardrails/Components/ModuleUpgrade/ModuleUpgradeModal';

const { Title } = Typography;

const CardWrapper = styled.div`
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: 1px solid #f5f5f5;
  .card {
    border-radius: 12px;

    min-height: 15rem;

    &:hover {
      background: ${(props) => props.hover};
      cursor: ${'pointer'};
    }
    @media (max-width: 768px) {
      flex-direction: column;
      height: 248px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
      height: 248px;
    }

    @media (min-width: 992px) and (max-width: 1500px) {
      height: 272px;
    }
  }
`;

const animateCard = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const AnimatedCard = styled(CardWrapper)`
  animation: ${animateCard} 0.5s ease-in-out;
  /* animation-delay: ${(props) => props.index * 0.05}s;  */
`;

export default function Dashboard() {
  const pref = localStorage.getItem('pref');
  const showBRDashboard = JSON.parse(pref);
  const { trackEvent } = useTracking();
  const location = useLocation();
  const history = useHistory();
  const [globalState, globalActions] = useGlobal();
  const [mappedCards, setMappedCards] = useState([]);
  const [trialModalModuleID, setTrialModalModuleID] = useState(null);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [moduleDetails, setModuleDetails] = useState({});
  const [showBuyModal, setShowBuyModal] = useState(false);

  const { usage } = globalState;

  const getPlanDetails = (card) => {
    var currentModule = usage.find(
      (item) => item.module_name === card.module_id
    );
    const isTrialInactive =
      currentModule.plan_type === 'trial' &&
      currentModule.plan_status === 'inactive';

    return {
      hasTrialStart: isTrialInactive,
      showExtra: isTrialInactive,
      extraText: isTrialInactive ? 'Start Now' : '',
    };
  };

  useEffect(() => {
    if (trialModalModuleID && usage.length)
      setModuleDetails(
        (usage || []).find(
          (item) => item.module_name === trialModalModuleID.module_id
        )
      );

    // setModuleDetails(usage);
  }, [trialModalModuleID]);

  useEffect(() => {
    if (Object.keys(usage).length > 0) {
      const usageMappedCards = data.map((card) => {
        const usageObject = card.isPlan ? getPlanDetails(card) : {};
        return { ...card, ...usageObject };
      });
      setMappedCards(usageMappedCards);
    }
  }, [usage]);

  const showModal = (show, module_id) => {
    if (show) {
      setTrialModalModuleID(module_id);
      setShowTrialModal(true);
    } else {
      setTrialModalModuleID(module_id);
      setShowTrialModal(true);
    }
  };

  const handleCard = (item, allowed) => {
    return (
      <Card
        bordered
        className="card"
        onClick={() => {
          allowed && handleClick(item);
        }}
        style={{
          opacity: allowed ? 1 : 0.5,
          cursor: allowed ? 'pointer' : 'not-allowed',
        }}>
        <Row className="icon" justify="space-between">
          <img src={item.image} alt="img" style={{ width: '64px' }} />
        </Row>
        {item?.tag && (
          <Row
            justify="end"
            style={{ position: 'absolute', right: 0, top: 24 }}>
            <Tag className="new-release-tag">{item?.tag}</Tag>
          </Row>
        )}
        <Row style={{ marginTop: '2em' }}>
          <Title level={4} className="card__title">
            <FormattedMessage id={item.title} />
          </Title>
        </Row>
        <Row style={{ marginTop: '0.7em' }}>
          <Col className="card__description">
            <FormattedMessage id={item.description} />
          </Col>
        </Row>
      </Card>
    );
  };

  const handleClick = (item) => {
    trackEvent({
      name: VIEW_DASHBOARD + '_' + item.title,
      meta: {
        location: location.pathname,
        modules: 'Dashboard',
      },
    });
    if (item.isPlan && item.hasTrialStart) {
      showModal(true, item);
    } else {
      history.push(item.route);
    }
  };

  return (
    <>
      {/* <RewardsDashboard /> */}
      <ModuleUpgradeModal
        moduleData={moduleDetails}
        moduleId={moduleDetails?.module_id}
        isModalVisible={showBuyModal}
        setIsModalVisible={setShowBuyModal}
      />
      <StartTrial
        setShowBuyModal={setShowBuyModal}
        usage={usage}
        moduleDetails={moduleDetails}
        setTrialModalModuleID={setTrialModalModuleID}
        showModal={showTrialModal}
        setShowModal={setShowTrialModal}
        fromDashboard={true}
      />
      {/* inline css required here */}
      <Row
        align="middle"
        gutter={[16, 16]}
        style={{ marginRight: '.5em', marginTop: '1em' }}>
        {data.map((item, idx) => {
          return (
            <Col
              key={idx}
              xs={24}
              sm={24}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}>
              <AnimatedCard hover={item.hover} index={idx}>
                <Can I={item.slug} a={item.permissionType} passThrough>
                  {(allowed) => handleCard(item, allowed)}
                </Can>
              </AnimatedCard>
              {/* {item.slug === 'view_recommendation' ||
                  item.slug === 'trade_fair' ? (
                    <AllProductsCard hover={item.hover}>
                      <Can
                        I={item.slug}
                        a={item.permissionType}
                        passThrough={showBRDashboard?.show_fair_dashboard}>
                        {handleCard(
                          item,
                          showBRDashboard?.show_fair_dashboard ? true : false
                        )}
                      </Can>
                    </AllProductsCard>
                  ) : (
                    <AllProductsCard hover={item.hover}>
                      <Can I={item.slug} a={item.permissionType} passThrough>
                        {(allowed) => handleCard(item, allowed)}
                      </Can>
                    </AllProductsCard>
                  )} */}
            </Col>
          );
        })}
      </Row>
    </>
  );
}
