import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Image, Input, Space, Skeleton } from 'antd';
import '../BulkUpdate/index.less';
import './bulkExportExcel.less';
import SWDrawer from 'react-common-components/SWDrawer';
import SelectBulkAttributes from './SelectBulkAttributes';
import SortableComponent from './SelectedAttributes';
import { Button } from 'components/index';
import { createNewTemplate, getTemplateList } from 'api/collectionApis';
import EmptyTemplate from 'assets/images/emptyTemplate.svg';
import { getProductAttributes } from 'api/customApis';
import { MdCheckCircle, MdInfo } from 'react-icons/md';
import { getUserPrefs } from 'utils/localItems';
import { openNotification } from 'components/Notification/Notification';
import { InfoCircleFilled } from '@ant-design/icons';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import {
  ATTRIBUTE_DTYPE_CONSTANTS,
  ATTRIBUTE_TABNAME_CONSTANTS,
  BULK_UPLOAD_DFAULT_ATTR,
  CUSTOM_ATTR_SECONDARY,
  CUSTOM_ATTR_SUBCATEGORY,
} from 'constants/attributeConstants';
const { Text } = Typography;

const CreateNewTemplate = ({
  setIsChangeTemplate,
  setIsCreateTemplate,
  isCreateTemplate,
  setIsDiscardTemplate,
  setTemplateList,
  templateList,
  setSelectedCard,
  isDiscard,
  isBulkUpload = false,
  attributes_list,
}) => {
  const [isCreate, setIsCreate] = useState(false);
  const [compulsoryAttribute, setCompulsoryAttribute] = useState([]);
  const [isCheck, setIsCheck] = useState(
    isBulkUpload ? BULK_UPLOAD_DFAULT_ATTR : []
  );
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [attributesList, setAttributesList] = useState([]);
  const [isName, setIsName] = useState(false);
  const { trackEvent } = useTracking();
  const location = useLocation();

  useEffect(() => {
    const isSubCategoryActive = getUserPrefs();
    const customeAttributes = isSubCategoryActive?.sub_category
      ? CUSTOM_ATTR_SUBCATEGORY
      : CUSTOM_ATTR_SECONDARY;

    getProductAttributes()
      .then((res) => {
        if (res?.status === 200) {
          if (isBulkUpload) {
            const nonCompulsoryAttributes = res?.data
              ?.filter(
                (item) =>
                  ATTRIBUTE_DTYPE_CONSTANTS.indexOf(item?.dtype) === -1 &&
                  ATTRIBUTE_TABNAME_CONSTANTS.indexOf(item?.tabname) === -1 &&
                  item?.internal_name !== 'buyer_note' &&
                  !item.compulsory
              )
              ?.sort((item1, item2) => item2?.priority - item1?.priority);

            let compulsoryAttributes = [
              ...customeAttributes,
              ...res?.data
                ?.filter(
                  (item) =>
                    ATTRIBUTE_DTYPE_CONSTANTS.indexOf(item?.dtype) === -1 &&
                    ATTRIBUTE_TABNAME_CONSTANTS.indexOf(item?.tabname) === -1 &&
                    item?.internal_name !== 'buyer_note' &&
                    item.compulsory
                )
                ?.sort((item1, item2) => item2?.priority - item1?.priority),
            ];
            compulsoryAttributes = compulsoryAttributes?.map((item) => ({
              ...item,
              isDisabled: true,
            }));
            setAttributesList([
              ...compulsoryAttributes,
              ...nonCompulsoryAttributes,
            ]);
            setIsCheck(compulsoryAttributes);
          } else {
            const data = [
              ...customeAttributes,
              ...res?.data
                ?.filter(
                  (item) =>
                    ATTRIBUTE_DTYPE_CONSTANTS.indexOf(item?.dtype) === -1 &&
                    ATTRIBUTE_TABNAME_CONSTANTS.indexOf(item?.tabname) === -1 &&
                    item?.internal_name !== 'buyer_note'
                )
                ?.sort((item1, item2) => item2?.priority - item1?.priority),
            ];
            setAttributesList(data);
          }
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
    setIsCreate(false);
  }, [isDiscard, isCreateTemplate]);

  useEffect(() => {
    const emptyData = () => {
      setIsCheck([]);
      setIsCheckAll(false);
      setTemplateName('');
      setIsName(false);
      setIsCreate(false);
    };
    emptyData();
  }, [isDiscard]);

  const handleCheckBoxClick = (e) => {
    const { id, checked, name, isDisabled = false } = e.target;
    setIsCheck([...isCheck, { id: id, name: name, isDisabled: isDisabled }]);
    setIsCheckAll(false);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item.id !== id));
    }
  };

  const createTemplate = () => {
    const attributes = {};
    for (let i = 0; i < isCheck?.length; i++) {
      attributes[i] = isCheck[i]?.id;
    }
    if (templateName?.trim()?.length === 0) {
      return;
    } else if (Object?.keys(attributes)?.length === 0) {
      const icon = (
        <InfoCircleFilled className="excel-new-template-notification" />
      );
      openNotification('topRight', 'Please select the details', icon);
      return;
    }

    const payload = {
      name: templateName,
      is_custom: true,
      slug: 'bulk_export_excel',
      template_type: 'user_custom',
      selected_attributes: { value: attributes },
      status: 'published',
      file_format: 'excel',
    };

    trackEvent({
      name: 'export_create_final_cta',
      meta: {
        data: payload,
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setIsCreate(true);
    createNewTemplate(payload)
      .then((newTemplate) => {
        setIsCheck([]);
        setIsCheckAll(false);
        setTemplateName('');
        setIsName(false);
        getTemplateList()
          .then((res) => {
            const { default_template_id, templates } = res?.data?.data;
            templates.forEach((template) => {
              if (
                template?.selected_attributes?.value &&
                template?.selected_attributes?.value !== 'all'
              ) {
                let attribute_list = template?.selected_attributes?.value;
                let filteredAttributes = Object.values(attribute_list).filter(
                  (attribute) => attribute in attributes_list
                );
                template.selected_attributes.value = { ...filteredAttributes };
              }
            });
            setSelectedCard({
              id: newTemplate?.data?.data?.id,
            });
            const defaultTemplate = templates?.filter(
              (item) => item?.id === default_template_id
            );
            const restTemplates = templates?.filter(
              (item) => item?.id !== default_template_id
            );
            setTemplateList({
              default_template_id: default_template_id,
              templates: [...defaultTemplate, ...restTemplates],
            });
          })
          .catch((err) => {
            console.error('error', err);
          });
        setIsCreateTemplate(false);
        setIsChangeTemplate(true);
        const icon = <MdCheckCircle className="notification-icon" />;
        openNotification('topRight', 'New template created successfully', icon);
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handleTemplateName = (e) => {
    setIsName(false);
    setTemplateName(e.target.value);
  };

  const handleCreateTemplate = () => {
    if (
      templateList?.templates.filter(
        (item) => item?.name === templateName?.trim()
      )?.length
    ) {
      const icon = <MdInfo className="excel-new-template-notification" />;
      openNotification('topRight', 'Template name already exists', icon);
      return;
    }
    createTemplate();
    setIsName(true);
  };

  return (
    <SWDrawer
      success="Success"
      dismiss="Cancel"
      width={804}
      show={isCreateTemplate}
      closable
      setShow={false}
      onClose={() => {
        isCheck?.length !== 0 || templateName?.length
          ? setIsDiscardTemplate(true)
          : setIsCreateTemplate(false);
      }}
      BottomActionBar={<></>}
      hideButtons={true}
      className={'export-bulk-excel-sheet'}>
      <Row className="create-template-container">
        <Col className="excel-create-template-header-container" span={12}>
          <Row>
            <Text className="excel-create-template-title">Select details</Text>
          </Row>
          <Row>
            <Text className="excel-create-template-text">
              Only selected details will be downloaded
            </Text>
          </Row>
          <Row className="attribute-container">
            {!attributesList.length ? (
              <Space className="select-bulk-attribute">
                {Array.from(Array(13).keys()).map((item) => (
                  <Skeleton.Input
                    active={true}
                    style={{ width: 320, overflow: 'scroll' }}
                    key={item}
                  />
                ))}
              </Space>
            ) : (
              <SelectBulkAttributes
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                handleCheckBoxClick={handleCheckBoxClick}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
                attributesList={attributesList}
                isBulkUpload={isBulkUpload}
              />
            )}
          </Row>
        </Col>
        <Col className="height" span={12}>
          <Row className="create-template-title">
            <Text className="drawer-header">Create template</Text>
          </Row>
          <Row className="create-template-padding">
            <Text className="excel-create-template-text">
              Enter template name and select details
            </Text>
          </Row>
          <Row className="create-template-title">
            <Text className="create-template-error">*</Text>
            <Text>Enter template name</Text>
          </Row>
          <Row className="create-template-name">
            <Input
              size="large"
              placeholder="Enter name"
              value={templateName}
              onChange={handleTemplateName}
            />
          </Row>
          <Row className="temnplate-name-error-container">
            {isName && templateName?.trim()?.length === 0 ? (
              <Row className="create-template-name">
                <Text className="create-template-error-text">
                  *Please fill all the mandatory fields
                </Text>
              </Row>
            ) : (
              <></>
            )}
          </Row>
          <Row className="create-template-attributes">
            {isCheck.length > 0 ? (
              <SortableComponent
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                handleCheckBoxClick={handleCheckBoxClick}
                isBulkUpload={isBulkUpload}
                compulsoryAttributes={compulsoryAttribute || []}
              />
            ) : (
              <Space
                direction="vertical"
                className="create-template-empty-card">
                <Row className="excel-container-width">
                  <Image src={EmptyTemplate} preview={false} width="100%" />
                </Row>
                <Row
                  justify="center"
                  className="excel-container-width create-template-empty-card-title">
                  <Text className="title-style">No details selected</Text>
                </Row>
                <Row
                  justify="center"
                  className="excel-container-width create-template-empty-text">
                  <Text className="sortable-component-text">
                    Select details on the left to arrange them in the order you
                    want
                  </Text>
                </Row>
              </Space>
            )}
          </Row>
          <Row className="create-template-footer" justify="space-between">
            <Col>
              <Button
                size={'large'}
                variant="outline_gray"
                className="excel-export-footer-button standard-button"
                onClick={() => {
                  isCheck?.length !== 0 || templateName?.length
                    ? setIsDiscardTemplate(true)
                    : setIsCreateTemplate(false);
                }}>
                Back
              </Button>
            </Col>
            <Col>
              <Button
                size={'large'}
                variant="dark"
                className="excel-export-footer-button standard-button"
                loading={isCreate}
                onClick={handleCreateTemplate}>
                Create
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </SWDrawer>
  );
};

export default CreateNewTemplate;
