import React from 'react';
import { Colors } from 'theme';
import Logo from 'assets/images/logo64.png';
import { Row } from 'antd';

export default function Home({ logoSize, fontSize }) {
  return (
    <div>
      <Row align="middle">
        <img src={Logo} alt="sourcewiz" width={logoSize} height={logoSize} />
        <span
          style={{
            fontSize,
            color: Colors.black,
            fontFamily: 'SatoshiBold',
            marginLeft: '0.5rem',
          }}>
          Sourcewiz
        </span>
      </Row>
    </div>
  );
}
