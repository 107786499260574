export const manualCost = {
  setManualCost: (store, payload) => {
    store.setState({ manualCost: payload });
  },
  updateManualCost: (store, payload) => {
    store.setState({
      manualCost: {
        ...store.state.manualCost,
        ...payload,
      },
    });
  },
};
