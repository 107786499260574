import {
  DEFAULT_SORT,
  FILTER_OPERATORS,
  INDEX_PROVIDER,
} from 'constants/filters';
import { getSmartSearchPayload } from './localItems';

export const parseJson = (jsonString, fallback = 0) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    if (fallback !== 0) return fallback;
    return jsonString;
  }
};

export const utcToLocal = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};

// Function which accepts unit id and uom Data andt returns the unit data after filtering from uom data
export const getUnitData = (
  value,
  uomData,
  isUnitCategory = false,
  isShortName = false
) => {
  const [unitId, unitName] = value?.split('--');
  const unitCategory =
    uomData &&
    Object.values(uomData)?.filter((items) =>
      items?.find((item) => item?.id == unitId)
    );
  if (isUnitCategory) {
    return unitCategory[0];
  }
  const unitData =
    unitCategory[0]?.filter((item) => item?.id == unitId)[0] || {};
  if (isShortName) {
    const shortName = `per ${unitData?.short_name}`;
    return shortName;
  }
  return unitData;
};

// Function which accepts an object of selected and uploaded images of type image_list and returns directus file ids in an array
export const getAllImgIds = (data) => {
  if (data) {
    const { uploaded_images = {}, selected_images = {} } = data;
    const uploadedImgIds = Object.keys(uploaded_images) || [];
    const selectedImgIds = Object.values(selected_images)?.flat() || [];
    return [...selectedImgIds, ...uploadedImgIds];
  }
};
//Function while gives params for productv2 api based on applied filters
export const getProductV2Payload = (appliedFiltersData, pageDetails, id) => {
  let payload = {};
  const defaultProductSortBy =
    JSON.parse(localStorage.getItem('pref'))?.default_products_sort_by ||
    DEFAULT_SORT;
  const {
    hitsPerPage,
    category_name = '',
    smart_search = '',
    category_id = '',
    ...otherFilters
  } = appliedFiltersData;
  if (smart_search) {
    //get smart search payload from session storage
    const smartSearchPayload = createSmartSearchPayload();
    const { page, records_per_page, ...others } = smartSearchPayload;
    payload = {
      id,
      page,
      hitsPerPage: records_per_page,
      filter_args: {
        index_provider: INDEX_PROVIDER?.[`smart_search`],
        ...others,
      },
    };
    return payload;
  }

  payload = {
    id,
    hitsPerPage: hitsPerPage || pageDetails?.hitsPerPage,
    page: 1,
    filter_args: {
      index_provider: INDEX_PROVIDER?.[`default`],
      sort_by: defaultProductSortBy,
    },
  };
  if (category_name) {
    payload = {
      ...payload,
      filter_args: { ...payload.filter_args, category_name },
    };
  }
  const perPageHits = pageDetails?.hitsPerPage || hitsPerPage;

  if (Object?.keys(otherFilters)?.length > 0) {
    const {
      sortBy = defaultProductSortBy,
      query = '',
      range = {},
      refinementList = {},
    } = otherFilters;
    const defaultFilters = otherFilters?.configure?.filters;
    const page = otherFilters?.page || 1;
    // For range filters
    const mappedRangeFilters = getMappedFilters('range', range);

    // For refinementLists
    //delete keys with empty string values => This happens when we select and deselect a filter
    Object.keys(refinementList)
      .filter((item) => !refinementList?.[item])
      .forEach((item) => delete refinementList?.[item]);
    const mappedRefinementList = getMappedFilters(
      'refinementList',
      refinementList
    );

    const { betweenDiffFilterType } = FILTER_OPERATORS;
    let filtersParams = ``;

    if (defaultFilters) {
      filtersParams += `${betweenDiffFilterType} ${defaultFilters}`;
    }
    if (mappedRangeFilters) {
      filtersParams += ` ${betweenDiffFilterType} ${mappedRangeFilters}`;
    }
    if (mappedRefinementList) {
      filtersParams += ` ${betweenDiffFilterType} ${mappedRefinementList}`;
    }
    const payload = {
      id,
      page,
      filter_args: {
        index_provider: INDEX_PROVIDER?.[`default`],
        sort_by: sortBy,
        filters: filtersParams,
        query,
      },
      hitsPerPage: perPageHits,
    };
    if (!payload?.hitsPerPage) {
      delete payload.hitsPerPage;
    }
    return payload;
  }
  if (!payload?.hitsPerPage) {
    delete payload.hitsPerPage;
  }
  return payload;
};

const getMappedFilters = (type, filterData) => {
  const { betweenSameFilterType, betweenDiffFilterType } = FILTER_OPERATORS;
  switch (type) {
    case 'refinementList': {
      const mappedRefinementList =
        Object.keys(filterData)?.length > 0 &&
        Object.keys(filterData)
          ?.map((item) => {
            const mappedItem = filterData?.[item]?.map(
              (element) => `'${item}':'${element}'`
            );
            return `(${mappedItem?.join(` ${betweenSameFilterType} `)})`;
          })
          ?.join(` ${betweenDiffFilterType} `);
      return mappedRefinementList;
    }
    case 'range': {
      const mappedRangeFilters =
        Object.keys(filterData)?.length > 0 &&
        Object.keys(filterData)
          ?.map((item) => {
            const itemValue = filterData?.[item];
            return `('${item}'>=${itemValue?.min} ${betweenDiffFilterType} '${item}'<=${itemValue?.max})`;
          })
          ?.join(` ${betweenDiffFilterType} `);
      return mappedRangeFilters;
    }
    default: {
      return false;
    }
  }
};

// function which creates payload in the required way from session storage
const createSmartSearchPayload = () => {
  const storedPayload = getSmartSearchPayload();
  const { params = {}, payload = {} } = storedPayload;
  const { images_url = {} } = payload;
  const { moodboard_imgs = [], smart_search_img = null } = images_url;
  const allImages = smart_search_img
    ? [...moodboard_imgs, smart_search_img]
    : [...moodboard_imgs];
  const newPayload = { ...payload, images_url: allImages };
  return { ...newPayload, ...params };
};

//function which takes hits and selected product's id and returns the selected product details from the hits
export const getSelectedProductDetails = (hits, id) => {
  if (hits && id) {
    const currentProduct =
      hits?.find((item) => parseInt(item?.id) === parseInt(id)) || {};
    return currentProduct;
  }
};
