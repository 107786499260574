import {
  Layout,
  Col,
  Row,
  Popover,
  Modal,
  message,
  Typography,
  Button,
  notification,
} from 'antd';
import Header from './Header/index';
import Sidebar from './Header/Sidebar';
import AppRoutes from './AppRoutes';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { LOGINS } from 'analytics/events';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Heimdall from './Guardrails/Heimdall/index';
import WebAppStoreButton from 'assets/images/WebAppStoreButton.svg';
import MobileAsset from 'assets/images/MobileAsset.svg';
import WebPlayStoreButton from 'assets/images/WebPlayStoreButton.svg';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useGlobal } from 'store/index';
import QrDrawer from './labelQR';
import ImportExportDrawer from './ManageProducts/BulkUpdate/ImportExportDrawer';
import BulkExcelDownload from './ManageProducts/BulkUpdate/BulkExcelDownload';
import { getUserPrefs } from 'utils/localItems';
import {
  getAllProductAttributes,
  getManufacturerSettings,
} from 'api/customApis';
import DiscardTemplate from './ManageProducts/BulkUpdate/DiscardTemplate';
import { getTemplateList, updateTemplate } from 'api/collectionApis';
import DeleteModal from './ManageProducts/DeleteModal';
import DeleteTemplateModal from './ManageProducts/BulkUpdate/DeleteTemplateModal';
import { openNotification } from 'components/Notification/Notification';
import { MdCheckCircle } from 'react-icons/md';
import {
  ATTRIBUTE_DTYPE_CONSTANTS,
  ATTRIBUTE_INTERNAL_NAME_CONSTANTS,
  ATTRIBUTE_TABNAME_CONSTANTS,
} from 'constants/attributeConstants';
import ImportDrawer from './ManageProducts/BulkUpdate/ImportDrawer';
import InvalidImportTemplate from './ManageProducts/BulkUpdate/InvalidImportTemplate';
import ImportCancelModal from './ManageProducts/BulkUpdate/ImportCancelModal';
import { bulkTriggerAction, getproductThreshold } from 'api/customApis';
import importerror from 'assets/images/importerror.svg';
import warningNotification from 'assets/images/warningnotification.svg';
import ChangeTemplate from './ManageProducts/BulkExcelExport/ChangeTemplate';
import CreateNewTemplate from './ManageProducts/BulkExcelExport/CreateNewTemplate';
import { setManufacturerSettings } from 'redux/actions/manufacturerSettingsActions';

const { Content } = Layout;
const { Text } = Typography;

export default function Index() {
  const [appModal, setAppModal] = useState(false);
  const { trackEvent } = useTracking();
  const location = useLocation();
  const [globalState, globalActions] = useGlobal();
  const [isExcelDrawer, setIsExcelDrawer] = useState(false);
  // const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);
  const [isChangeTemplate, setIsChangeTemplate] = useState(false);
  const [isBulkExcelDownload, setIsBulkExcelDownload] = useState(false);
  const [templateList, setTemplateList] = useState();
  const [isDiscardTemplate, setIsDiscardTemplate] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [defaultBulkExcelTemplate, setDefaultBulkExcelTemplate] = useState();
  const [template, setTemplate] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [isDiscard, setIsDiscard] = useState(false);
  const [viewDetailsActive, setViewDetailsActive] = useState(false);
  const [attributesList, setAttributesList] = useState([]);
  const [defaultAttributes, setDefaultAttributes] = useState([]);
  const [isTemplateInValid, setIsTemplateInValid] = useState(false);
  const [isCancelImport, setIsCancelImport] = useState(false);
  const [cancelTask, setCancelTask] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [taskDetail, setTaskDetail] = useState('');
  const [currentSlug, setCurrentSlug] = useState('');
  const { isImportExportDrawerActive, isBulkUploadExcel, isUploadOpen } =
    globalState;
  const [excelLimitThreshold, setExcelLimitThreshold] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getManufacturerSettings()
      .then((res) => {
        if (res?.status === 200) {
          const data = res?.data;
          dispatch(setManufacturerSettings(data));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (isImportExportDrawerActive) {
      getproductThreshold('excel_export_image_config')
        .then((res) => {
          if (res?.status === 200) {
            setExcelLimitThreshold(res?.data?.value?.value);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isImportExportDrawerActive]);

  const fetchTemplates = (attributes_list) => {
    getTemplateList()
      .then((res) => {
        if (res?.status === 200) {
          const { default_template_id, templates } = res?.data?.data;

          templates.forEach((template) => {
            if (
              template?.selected_attributes?.value &&
              template?.selected_attributes?.value !== 'all'
            ) {
              let attribute_list = template?.selected_attributes?.value;
              let filteredAttributes = Object.values(attribute_list).filter(
                (attribute) => attribute in attributes_list
              );
              template.selected_attributes.value = { ...filteredAttributes };
            }
          });

          setSelectedCard({
            id: default_template_id,
          });
          const defaultTemplate = templates?.filter(
            (item) => item?.id === default_template_id
          );
          const restTemplates = templates?.filter(
            (item) => item?.id !== default_template_id
          );

          setTemplateList({
            default_template_id: default_template_id,
            templates: [...defaultTemplate, ...restTemplates],
          });
          setDefaultBulkExcelTemplate(
            res?.data?.data?.templates?.filter(
              (template) => template.id === res?.data?.data?.default_template_id
            )?.[0]
          );
        }
      })
      .catch((err) => {
        console.error('error', err);
      });
  };

  useEffect(() => {
    const isSubCategoryActive = getUserPrefs();
    const customeAttributes = isSubCategoryActive?.sub_category
      ? {
          image: 'Image',
          product_id: 'Product ID',
          variant_id: 'Variant ID',
          secondary_category: 'Category',
          sub_category: 'Sub Category',
        }
      : {
          image: 'Image',
          product_id: 'Product ID',
          variant_id: 'Variant ID',
          secondary_category: 'Category',
        };

    const defaultCustomeAttributes = isSubCategoryActive?.sub_category
      ? ['Image', 'Product ID', 'Variant ID', 'Category', 'Sub Category']
      : ['Image', 'Product ID', 'Variant ID', 'Category'];

    getAllProductAttributes()
      .then((res) => {
        const allAttributes = {
          ...customeAttributes,
          ...res?.data
            ?.filter(
              (item) =>
                !ATTRIBUTE_DTYPE_CONSTANTS?.includes(item?.dtype) &&
                !ATTRIBUTE_TABNAME_CONSTANTS?.includes(item?.tabname) &&
                !ATTRIBUTE_INTERNAL_NAME_CONSTANTS?.includes(
                  item?.internal_name
                )
            )
            ?.sort((item1, item2) => item2?.priority - item1?.priority)
            ?.reduce((acc, elem) => {
              acc[elem?.id] = elem?.name;
              return acc;
            }, {}),
        };
        setAttributesList(allAttributes);
        fetchTemplates(allAttributes);
        const defaultAttr = res?.data
          ?.filter(
            (item) =>
              !ATTRIBUTE_DTYPE_CONSTANTS?.includes(item?.dtype) &&
              !ATTRIBUTE_TABNAME_CONSTANTS?.includes(item?.tabname) &&
              !ATTRIBUTE_INTERNAL_NAME_CONSTANTS?.includes(item?.internal_name)
          )
          ?.sort((item1, item2) => item2?.priority - item1?.priority)
          ?.reduce((arr, item) => {
            arr?.push(item?.name);
            return arr;
          }, []);

        const sortedData = [...defaultCustomeAttributes, ...defaultAttr];
        setDefaultAttributes(sortedData);
      })
      .catch((err) => {
        console.error('error', err);
      });
  }, []);

  const deleteTemplate = (template) => {
    const payload = {
      status: 'archived',
    };
    const restTemplates = templateList?.templates?.filter(
      (item) => item?.id !== template?.id
    );
    const { templates, ...restData } = templateList;
    setTemplateList({ ...restData, templates: restTemplates });

    setSelectedCard({ id: defaultBulkExcelTemplate?.id });
    updateTemplate(template?.id, payload)
      .then(() => {
        const icon = (
          <MdCheckCircle
            style={{
              color: '#0B7764',
              fontSize: '20px',
            }}
          />
        );
        openNotification('topRight', 'Template deleted successfully', icon);
      })
      .catch((err) => {
        console.error('error', err);
      });
  };

  const handleTemplateDelete = (template) => {
    setShowDeleteModal(true);
    setTemplate(template);
  };

  const cancelImportTask = () => {
    setCancelLoading(true);
    const payload = {
      trigger_action: 'dismiss',
      task_id: cancelTask[0]?.task_id,
    };
    bulkTriggerAction(payload)
      .then((res) => {
        if (res?.status === 200) {
          setIsCancelImport(false);
          setIsConfirm(false);
          setCancelLoading(false);
          globalActions.bulkUpdateActions.setIsUploadOpen(false);
          globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
          if (isBulkUploadExcel) {
            history.push('/bulk-upload');
            globalActions.bulkUpdateActions.setIsBulkUploadExcel(false);
          }
          trackEvent({
            name: 'bulk_import_cancel_confirm',
            meta: {
              location: location.pathname,
              modules: 'Manage Products',
              property: 'Bulk excel import',
            },
          });
        }
      })
      .catch((err) => {
        setCancelLoading(false);
        message.error('something went wrong!');
        console.log(err);
      });
  };

  return (
    <div>
      <Layout>
        <Header />

        {/* <TaskHistory /> */}

        <Content
          className="site-layout"
          style={{
            padding: '16px 50px 16px 25px',
            marginTop: 64,
            background: 'white',
          }}

          // style={{ background: "white", paddingRight: "2em" }}
        >
          <Row>
            <Col
              span={2}
              onLoad={() => {
                if (location.pathname === '/') {
                  trackEvent({
                    name: LOGINS,
                    meta: {
                      location: location.pathname,
                      modules: 'Overall',
                      property: 'Overall',
                      userId: localStorage.getItem('userId'),
                    },
                  });
                }
              }}>
              <Sidebar changeAppModalStatus={setAppModal} />
            </Col>

            <Col span={22}>
              <Heimdall />

              <QrDrawer />

              <Modal
                width={'70%'}
                bodyStyle={{ padding: 0, borderRadius: 80 }}
                style={{
                  position: 'relative',
                  padding: 0,
                  overflow: 'hidden',
                }}
                visible={appModal}
                onCancel={() => setAppModal(false)}
                destroyOnClose={true}
                footer={null}
                centered>
                <Row justify="space-between">
                  <Col
                    justify="center"
                    style={{
                      width: '50%',
                      padding: '2.75rem',
                      minHeight: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                    <Col style={{ height: 'max-content', margin: 'auto' }}>
                      <Col>
                        <h1 style={{ fontWeight: '300', fontSize: '1.25rem' }}>
                          The Sourcewiz App
                        </h1>
                        <h1 style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                          Digital factory in your pocket
                        </h1>
                        <hr />
                      </Col>
                      <Col style={{ marginTop: '2rem' }}>
                        <Row
                          style={{
                            marginTop: '1rem',
                            width: '100%',
                            flexFlow: 'nowrap',
                          }}>
                          <AiFillCheckCircle size="2rem" color="#7DB8AD" />
                          <p
                            style={{
                              fontSize: '1rem',
                              fontWeight: '400',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              marginLeft: '0.5rem',
                              marginBottom: 0,
                              color: '#4F5655',
                            }}>
                            View products anywhere, anytime in palm of your
                            hands.
                          </p>
                        </Row>
                        <Row
                          style={{
                            marginTop: '1rem',
                            width: '100%',
                            flexFlow: 'nowrap',
                          }}>
                          <AiFillCheckCircle size="2rem" color="#7DB8AD" />
                          <p
                            style={{
                              fontSize: '1rem',
                              fontWeight: '400',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              marginLeft: '0.5rem',
                              marginBottom: 0,
                              color: '#4F5655',
                            }}>
                            Click pictures and quick add products directly from
                            your phone.
                          </p>
                        </Row>
                        <Row
                          style={{
                            marginTop: '1rem',
                            width: '100%',
                            flexFlow: 'nowrap',
                          }}>
                          <AiFillCheckCircle size="2rem" color="#7DB8AD" />
                          <p
                            style={{
                              fontSize: '1rem',
                              fontWeight: '400',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              marginLeft: '0.5rem',
                              marginBottom: 0,
                              color: '#4F5655',
                            }}>
                            Sort & filter products for easy viewing.
                          </p>
                        </Row>
                      </Col>
                      <Row
                        align="middle"
                        style={{
                          flexFlow: 'nowrap',
                          marginTop: '3rem',
                          display: 'flex',
                          flexShrink: 0,
                          flexx: '0 0 auto',
                          width: '100%',
                          gap: '0.5rem',
                        }}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://play.google.com/store/apps/details?id=com.sourcewizmobileapp">
                          <img alt="" src={WebPlayStoreButton} />
                        </a>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://apps.apple.com/in/app/sourcewiz/id1631787469">
                          <img alt="" src={WebAppStoreButton} />
                        </a>
                      </Row>
                    </Col>
                  </Col>
                  <Col
                    style={{
                      width: '50%',
                      overflow: 'hidden',
                    }}>
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      alt=""
                      src={MobileAsset}
                    />
                  </Col>
                </Row>
              </Modal>

              <ImportExportDrawer
                {...{
                  isExcelDrawer,
                  isUploadOpen,
                  setIsExport,
                  isExport,
                  setIsCreateTemplate,
                  setIsChangeTemplate,
                  templateList,
                  setTemplateList,
                  setIsBulkExcelDownload,
                  defaultBulkExcelTemplate,
                  setIsDiscard,
                  setIsTemplateInValid,
                  isTemplateInValid,
                  setTaskDetail,
                  excelLimitThreshold,
                }}
              />

              <ChangeTemplate
                setIsChangeTemplate={setIsChangeTemplate}
                isChangeTemplate={isChangeTemplate}
                templateList={templateList}
                setTemplateList={setTemplateList}
                setIsCreateTemplate={setIsCreateTemplate}
                setIsBulkExcelDownload={setIsBulkExcelDownload}
                setDefaultBulkExcelTemplate={setDefaultBulkExcelTemplate}
                setTemplate={setTemplate}
                handleTemplateDelete={handleTemplateDelete}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                setIsDiscard={setIsDiscard}
                viewDetailsActive={viewDetailsActive}
                setViewDetailsActive={setViewDetailsActive}
                defaultBulkExcelTemplate={defaultBulkExcelTemplate}
                defaultAttributes={defaultAttributes}
                attributesList={attributesList}
                title={'Excel Export'}
                subTitle={
                  'Select from available template(s) or create your own'
                }
              />
              <CreateNewTemplate
                setIsChangeTemplate={setIsChangeTemplate}
                setIsCreateTemplate={setIsCreateTemplate}
                isCreateTemplate={isCreateTemplate}
                setIsDiscardTemplate={setIsDiscardTemplate}
                setTemplateList={setTemplateList}
                templateList={templateList}
                setSelectedCard={setSelectedCard}
                isDiscard={isDiscard}
                attributes_list={attributesList}
              />
              {isBulkExcelDownload && (
                <BulkExcelDownload
                  isBulkExcelDownload={isBulkExcelDownload}
                  setIsBulkExcelDownload={setIsBulkExcelDownload}
                />
              )}

              {isDiscardTemplate && (
                <DiscardTemplate
                  setIsDiscardTemplate={setIsDiscardTemplate}
                  isDiscardTemplate={isDiscardTemplate}
                  setIsCreateTemplate={setIsCreateTemplate}
                  setIsDiscard={setIsDiscard}
                />
              )}
              {showDeleteModal && (
                <DeleteTemplateModal
                  setViewDetailsActive={setViewDetailsActive}
                  deleteTemplate={deleteTemplate}
                  setShowDeleteModal={setShowDeleteModal}
                  showDeleteModal={showDeleteModal}
                  template={template}
                />
              )}

              {isTemplateInValid && (
                <InvalidImportTemplate
                  isTemplateInValid={isTemplateInValid}
                  setIsTemplateInValid={setIsTemplateInValid}
                />
              )}
              {isCancelImport && (
                <ImportCancelModal
                  {...{
                    setIsCancelImport,
                    isCancelImport,
                    setCancelTask,
                    cancelTask,
                    isConfirm,
                    setIsConfirm,
                    cancelImportTask,
                    cancelLoading,
                    currentSlug,
                    setCurrentSlug,
                  }}
                />
              )}

              {isUploadOpen && (
                <ImportDrawer
                  {...{
                    isUploadOpen,
                    setIsCancelImport,
                    isCancelImport,
                    setIsTemplateInValid,
                    setCancelTask,
                    cancelTask,
                    setTaskDetail,
                    taskDetail,
                    currentSlug,
                    setCurrentSlug,
                  }}
                  setIseExcelDrawer={setIsExcelDrawer}
                />
              )}

              <div
                className="site-layout-background"
                style={{ minHeight: 380, marginTop: '0.5em' }}>
                <AppRoutes />
              </div>
            </Col>
          </Row>
          <Row>
            {/* {
            useContactsWidget()
          } */}
          </Row>
        </Content>
      </Layout>
    </div>
  );
}
