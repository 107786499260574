import { Switch, Route } from 'react-router-dom';
import Home from '../routes/Home/Home';
import ResetLinkSent from '../routes/Home/ResetLinkSent';
import ChangePassword from '../routes/Home/ChangePassword';
import OtpVerify from '../routes/Home/OtpVerify';
import MobileRegistration from '../routes/Home/MobileRegistration';

export default function PublicRoutes() {
  return (
    <Switch>
      <Route path="/reset-link-sent/:email">
        <ResetLinkSent />
      </Route>
      <Route path="/otp-verify">
        <OtpVerify />
      </Route>
      <Route path="/mobile-registration">
        <MobileRegistration />
      </Route>
      <Route path="/change-password">
        <ChangePassword />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
