import { useEffect, useState } from 'react';
import { getAccessToken } from 'react-common-components/utils';

export const useCustomFetch = (axiosInstance, url, params) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);
  let parameters = {};
  if (params) {
    parameters = params;
  }
  useEffect(() => {
    setIsLoading(true);
    axiosInstance.URL.get(url, {
      params: parameters,
    })
      .then((res) => {
        setApiData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setServerError(error);
        setIsLoading(false);
      });
  }, [axiosInstance, url]);

  return { isLoading, apiData, serverError };
};
