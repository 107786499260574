import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, Alert } from 'antd';
import { Colors } from '../../theme';
import { useGlobal } from '../../store/index';
import axios from 'axios';
import API_KEY from '../../api';
import { useHistory } from 'react-router-dom';

export default function SignIn({ changeState }) {
  const [globalState, globalActions] = useGlobal();
  const [formData, setFormData] = useState(null);
  const { signupNew, error } = globalState;
  const [createAccountbuttonLogging, setcreateAccountbuttonLogging] =
    useState(false);
  const history = useHistory();
  useEffect(() => {
    if (signupNew) {
      API_KEY.URL.post(API_KEY.path.login, formData)
        .then((authData) => {
          localStorage.setItem('temp', JSON.stringify(authData?.data?.data));
          localStorage.setItem(
            'email',
            JSON.stringify(formData?.email.toLowerCase())
          );
          axios
            .post(process.env.REACT_APP_AUTHO_URL, {
              client_id: process.env.REACT_APP_AUTHO_CLIENT_ID,
              client_secret: process.env.REACT_APP_AUTHO_CLIENT_SECRET,
              connection: 'email',
              email: formData.email,
              send: 'code',
            })
            .then((result) => {
              history.push(`/otp-verify`);
            });
        })
        .catch((err) => {
          var er = err?.response?.data?.errors[0].message
            ? err?.response?.data?.errors[0].message
            : 'Something went wrong. Try again';
          console.log(err);
        });

      // store.setState({ isloggedIn: authData?.data?.data, loading: false });

      // globalActions.authActions.signinAction(formData);
    }
  }, [signupNew, globalActions, formData]);
  const onFinish = (values) => {
    setcreateAccountbuttonLogging(true);

    globalActions.authActions.signup({
      ...values,
      role: '418bd52e-13b8-4500-a6a7-2a4587a1b62d',
    });

    setFormData(values);
  };

  const onFinishFailed = (errorInfo) => {
    setcreateAccountbuttonLogging(false);
  };
  const styles = { marginTop: '2rem' };
  return (
    <div>
      <Row>
        <Col span={20}>
          {error && <Alert type="error" message={error} banner />}
          <Form
            name="signUp"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              style={styles}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email',
                },
              ]}>
              <Input size="large" />
            </Form.Item>

            <Form.Item
              style={styles}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
              ]}>
              <Input.Password
                size="large"
                placeholder="Ensure you use a strong password"
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  },
                }),
              ]}>
              <Input.Password
                visibilityToggle={false}
                size="large"
                placeholder="Ensure you use a strong password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{ width: '100%', marginTop: '3rem' }}
                htmlType="submit"
                size="large"
                loading={createAccountbuttonLogging}>
                {createAccountbuttonLogging
                  ? 'Creating account...'
                  : 'Create Account'}
              </Button>
            </Form.Item>
          </Form>{' '}
        </Col>
      </Row>
      <Row justify="center">
        <Col pull={2}>
          {' '}
          <span style={{ fontWeight: 'bolder' }}>
            Already have an account?{' '}
            <span
              style={{
                fontWeight: 'bolder',
                color: Colors.primaryColor,
                cursor: 'pointer',
              }}
              onClick={() => changeState(false)}>
              {' '}
              Log In
            </span>
          </span>
        </Col>
      </Row>
    </div>
  );
}
