import API_KEY from '../../api';

export const products = {
  getProducts: async (store, payload) => {
    const { params } = payload;
    store.setState({ loading: true });
    const data = await API_KEY.URL.get(API_KEY.path.products, { params });
    store.setState({ products: data?.data, loading: false });
  },
  getProductsFilters: async (store, filters, fields, search_query) => {
    store.setState({ loading: true });
    const data = await API_KEY.URL.get(API_KEY.path.products, {
      params: {
        filter: filters
          ? filters
          : {
              user_id: {
                _eq: `$CURRENT_USER`,
              },
            },
        fields: fields,
        meta: 'total_count,filter_count',
        search: search_query,
      },
    });
    store.setState({ products: data?.data, loading: false });
  },

  addProduct: async (store, product) => {
    const prevProduct = store.state.selectedProdutcs;
    const newState = [...prevProduct, product];
    store.setState({ selectedProdutcs: newState, loading: false });
  },

  removeProduct: async (store, product) => {
    const prevProduct = store.state.selectedProdutcs;
    const newState = prevProduct.filter((p) => p.id !== product.id);
    store.setState({ selectedProdutcs: newState, loading: false });
  },

  selectedProduct: async (store, payload) => {
    store.setState({ selectedProdutcs: payload, loading: false });
  },
  clearProducts: (store, payload) => {
    store.setState({ products: null, loading: false });
  },
  setPageDetails: (store, payload) => {
    store.setState({ pageDetails: payload });
  },
  setCollectionId: (store, payload) => {
    store.setState({ collectionId: payload });
  },
};
