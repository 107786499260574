import { Button, Col, Drawer, Row } from 'antd';
import React from 'react';
import { COLORS } from './constants/COLOR';
import './common-style.css';

export default function SWDrawer({
  children,
  success,
  dismiss,
  onSuccess,
  onDismiss,
  show,
  setShow,
  title,
  hideButtons,
  BottomActionBar,
  size,
  width,
  onClose,
  className,
  // displayDrawer
}) {
  return (
    <Drawer
      className={className ? className : 'sw-drawer'}
      size={size ? size : 'default'}
      title={title ? title : ''}
      closable={false}
      placement="right"
      onClose={() => {
        setShow ? setShow(false) : onClose();
      }}
      width={width ? width : 420}
      visible={show ? show : false}
      style={{ paddingBottom: 100, zIndex: 999 }}
      forceRender={true}>
      {children ? children : null}
      {hideButtons ? null : (
        <div
          style={{
            background: COLORS.gray1,
            position: 'absolute',
            bottom: 0,
            height: 72,
            width: '100%',
            left: 0,
            borderTop: '1px solid #d9d9d9 ',
          }}>
          {BottomActionBar ? (
            BottomActionBar
          ) : (
            <Row align="middle" style={{ height: 72 }}>
              <Col span={12} style={{ textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    if (onDismiss) onDismiss();
                  }}
                  style={{ minWidth: 128 }}>
                  {dismiss ? dismiss : 'Cancel'}
                </Button>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <Button
                  style={{
                    minWidth: 128,
                    background: '#000000',
                    color: COLORS.gray1,
                  }}
                  onClick={() => {
                    if (onSuccess) onSuccess();
                  }}>
                  {success ? success : 'Apply'}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    </Drawer>
  );
}

export const BottomBar = ({ children }) => {
  return (
    <div
      style={{
        background: COLORS.gray1,
        position: 'absolute',
        bottom: 0,
        height: 72,
        width: '100%',
        left: 0,
        borderTop: '1px solid #d9d9d9 ',
        zIndex: 10,
      }}>
      <Row align="middle" style={{ height: 72 }}>
        {children}
      </Row>
    </div>
  );
};
