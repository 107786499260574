import { Col, Row, Typography, Switch } from 'antd';
import { useIntl } from 'react-intl';
// import './styles.less';
const { Text } = Typography;

const IncludeImageUrl = ({ setIsImageUrl, isImageUrl }) => {
  const intl = useIntl();
  const handleImageUrl = () => {
    setIsImageUrl(!isImageUrl);
  };
  return (
    <Row justify="space-between" className="image-url-conatiner">
      <Col>
        <Text>
          {intl.formatMessage({
            id: 'sourcewiz.bulkUpload.imageUrlText',
          })}
        </Text>
      </Col>
      <Col>
        <Switch defaultChecked onChange={handleImageUrl} />
      </Col>
    </Row>
  );
};
export default IncludeImageUrl;
