export const sidebar = {
  showSidebar: (store, payload) => {
    store.setState({
      sidebar: { ...store.sidebar, show: payload },
    });
  },
  confirmCheck: (store, payload) => {
    store.setState({
      sidebar: { ...store.sidebar, confirmCheck: payload },
    });
  },
};
