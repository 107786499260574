import { Row, Col, Typography } from 'antd';
import { PlanDiv } from 'routes/SourceWiz/Guardrails/Components/CommonFunction/PlanDetailsDiv';
import { numberWithCommas } from 'routes/SourceWiz/Guardrails/Components/CommonFunction/commonFunctions';
import moment from 'moment';
import './ActivePlanBanner.css';
const { Title, Text } = Typography;
export const ActivePlanBanner = ({ data }) => {
  return (
    data && (
      <>
        <PlanDiv>
          <Row justify="space-between">
            <Col>
              <Title level={5} style={{ fontFamily: 'SatoshiBold' }}>
                Sourcewiz{' '}
                {data?.plan_label?.toUpperCase() ||
                  data?.plan?.label?.toUpperCase()}
              </Title>
            </Col>
            {data?.plan?.name?.toLowerCase() !== 'early bird' &&
              data?.plan_label?.toLowerCase() !== 'early bird' && (
                <Col>
                  <Title level={5}>
                    ₹ {numberWithCommas(data?.amount)} / year
                  </Title>
                </Col>
              )}
          </Row>
          <Row justify="space-between">
            <Col>
              <Text className="subtext-subdiv">
                Next billing date on{' '}
                {moment(data?.end_date).format('Do MMMM YYYY')}
              </Text>
            </Col>
            {data?.plan?.name?.toLowerCase() !== 'early bird' &&
              data?.plan_label?.toLowerCase() !== 'early bird' && (
                <Col>
                  <Text className="subtext-subdiv">Exclusive of GST</Text>
                </Col>
              )}
          </Row>
        </PlanDiv>
      </>
    )
  );
};
