import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const propTypes = {
  level: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.style,
  size: PropTypes.string,
  variant: PropTypes.oneOf(['outline', 'primary', 'dark', 'outline_red']),
};
const Index = ({ level, type, style, children, variant, size, ...props }) => {
  const buttonStyle = (variant) => {
    let newStyle = {};
    switch (variant) {
      case 'outline':
        newStyle = {
          border: '1px solid #0B7764',
          borderRadius: '4px',
          color: '#0B7764',
          backgroundColor: '#F0F8F6',
        };
        break;
      case 'dashed':
        newStyle = {
          border: '1px dashed #0B7764',
          borderRadius: '4px',
          color: '#0B7764',
          backgroundColor: '#F0F8F6',
        };
        break;
      case 'primary':
        newStyle = {
          backgroundColor: '#0B7764',
          border: '1px solid #0B7764',
          borderRadius: '4px',
          color: '#fff',
        };
        break;
      case 'dark':
        newStyle = {
          backgroundColor: '#001529',
          border: '1px solid #001529',
          borderRadius: '4px',
          color: '#fff',
        };
        break;
      case 'outline_red':
        newStyle = {
          border: '1px solid #F34D4D',
          borderRadius: '4px',
          color: '#F34D4D',
          backgroundColor: '#FFFFFF',
        };
        break;
      case 'outline_gray':
        newStyle = {
          border: '1px solid #CDCFCE',
          color: '#4F5655',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
        };
        break;
      case 'disabled': 
        newStyle = {
          border: 'none',
          color: '#9B9F9E',
          borderRadius: '4px',
          backgroundColor: '#E6E7E7',
        };
        break;
      default:
        break;
    }
    return newStyle;
  };
  return (
    <Button
      // level={level}
      type={type || 'secondary'}
      style={{ ...style, ...buttonStyle(variant) }}
      {...props}
      size={size || 'large'}>
      {children}
    </Button>
  );
};

Index.propTypes = propTypes;

export default Index;
