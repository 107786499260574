import React from 'react';
import { Layout, Avatar, Typography, Popover, Button } from 'antd';
import { useGlobal } from '../../store/index';
import { UserOutlined } from '@ant-design/icons';
import Logo from './Logo.svg';
import { useHistory } from 'react-router-dom';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const { Header } = Layout;
const { Title } = Typography;

export default function HeaderComponent() {
  const [globalState, globalActions] = useGlobal();
  const history = useHistory();

  const handleLogout = () => {
    clearLocalStorageAndCookie();
    if (sessionStorage.getItem('routing-path')) {
      history.push(sessionStorage.getItem('routing-path'));
    } else {
      history.push('/');
    }
    globalActions.authActions.signoutAction();
    window.location.reload();
  };
  const content = (
    <div>
      <Button
        onClick={() => {
          handleLogout();
        }}
        type="ghost">
        Logout
      </Button>
    </div>
  );
  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        background: 'white',
        borderBottom: '1px solid lightgray',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          height: '58px',
          padding: '1rem',
        }}>
        <Title
          style={{
            // color: Colors.primaryColor,
            fontWeight: 'normal',
            fontFamily: 'SatoshiBold',
            fontSize: 25,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 17,
          }}>
          <img
            src={Logo}
            alt="sourcewiz"
            height={25}
            width={25}
            style={{ marginRight: '0.5rem' }}
          />
          Sourcewiz
        </Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {/* <div>
              <Title
                level={5}
                style={{
                  fontWeight: "bolder",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
              >
                Recent Activity
              </Title>{" "}
            </div>

            <div>
              <Title
                level={5}
                style={{
                  fontWeight: "bolder",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
              >
                Help
              </Title>
            </div> */}

          <Popover placement="bottom" content={content} trigger="click">
            <Avatar
              style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
              icon={<UserOutlined />}
            />
          </Popover>
        </div>
      </div>
    </Header>
  );
}
