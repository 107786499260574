import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Alert, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { Colors } from 'theme';
import { useGlobal } from 'store/index';
import axios from 'axios';
import API_KEY from 'api';
import { EMAILS } from 'utils/emails';
import { getAccountDetails } from 'api/customApis';
import { generateOTP } from 'api/authApis';
import { useTracking } from 'react-tracking';
import { LOGINS } from 'analytics/events';

export default function SignIn({ changeState }) {
  const [globalState, globalActions] = useGlobal();
  const history = useHistory();
  const [email, setEmail] = useState(null);
  const [loginButtonLoading, setloginButtonLoading] = useState(false);
  const [ForgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const { isloggedIn, error } = globalState;
  const [errorState, setErrorState] = useState('');
  const { trackEvent } = useTracking();
  const location = useLocation();
  const onFinish = async (values) => {
    values.email = email;
    setloginButtonLoading(true);
    try {
      const authData = await API_KEY.URL.post(API_KEY.path.login, values);
      const email = values?.email.toLowerCase();
      localStorage.setItem('temp', JSON.stringify(authData?.data?.data));
      localStorage.setItem('email', JSON.stringify(email));
      const token = authData?.data?.data?.access_token;
      // if token is not undefined
      if (token) {
        const myData = await getAccountDetails(token);
        if (myData.data.data?.meta?.otp_verification === false) {
          globalActions.authActions.signinAction(values);
          setloginButtonLoading(true);
          if (error) {
            setloginButtonLoading(false);
          }
        } else {
          await generateOTP(
            {
              email,
              mobile: myData.data.data?.mobile,
              country: myData.data.data?.country?.id,
            },
            token
          );
          setloginButtonLoading(false);
          history.push({
            pathname: '/otp-verify',
            state: {
              email,
              mobile: myData.data.data?.mobile,
              country: myData.data.data?.country,
              token,
              onlyMobileNum: false,
            },
          });
        }
      } else {
        setloginButtonLoading(false);
        setErrorState('Invalid credentials. Please try again');
      }
    } catch (err) {
      var er = err?.response?.data?.errors?.[0].message
        ? err?.response?.data?.errors[0].message
        : 'Something went wrong. Try again';
      setErrorState(er);
      setloginButtonLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      setloginButtonLoading(false);
    }
  }, [error]);

  const onFinishFailed = (errorInfo) => {
    setloginButtonLoading(false);
  };
  const styles = { marginTop: '2rem' };

  const handleForgotPassword = () => {
    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    if (validateEmail(email)) {
      setForgotPasswordLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_DIRECTUS}${API_KEY.path.passwordRequest}`,
          {
            email: email,
            reset_url: process.env.REACT_APP_RESET_URL,
          }
        )
        .then(() => {
          setForgotPasswordLoading(false);
          history.push(`/reset-link-sent/${email}`);
        });
    } else {
      message.error('Email is not valid');
    }
  };
  const handleEmailChange = (e) => {
    if (e.target.value) {
      setEmail(e.target.value.toLowerCase());
    }
  };

  return (
    <div>
      <Row>
        <Col span={20}>
          {errorState || error ? (
            <Alert
              type="error"
              message={errorState || error}
              banner
              data-test="alert_invalid_credentials"
            />
          ) : null}
          <Form
            name="signUp"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please enter a valid email',
                },
              ]}
              style={styles}
              label="Email"
              name="email">
              <Input
                size="large"
                onChange={(e) => handleEmailChange(e)}
                data-test="input_email"
              />
            </Form.Item>

            <Form.Item
              style={styles}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password',
                },
              ]}>
              <Input.Password size="large" data-test="input_password" />
            </Form.Item>
            {/* <span
              style={{
                color: Colors.primaryColor,
                marginTop: "1rem",
                cursor: "pointer",
              }}
              onClick={() => handleForgotPassword()}
            >
              Forgot Password ?
            </span> */}

            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={handleForgotPassword}
              data-test="button_forget_password"
              loading={ForgotPasswordLoading}>
              {ForgotPasswordLoading
                ? 'Sending reset email...'
                : 'Forgot Password ?'}
            </Button>

            <Form.Item>
              <Button
                type="primary"
                style={{ width: '100%', marginTop: '2rem' }}
                htmlType="submit"
                size="large"
                data-test="button_login"
                loading={loginButtonLoading}>
                {loginButtonLoading ? 'Logging in..' : 'Log In'}
              </Button>
            </Form.Item>
          </Form>{' '}
        </Col>
      </Row>
      <Row justify="center">
        <Col pull={2}>
          {' '}
          <span style={{ fontWeight: 'bolder' }}>
            New to Sourcewiz?
            <a
              style={{
                fontWeight: 'bolder',
                color: Colors.primaryColor,
                cursor: 'pointer',
              }}
              rel="noreferrer"
              href="https://cal.com/team/sourcewiz/product-demo"
              target="_blank">
              {' '}
              Book a Demo Now
            </a>
          </span>
        </Col>
      </Row>
    </div>
  );
}
