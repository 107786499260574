import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import utils from '../utils';
import { useGlobal } from 'store/index';
import selectors from '../selectors';
import { Col, Row, Typography } from 'antd';
import UpdateImage from 'assets/images/UpdateImage.svg';
import { useIntl } from 'react-intl';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { BULK_IMAGE_UPDATE_START } from 'analytics/events';

const { Text } = Typography;

export default function BulkUpdateImage({ hideDrawer, importSocketData }) {
  const [selectedProducts, setSelectedProducts] = useState({});
  const intl = useIntl();
  const history = useHistory();
  const { trackEvent } = useTracking();

  let importTasks = Object.values(importSocketData)?.filter((item) =>
    item?.action?.includes('bulk_update_images')
  );
  importTasks = importTasks[importTasks?.length - 1];

  const tempData = useSelector((state) =>
    selectors.selectTempCheckedProducts()(state)
  );

  useEffect(() => {
    const finalSelection = utils.getSelectionData(tempData);
    setSelectedProducts(finalSelection);
  }, [tempData]);

  const startBulkUpload = () => {
    trackEvent({
      name: BULK_IMAGE_UPDATE_START,
      meta: {
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    hideDrawer();
    history.push('/bulk-image-upload/upload');
  };

  const continueBulkImageUpdate = () => {
    trackEvent({
      name: BULK_IMAGE_UPDATE_START,
      meta: {
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    hideDrawer();
    history.push(
      importTasks?.task_meta?.current_state === 'image_grouping'
        ? `/bulk-image-upload/grouping/${importTasks?.task_id}`
        : `/bulk-image-upload/linking/${importTasks?.task_id}`
    );
  };

  return (
    <Row className="import-excel-wrap" justify="space-between" align="center">
      <Col span={2} className="cardIcon">
        <img
          src={UpdateImage}
          className="import-excel-wrap_img"
          alt="importExcel"
        />
      </Col>
      <Col span={2} />
      <Col className="import-excel-wrap_import-text-area" span={20}>
        <Row justify="start">
          <Col>
            <Row>
              <>
                <Text className="import-excel-wrap_import-text-area_head">
                  Update Images
                  {Object.keys(selectedProducts)?.length > 0 && (
                    <Text>
                      {' '}
                      for {Object.keys(selectedProducts)?.length} product(s)
                    </Text>
                  )}
                </Text>
                {importTasks?.status && importTasks?.status !== 'success' && (
                  <Col>
                    <Row>
                      <Text className="import-excel-wrap_status-in-progress">
                        IN PROGRESS
                      </Text>
                    </Row>
                  </Col>
                )}
              </>
            </Row>
          </Col>
        </Row>

        <Row>
          <Text className="import-excel-wrap_import-text-area_sub">
            {intl.formatMessage({
              id: 'sourcewiz.manageProducts.bulkImageUpdate.uploadImages',
            })}
          </Text>
        </Row>
        <Row className="updateImageCta">
          {importTasks?.status && importTasks?.status !== 'success' ? (
            <Button
              onClick={() => continueBulkImageUpdate()}
              className="excel-card-button standard-button export-download-cta">
              Continue
            </Button>
          ) : (
            <Button
              onClick={() => startBulkUpload()}
              className="excel-card-button standard-button export-download-cta">
              Start update
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
}
