export const bulkUpdateActions = {
  setIsImportExportDrawerActive: async (store, payload) => {
    store.setState({ isImportExportDrawerActive: payload });
  },
  setSelectedProducts: async (store, payload) => {
    store.setState({ selectedProducts: payload });
  },
  setIsUploadOpen: async (store, payload) => {
    store.setState({ isUploadOpen: payload });
  },
  setImportSocketData: async (store, payload) => {
    store.setState({ importSocketData: payload });
  },
  setViewSocketData: async (store, payload) => {
    store.setState({ viewSocketData: payload });
  },
  setIsImportSocketEqual: async (store, payload) => {
    store.setState({ isImportSocketEqual: payload });
  },
  setIsBulkUploadExcel: async (store, payload) => {
    store.setState({ isBulkUploadExcel: payload });
  },
  setBulkUploadTaskId: async (store, payload) => {
    store.setState({ bulkUploadTaskId: payload });
  },
};
