import { Row, Typography } from 'antd';
import postRequestSvg from '../../../../../assets/images/postupgraderequest.svg';
import { PostRequestTexts } from './PostRequestTexts';
import './PostRequestContents.css';
const { Title, Text } = Typography;
export const PostRequestConstants = () => {
  return (
    <>
      <Row align="center">
        <img src={postRequestSvg} alt="img" />
      </Row>
      <Row align="center" className="post-request-modal-row">
        <Title level={5}>{PostRequestTexts?.title}</Title>
      </Row>
      <Row align="center">
        <Text>{PostRequestTexts?.text}</Text>
      </Row>
      <Row align="center" className="post-request-modal-row">
        <Text>Need help? </Text>
        <Text className="post-request-help-text">
          +919215777736 |{' '}
          <a href="mailto:support@sourcewiz.co">support@sourcewiz.co</a>
        </Text>
      </Row>
    </>
  );
};
