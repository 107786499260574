import {
  Modal,
  Row,
  Typography,
  Col,
  Divider,
  Button,
  Skeleton,
  message,
} from 'antd';
import { PYTHON_API_KEY } from 'api/customApis';
import './PlanExpiredModal.css';
import { useEffect, useState } from 'react';
import { UpgradePlanModal } from 'routes/SourceWiz/Guardrails/Components/PlanUpgrade/UpgradePlanModal';
import { UpgradeRequestModal } from '../UpgradeRequest/UpgradeRequestModal';
import { ActivePlanBanner } from 'routes/SourceWiz/ProfileSettings/Subscription/ActivePlanBanner/ActivePlanBanner';
import { BreakdownSection } from 'routes/SourceWiz/ProfileSettings/PaymentAndInvoice/BreakdwonSection/BreakdownSection';
import PYTHON_KEY from 'api/pythonService';
import style from 'styled-components';
import { RequestStatusModal } from '../UpgradeRequest/RequestStatusModal';
import { PostRequestTexts } from '../CommonFunction/PostRequestTexts';
import { LogoutComponent } from '../CommonFunction/LogoutComponent';
const { Title, Text } = Typography;
const ButtonContainer = style.div`
  .renew-plan-btn{
    background: #1D2624;
    color: white;
    border: none;
  }
`;
export const PlanExpiredModal = ({
  isPlanExpired,
  setIsPlanExpired,
  forRenew,
  daysLeftInPlan,
}) => {
  const [planData, setPlanData] = useState([]);
  const [isChangePlanModal, setIsChangePlanModal] = useState(false);
  const [isUpgradeRequestModal, setIsUpgradeRequestModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const [renewBtnLoading, setRenewBtnLoading] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  useEffect(() => {
    if (isPlanExpired) {
      getPlanData();
    }
  }, [isPlanExpired]);
  const getPlanData = () => {
    setLoading(true);
    PYTHON_API_KEY.URL.get(PYTHON_API_KEY.path.invoiceDetails, {})
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          const data = res?.data?.recurring_plan_details;
          setPlanData(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const handleRenewPlan = () => {
    setRenewBtnLoading(true);
    PYTHON_KEY.URL.get(PYTHON_KEY.path.availableplans, {})
      .then((res) => {
        if (res?.status === 200) {
          setRenewBtnLoading(false);
          const pending_requests = res?.data?.pending_requests;
          const plan_request_pending = pending_requests?.filter(
            (item) => item?.feature_type === 'plan'
          );
          if (plan_request_pending?.length > 0) {
            setIsRequestPending(true);
          } else {
            handleRequestModal();
          }
        }
      })
      .catch((err) => {
        console.log(err, 'error');
        setRenewBtnLoading(false);
        message.err(`Something went wrong`);
      });
  };
  const handleRequestModal = () => {
    setSelectedPlan({
      request_type: 'upgrade',
      feature_type: 'plan',
      feature_identifier: planData?.current_plan?.plan_id,
    });
    if (selectedPlan) {
      setIsUpgradeRequestModal(true);
    }
  };
  const handleCancel = () => {
    if (forRenew) {
      setIsPlanExpired(false);
    } else {
      return;
    }
  };
  return (
    planData && (
      <>
        <Modal
          visible={isPlanExpired}
          closable={forRenew ? true : false}
          footer={null}
          width={650}
          onCancel={handleCancel}>
          {loading ? (
            <Row>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Row>
          ) : (
            <>
              <Row>
                <Col span={21}>
                  {forRenew ? (
                    <Title level={5}>
                      Your {planData?.current_plan?.plan_label} plan is expiring{' '}
                      {daysLeftInPlan === 0
                        ? ` today`
                        : ` in ${daysLeftInPlan}`}{' '}
                      {daysLeftInPlan > 1
                        ? 'days'
                        : daysLeftInPlan !== 0 && 'day'}
                    </Title>
                  ) : (
                    <Title level={4}>
                      Your {planData?.current_plan?.plan_label} plan has expired
                    </Title>
                  )}
                </Col>
                {isPlanExpired && !forRenew && <LogoutComponent />}
                <br />
              </Row>
              <Text className="sub-text-plan-expired">
                Select from our range of plans that best suit your requirements
              </Text>

              {planData?.current_plan && (
                <ActivePlanBanner data={planData?.current_plan} />
              )}

              {/* Breakdown Section */}
              {planData &&
                planData?.current_plan?.plan_name?.toLowerCase() !==
                  'early bird' && (
                  <BreakdownSection width={'100%'} data={planData} />
                )}

              <Row style={{ marginTop: '2rem' }} justify="space-between">
                <Col>
                  <Button onClick={() => setIsChangePlanModal(true)}>
                    Change plan
                  </Button>
                </Col>
                <Col>
                  <ButtonContainer>
                    <Button
                      className="renew-plan-btn"
                      type="primary"
                      loading={renewBtnLoading}
                      onClick={handleRenewPlan}>
                      Renew plan
                    </Button>
                  </ButtonContainer>
                </Col>
              </Row>
            </>
          )}
        </Modal>
        <UpgradePlanModal
          forDemo={false}
          isModalVisible={isChangePlanModal}
          isPlanExpired={true}
          setIsModalVisible={setIsChangePlanModal}
        />
        <UpgradeRequestModal
          requestPayload={selectedPlan}
          upgradeRequestModal={isUpgradeRequestModal}
          setUpgradeRequestModal={setIsUpgradeRequestModal}
        />
        {isRequestPending && (
          <RequestStatusModal
            data={PostRequestTexts}
            requestStatus={'post_request'}
            requestStatusModal={isRequestPending}
            setRequestStatusModal={setIsRequestPending}
          />
        )}
      </>
    )
  );
};
