export const COLORS = {
  green1: '#F0F8F6',
  green2: '#C9E4DF',
  green3: '#7DBBAD',
  green4: '#6DADA2',
  green5: '#54A093',
  green6: '#3C9283',
  green7: '#0B7764',
  green8: '#14453C',
  green9: '#0C2924',
  green10: '#040E0C',
  gray1: '#FFFFFF',
  gray2: '#F5F5F5',
  gray3: '#E6E7E7',
  gray4: '#CDCFCE',
  gray5: '#B4B7B6',
  gray6: '#9B9FFE',
  gray7: '#828786',
  gray8: '#686E6D',
  gray9: '#4F5655',
  iconcolor: 'rgba(0,0,0,0.54)',
  iconColorRed:'#F34D4D'
};
