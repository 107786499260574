import React, { useEffect, useState } from 'react';
import { getCountryCodes } from 'api/customApis';
import { Form, Input, Select, Skeleton, Row, Col } from 'antd';
import { useGlobal } from '../../store/index';

const { Option } = Select;
export default function PhoneNumber({ form, field, name, rest }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [globalState] = useGlobal();

  useEffect(() => {
    setCountryCodes(globalState?.countriesList);
  }, []);

  const prefixSelector = (
    <Form.Item
      {...rest}
      name={[name[0], 'country_code']}
      style={{ marginBottom: 0 }}
      initialValue={`+91 (IND)`}>
      <Select
        dropdownAlign={{ points: ['bl', 'tl'] }}
        allowClear
        className="country_codes_select"
        showSearch
        style={{ width: 130 }}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        // value={99}
        // onChange={(value) => {
        //   setSelectedCoutryCode(value);
        // }}
      >
        {countryCodes.map((item) => {
          return (
            <Option
              key={item.id}
              value={
                item.phonecode
              }>{`${item.iso3} (+${item.phonecode})`}</Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form.Item
          {...rest}
          style={{ flexGrow: 1, paddingRight: '0.5rem' }}
          label={field?.label}
          name={name}>
          <Input
            onWheel={(e) => e.currentTarget.blur()}
            addonBefore={prefixSelector}
            type="number"
            size="large"
            // onChange={(e) =>
            //   setPhoneNumber(`${selectedCoutryCode}${e.target.value}`)
            // }
          />
        </Form.Item>
      )}
    </>
  );
}
