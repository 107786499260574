import React from 'react';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Row, Col, Typography, Form, Input, Button } from 'antd';
import Logo from '../../assets/images/logo64.png';
import { Colors } from '../../theme';
import { useParams } from 'react-router-dom';
import API_KEY from '../../api';
import PasswordResetDone from './PasswordResetDone';

const { Title, Text } = Typography;

export default function ChangePassword() {
  const params = useParams();
  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  const [passwordResetDone, setpasswordResetDone] = useState(false);
  const onResetPasssword = (formValues) => {
    axios
      .post(`${process.env.REACT_APP_DIRECTUS}${API_KEY.path.passwordReset}`, {
        token: urlParams.get('token'),
        password: formValues.password,
      })
      .then(() => {
        setpasswordResetDone(true);
      });
  };
  return (
    <div
      style={{
        height: '100vh',
        background: '#E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Row
        style={{
          width: '80%',
          background: 'white',
          padding: '4rem',
          borderRadius: 12,
        }}>
        <Col span={24}>
          <Row align="middle">
            <img src={Logo} alt="sourcewiz" width="40px" height="40px" />
            <span
              style={{
                fontSize: '32px',
                // fontWeight: "bolder",
                color: Colors.black,
                fontFamily: 'SatoshiBold',
                // marginBottom:'2rem'
                marginLeft: '0.5rem',
              }}>
              Sourcewiz
            </span>
          </Row>
          {passwordResetDone ? (
            <PasswordResetDone />
          ) : (
            <div>
              <Row
                style={{ marginTop: '2rem', marginBottom: '2rem' }}
                justify="center">
                <Title level={4}>Create new password</Title>
              </Row>
              <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onResetPasssword}>
                <Row>
                  <Col span={8} offset={8}>
                    <Form.Item
                      label="New Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}>
                      <Input.Password
                        size="large"
                        placeholder="Ensure you use a strong password"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} offset={8}>
                    <Form.Item
                      label="Confirm Password"
                      name="confirm_password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              'The two passwords that you entered do not match!'
                            );
                          },
                        }),
                      ]}>
                      <Input.Password
                        visibilityToggle={false}
                        size="large"
                        placeholder="Ensure you use a strong password"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} offset={8}>
                    <Button
                      style={{ width: '100%' }}
                      size="large"
                      type="primary"
                      htmlType="submit">
                      Reset password
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
          <Row justify="center" style={{ marginTop: '5rem' }}>
            <Col>
              <Text type="secondary">
                If you are still facing issues to reset your password reach out
                to{' '}
                <span style={{ color: Colors.primaryColor }}>
                  support@sourcewiz.co
                </span>
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
