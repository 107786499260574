import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;
export default function Index({ type, style, children, ...props }) {
  return (
    <Text
      type={type || 'secondary'}
      style={{ ...style, padding: 0, margin: 0 }}
      {...props}>
      {children}
    </Text>
  );
}
