import { useCallback, useState } from 'react';
import { interval } from './interval';

const use1Second = interval(1e3);

export const useTimer = ({
  seconds: initialSeconds = 0,
  running: initiallyRunning = false,
} = {}) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [running, setRunning] = useState(initiallyRunning);

  const start = () => setRunning(true);
  const pause = () => setRunning(false);
  const reset = () => setSeconds(initialSeconds);
  const stop = () => {
    pause();
    reset();
  };

  const tick = useCallback(
    () =>
      running
        ? setSeconds((seconds) => {
            if (seconds === 0) {
              stop();
              return 0;
            }
            return seconds - 1;
          })
        : undefined,
    [running]
  );

  use1Second(tick);

  return { pause, reset, running, seconds, start, stop };
};
