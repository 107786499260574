import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Typography, message, Select, Input } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from 'theme';
import HomeSVG from './Home.svg';
import Logo from 'assets/images/logo64.png';

import { updateMobileNumber, getCountryCodes } from 'api/customApis';
import { generateOTP } from 'api/authApis';
import { getOpenAuthCountryCodes } from 'api/noAuthApis';

const { Option } = Select;
const { Text } = Typography;

const Imagecontainer = styled.div`
  img {
    width: 95%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export default function MobileRegistration() {
  const location = useLocation();
  const params = location.state;
  const history = useHistory();
  const [countryCodes, setCountryCode] = useState([]);
  const [countryId, setCountryId] = useState();
  const [country, setCountry] = useState();
  const [mobile, setMobileNum] = useState();
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleRegisterMobileNum = () => {
    if (mobile && countryId) {
      generateOTP({ mobile, country: countryId }, params.token)
        .then(() => {
          setButtonLoading(false);
          history.push({
            pathname: '/otp-verify',
            state: {
              mobile,
              country,
              token: params.token,
              onlyMobileNum: true,
              updateMobileNum: true,
            },
          });
        })
        .catch((e) => {
          message.error(`Failed to generate OTP`);
          setButtonLoading(false);
        });
    } else {
      if (!mobile) {
        message.error('Please enter your contact number');
      } else {
        message.error('Please enter your country code');
      }
    }
  };

  const fetchData = async () => {
    try {
      const res3 = await getOpenAuthCountryCodes();
      setCountryCode(res3.data.data);
      setLoading(false);
    } catch (e) {
      message.error('Failed to fetch country codes');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        background: '#E5E5E5',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Row
        style={{
          width: '80%',
          background: 'white',
          padding: '4rem',
          borderRadius: 12,
        }}>
        <Col sm={24} xs={24} md={24} lg={12} xl={12}>
          <Row align="middle">
            <img src={Logo} alt="sourcewiz" width="40px" height="40px" />
            <span
              style={{
                fontSize: '32px',
                color: Colors.black,
                fontFamily: 'SatoshiBold',
                marginLeft: '0.5rem',
              }}>
              Sourcewiz
            </span>
          </Row>
          <Row style={{ marginTop: '1.5rem' }}>
            <Col span={24}>
              <Row style={{ marginTop: '4rem' }}>
                <Text
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#040E0C',
                  }}>
                  Register your mobile number
                </Text>
              </Row>
              <Row style={{ marginTop: '0.5rem' }}>
                <Text
                  style={{
                    fontSize: '14px',
                    color: '#4F5655',
                  }}>
                  OTP will be sent to the registered number
                </Text>
              </Row>

              <Row style={{ marginTop: '1rem' }}>
                <Row style={{ width: '100%' }}>
                  <Col span={6}>
                    <Select
                      dropdownAlign={{ points: ['bl', 'tl'] }}
                      allowClear
                      className="country_codes_select"
                      showSearch
                      style={{ width: '100%', height: '100%' }}
                      placeholder="code"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(value, e) => {
                        setCountry(e?.data);
                        setCountryId(value);
                      }}
                      required={true}
                      defaultValue={countryId}>
                      {countryCodes.map((item) => {
                        return (
                          <Option
                            data={item}
                            value={
                              item.id
                            }>{`${item.iso3}+(${item.phonecode})`}</Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={16} offset={1}>
                    <Input
                      size="large"
                      onChange={(e) => setMobileNum(e.target.value)}
                      value={mobile}
                      type="number"
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  </Col>
                </Row>
              </Row>

              <Row style={{ marginTop: '7rem' }}>
                <Col span={11}>
                  <Button
                    type="secondary"
                    size="large"
                    style={{ width: '100%' }}
                    onClick={() => {
                      history.goBack();
                    }}>
                    {'Go back'}
                  </Button>
                </Col>
                <Col span={11} offset={1}>
                  <Button
                    loading={buttonLoading}
                    type="primary"
                    size="large"
                    style={{ width: '100%' }}
                    onClick={handleRegisterMobileNum}>
                    Register
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={24} xs={24} md={12} lg={12} xl={12}>
          <Imagecontainer>
            <img src={HomeSVG} alt="sourcewiz" />
          </Imagecontainer>
        </Col>
      </Row>
    </div>
  );
}
