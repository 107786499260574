import React from 'react';
import { Modal, Row, Typography, Image } from 'antd';
import bulkExcelDownload from 'assets/gif/bulkExcelDownload.gif';
import './index.less';
import Button from 'react-common-components/Button';

const { Text } = Typography;

export default function BulkExcelDownload({
  isBulkExcelDownload,
  setIsBulkExcelDownload,
}) {
  return (
    <Modal
      className="download-excel-modal"
      title=""
      centered
      visible={isBulkExcelDownload}
      width={441}
      footer={null}
      onOk={() => setIsBulkExcelDownload(false)}
      onCancel={() => setIsBulkExcelDownload(false)}>
      <Row>
        <Image
          src={bulkExcelDownload}
          preview={false}
          className="bulk-excel-modal-img"
        />
      </Row>
      <Row className="download-excel-modal-title" justify="center">
        <Row>
          <Text className="title-style">
            File is getting ready for download
          </Text>
        </Row>
        <Row>
          <Text>You can check the progress in the task bar</Text>
        </Row>
        <Row>
          <Button
            onClick={() => setIsBulkExcelDownload(false)}
            variant="dark"
            size="large"
            className="title-style">
            Okay, Got it!
          </Button>
        </Row>
      </Row>
    </Modal>
  );
}
