export const packagingAttributes = {
  addAttributes: (store, payload) => {
    store.setState({
      packagingAttributes: payload,
    });
  },
  updateAttributes: (store, payload) => {
    const newAttributes = store.state.packagingAttributes.filter(
      (val) =>
        val.attribute_values_id.attribute_id.id !==
        payload.attribute_values_id.attribute_id.id
    );
    store.setState({
      packagingAttributes: [...newAttributes, payload],
    });
  },
};
