import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Divider, Image } from 'antd';
import Selectors from '../selectors';
import '../BulkUpdate/index.less';
import ExportBulkExcel from 'assets/images/ExportData.svg';
import { useGlobal } from 'store/index';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from 'components/index';
import { downloadBulkExcel } from 'api/collectionApis';
import utils from '../utils';
import ExcelLimitWarning from 'assets/images/excellimitwarning.svg';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';
const { Text } = Typography;

const ExportExcelCard = ({
  setIsCreateTemplate,
  setIsChangeTemplate,
  setIsBulkExcelDownload,
  defaultBulkExcelTemplate,
  setIsDiscard,
  excelLimitThreshold,
}) => {
  const [globalState, globalActions] = useGlobal();
  const [selectedProduct, setSelectedProduct] = useState({});
  const { pageDetails } = globalState;
  const { trackEvent } = useTracking();
  const location = useLocation();
  const intl = useIntl();

  const handleExportDrawer = () => {
    trackEvent({
      name: 'export_create_template_entrypoint_cta',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });
    setIsDiscard(false);
    setIsCreateTemplate(true);
  };

  const tempData = useSelector((state) =>
    Selectors.selectTempCheckedProducts()(state)
  );
  useEffect(() => {
    let finalSelection = utils.getSelectionData(tempData);
    setSelectedProduct(finalSelection);
  }, [tempData]);

  const downloadExcelSheet = (id) => {
    let data = {};
    for (let items in selectedProduct) {
      let variantIds = selectedProduct[items]?.variants?.variant_ids;
      variantIds = variantIds.filter((elem) => elem !== Number(items));
      data[items] = variantIds?.filter((id) => id !== 'undefined');
    }

    const payload = {
      product_ids: Object.keys(selectedProduct)?.length ? data : 'all',
      template_id: id,
    };

    trackEvent({
      name: 'export_download_main',
      meta: {
        data: payload,
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel Export',
      },
    });

    downloadBulkExcel(payload);
    globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
    setIsBulkExcelDownload(true);
  };

  return (
    <>
      <Row
        className="import-excel-wrap exportExcelWrap custom-export-main"
        justify="space-between">
        <Row>
          <Col span={2} className="cardIcon">
            <img
              src={ExportBulkExcel}
              className="import-excel-wrap_img"
              alt="exportExcel"
            />
          </Col>

          <Col span={2} />
          <Col className="import-excel-wrap_import-text-area" span={20}>
            <Row>
              <Text className="import-excel-wrap_import-text-area_head">
                Export data of{' '}
                <Text className="excel-card-selected-products">
                  {Object.keys(selectedProduct)?.length
                    ? Object.keys(selectedProduct)?.length?.toLocaleString()
                    : pageDetails?.nbHits?.toLocaleString()}
                </Text>{' '}
                product(s)
              </Text>
            </Row>
            <Row>
              <Text className="import-excel-wrap_import-text-area_sub">
                Information will be downloaded as per{' '}
                <Text className="excel-card-title">
                  {defaultBulkExcelTemplate?.name}
                </Text>
                .{' '}
                <Text
                  onClick={() => {
                    trackEvent({
                      name: 'export_change_template',
                      meta: {
                        location: location.pathname,
                        modules: 'Manage Products',
                        property: 'Bulk excel Export',
                      },
                    });
                    setIsChangeTemplate(true);
                  }}
                  className="excel-card-text">
                  {/* Change Template */}
                  {intl.formatMessage({
                    id: 'sourcewiz.button.changeTemplate',
                  })}
                </Text>
              </Text>
            </Row>
          </Col>
        </Row>

        {excelLimitThreshold?.product_threshold &&
          ((Object.keys(selectedProduct)?.length === 0 &&
            pageDetails?.nbHits > excelLimitThreshold?.product_threshold) ||
            Object.keys(selectedProduct)?.length >
              excelLimitThreshold?.product_threshold) && (
            <Row justify="start" className="excel-limit-note">
              <img
                src={ExcelLimitWarning}
                className="excel-limit-note_img"
                alt="excel-limit-note"
              />
              <Text className="excel-limit-note_txt">
                {intl.formatMessage({
                  id: 'sourcewiz.bulkExport.thumbnailMessage',
                })}
              </Text>
            </Row>
          )}
        <Row className="info">
          <Text>
            <InfoCircleFilled style={{ color: 'rgba(230, 161, 69, 1)' }} />{' '}
            Sheet will have thumbnail urls & not images
          </Text>
        </Row>
        <Row className="excel-export-card-footer-container">
          <Col>
            <Button
              onClick={() => downloadExcelSheet(defaultBulkExcelTemplate?.id)}
              className="excel-card-button standard-button export-download-cta">
              Download
            </Button>
          </Col>
          <Col className="excel-card-template-button">
            <Button
              onClick={handleExportDrawer}
              className="excel-card-button standard-button export-create-template-cta">
              Create template
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ExportExcelCard;
