import React, { useEffect, useState } from 'react';
import './index.less';
import { Row, Col, Button, Typography, notification, message } from 'antd';
import SWDrawer from 'react-common-components/SWDrawer';
import ImportValidationStep from './ImportValidationStep';
import closeicon from 'assets/images/closeIcon.svg';
import greenTick from 'assets/images/greenTick.svg';
import { getStepsImport, uploadImportBulk } from 'api/customApis';
import { useGlobal } from 'store/index';
import importerror from 'assets/images/importerror.svg';
import { useHistory } from 'react-router-dom';
import Footer from './Footer';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import * as equal from 'deep-equal';
import { STATUSES } from 'constants/status';
import bulkActionApis from 'api/bulkActionApis';
const { Text } = Typography;

function ImportDrawer({
  isUploadOpen,
  setIseExcelDrawer,
  setIsCancelImport,
  setIsTemplateInValid,
  setCancelTask,
  setTaskDetail,
  taskDetail,
  setCurrentSlug,
}) {
  const [globalState, globalActions] = useGlobal();
  const [stepData, setStepData] = useState([]);
  const [usableStepData, setUsableStepData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { importSocketData, viewSocketData } = globalState;
  const history = useHistory();
  const [isNotificationTrigger, setIsNotificationTrigger] = useState(false);
  const [isCancel, setIsCancel] = useState(true);
  const currentEmail = localStorage.getItem('email').slice(1, -1);
  const isAdmin = localStorage.getItem('isAdmin');
  const { isBulkUploadExcel, bulkUploadTaskId } = globalState;

  const { trackEvent } = useTracking();
  const location = useLocation();

  const getSteps = () => {
    // if(isBulkUploadExcel) {
    //   //
    // } else {
    const key = isBulkUploadExcel ? 'steps_bulk_upload' : 'steps_bulk_import';
    if (isBulkUploadExcel) {
      setTaskDetail(bulkUploadTaskId);
    }
    getStepsImport(key)
      .then((res) => {
        if (res?.status === 200) {
          setStepData(res?.data?.value?.value?.steps);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  useEffect(() => {
    if (isUploadOpen) {
      getSteps();
    }
  }, [isUploadOpen]);

  const uploadExcel = async (options, taskid) => {
    trackEvent({
      name: 'bulk_import_upload_again',
      meta: {
        location: location.pathname,
        modules: 'Manage Products',
        property: 'Bulk excel import',
      },
    });
    setIsNotificationTrigger(false);
    globalActions.bulkUpdateActions.setViewSocketData({});
    const { file } = options;
    const fmData = new FormData();
    fmData.append('excel_file', file);
    if (isBulkUploadExcel) {
      bulkActionApis
        .importBulkExcel(fmData, taskid)
        .then((res) => {
          if (res?.status === 200) {
            setTaskDetail(res?.data?.data?.task_id);
            globalActions.bulkUpdateActions.setIsUploadOpen(true);
            setTimeout(() => setLoading(false), 1000);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            setIsTemplateInValid(true);
            setLoading(false);
          } else {
            message.error({
              content: 'Failed to upload. Please try again',
              style: {
                right: '95px',
                position: 'absolute',
                textAlign: 'center',
              },
            });
            setLoading(false);
          }
          console.log(err);
        });
    } else {
      uploadImportBulk(fmData, taskid)
        .then((res) => {
          if (res?.status === 200) {
            setTaskDetail(res?.data?.data?.task_id);
            globalActions.bulkUpdateActions.setIsUploadOpen(true);
            setTimeout(() => setLoading(false), 1000);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            setIsTemplateInValid(true);
            setLoading(false);
          } else {
            message.error({
              content: 'Failed to upload. Please try again',
              style: {
                right: '95px',
                position: 'absolute',
                textAlign: 'center',
              },
            });
            setLoading(false);
          }
          console.log(err);
        });
    }
  };

  const socketChange = () => {
    let importTasks = viewSocketData?.task_id
      ? importSocketData &&
        importSocketData?.filter(
          (item) => viewSocketData?.task_id === item?.task_id
        )[0]
      : importSocketData &&
        importSocketData?.filter((item) => item?.task_id === taskDetail)[0];

    if (
      importTasks?.steps_status?.product_update === STATUSES.DONE ||
      importTasks?.steps_status?.product_upload === STATUSES.DONE ||
      (importTasks?.task_id &&
        importTasks?.created_by_email !== currentEmail &&
        isAdmin !== 'true')
    ) {
      setIsCancel(false);
    }

    const updatedData =
      stepData?.length > 0 &&
      stepData?.map((item, index) => {
        return {
          ...item,
          task_id: importTasks?.task_id,
          steps_status: importTasks?.steps_status,
          total_count: importTasks?.total_count,
          download_link: importTasks?.download_link,
          created_by_email: importTasks?.created_by_email,
          sub_label:
            item?.slug === 'data_backup'
              ? 'Data backup of ' + importTasks?.total_count + ' products'
              : item?.sub_label,
        };
      });
    if (updatedData) {
      setLoading(false);
    }
    setCurrentSlug(importTasks?.slug);
    setUsableStepData(updatedData);
  };

  useEffect(() => {
    if (isUploadOpen) {
      socketChange();
    }
  }, [isUploadOpen, importSocketData, stepData]);

  const onBack = () => {
    if (usableStepData?.length > 0) {
      trackEvent({
        name: 'bulk_import_back',
        meta: {
          location: location.pathname,
          modules: 'Manage Products',
          property: 'Bulk excel import',
        },
      });
      globalActions.bulkUpdateActions.setIsBulkUploadExcel(false);
      globalActions.bulkUpdateActions.setViewSocketData({});
      globalActions.bulkUpdateActions.setIsUploadOpen(false);
      setIseExcelDrawer(false);
    }
  };

  return (
    <SWDrawer
      success="Success"
      dismiss="Cancel"
      width={459}
      show={isUploadOpen}
      closable
      setShow={false}
      onClose={() => {
        if (usableStepData?.length > 0) {
          globalActions.bulkUpdateActions.setViewSocketData({});
          globalActions.bulkUpdateActions.setIsUploadOpen(false);
        }
      }}
      BottomActionBar={<></>}
      hideButtons={true}>
      <div className="importWrapper">
        <Row justify="space-between">
          <Col>
            <Row>
              <Text className="importWrapper_head">Bulk Upload via Excel</Text>
            </Row>
            <Row>
              <Text className="importWrapper_sub">
                Please wait while we update your digital library
              </Text>
            </Row>
          </Col>
          {isCancel && (
            <Col
              onClick={() => {
                trackEvent({
                  name: 'bulk_import_cancel',
                  meta: {
                    location: location.pathname,
                    modules: 'Manage Products',
                    property: 'Bulk excel import',
                  },
                });
                setCancelTask(usableStepData);
                setIsCancelImport(true);
              }}
              className="importWrapper_closeDiv">
              <img
                src={closeicon}
                className="importWrapper_closeIcon"
                alt="cance"
              />
              <Text className="importWrapper_cnclBtn">Cancel</Text>
            </Col>
          )}
        </Row>

        <div className="importWrapper_cardsWrapper">
          <ImportValidationStep
            steps={usableStepData}
            {...{
              uploadExcel,
              loading,
              setIsNotificationTrigger,
              setLoading,
            }}
          />
        </div>

        <Footer onBack={onBack} btnLabel="Back" />
      </div>
    </SWDrawer>
  );
}

export default ImportDrawer;
