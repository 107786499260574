import { notification } from 'antd';
import axios from 'axios';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const userData = localStorage.getItem('user');
const user = userData && JSON.parse(userData);
const baseURL = process.env.REACT_APP_API_SERVICE;

const PYTHON_KEY = {
  URL: axios.create({
    baseURL: process.env.REACT_APP_API_SERVICE,
    params: {
      access_token: user?.access_token,
    },
    headers: { 'access-token': `${user?.access_token}` },
  }),
  path: {
    session: `analytics/session/`,
    getdetails: `analytics/getdetails/`,
    getFeature: (feature) => `user/features/${feature}`,
    setFeature: () => `user/features/set-feature`,
    getUOMViaCategory: (category, addBaseURL) => `${baseURL}uom/${category}`,
    usage: `usage/`,
    notifications: 'notification/first/',
    dismiss_notification: 'notification/dismiss',
    availableplans: 'plan/upgrade',
    currency: 'uom/currency',
    document: {
      create: 'document/record/create',
      update: 'document/record/update',
      copy: 'document/record/copy/',
    },
    bulkUpdate: 'items/bulk_attribute_update',
    currencyConversion: 'uom/currency',
    download_costing_excel: 'quotation/product/excel/costing',
    productAttachments: (productId) =>
      `${baseURL}items/products/${productId}/attachments/`,
    getQRTemplates: `qr_code_pdf_service/get_qr_templates`,
    bulkUpdate: 'items/bulk_attribute_update',
    currencyConversion: 'uom/currency',
    bulkDuplicacyCheck: 'items/productsv2/check/duplicate',
    filters: 'filters',
    getAttributes: (type) => `attribute/${type}`,
    attributes: 'attribute',
    editItem: (itemType, itemId) =>
      `quotation/product/products/${itemType}/${itemId}`,
    smartSearch: 'smart_search/',
    smartSearchFacets: 'smart_search/facets',
    uploadSmartSearchImg: 'smart_search/upload_image',
    smartSearchExtractImgs: 'smart_search/extract_images',
    getLinkingTableData: (taskId) => `bulk/link/linked_table/${taskId}`,
    deleteBulkImgGroup: `bulk/update_images/group`,
    unlinkBulkImgProduct: `bulk/link/unlink`,
    unlinkBulkImgAllGroups: `bulk/link/unlink/all`,
    editBulkImgGroupImgs: `bulk/update_images/edit/images`,
    saveBulkImgUpdate: `bulk/update_images/save`,
    linkSearchProducts: `bulk/link/search_products`,
    linkProductToGroup: `bulk/link/link`,
    cataloguePDF: 'catalogue/pdfService/generate',
  },
};

// Add a response interceptor
PYTHON_KEY.URL.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 405) {
      notification.error({
        message: <span style={{ color: 'white' }}>Warning</span>,
        description:
          'This IP address is not allowed to access the platform. Please contact the administrator.',
        style: {
          color: 'white',
          background: 'rgb(0,0,0,0.9)',
          borderRadius: 8,
        },
      });
      setTimeout(() => {
        clearLocalStorageAndCookie();
        window.location = '/'; // <-- add your path
        window.location.reload();
      }, 3000);
    } else return Promise.reject(error);
  }
);

PYTHON_KEY.URL.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response && error?.response?.status === 401) {
      notification.error({
        message: <span>Session expired</span>,
        description: 'Your session is expired. Please login again',
        style: {
          borderRadius: 8,
        },
      });
      setTimeout(() => {
        clearLocalStorageAndCookie();
        window.location = '/'; // <-- add your path
        localStorage.setItem('logout-event', 'logout' + Math.random());
        // window.location.reload();
      }, 3000);
    } else return Promise.reject(error);
  }
);

export default PYTHON_KEY;
