import { Select, Form } from 'antd';
import CreatableSelect from 'react-select/creatable';

const { Option } = Select;

const ConfigurableSelect = ({ field, facetsData, name, rest }) => {
  const { defaults = {} } = field;
  const selectMode = (defaults = {}) => {
    const { allow_adding_new, mode } = defaults;
    return allow_adding_new ? 'tags' : mode;
  };
  const selectOptions = (defaults = {}) => {
    const { options, options_type } = defaults;
    if (options_type === 'options') {
      return options?.length > 0
        ? options?.map((item) => ({ label: item, value: item }))
        : [];
    } else {
      const options = [];
      if (field?.facets) {
        if (field?.facets in facetsData) {
          for (const property in facetsData?.[field?.facets]) {
            options.push({ label: property, value: property });
          }
        }
      }
      return options;
    }
  };
  const mode = selectMode(defaults || {});
  const isSingleOpenSelect =
    defaults?.allow_adding_new && defaults?.mode === 'single';

  const creatableOptions = selectOptions(defaults || {})?.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  return (
    <Form.Item
      {...rest}
      name={name}
      label={field?.label}
      rules={[
        {
          required: field?.required,
          message: field?.label + ' is required',
        },
      ]}>
      {isSingleOpenSelect ? (
        <CreatableSelect isClearable options={creatableOptions} />
      ) : (
        <Select
          mode={mode}
          placeholder={field?.placeholder}
          size="large"
          showSearch
          filterOption={(input, option) =>
            option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }>
          {selectOptions(defaults || {})?.map((item, index) => {
            return (
              <Option value={item?.value} key={`${item}:${index}`}>
                {item?.label}
              </Option>
            );
          })}
        </Select>
      )}
    </Form.Item>
  );
};

export default ConfigurableSelect;
