export const quotationActions = {
  setQuotationProducts: (store, payload) => {
    store.setState({ quotationProducts: payload });
  },
  setQuotationAttributes: (store, payload) => {
    store.setState({ quotationAttributes: payload });
  },
  setSelectedQuotationAttributes: (store, payload) => {
    store.setState({ quotationSelectedAttributes: payload });
  },
  setQuotationTableState: (store, payload) => {
    store.setState({ quotationTableState: payload });
  },
  setQuotationId: (store, payload) => {
    store.setState({ quotationId: payload });
  },
};
