export const contactActions = {
  editContactAction: async (store, data, tab, setBuyerData) => {
    if (data || tab) {
      store.setState({
        editContact: {
          data,
          tab,
          setBuyerData,
        },
        loading: false,
      });
    } else {
      store.setState({
        editContact: {},
        loading: false,
        setBuyerData,
      });
    }
  },
};
