import React, { useState, memo } from 'react';
import './index.less';
import { Row, Col, Button, Typography, Upload, Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import importerror from 'assets/images/importerror.svg';
import importgreencheck from 'assets/images/importgreencheck.svg';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import ImportMoreInfo from 'assets/images/importmoreinfo.svg';
import { useIntl } from 'react-intl';
import { STATUSES } from 'constants/status';

const { Text } = Typography;

function ImportValidationStep({ steps, uploadExcel, loading, setLoading }) {
  const intl = useIntl();
  const currentEmail = localStorage.getItem('email').slice(1, -1);
  const isAdmin = localStorage.getItem('isAdmin');
  const [showMore, setShowMore] = useState(false);
  const { trackEvent } = useTracking();
  const location = useLocation();

  const validator = (status) => {
    switch (status) {
      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusPending' }):
        return 'in progress';
      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusDraft' }):
        return '';
      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusFailed' }):
        return intl.formatMessage({ id: 'sourcewiz.bulkImport.statusFailed' });
      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusDone' }):
        return intl.formatMessage({ id: 'sourcewiz.bulkImport.statusDone' });
      default:
        break;
    }
  };

  const ImgValidation = (status, item) => {
    switch (status) {
      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusDone' }):
        return (
          <img className="validationStep_imgStatus" src={importgreencheck} />
        );

      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusFailed' }):
        return (
          <img
            className="validationStep_imgStatus validation_error"
            src={importerror}
          />
        );

      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusDraft' }):
        return (
          <img
            className="validationSteps_imgStatus"
            src={`${process.env.REACT_APP_DIRECTUS}assets/${item?.status_img}`}
            alt="img"
            height="30px"
          />
        );

      case intl.formatMessage({ id: 'sourcewiz.bulkImport.statusPending' }):
        return (
          <LoadingOutlined
            style={{
              fontSize: 24,
              color: '#0B7764',
              marginLeft: '5px',
            }}
            spin
          />
        );

      default:
        break;
    }
  };

  const FailedOptions = (status, item) => {
    const blockUploadPermission =
      item?.created_by_email !== currentEmail && isAdmin !== 'true';
    switch (status) {
      case 'failed':
        return (
          <Row justify="space-between" style={{ width: '100%' }}>
            <Button
              onClick={() => {
                trackEvent({
                  name: 'bulk_import_download_failed_sheet',
                  meta: {
                    data: item,
                    location: location.pathname,
                    modules: intl.formatMessage({
                      id: 'sourcewiz.button.manageProduct',
                    }),
                    property: intl.formatMessage({
                      id: 'sourcewiz.bulkImport.property',
                    }),
                  },
                });
                window.open(item?.download_link);
              }}
              className="validationStep_downloadSheet">
              Download sheet
            </Button>
            <Upload
              name="file"
              disabled={loading || blockUploadPermission}
              showUploadList={false}
              customRequest={(e) => {
                setLoading(true);
                uploadExcel(e, item.task_id);
              }}
              onClick={() => {}}
              accept=".xlsx">
              <Button
                loading={loading}
                disabled={loading || blockUploadPermission}
                style={{ width: '120px' }}
                className="validationStep_uploadAgain">
                Upload again
              </Button>
            </Upload>
          </Row>
        );

      default:
        break;
    }
  };

  const viewSubLabel = (status, item) => {
    switch (status) {
      case 'failed':
        return (
          <Row>
            <Text className="validationStep_subText">
              {item?.status_sub_lables[status].replace(
                '{product_count}',
                item?.total_count
              )}
            </Text>
          </Row>
        );

      case 'draft':
        return (
          <Row>
            <Text className="validationStep_subText">
              {item?.sub_label.replace('{}', item?.total_count)}
            </Text>
          </Row>
        );

      case 'pending':
        return (
          <Row>
            <Text className="validationStep_subText">
              {item?.status_sub_lables[status].replace(
                '{product_count}',
                item?.total_count
              )}
            </Text>
          </Row>
        );

      case 'done':
        return '';
      default:
        break;
    }
  };

  const viewLabel = (status, item) => {
    if (item?.steps_status && status) {
      return (
        <Row>
          <Text className="validationStep_name">
            {item?.status_lables[item?.steps_status[item?.slug]].replace(
              '{product_count}',
              item?.total_count
            )}
          </Text>
        </Row>
      );
    } else
      return (
        <Row>
          <Text className="validationStep_name">{item?.label}</Text>
        </Row>
      );
  };

  const StatusValidation = (status, item) => {
    switch (status) {
      case 'done':
        return <Text className="validationStep_statusDone">done</Text>;

      case 'pending':
        return (
          <Text className="validationStep_statusInProgress">in progress</Text>
        );

      case 'failed':
        return <Text className="validationStep_statusFailed">failed</Text>;

      default:
        break;
    }
  };

  const ViewShowMore = (item) => {
    return (
      <>
        {showMore && item?.moreInfo && (
          <Row className="validationStep_extraDescList">
            {item?.moreInfo &&
              item?.moreInfo?.map((item) => {
                return (
                  <li
                    style={{
                      fontSize: '12px',
                      paddingBottom: '10px',
                    }}>
                    {item}
                  </li>
                );
              })}
          </Row>
        )}
      </>
    );
  };

  return (
    <>
      {steps?.length > 0 &&
        steps
          ?.sort((a, b) => a.order - b.order)
          .map((item) => {
            const { steps_status, slug, order, status_lables, learn_more } =
              item;
            return (
              <>
                {steps_status ? (
                  <>
                    <Row
                      style={{ width: '100%' }}
                      span={24}
                      className={`validationStep ${
                        steps_status &&
                        (steps_status[slug] === STATUSES.PENDING ||
                          steps_status[slug] === STATUSES.FAILED) &&
                        'validationStepInProgress'
                      }`}>
                      <Col span={3}>
                        <Row>
                          {steps_status &&
                            ImgValidation(steps_status[slug], item)}
                        </Row>
                        {steps_status &&
                          steps_status[slug] !== STATUSES.PENDING &&
                          steps_status[slug] !== STATUSES.FAILED && (
                            <div
                              className={`validationStep_processLine ${
                                order === steps.length
                                  ? 'validationStep_processLineRemove'
                                  : ''
                              }`}
                            />
                          )}
                      </Col>
                      <Col span={20}>
                        {viewLabel(status_lables[steps_status[slug]], item)}
                        {viewSubLabel(steps_status[slug], item)}

                        {(learn_more ||
                          (steps_status &&
                            validator(steps_status[slug]) === 'failed')) && (
                          <Row
                            onClick={() => learn_more && setShowMore(!showMore)}
                            className={`validationStep_extraDesc ${
                              learn_more &&
                              showMore &&
                              'validationStep_extraDescShow'
                            }`}>
                            {steps_status && (
                              <Text className="validationStep_extraSubText">
                                {learn_more && (
                                  <img
                                    style={{
                                      marginBottom: '3px',
                                      marginRight: '5px',
                                    }}
                                    alt="more info"
                                    src={ImportMoreInfo}
                                  />
                                )}
                                {learn_more}
                              </Text>
                            )}
                            {steps_status &&
                              FailedOptions(steps_status[slug], item)}
                            {ViewShowMore(item)}
                          </Row>
                        )}
                      </Col>
                      <Col span={1} className="validationStep_status">
                        {steps_status &&
                          StatusValidation(steps_status[slug], item)}
                      </Col>
                    </Row>
                    {steps_status &&
                      steps_status[slug] === STATUSES.FAILED &&
                      slug === 'file_validation' && (
                        <Col span={24}>
                          <div
                            style={{ marginLeft: '24px', marginTop: '10px' }}
                            className={`validationStep_processLine ${
                              order === 4 && 'validationStep_processLineRemove'
                            }`}
                          />
                        </Col>
                      )}
                  </>
                ) : (
                  <Skeleton active />
                )}
              </>
            );
          })}
    </>
  );
}

export default memo(ImportValidationStep);
