import PYTHON_KEY from 'api/pythonService';

export const getSessionId = (userId, org) => {
  return PYTHON_KEY.URL.post(
    `${PYTHON_KEY.path.session}`,
    {},
    {
      params: {
        visitor: userId,
        identifier: org,
      },
    }
  );
};

export const sendEvent = (sessionId, eventName, event) => {
  return PYTHON_KEY.URL.post(`${PYTHON_KEY.path.getdetails}`, event, {
    params: {
      session: sessionId,
      name: eventName,
    },
  });
};
