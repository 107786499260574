import {
  Layout,
  Avatar,
  Typography,
  Popover,
  Button,
  Row,
  Col,
  Select,
  notification,
  Tooltip,
} from 'antd';
import { InfoCircleFilled, CloseOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useGlobal } from '../../../store/index';
import { useHistory } from 'react-router-dom';
import { MdSettings, MdOutlineWifiOff } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoLogOutOutline } from 'react-icons/io5';
import HeaderActivity from './HeaderActivity';
import { LOGOUT, PROFILE_SETTINGS } from 'analytics/events';
import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parseJson } from 'utils/commonFunctions';
import { useIntl } from 'react-intl';
import importerror from 'assets/images/importerror.svg';
import greenTick from 'assets/images/greenTick.svg';
import warningNotification from 'assets/images/warningnotification.svg';
import Cookies from 'js-cookie';

import {
  handleRewardv2Notifications,
  VoucherModal,
} from '../Dashboard/RewardsModal';
import { getRewardSetupStatus } from 'api/rewardApis';
import './styles.less';
import '../styles.css';
import {
  CUSTOM_NOTIFICATION_TYPES,
  NOTIFICATION_TYPE_FILE_DOWNLOAD,
  RESTORE_NOTIFICATIONS,
} from 'constants/filters';
import { subscribe } from 'utils/notificationUtils';
import { ChannelScope, ChannelType, EventType } from 'constants/notifications';
import {
  clearLocalStorageAndCookie,
  getRootDomain,
} from 'utils/logoutUsingCookies';

const { Header } = Layout;
const { Title, Text } = Typography;
const MenuBox = ({ label, onClick, renderIcon }) => {
  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <Button onClick={onClick} type="ghost" style={{ border: 0, padding: 0 }}>
        {label}
      </Button>
      <Button
        onClick={onClick}
        type="ghost"
        style={{ border: 0, padding: 0, margin: '0.2rem 0 0 1rem' }}>
        {renderIcon()}
      </Button>
    </div>
  );
};

export default function Index() {
  const intl = useIntl();
  const ws = useRef(null);
  const [globalState, globalActions] = useGlobal();
  const { showModal } = globalState;
  const history = useHistory();
  const [isOnline, setIsOnline] = useState(true);

  const errorNotify = {
    width: 550,
    background: '#FDDBDB',
    borderRadius: '8px',
  };
  const successNotify = {
    width: 550,
    background: '#F0F8F6',
    borderRadius: '8px',
  };
  const warningNotify = {
    width: 450,
    background: '#FFF0DB',
    borderRadius: '8px',
  };

  const closeModal = () => {
    globalActions.showModalAction.setShowModal(false);
  };

  const connectRewardsWebsocket = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId);
    }
    const userId = parseJson(localStorage.getItem('userId'));

    ws.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET}/user/${userId}/ws?device_id=${deviceId}`
    );
    ws.current.onopen = () => {};
    ws.current.onclose = () => {
      setTimeout(function () {
        connectRewardsWebsocket();
      }, 5000);
    };
    ws.current.onmessage = onMessageNotification;

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  };
  function getWSNotificationData(orgId, userId) {
    subscribe(
      orgId,
      ChannelType.NOTIFICATIONS,
      EventType.NEW_NOTIFICATION,
      onMessageNotification,
      ChannelScope.USER,
      userId
    );
  }
  useEffect(() => {
    // connectRewardsWebsocket();
    const mId = parseJson(localStorage.getItem('org'))?.id;
    const userId = parseJson(localStorage.getItem('userId'));
    getWSNotificationData(mId, userId);
  }, []);

  const onMessageNotification = (notification) => {
    if (notification) {
      if (notification?.type === 'rewards') {
        if (notification?.message_type === 'success') {
          handleRewardv2Notifications(notification?.data);
        }
        // } else if (notification?.type === 'multiple_login_alert') {
        //   multipleLoginNotification(
        //     'top',
        //     notification?.data?.title,
        //     notification?.data?.description,
        //     notification?.message_type
        //   );
      } else if (NOTIFICATION_TYPE_FILE_DOWNLOAD.includes(notification?.type)) {
        var notificationData = notification;
        pptDownloadNotification(notificationData);
      } else if (CUSTOM_NOTIFICATION_TYPES.includes(notification?.type)) {
        if (notification?.status === 'success') {
          hitImportNotification(successNotify, notification, 'success');
        } else if (notification?.status === 'failed') {
          hitImportNotification(errorNotify, notification, 'failed');
        }
      } else if (RESTORE_NOTIFICATIONS.includes(notification?.action)) {
        hitRestoreNotification(warningNotify, notification, 'warning');
      }
    }
  };

  const restoreNotificationData = (stat, type, count, item) => {
    switch (stat) {
      case 'failed':
        if (type === 'sublabel') {
          return 'Please retry to continue restoring the previous data of these products';
        }
        if (type === 'label') {
          return count + ' products restore failed';
        }
      case 'warning':
        if (type === 'sublabel') {
          if (item?.restored_from === 'backup_history') {
            return 'Please wait while we are restoring your data.';
          } else {
            return 'Some products got updated before cancellation. Please wait while we are restoring your data.';
          }
        }
        if (type === 'label') {
          return 'Restoring ' + count + ' products';
        }
      default:
        break;
    }
  };

  const RestoreNotifier = (type, item, stat) => {
    return (
      <div>
        <Row className="notification-wrap">
          <Text className="notification-wrap_sm-text">
            By {localStorage.getItem('email').slice(1, -1)}
          </Text>
        </Row>
        <Row justify="space-between">
          <Col className="notification-wrap_head-div">
            <Row className="notification-wrap_head-div_sub">
              <img
                src={stat === 'failed' ? importerror : warningNotification}
                className="notification-wrap_head-div_img"
              />
              <Text className="notification-wrap_head-div_txt">
                {restoreNotificationData(
                  stat,
                  'label',
                  item?.total_count,
                  item
                )}
              </Text>
            </Row>
            <Row>
              <Text className="notification-wrap_head-div_sub-txt">
                {restoreNotificationData(
                  stat,
                  'sublabel',
                  item?.total_count,
                  item
                )}
              </Text>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const hitRestoreNotification = (type, item, stat) => {
    notification.open({
      message: RestoreNotifier(type, item, stat),
      style: type,
    });
  };

  const handleImportNotification = (stat, item) => {
    if (stat === 'success') {
      trackEvent({
        name: 'bulk_import_view_backup',
        meta: {
          location: location.pathname,
          modules: 'Manage Products',
          property: 'Bulk excel import',
        },
      });
      notification.destroy();
      globalActions.bulkUpdateActions.setIsUploadOpen(false);
      globalActions.bulkUpdateActions.setIsImportExportDrawerActive(false);
      history.push('/profile-settings/8');
    } else if (stat === 'failed') {
      trackEvent({
        name: 'bulk_import_view_details',
        meta: {
          location: location.pathname,
          modules: 'Manage Products',
          property: 'Bulk excel import',
        },
      });
      notification.destroy();
      globalActions.bulkUpdateActions.setViewSocketData(item);
      globalActions.bulkUpdateActions.setIsUploadOpen(true);
    }
  };

  const ImportNotification = (type, item, stat) => {
    return (
      <div>
        <Row className="notification-container">
          <Text className="notification-sm-text">
            By {localStorage.getItem('email')}
          </Text>
        </Row>
        <Row justify="space-between">
          <Col className="notification-body">
            <Row className="notification-status">
              <img
                src={stat === 'failed' ? importerror : greenTick}
                className="notication-img"
              />
              <Text className="notification-lg-text">
                {item?.total_count}
                {stat === 'failed' && ' products update by excel failed'}
                {stat === 'success' && ' products updated successfully'}
              </Text>
            </Row>
            <Row>
              <Text className="notification-md-text">
                {stat === 'failed' &&
                  'Download the response sheet, correct the data and upload again.'}
                {stat === 'success' &&
                  'We have kept backup for these product in Profile Setting > Backups'}
              </Text>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col></Col>
          <Col>
            <Button
              onClick={() => {
                handleImportNotification(stat, item);
              }}
              className="notification-btn">
              {stat === 'success' && 'View backup'}
              {stat === 'failed' && 'View details'}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const hitImportNotification = (type, item, stat) => {
    notification.open({
      message: ImportNotification(type, item, stat),
      style: type,
    });
  };

  const pptDownloadNotification = (notificationData, type = 'success') => {
    notification.open({
      message: renderPptDownloadBody(notificationData),
      closeIcon: (
        <AiFillCloseCircle style={{ color: '#9B9F9E', fontSize: 20 }} />
        // <></>
      ),
      className: 'downloadNotification',
      duration: 5,
    });
  };

  const renderPptDownloadBody = (notificationData) => {
    return (
      <>
        <Row className="details">
          <Col>
            <Row className="user">By {notificationData?.created_by_email}</Row>
            <Row align="middle">
              <Col span={18}>
                {(notificationData?.type === 'ppt_download' ||
                  notificationData?.type === 'excel_export_bulk_update' ||
                  notificationData?.type === 'catalogue_pdf') && (
                  <Row className="fileType">Your file is ready to download</Row>
                )}
                {notificationData?.type === 'excel_export_bulk_update' && (
                  <Row>
                    <Text style={{ color: '#363E3D', fontSize: '14px' }}>
                      {notificationData?.template_name}
                    </Text>
                  </Row>
                )}
                <Row className="catalogueName">
                  {`${notificationData?.collection_name}${
                    notificationData?.type === 'catalogue_pdf' ? ' PDF' : ''
                  }`}
                </Row>
              </Col>
              <Col span={4} offset={1}>
                <Button
                  type="primary"
                  onClick={(e) => {
                    const collection_download_link =
                      notificationData?.download_link;
                    window.open(collection_download_link, '_blank');
                  }}>
                  Download
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const multipleLoginNotification = (
    placement,
    title,
    description,
    type = 'success'
  ) => {
    document
      .querySelectorAll('.multi_login_notification')
      .forEach((el) => el.remove());
    notification.open({
      message: renderMultipelLoginAlertBody(title, description),
      closeIcon: <CloseOutlined style={{ marginTop: '13px' }} />,
      placement: 'top',
      className: 'multi_login_notification login-notification',
      duration: 0,
    });
  };

  const renderMultipelLoginAlertBody = (title, description) => {
    return (
      <>
        <Row style={{ display: 'block', textAlign: 'center' }}>
          <Row justify="center">
            <Col
              xs={2}
              sm={2}
              md={{ span: 2 }}
              lg={{ span: 2 }}
              xl={{ span: 2 }}
              className="icon">
              <InfoCircleFilled
                style={{ color: '#F34D4D', fontSize: '30px' }}
              />
            </Col>
            <Col
              xs={21}
              sm={21}
              md={{ span: 21 }}
              lg={{ span: 21 }}
              xl={{ span: 21 }}
              style={{ margin: '3px 15px 0 20px', maxWidth: '24rem' }}>
              <Row>
                <Title level={5}>
                  {title}
                  {/* You have earned 20 coins! */}
                </Title>
              </Row>
              <Row>
                <Text level={6} style={{ textAlign: 'left', color: '#4F5655' }}>
                  {description}
                  {/* Now, you just need 60 coins to win your next big prize */}
                </Text>
              </Row>
            </Col>
          </Row>
        </Row>
      </>
    );
  };

  const handleLogout = () => {
    clearLocalStorageAndCookie();
    localStorage.setItem('logout-event', 'logout' + Math.random());
    if (sessionStorage.getItem('routing-path')) {
      history.push(sessionStorage.getItem('routing-path'));
    } else {
      history.push('/');
    }
    globalActions.authActions.signoutAction();
    window.location.reload();
  };
  const { trackEvent } = useTracking();
  const location = useLocation();

  const content = (
    <>
      <div>
        <MenuBox
          label={intl.formatMessage({ id: 'sourcewiz.button.profileSettings' })}
          onClick={() => {
            history.push('/profile-settings/1');
            trackEvent({
              name: PROFILE_SETTINGS,
              meta: {
                user_id: localStorage.getItem('userId'),
                location: location.pathname,
                modules: 'Overall',
                property: 'Overall',
              },
            });
          }}
          renderIcon={() => <MdSettings size={20} color="#B4B7B6" />}
        />
        <div
          style={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            borderBottom: '0.5px solid rgb(233, 234, 233)',
          }}
        />

        <MenuBox
          label={intl.formatMessage({ id: 'sourcewiz.button.logout' })}
          onClick={() => {
            globalActions.authActions.signoutAction();
            trackEvent({
              name: LOGOUT,
              meta: {
                user_id: localStorage.getItem('userId'),
                location: location.pathname,
                modules: 'Overall',
                property: 'Overall',
              },
            });
            handleLogout();
          }}
          renderIcon={() => <IoLogOutOutline size={20} color="#B4B7B6" />}
        />
      </div>
    </>
  );

  const handleUpdate = () => {
    getRewardSetupStatus()
      .then((res) => {
        if (res?.status === 200) {
          globalActions.milestoneInfoAction.setRewardsData(res?.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    function changeStatus() {
      setIsOnline(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);
  return (
    <Header
      style={{
        position: 'fixed',
        zIndex: 100,
        width: '100%',
        background: 'white',
        borderBottom: '1px solid #E9EAE9',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          height: '58px',
          padding: '1rem',
        }}>
        <Title
          style={{
            // color: Colors.primaryColor,
            fontWeight: 'normal',
            fontFamily: 'SatoshiBold',
            fontSize: 25,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 17,
          }}></Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          {isOnline ? (
            ''
          ) : (
            <Tooltip
              placement="bottom"
              title={
                'No internet connection. Please check your internet settings.'
              }>
              <MdOutlineWifiOff
                style={{ marginLeft: 8, marginRight: 8 }}
                size={28}
              />
            </Tooltip>
          )}
          <VoucherModal
            showModal={showModal}
            closeModal={closeModal}
            handleUpdate={handleUpdate}
          />
          <div
            style={{
              paddingTop: 5,
              alignItems: 'center',
              marginRight: '1rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            <HeaderActivity />
          </div>
          <Popover
            placement="bottom"
            content={content}
            trigger="click"
            onClick={() => {}}>
            <Avatar
              style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
              icon={<UserOutlined />}
              onClick={() => {}}
            />
          </Popover>
        </div>
      </div>
    </Header>
  );
}
