export const AttributeActions = Object.freeze({
  TOGGLE_MODAL: 'attributeSettingAction/TOGGLE_MODAL',
  GET_ATTRIBUTE_SECTION_DATA:
    'attributeSettingAction/GET_ATTRIBUTE_SECTION_DATA',
  DELETE_ATTRIBUTE: 'attributeSettingAction/DELETE_ATTRIBUTE',
  FETCH_DATA: 'attributeSettingAction/FETCH_DATA',
  GET_ALL_ATTRIBUTE_DATA: 'attributeSettingAction/GET_ALL_ATTRIBUTE_DATA',
  TOGGLE_RADIOBUTTON: 'attributeSettingAction/TOGGLE_RADIOBUTTON',
});

export const toggleModal = (data) => ({
  type: AttributeActions.TOGGLE_MODAL,
  payload: data,
});

export const getAllAttributesData = (data) => ({
  type: AttributeActions.GET_ALL_ATTRIBUTE_DATA,
  payload: data,
});

export const getAttributeSectionData = (data) => ({
  type: AttributeActions.GET_ATTRIBUTE_SECTION_DATA,
  payload: data,
});

export const deleteAttribute = (data) => ({
  type: AttributeActions.DELETE_ATTRIBUTE,
  payload: data,
});

export const fetchData = (data) => ({
  type: AttributeActions.FETCH_DATA,
  payload: data,
});

export const toggleRadioButtonValue = (data) => ({
  type: AttributeActions.TOGGLE_RADIOBUTTON,
  payload: data,
});

const ACTION_HANDLERS = {
  AttributeActions,
  toggleModal,
  getAttributeSectionData,
  deleteAttribute,
  fetchData,
  toggleRadioButtonValue,
};

export default ACTION_HANDLERS;
