import PYTHON_KEY from 'api/pythonService';
export const permissions = {
  getPermissions: async (store, userId) => {
    var user = localStorage.getItem('user');
    const userData = JSON.parse(user);
    try {
      store.setState({ loading: true });
      // const response = await  axios.get(`https://d6fdaa27-11f8-488b-9b21-a59143c8107c.mock.pstmn.io/user/permissions/2`);
      const response = await PYTHON_KEY.URL.get(
        `${process.env.REACT_APP_API_SERVICE}user/permissions/config/${userId}`,
        {}
      );
      store.setState({ permissions: response?.data, loading: false });
    } catch (error) {
      console.log('error:', error);
    }
  },
};
