import API_KEY from 'api';
import PYTHON_KEY from 'api/pythonService';
import axios from 'axios';
import { QUOTATION_KEY } from './quotationApis';

const pythonBaseUrl = process.env.REACT_APP_API_SERVICE;
const pythonCostUrl = process.env.REACT_APP_QUOTATION;

const userData = localStorage.getItem('user');
const user = userData && JSON.parse(userData);
const access_token = user?.access_token;

export const PYTHON_API_KEY = {
  URL: axios.create({
    baseURL: pythonBaseUrl,
    params: {
      access_token: user?.access_token,
    },
    headers: {
      'access-token': user?.access_token,
    },
    validateStatus: function (status) {
      return status >= 200 && status < 300;
    },
  }),
  path: {
    enquiries: 'quotation/enquiries',
    createEnquiries: 'quotation/enquiry_quotation/create',
    getQuotationsByEnquiry: 'quotation/quotations',
    createQuotation: 'quotation/enquiry_quotation/quotation',
    updateQuotation: 'quotation/enquiry_quotation/quotation/update',
    updateEnquiry: 'quotation/enquiry_quotation/update',
    quotationTable: 'quotation/products/table',
    updateAttributes: 'quotation/enquiry_quotation/update_attribute',
    updateEnquiry: 'quotation/enquiry_quotation/update',
    copyQuotation: 'quotation/copy',
    deleteProduct: 'quotation/enquiry_quotation/delete_products',
    downloadExcel: 'quotation/excel',
    downloadPdf: 'quotation/pdf',
    prospectsPost: 'prospect/prospects',
    enableTrial: 'account/enable_trial',
    moduleUpgrade: 'upgrade',
    subscriptionDetails: 'plan/current',
    invoiceDetails: 'subscription',
    userUpgradeRequest: 'user_request/add',
    attributesHistory: 'history/attribute/?type=attribute',
    user: 'user/member',
    allUser: 'user/member/all',
    userPermission: 'user/permissions',
    attribute: (manufacturerId) =>
      `attribute/?manufacturer_id=${manufacturerId}`,
  },
};

export const getProductDetailsV2 = (id) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}items/productsv2/?id=${id}&token=${token}`
  );
};

export const getMultipleProductDetailsV2 = (productIds) => {
  const params = new URLSearchParams();
  productIds?.forEach((item) => {
    params.append('ids', item);
  });
  const requestParams = {
    params: params,
  };
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}items/productsv2/multiple`,
    requestParams
  );
};

export const getProductDetailsV3 = (payload) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.post(`${pythonBaseUrl}items/productsv3/`, payload, {
    headers: { 'access-token': `${token}` },
  });
};

export const getAccountDetails = (token) => {
  if (!token) {
    const user = localStorage.getItem('user');
    token = JSON.parse(user)?.access_token;
  }
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}user/me?access_token=${token}`);
};

export const getCountryCodes = () => {
  return PYTHON_KEY.URL.get(`${pythonBaseUrl}auth/country_codes/`);
};

export const updateMobileNumber = (data, token) => {
  if (!token) {
    const user = localStorage.getItem('user');
    token = JSON.parse(user)?.access_token;
  }
  return axios.post(`${pythonBaseUrl}auth/update_mobile`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const updateWhatsappNumber = (data, token) => {
  if (!token) {
    const user = localStorage.getItem('user');
    token = JSON.parse(user)?.access_token;
  }
  return axios.post(`${pythonBaseUrl}auth/update_whatsapp_mobile`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const updateAccountDetails = (userId, data) => {
  // const user = localStorage.getItem("user");
  // const token = JSON.parse(user).access_token;

  return API_KEY.URL.patch(
    `${process.env.REACT_APP_DIRECTUS}users/${userId}`,
    data,
    {
      // headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export const getCompanyDetails = (id) => {
  // const user = localStorage.getItem("user");
  // const token = JSON.parse(user).access_token;
  return API_KEY.URL.get(
    `${process.env.REACT_APP_DIRECTUS}items/manufacturer/${id}`
  );
};

export const updateCompanyDetails = (id, data) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;

  return API_KEY.URL.patch(
    `${process.env.REACT_APP_DIRECTUS}items/manufacturer/${id}`,
    data
    // {
    // 	headers: { Authorization: `Bearer ${token}` },
    // }
  );
};

export const getLinkSecurityData = (collectionLink) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}link_security/?token=${token}&collection_link=${collectionLink}`
  );
};

export const createLinkSecurityData = (collectionLink, collectionId, data) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}link_security/create/?token=${token}&collection_link=${collectionLink}&collection_id=${collectionId}`,
    data
  );
};

export const setDefaultLinkSecurities = (data) => {
  return PYTHON_KEY.URL.post(
    `${pythonBaseUrl}manufacturer/add_or_update_settings`,
    data,
    {
      headers: {
        'access-token': user?.access_token,
      },
    }
  );
};

export const getDefaultLinkSecurities = () => {
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}manufacturer/get_settings?key=catalogue_link_settings_defaults`,
    {
      headers: {
        'access-token': user?.access_token,
      },
    }
  );
};

export const updateLinkSecurityData = (id, data) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.put(
    `${pythonBaseUrl}link_security/update/${id}?token=${token}`,
    data
  );
};

// Get Api to get the currencies and the conversion factor

export const getCurrency = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.get(`${pythonCostUrl}uom/currency?token=${token}`);
};

// Mock Patch API to change the data accordingly

export const editCurrency = async (id) => {
  return PYTHON_KEY.URL.patch(`${pythonCostUrl}uom/Currency/${id}`);
};

// Patch/Edit Api to save the edited changes

export const saveEdited = async (data) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.patch(`${pythonCostUrl}uom`, data);
};

// Mock Patch API to change the data accordinglly
export const editMeasurements = async (id) => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.patch(`${pythonCostUrl}uom/Length/${id}`);
};

// Get Api to get the Unit of measures

export const getMeasurements = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user).access_token;
  return PYTHON_KEY.URL.get(`${pythonCostUrl}uom/uom?token=${token}`);
};

export const ProductInfo = () => {
  const user = localStorage.getItem('user');
  const token = JSON.parse(user)?.access_token;

  return axios.get(`${pythonCostUrl}/attribute/product?access_token=${token}`);
};

// Get api to get the product price history
export const getProductPriceHistory = (productId, attributeId) => {
  return PYTHON_API_KEY.URL.get(
    `${PYTHON_API_KEY.path.attributesHistory}&parent_reference=${productId}&base_reference=${attributeId}`
  );
};

// Get api for downloading excel sheet of costing
export const downloadCostingExcel = (id) => {
  const userData = localStorage.getItem('user');
  const user = userData && JSON.parse(userData);
  return PYTHON_KEY.URL.get(`${PYTHON_KEY.path.download_costing_excel}/${id}`, {
    responseType: 'blob',
    headers: {
      'access-token': user?.access_token,
    },
  });
};

// Get api for getting manufacturer settings
export const getManufacturerSettings = () => {
  const user = localStorage.getItem('user');
  const access_token = JSON.parse(user)?.access_token;
  return PYTHON_KEY.URL.get(
    `${process.env.REACT_APP_API_SERVICE}manufacturer/get_settings`,
    {
      headers: {
        'access-token': access_token,
      },
    }
  );
};

export const getProductAttributes = () => {
  return PYTHON_KEY.URL.get(`${PYTHON_KEY.path.getAttributes('product')}`, {
    params: {},
  });
};

export const getAllProductAttributes = () => {
  return PYTHON_KEY.URL.get(`${PYTHON_KEY.path.getAttributes('product')}`, {
    params: {},
  });
};

export const getSpecificManufacturerSettings = (key) => {
  return PYTHON_KEY.URL.get(
    `${process.env.REACT_APP_API_SERVICE}manufacturer/get_settings?key=${key}`,
    {
      headers: {
        'access-token': access_token,
      },
    }
  );
};

// Post api to push the data to secondary category table
export const createSecondaryCategory = (data) => {
  return API_KEY.URL.post(API_KEY.path.secondaryCategory, data);
};

//Post api to map the secondary category to the manufacturer id
export const saveManufacturerCategory = (data) => {
  return API_KEY.URL.post(
    `${API_KEY.path.manufacturer_secondary_category}`,
    data
  );
};

// Api for creating a resource library item (material/process/packaging item)
export const addResourceItem = (itemType, data) => {
  return QUOTATION_KEY.URL.post(QUOTATION_KEY.path.addItem(itemType), data);
};

//Api for editing a resource library item (material/process/packaging item)
export const editResourceItem = (itemType, id, data) => {
  return QUOTATION_KEY.URL.patch(
    QUOTATION_KEY.path.editItem(itemType, id),
    data
  );
};

// Api to fetch all categories
export const getAllCategories = () => {
  return API_KEY.URL.get(`${API_KEY.path.manufacturer_secondary_category}`, {
    params: {
      fields: 'secondary_category_id.*',
      limit: -1,
    },
  });
};

//Api to get item details
export const getItemDetails = (id) => {
  return QUOTATION_KEY.URL.get(QUOTATION_KEY.path.getItem(id));
};

export const getStepsImport = (key) => {
  // steps_bulk_import
  return PYTHON_KEY.URL.get(
    `${process.env.REACT_APP_API_SERVICE}manufacturer/get_settings?key=${key}`,
    {
      headers: {
        'access-token': access_token,
      },
    }
  );
};

// bulkImportUpload
export const uploadImportBulk = (data, taskid) => {
  return axios.post(`${pythonBaseUrl}bulk/import/upload`, data, {
    headers: { 'access-token': `${access_token}` },
    params: { task_id: taskid },
  });
};

export const backupSettings = (historyType) => {
  return PYTHON_KEY.URL.get(
    `${process.env.REACT_APP_API_SERVICE}backup/history/${historyType}`,
    {
      headers: {
        'access-token': access_token,
      },
    }
  );
};

export const backupRestore = (backupid) => {
  return PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}backup/history/restore`,
    {},
    {
      headers: {
        'access-token': access_token,
      },
      params: { backup_id: backupid },
    }
  );
};

export const bulkTriggerAction = (data) => {
  return PYTHON_KEY.URL.post(
    `${process.env.REACT_APP_API_SERVICE}bulk/trigger_action`,
    data,
    {
      headers: {
        'access-token': access_token,
      },
    }
  );
};

export const getproductThreshold = (key) => {
  return PYTHON_KEY.URL.get(
    `${pythonBaseUrl}manufacturer/get_settings?key=${key}`,
    {
      headers: {
        'access-token': user?.access_token,
      },
    }
  );
};

// axios global interceptor to catch errors
PYTHON_API_KEY.URL.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    return error.toJSON();
  }
);
