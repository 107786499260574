import ProductIcon from '../assets/images/cat-icon.svg';
import CollectionIcon from '../assets/images/collection-icon.svg';
import Prospects from '../assets/images/Prospects.svg';
import Quotations from '../assets/images/Dashboard/QuotationsIcon.svg';
import History from '../assets/images/history.svg';
import DisabledCollectionIcon from '../assets/images/disabled-collection-icon.svg';
import DisabledHistory from '../assets/images/disabled-history.svg';
import TradingPartners from '../assets/images/Dashboard/TradingPartners.svg';
import InvoiceOrders from '../assets/images/Dashboard/InvoicesOrders.svg';
import { PERMISSIONS } from 'constants/permissions';
import { MODULE_LIST } from 'routes/SourceWiz/Guardrails/Heimdall/ModuleList';
import tradefair from '../assets/images/Trade fair icon.svg';
const data = [
  {
    title: 'sourcewiz.dashboard.allProducts.title',
    description: 'sourcewiz.dashboard.allProducts.subTitle',
    link: 'View all products',
    route: '/master-catalogue',
    image: ProductIcon,
    disable: '',
    hover: '#f5fcfb',
    slug: PERMISSIONS.view_products.slug,
    permissionType: PERMISSIONS.view_products.permissionType,
  },

  {
    title: 'sourcewiz.dashboard.createCatalogue.title',
    description: 'sourcewiz.dashboard.createCatalogue.subTitle',
    link: 'Start creating',
    route: '/create-collection',
    image: CollectionIcon,
    disable: DisabledCollectionIcon,
    hover: '#fffcf7',
    slug: PERMISSIONS.create_catalogues.slug,
    permissionType: PERMISSIONS.create_catalogues.permissionType,
  },
  {
    title: 'sourcewiz.dashboard.allCatalogue.title',
    description: 'sourcewiz.dashboard.allCatalogue.subTitle',
    link: 'View all catalogues',
    route: '/catalogue-history',
    image: History,
    disabe: DisabledHistory,
    hover: '#fafcf5',
    slug: PERMISSIONS.view_catalogues.slug,
    permissionType: PERMISSIONS.view_catalogues.permissionType,
  },

  {
    title: 'Buyers',
    description: 'Maintain a list of all buyer contacts',
    link: 'View all prospects',
    route: '/buyers',
    image: Prospects,
    disable: '',
    hover: '#edf3fa',
    slug: PERMISSIONS.view_quotation.slug,
    permissionType: PERMISSIONS.view_quotation.permissionType,
  },

  {
    title: 'Invoices & Quotations',
    description:
      'You can maintain all your buyer vendors documents at one place',
    link: 'View all documents',
    route: '/invoices-orders/all-documents',
    image: InvoiceOrders,
    hover: '#F2EFF9',
    isPlan: true,
    module_id: MODULE_LIST.quotation.id,
    slug: PERMISSIONS.view_quotation.slug,
    permissionType: PERMISSIONS.view_quotation.permissionType,
    // tag: 'NEW',
  },
  // {
  //   title: 'IHGF Trade fair',
  //   description:
  //     'Manage your buyer session, generate QR labels for the trade fair to scan and view product details, all on the mobile app',
  //   link: 'View all documents',
  //   route: '/tradefair',
  //   image: tradefair,
  //   hover: '#F9FBF1',
  //   isPlan: true,
  //   module_id: MODULE_LIST.quotation.id,
  //   slug: 'trade_fair',
  //   permissionType: PERMISSIONS.view_quotation.permissionType,
  //   tag: 'NEW',
  // },

  // {
  //   title: "quotations.quotations",
  //   description: "sourcewiz.dashboard.quotations.subTitle",
  //   link: "View all quotations",
  //   route: "/enquiries",
  //   image: Quotations,
  //   hover: "#FBFBFB",
  //   slug: PERMISSIONS.view_quotation.slug,
  //   permissionType: PERMISSIONS.view_quotation.permissionType,
  //   tag: "BETA",
  // },

  // {
  //   title: "sourcewiz.recommendations.buyerRecommendations",
  //   description: "sourcewiz.dashboard.prospects.subTitle",
  //   link: "View all recommendations",
  //   route: "/buyer-list",
  //   image: TradingPartners,
  //   hover: "#edf3fa",
  //   slug: "view_recommendation",
  //   permissionType: "Recommendations",
  //   slug: PERMISSIONS.view_recommendation.slug,
  //   permissionType: PERMISSIONS.view_recommendation.permissionType,
  // },
];

export default data;
