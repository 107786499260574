export const getUserPrefs = () => {
  if (localStorage.getItem('pref')) {
    return JSON.parse(localStorage.getItem('pref'));
  }
  return null;
};

export const getSmartSearchPayload = () => {
  if (sessionStorage.getItem('smart_search_payload')) {
    return JSON.parse(sessionStorage.getItem('smart_search_payload'));
  }
  return {};
};

export const setSmartSearchPayload = (data = {}) => {
  const payload = JSON.stringify(data);
  sessionStorage.setItem('smart_search_payload', payload);
};

export const clearSmartSearchPayload = () => {
  sessionStorage.removeItem('smart_search_payload');
};
