import React from 'react';
import { Input, Typography, Row, Col, Form } from 'antd';
import { Colors } from '../../theme';

const { Title, Text } = Typography;

export default function BasicDetails(props) {
  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <Row>
        <Title level={4} style={{ fontFamily: 'SatoshiBold' }}>
          Start growing your business
        </Title>
      </Row>
      <Row>
        <Text style={{ color: Colors.gray }}>
          Build up your online profile to attract more potential customers
        </Text>
      </Row>
      <Row style={{ marginTop: '2.5rem' }}>
        <Col span={20}>
          <Form.Item
            rules={[{ required: true, message: 'Enter valid company name' }]}
            label="Company Name"
            name="name"
            {...formItemLayout}>
            <Input
              min={3}
              placeholder="Enter the legal name of  your company"
            />
          </Form.Item>

          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Established"
                name="established"
                {...formItemLayout}>
                <Input type="number" placeholder="Enter the year" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="City" name="city" {...formItemLayout}>
                <Input placeholder="Select location(s)" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Description" name="description" {...formItemLayout}>
            <Input.TextArea placeholder="Add more details about the company (optional)" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
