import getSymbolFromCurrency from 'currency-symbol-map';
import { getSanitizedSymbolFromCurrency } from 'utils/helpers';

export const DEFAULTATTRID = [321, 322, 323, 324, 325, 326];

export const getTotal = (productData) => {
  const amountKeys = Object.keys(productData).filter(
    (item) =>
      (item.includes('Quantity') && item.includes('number')) ||
      (item.includes('Selling Price') && item.includes('values'))
  );
  const total = amountKeys.reduce((agg, item) => {
    return agg * parseFloat(productData[item] || 0);
  }, 1);

  return total.toFixed(2);
};

export const getUnitString = (unit) => {
  const matchRegex = /(per |per-|per -)/g;
  return unit.replaceAll(matchRegex, '');
};

export const currencyHexCoder = (currency) => {
  return getSanitizedSymbolFromCurrency(currency);
  // if (getSymbolFromCurrency(currency)) return getSymbolFromCurrency(currency);
  // return currency;
};

export const getDefaultAttributes = (type) => {
  return new Promise(function (resolve) {
    resolve({
      data: [
        {
          name: 'Name',
          dtype: 'text',
          id: DEFAULTATTRID[0],
        },
        {
          name: 'Email',
          dtype: 'text',
          id: DEFAULTATTRID[1],
        },
        {
          name: 'Address line 1',
          dtype: 'text',
          id: DEFAULTATTRID[2],
        },
        {
          name: 'Address line 2',
          dtype: 'text',
          id: DEFAULTATTRID[3],
        },
        {
          name: 'City',
          dtype: 'text',
          id: DEFAULTATTRID[4],
        },
        {
          name: 'Country',
          dtype: 'text',
          id: DEFAULTATTRID[5],
        },
      ],
      status: 200,
    });
  });
};
