import { Modal, Row, Col, Typography, message, Space } from 'antd';
import { PYTHON_API_KEY } from 'api/customApis';
import { useEffect, useState } from 'react';
import { useGlobal } from 'store/index';
import { useHistory } from 'react-router-dom';
import { IoMdCloseCircle } from 'react-icons/io';
import './dashboard.css';
import Button from 'react-common-components/Button';

const { Title, Text } = Typography;

export const StartTrial = ({
  showModal,
  moduleDetails,
  setShowModal,
  setShowBuyModal,
  usage,
  handleQuotationModal,
  fromDashboard,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState({});
  const [globalState, globalAction] = useGlobal();

  const [moduleData, setModuleData] = useState(moduleDetails || null);
  const handleBuyRequest = () => {
    setShowModal(false);
    setShowBuyModal(true);
  };

  useEffect(() => {
    setModuleData(moduleDetails);
  }, [moduleDetails]);

  const handleStartFreeTrial = () => {
    const usageModule = usage.find(
      (item) => item?.module_id === moduleData?.module_id
    );
    setLoading((state) => {
      return { ...state, start: true };
    });
    PYTHON_API_KEY.URL.post(
      PYTHON_API_KEY.path.enableTrial,
      {},
      {
        params: {
          module_id: usageModule.module_id,
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setLoading((state) => {
            return { ...state, start: false };
          });
          globalAction.guardrails.setReloadUsageData(true);
          setShowModal(false);
          // if (shouldNotUse) {
          //   setShouldNotUse(false);
          // }
          message.success(`${usageModule.module_label} trial started`);
          if (fromDashboard) {
            history.push('/invoices-orders/all-documents');
          } else {
            handleQuotationModal(true);
          }
          // history.push(moduleData.route || moduleData.url);
          // Only useful in Catalogue -- Optional
          //
        } else {
          setLoading((state) => {
            return { ...state, start: false };
          });
          message.error('Something went wrong');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading((state) => {
          return { ...state, start: false };
        });
        message.error(`Something went wrong`);
        // setUnlockTrialModal(false)
      });
  };
  return (
    <>
      <Modal
        width={500}
        footer={null}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        closable
        bodyStyle={{
          padding: '2.1em 1.5em',
        }}
        closeIcon={
          <IoMdCloseCircle
            size={22}
            color={'#9B9F9E'}
            style={{ marginTop: '2em' }}
          />
        }>
        <>
          <Row>
            <Title level={4}>Invoices & Quotations</Title>
          </Row>
          <Row>
            <div className="start-trail-main-section">
              <Row style={{ paddingBottom: '2em' }}>
                <Col span={24}>
                  <Row>
                    <Title level={4}>14 day free trial</Title>
                  </Row>
                  <Text className="start-trial-sub-text">
                    You can use the module on trial for 14 days
                  </Text>
                </Col>
              </Row>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col span={12}>
                  <Button
                    variant={'dark'}
                    className={'btn-full-width'}
                    size="large"
                    loading={loading['start']}
                    onClick={() => handleStartFreeTrial()}>
                    Start trial
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col span={24}>
              <Row justify="center">
                <Text type="secondary">Want to know more?</Text>
              </Row>
              <Row justify="center">
                <Text type="secondary" className="start-trial-sub-text">
                  Call +91-9215777736 for any questions
                </Text>
              </Row>
            </Col>
          </Row>
        </>
      </Modal>
    </>
  );
};
