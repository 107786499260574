import { notification, Typography } from 'antd';
import './notification.less';

const { Text } = Typography;

export const openNotification = (
  placement,
  message = '',
  icon,
  description = ''
) => {
  notification.open({
    message: <Text className="open-notification-text">{message}</Text>,
    description: <Text>{description}</Text>,
    className: 'notification-pannel',
    duration: 2,
    closeIcon: <></>,
    icon: icon,
    placement,
  });
};
