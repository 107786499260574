export const EventType = {
  TASK_UPDATED: 'taskUpdated',
  TASK_DISMISSED: 'taskDismissed',
  NEW_NOTIFICATION: 'newNotification',
};

export const ChannelType = {
  TASKS: 'Tasks',
  NOTIFICATIONS: 'Notifications',
};

export const ChannelScope = {
  ORGANIZATION: 'oid',
  USER: 'uid',
};

export const NotificationProvider = {
  PUSHER: 'pusher',
  PUBNUB: 'pubnub',
};

export const MAX_CHANNEL_NAME_SIZE = 164;
