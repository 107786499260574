import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Drawer, Divider, Progress, Typography } from 'antd';
import { useGlobal } from 'store/index';
import { getRewardSetupStatus } from 'api/rewardApis';

import './title.less';

import { GrFormClose } from 'react-icons/gr';

import money from '../../../assets/images/rewardsv2/moneyComp.svg';
import money2 from '../../../assets/images/rewardsv2/moneyCard.svg';
import money3 from '../../../assets/images/rewardsv2/moneyMissed.svg';

import claimreward from '../../../assets/images/rewardsv2/claimreward.svg';
import arrow from '../../../assets/images/rewardsv2/arrow.svg';

import moment from 'moment';

const { Text } = Typography;

const CATALOGUE_CREATE = 'catalogue_create';
const PRODUCT_ADD = 'product_add';
const PRODUCT_EDIT = 'product_edit';
const QUOTATION_CREATE = 'quotation_create';

export const RewardStartComponent = ({ handleStartNow }) => {
  return (
    <>
      <Row className="reward">
        <Col span={12} className="reward-text">
          <Row className="spacing">Want to win vouchers?</Row>
          <Row className="reward-title">
            Complete simple tasks and get gift vouchers!
          </Row>
          <Row>
            <Button className="btn" onClick={() => handleStartNow()}>
              Start now
            </Button>
          </Row>
        </Col>
        <Col span={12}>
          <Row className="money-svg" justify="end">
            <img src={money} alt="img" height={'170'} loading="lazy" />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const NoRewardCard = ({ openDrawer }) => {
  return (
    <>
      <Row
        className="reward-card spacing small-card"
        onClick={() => openDrawer()}>
        <Col span={12}>
          <Row className="spacing text">Rewards</Row>
          <Text ellipsis className="spacing text-desc">
            No active rewards yet
          </Text>
        </Col>
        <Col span={12}>
          <Row className="spacing view-details" justify="end">
            View details
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const RewardVoucherCard = ({
  createRewardAction,
  rewardsData,
  offerDays,
  openDrawer,
}) => {
  const action = rewardsData?.current_active_milestone_json?.action;
  const target = rewardsData?.current_active_milestone_json?.progress?.target;
  const reward = rewardsData?.current_active_milestone_json?.reward;
  return (
    <>
      <Row className="reward">
        <Col span={16} className="reward-text" style={{ padding: '1.75rem' }}>
          <Row className="text offer">{`Offer ends in ${offerDays} ${
            offerDays === 1 ? 'day' : 'days'
          }`}</Row>
          <Row className="reward-title" style={{ marginTop: '1.5rem' }}>
            {action === CATALOGUE_CREATE &&
              `Reward alert! Create ${target} catalogues to win ₹ ${reward}`}
            {action === PRODUCT_ADD &&
              `Reward alert! Add ${target} products to win ₹ ${reward}`}
            {action === PRODUCT_EDIT &&
              `Reward alert! Edit ${target} products to win ₹ ${reward}`}
            {action === QUOTATION_CREATE &&
              `Reward alert! Create ${target} quotations to win ₹ ${reward}`}
          </Row>
          <Row>
            <Button className="btn" onClick={() => createRewardAction()}>
              {action === CATALOGUE_CREATE && `Create now`}
              {action === PRODUCT_ADD && `Add now`}
              {action === PRODUCT_EDIT && `Edit now`}
              {action === QUOTATION_CREATE && `Create now`}
            </Button>
            <Button
              className="btn"
              style={{
                color: '#4F5655',
                backgroundColor: '#FFFFFF',
                marginLeft: '1rem',
              }}
              onClick={() => openDrawer()}>
              View Details
            </Button>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="end" style={{ paddingRight: '2rem' }}>
            {action === CATALOGUE_CREATE && (
              <img src={money} alt="img" height={'165'} loading="lazy" />
            )}
            {action === PRODUCT_ADD && (
              <img src={money} alt="img" height={'165'} loading="lazy" />
            )}
            {action === PRODUCT_EDIT && (
              <img src={money} alt="img" height={'165'} loading="lazy" />
            )}
            {action === QUOTATION_CREATE && (
              <img src={money} alt="img" height={'165'} loading="lazy" />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const RewardDisplayCard = ({ openDrawer, offerDays, rewardsData }) => {
  const current = rewardsData?.current_active_milestone_json?.progress?.current;
  const target = rewardsData?.current_active_milestone_json?.progress?.target;
  const action = rewardsData?.current_active_milestone_json?.action;

  return (
    <>
      <Row
        className="reward-card spacing small-card"
        justify="right"
        onClick={() => openDrawer()}>
        <Col span={16}>
          <Row className="spacing text">
            Active reward{' '}
            <span
              style={{
                color: '#1D2624',
                fontSize: '0.75rem',
                fontWeight: '400',
                padding: '0.25rem',
              }}>
              {`ends in ${offerDays} ${offerDays === 1 ? 'day' : 'days'}`}
            </span>{' '}
          </Row>
          <Text ellipsis className="spacing text-desc">
            {action === CATALOGUE_CREATE &&
              `${current}/${target} catalogues generated`}
            {action === PRODUCT_ADD && `${current}/${target} products added`}
            {action === PRODUCT_EDIT && `${current}/${target} products edited`}
            {action === QUOTATION_CREATE &&
              `${current}/${target} quotations created`}
          </Text>
        </Col>
        <Col span={8}>
          <Row
            className="spacing view-details"
            justify="end"
            style={{ padding: '0.25rem' }}>
            View details
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const ClaimRewardCard = ({
  openModal,
  handleDismissClaim,
  rewardsData,
  pending,
  setCardView,
  handleOpenDrawer,
  setRewardDrawer,
}) => {
  const [globalState, globalActions] = useGlobal();
  const action = pending?.action;
  const target = pending?.progress?.target;
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingReward, setPendingReward] = useState(0);
  setCardView(false);

  useEffect(() => {
    rewardsData?.completed_milestones_list?.forEach((item) => {
      if (item?.claim_status === 'pending') {
        setPendingCount((state) => state + 1);
        setPendingReward((state) => state + item?.reward);
        setCardView(false);
      }
    });
  }, []);

  return (
    <>
      <Row className="reward">
        <Col span={14} className="reward-text">
          {pendingCount === 1 ? (
            <Row className="pending-count">
              {action === CATALOGUE_CREATE &&
                `Won ₹ ${pending?.reward} for creating ${target} catalogues`}
              {action === PRODUCT_ADD &&
                `Won ₹ ${pending?.reward} for adding ${target} products`}
              {action === PRODUCT_EDIT &&
                `Won ₹ ${pending?.reward} for editing ${target} products`}
              {action === QUOTATION_CREATE &&
                `Won ₹ ${pending?.reward} for creating ${target} quotations`}
            </Row>
          ) : (
            <Row className="pending-count">
              Won vouchers for {pendingCount} tasks
            </Row>
          )}

          <Row className="reward-title">
            {`You have ${pendingCount} unclaimed ${
              pendingCount === 1 ? 'voucher' : 'vouchers'
            } worth ₹ ${pendingReward}`}
          </Row>

          <Row>
            {pendingCount === 1 ? (
              <Button
                className="btn"
                onClick={() => {
                  handleDismissClaim();
                  globalActions.milestoneInfoAction.setMilestoneInfo(pending);
                  openModal();
                }}>
                Claim Now
              </Button>
            ) : (
              <Button
                className="btn"
                onClick={() => {
                  handleDismissClaim();
                  handleOpenDrawer();
                  setRewardDrawer(true);
                }}>
                View Details
              </Button>
            )}
            <Button
              className="btn claim-later"
              style={{
                color: '#4F5655',
                backgroundColor: '#FFFFFF',
              }}
              onClick={() => handleDismissClaim()}>
              Claim Later
            </Button>
          </Row>
        </Col>
        <Col span={10}>
          <Row justify="end">
            <img
              src={claimreward}
              alt="img"
              width={'445'}
              height={'165'}
              loading="lazy"
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const EndRewardCard = ({
  handleDismiss,
  rewardsData,
  setCardView,
  handleUpdate,
  handleMissedReward,
  changeState,
  setChangeState,
}) => {
  const [globalState, globalActions] = useGlobal();
  const current = rewardsData?.current_active_milestone_json?.progress?.current;
  const target = rewardsData?.current_active_milestone_json?.progress?.target;
  const action = rewardsData?.current_active_milestone_json?.action;

  setCardView(false);

  useEffect(() => {
    setChangeState(true);
    getRewardSetupStatus()
      .then((res) => {
        if (res?.status === 200) {
          globalActions.milestoneInfoAction.setRewardsData(res?.data);
          handleMissedReward(res?.data);
        }
      })
      .catch((err) => {});
  }, [globalActions, handleMissedReward]);

  return (
    <>
      <Row
        className="reward"
        style={{ background: ' linear-gradient(to right, #E4E4E4, #F5F5F5)' }}>
        <Col span={12} className="reward-text" style={{ padding: '1.75rem' }}>
          <Row className="text offer">Offer ended</Row>{' '}
          <Row className="reward-title" style={{ marginTop: '1.5rem' }}>
            {action === CATALOGUE_CREATE &&
              `Missed it! ${current}/${target} catalogues generated`}
            {action === PRODUCT_ADD &&
              `Missed it! ${current}/${target} products added`}
            {action === PRODUCT_EDIT &&
              `Missed it! ${current}/${target} products edited`}
            {action === QUOTATION_CREATE &&
              `Missed it! ${current}/${target} quotations created`}
          </Row>
          <Row>
            <Button
              className="btn"
              onClick={() => {
                handleDismiss();
                handleUpdate();
              }}>
              Dismiss
            </Button>
          </Row>
        </Col>
        {!changeState && (
          <Col span={12}>
            <Row justify="end" style={{ paddingRight: '2rem' }}>
              {action === CATALOGUE_CREATE && (
                <img src={money3} alt="img" height={'165'} loading="lazy" />
              )}
              {action === PRODUCT_ADD && (
                <img src={money3} alt="img" height={'165'} loading="lazy" />
              )}
              {action === PRODUCT_EDIT && (
                <img src={money3} alt="img" height={'165'} loading="lazy" />
              )}
              {action === QUOTATION_CREATE && (
                <img src={money3} alt="img" height={'165'} loading="lazy" />
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export const RewardComponent = ({
  createRewardAction,
  closeDrawer,
  showDrawer,
  offerDays,
  rewardsData,
  openModal,
  rewardDrawer,
  setRewardDrawer,
}) => {
  const [globalState, globalActions] = useGlobal();
  const action = rewardsData?.current_active_milestone_json?.action;
  const current = rewardsData?.current_active_milestone_json?.progress?.current;
  const target = rewardsData?.current_active_milestone_json?.progress?.target;
  const reward = rewardsData?.current_active_milestone_json?.reward;

  const VoucherCard = () => {
    return (
      <>
        <Row className="reward" style={{ marginRight: '0' }}>
          <Col span={18} className="reward-text">
            <Row className="text offer">{`Offer ends in ${offerDays} ${
              offerDays === 1 ? 'day' : 'days'
            }`}</Row>{' '}
            <Row className="reward-title" style={{ marginTop: '1.5rem' }}>
              {action === CATALOGUE_CREATE &&
                `Create ${target} catalogues to win ₹ ${reward}`}
              {action === PRODUCT_ADD &&
                `Add ${target} products to win ₹ ${reward}`}
              {action === PRODUCT_EDIT &&
                `Edit ${target} products to win ₹ ${reward}`}
              {action === QUOTATION_CREATE &&
                `Create ${target} quotations to win ₹ ${reward}`}
            </Row>
            <Row>
              <Button className="btn" onClick={() => createRewardAction()}>
                {action === CATALOGUE_CREATE && `Create now`}
                {action === PRODUCT_ADD && `Add now`}
                {action === PRODUCT_EDIT && `Edit now`}
                {action === QUOTATION_CREATE && `Create now`}
              </Button>
            </Row>
          </Col>
          <Col span={6}>
            <Row justify="end">
              {action === CATALOGUE_CREATE && (
                <img
                  src={money2}
                  alt="img"
                  style={{ position: 'absolute', bottom: '0' }}
                  height={200}
                  loading="lazy"
                />
              )}
              {action === PRODUCT_ADD && (
                <img
                  src={money2}
                  alt="img"
                  style={{ position: 'absolute', bottom: '0' }}
                  height={200}
                  loading="lazy"
                />
              )}
              {action === PRODUCT_EDIT && (
                <img
                  src={money2}
                  alt="img"
                  style={{ position: 'absolute', bottom: '0' }}
                  height={200}
                  loading="lazy"
                />
              )}
              {action === QUOTATION_CREATE && (
                <img
                  src={money2}
                  alt="img"
                  style={{ position: 'absolute', bottom: '0' }}
                  height={200}
                  loading="lazy"
                />
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: '1rem' }}>
          {action === CATALOGUE_CREATE &&
            `${current}/${target} catalogues generated`}
          {action === PRODUCT_ADD && `${current}/${target} products added`}
          {action === PRODUCT_EDIT && `${current}/${target} products edited`}
          {action === QUOTATION_CREATE &&
            `${current}/${target} quotations created`}
        </Row>
        <Row>
          <Progress
            strokeColor={{
              '0%': '#0B7763',
              '100%': '#B6DA25',
            }}
            percent={(current / target) * 100}
            showInfo={false}
          />
        </Row>
      </>
    );
  };

  const NoRewardDrawerCard = () => {
    return (
      <>
        <Row className="reward-card drawer-noreward">
          <Col>
            <Row className="spacing text">No active rewards yet</Row>
            <Row className="spacing text-desc">
              We will notify for upcoming tasks
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const RewardDetailsCard = () => {
    return (
      <>
        <Row className="total-card">
          <Col span={14}>
            <Row className="spacing text">Total Rewards earned</Row>
            {rewardsData?.completed_milestones_list?.length > 0 && (
              <Row
                className="spacing"
                style={{
                  fontSize: '14px',
                  marginTop: '1rem',
                  cursor: 'pointer',
                }}
                onClick={() => setRewardDrawer(true)}>
                View your previous rewards{' '}
                <img
                  className="previous-arrow"
                  src={arrow}
                  alt="img"
                  height={'13'}
                  loading="lazy"
                />
              </Row>
            )}
          </Col>
          <Col span={10}>
            <Row className="spacing text-bold" justify="end">
              ₹ {rewardsData?.total_rewards_earned}
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={closeDrawer}
        visible={showDrawer}
        width={450}
        closable={false}
        style={{ marginRight: `${rewardDrawer ? '10rem' : '0'}` }}
        footerStyle={{ height: '4.5rem' }}
        footer={true}>
        {rewardsData?.reward_setup_status &&
          rewardsData?.current_active_milestone_json?.progress?.current >= 0 &&
          offerDays > 0 && <VoucherCard />}
        {rewardsData?.reward_setup_status &&
          Object.keys(rewardsData?.current_active_milestone_json).length ===
            0 && <NoRewardDrawerCard />}
        <RewardDetailsCard />
        <Row className="spacing" align="center">
          <Button
            className="close-btn"
            onClick={() => {
              setRewardDrawer(false);
              closeDrawer();
            }}>
            Close
          </Button>
        </Row>
        <Drawer
          placement="right"
          onClose={closeDrawer}
          visible={rewardDrawer}
          width={350}
          closable={false}
          mask={false}
          maskStyle={{ boxShadow: 'none' }}
          footer={false}
          bodyStyle={{ backgroundColor: '#f5f5f5' }}>
          <Row className="bold-text prev-title">
            Previous Rewards{' '}
            <GrFormClose
              className="close-icon"
              size={25}
              onClick={() => {
                setRewardDrawer(false);
              }}
            />
          </Row>

          {rewardsData?.completed_milestones_list?.map((item) => (
            <Row>
              <Col span={24}>
                {item?.claim_status === 'failed' ? (
                  <Row className="text spacing">
                    Missed it
                    <span className="claim-status failed">FAILED</span>
                  </Row>
                ) : (
                  <Row className="text spacing">
                    Won ₹ {item?.reward}
                    {item?.claim_status === 'claimed' && (
                      <span className="claim-status claimed">CLAIMED</span>
                    )}
                  </Row>
                )}
              </Col>
              <Col span={18}>
                <Row className="text-desc spacing">
                  {item?.action === CATALOGUE_CREATE &&
                    `Created ${item?.progress?.current}/${item?.progress?.target} catalogues`}
                  {item?.action === PRODUCT_ADD &&
                    `Added ${item?.progress?.current}/${item?.progress?.target} products`}
                  {item?.action === PRODUCT_EDIT &&
                    `Edited ${item?.progress?.current}/${item?.progress?.target} products`}
                  {item?.action === QUOTATION_CREATE &&
                    `Created ${item?.progress?.current}/${item?.progress?.target} quotations`}
                </Row>
              </Col>
              <Col span={6}>
                <Row className="text-desc spacing">
                  {moment(item?.completed_date).format('DD MMM YYYY')}
                </Row>
              </Col>
              {item?.claim_status === 'pending' && (
                <Row
                  className="claim-text spacing"
                  onClick={() => {
                    setRewardDrawer(false);
                    globalActions.milestoneInfoAction.setMilestoneInfo(item);
                    openModal();
                  }}
                  style={{ cursor: 'pointer' }}>
                  Claim your voucher
                </Row>
              )}
              <Divider className="divider" />
            </Row>
          ))}
        </Drawer>
      </Drawer>
    </>
  );
};
