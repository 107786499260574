import Loading from 'containers/Loading';
import React, { useEffect, useState } from 'react';
import { clearLocalStorageAndCookie } from 'utils/logoutUsingCookies';

const ClearLocalStorage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    clearLocalStorageAndCookie();
    setTimeout(() => {
      window.location.reload();
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <div></div>;
};

export default ClearLocalStorage;
