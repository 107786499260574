export const packagingFields = {
  setData: (store, payload) => {
    store.setState({
      packagingForm: { ...payload },
    });
  },
  updateField: (store, payload) => {
    store.setState({
      packagingForm: { ...store.packagingForm, payload },
    });
  },
};
