import React from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import './index.less';
import Notdeletable from 'assets/images/importnotdeletable.svg';
import Importcolumncheck from 'assets/images/importcolumncheck.svg';
import Importeligibility from 'assets/images/importeligibility.svg';
import * as invalidGif from './invalidimportgif.json';
import Lottie from 'react-lottie';

const { Text } = Typography;
function InvalidImportTemplate({ isTemplateInValid, setIsTemplateInValid }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: invalidGif.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const causeSteps = [
    {
      id: 0,
      img: Importeligibility,
      label:
        'Make sure you only upload an eligible template downloaded via bulk export',
    },
    {
      id: 1,
      img: Importcolumncheck,
      label: 'Make sure all column names are correct',
    },
    {
      id: 2,
      img: Notdeletable,
      label:
        'Make sure you haven’t deleted or added any columns in the exported template',
    },
  ];
  return (
    <Modal
      visible={isTemplateInValid}
      destroyOnClose={true}
      footer={null}
      closeIcon={''}
      closable={false}
      centered
      bodyStyle={{ padding: '2em 3em' }}>
      <Row justify="center" className="validation-failed">
        <Col className="validation-failed_headDiv">
          <Lottie options={defaultOptions} height={'116px'} width={'116px'} />
          <Row>
            <Text className="validation-failed_head">
              Invalid template file
            </Text>
          </Row>
        </Col>
      </Row>
      <Row className="validation-failed_cause-steps">
        {causeSteps?.length > 0 &&
          causeSteps?.map((item) => {
            return (
              <div className="validation-failed_cause-steps_flexDiv">
                <img
                  src={item?.img}
                  className="validation-failed_cause-steps_flexDiv_img"
                />
                <Text className="validation-failed_cause-steps_flexDiv_step-label">
                  {item?.label}
                </Text>
              </div>
            );
          })}
      </Row>
      <Row justify="center" className="validation-failed_close-cta-div">
        <Button
          className="validation-failed_close-cta-div_close-cta"
          onClick={() => setIsTemplateInValid(false)}>
          Okay, Got it!
        </Button>{' '}
      </Row>
    </Modal>
  );
}

export default InvalidImportTemplate;
