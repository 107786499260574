import React, { useEffect } from 'react';
import SourceApp from '../src/containers/SourceApp';
import { store } from './store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import tracker from './analytics/tracker';
import MaintainanceScreen from 'components/MaintainanceScreen';
import { getBooleanValue } from 'utils/formFieldHelpers';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-042275 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Sourcewiz )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 May 2024 )____[v2]_MTcxNTY0MTIwMDAwMA==dd05384613e861eb9d043987659cf591'
);

Sentry.init({
  dsn: 'https://a3bfb751a49c4dd3a36a2ed798131472@o1032636.ingest.sentry.io/5999596',
  integrations: [new Sentry.BrowserTracing()],
  autoSessionTracking: true,
  environment: process.env.REACT_APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // replaysOnErrorSampleRate: 1.0,
  // replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
});

const App = () => {
  const logoutEvent = window.addEventListener('storage', function (event) {
    if (event.key === 'logout-event') {
      window.location.reload();
    }
  });
  useEffect(() => {
    window.addEventListener('storage', logoutEvent);

    return () => {
      window.removeEventListener('storage', logoutEvent);
    };
  }, [logoutEvent]);

  const isMaintenance = getBooleanValue(
    process?.env?.REACT_APP_MAINTAINANCE || ''
  );

  return (
    <>
      {isMaintenance ? (
        <MaintainanceScreen />
      ) : (
        <Provider store={store}>
          <SourceApp />
        </Provider>
      )}
    </>
  );
};

export default tracker(App);
