import { Row, Col, Image, Button, Typography, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PasswordResetDoneSVG from './PasswordResetDone.svg';
const { Title, Text } = Typography;
export default function PasswordResetDone() {
  const history = useHistory();
  return (
    <div>
      <Col span={24}>
        <Row
          style={{ marginBottom: '2rem', marginTop: '2rem' }}
          justify="center"
          align="middle">
          <Space direction="vertical" align="center">
            <Image src={PasswordResetDoneSVG} preview={false} />
            <Title level={4}>Password reset successfully</Title>
          </Space>
        </Row>
        <Row justify="center">
          <Button
            size="large"
            type="primary"
            onClick={() => {
              history.push('/');
            }}>
            Login with new password
          </Button>
        </Row>
      </Col>
    </div>
  );
}
