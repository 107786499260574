import Cookies from 'js-cookie';

export function clearLocalStorageAndCookie() {
  localStorage.clear();
  Cookies.remove('access_token', {
    domain: getRootDomain(window.location.href),
  });
}

export function getRootDomain(url) {
  const a = document.createElement('a');
  a.href = url;
  const domainParts = a.hostname.split('.');
  const length = domainParts.length;

  if (length > 1) {
    return domainParts[length - 2] + '.' + domainParts[length - 1];
  }

  return a.hostname === 'localhost' ? a.hostname : '.' + a.hostname;
}
