/*
 *
 * ManageProducts Selectors
 *
 */

import { createSelector } from 'reselect';

const selectManageProducts = (state) => state.manageProducts;

const selectTempCheckedProducts = () =>
  createSelector(
    selectManageProducts,
    (manageProducts) => manageProducts.tempSelectedProducts
  );

const selectPendingTaskCount = () =>
  createSelector(
    selectManageProducts,
    (manageProducts) => manageProducts.pendingTaskCount
  );

const selectCurrentlyProcessingProducts = () =>
  createSelector(
    selectManageProducts,
    (manageProducts) => manageProducts.productsCurrentlyProcessing
  );

const selectProductIsChecked = (id) =>
  createSelector(selectTempCheckedProducts(), (tempSelectedProducts) => {
    return !!tempSelectedProducts[id]?.value;
  });

const selectProductsInDraft = (id) =>
  createSelector(selectManageProducts(), (manageProducts) => {
    return manageProducts.pendingTaskCount;
  });

const selectProductIsDisabled = (id) =>
  createSelector(
    selectCurrentlyProcessingProducts(),
    (productsCurrentlyProcessing) => {
      return productsCurrentlyProcessing[id];
    }
  );
const selectAllTasks = () =>
  createSelector(
    selectManageProducts,
    (manageProducts) => manageProducts.allTasks
  );

export default {
  selectTempCheckedProducts,
  selectProductIsChecked,
  selectProductIsDisabled,
  selectPendingTaskCount,
  selectCurrentlyProcessingProducts,
  selectProductsInDraft,
  selectAllTasks,
};
