import React from 'react';
import {
  useTable,
  usePagination,
  useFlexLayout,
  useRowSelect,
  useResizeColumns,
} from 'react-table';
import { Input, Select, Button, Checkbox, Switch } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid lightgray;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;
      height: 50px;

      overflow-y: scroll;
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
      select {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
      ${
        '' /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: white;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: red;
        }
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
  .textarea {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
    min-height: 64px;
    width: 140px;
    :focus {
      min-height: 64px;
      width: 130px;
      display: block;
      position: fixed;
      z-index: 1000;
    }
  }
  .image {
    width: 50px !important;
  }
`;
const HideColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e?.target?.value ? e.target.value : e);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(row?.index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderCellComponent = (type) => {
    const componentsObj = {
      firstName: <Input value={value} onChange={onChange} onBlur={onBlur} />,
      lastName: (
        <Input.TextArea
          className="textarea"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ),
      age: <Input value={value} onChange={onChange} onBlur={onBlur} />,
      visits: <Input value={value} onChange={onChange} onBlur={onBlur} />,
      status: (
        <Select defaultValue={'Status'}>
          <Select.Option value={'Draft'}>Draft</Select.Option>
          <Select.Option value={'Publish'}>Publish</Select.Option>
          <Select.Option value={'Inprogress'}>Inprogress</Select.Option>
          <span style={{ color: 'blue' }}>Add New</span>
        </Select>
      ),
      progress: <Input value={value} onChange={onChange} onBlur={onBlur} />,
      gender: (
        <Select
          mode="tags"
          placeholder="Please select"
          defaultValue={[value]}
          onChange={onChange}
          style={{ width: '100%' }}>
          <Option value="M">MALE</Option>
          <Option value="FEMALE">FEMALE</Option>
          <Option value="Other">Other</Option>
          <span style={{ color: 'blue' }}>Add New</span>
        </Select>
      ),
      image: <img src={value} alt="product" width="50px" />,
    };

    return componentsObj[type];
  };

  return renderCellComponent(id);
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

const defaultPropGetter = () => ({});

export default function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    allColumns,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
    resetResizing,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useResizeColumns,
    usePagination,
    useFlexLayout,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div style={{ width: 150 }}>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Render the UI for your table
  return (
    <>
      <Button onClick={resetResizing}>Reset Column Size</Button>

      <Button>Full Screen</Button>
      <div>
        <Checkbox {...getToggleHideAllColumnsProps()} /> Toggle All
      </div>

      <HideColumns>
        {allColumns.map((column) => (
          <label key={column.id}>
            <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
            {column.id}
          </label>
        ))}
      </HideColumns>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {JSON.stringify(selectedFlatRows.map((row) => row.original))}
    </>
  );
}
