import axios from 'axios';
const pythonBaseUrl = process.env.REACT_APP_API_SERVICE;

// const myPromise = new Promise((resolve, reject) => {
//   setTimeout(() => {
//     resolve('foo');
//   }, 300);
// });

export const generateOTP = (data, token) => {
  console.log('dataL', data);
  if (!token) {
    const user = localStorage.getItem('user');
    token = JSON.parse(user)?.access_token;
  }
  // return myPromise
  return axios.post(`${pythonBaseUrl}auth/generate_otp`, data, {
    headers: { 'access-token': `${token}` },
  });
};

export const verifyOTP = (data, token) => {
  if (!token) {
    const user = localStorage.getItem('user');
    token = JSON.parse(user)?.access_token;
  }
  return axios.post(`${pythonBaseUrl}auth/verify/otp`, data, {
    headers: { 'access-token': `${token}` },
  });
};
