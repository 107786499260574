export const MODULE_LIST = {
  product_library: {
    name: 'Product Library',
    id: 'MOD-0002',
    upgrade_title: 'You have reached the SKU limit for purchased plan',
    upgrade_text: 'Select add on or upgrade plan to increase your limit',
    on_lite_title: 'Upgrade your plan to get more SKUs',
    on_lite_text: 'You have completely used the quota of products',
    enterprise_title: 'Purchase addon SKUs to meet your usage',
    enterprise_text: 'You have completely used the quota of products',
  },
  quick_catalogue_generator: {
    name: 'Catalogue Generator',
    id: 'MOD-0003',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  basic_template_library: {
    name: 'Basic Template Library',
    id: 'MOD-0004',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  advanced_template_library: {
    name: 'Advanced Template Library',
    id: 'MOD-0005',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  prospect_analytics: {
    name: 'Prospect Analytics',
    id: 'MOD-0006',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  cost_calculator: {
    name: 'Cost Calculator',
    id: 'MOD-0007',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  qr_or_label_generator: {
    name: 'QR or Label Generator',
    id: 'MOD-0008',
    upgrade_title: 'Your trial period for this feature has ended',
    upgrade_text:
      'Select add on or upgrade plan to continue using this feature',
    on_lite_title: 'Upgrade your plan to get trade fair assistant',
    on_lite_text: 'Your trial period for this feature has ended',
    enterprise_title: 'Purchase trade fair assistant',
    enterprise_text:
      'Select add on or upgrade plan to continue using this feature',
  },
  vistior_analystics: {
    name: 'Visitor Analytics',
    id: 'MOD-0009',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  background_remover: {
    name: 'Background Remover',
    id: 'MOD-0010',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  advanced_image_enhancer: {
    name: 'Advanced Image Enhancer',
    id: 'MOD-0011',
    upgrade_title: 'Your trial period for this feature has ended',
    upgrade_text: 'Select add on or upgrade plan to get this feature',
    on_lite_title: 'Upgrade your plan to get advance image editor',
    on_lite_text: 'Your trial period for this feature has ended',
  },
  '3d_visualiser': {
    name: '3D Visualiser',
    id: 'MOD-0012',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  watermark_protector: {
    name: 'Watermark Protector',
    id: 'MOD-0013',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  quotation: {
    name: 'Quotation',
    id: 'MOD-0014',
    upgrade_title: 'You have reached the limit for creating documents',
    upgrade_text: 'Select add on or upgrade plan to increase your limit',
    on_lite_title: 'Upgrade your plan to create more documents',
    on_lite_text: 'Select add on or upgrade plan to increase your limit',
    enterprise_title: 'Upgrade your plan to create more documents',
    enterprise_text: 'Select add on or upgrade plan to increase your limit',
  },
  purchase_order: {
    name: 'Purchase Order',
    id: 'MOD-0015',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  proforma_invoice: {
    name: 'Proforma Invoice',
    id: 'MOD-0016',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  sales_order: {
    name: 'Sales order',
    id: 'MOD-0017',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  packing_list: {
    name: 'Packing List',
    id: 'MOD-0018',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
  users: {
    name: '# of Users',
    id: 'MOD-0019',
    upgrade_title: 'You have reached the limit for number of users',
    upgrade_text: 'Select add on or upgrade plan to increase your limit',
    on_lite_title: 'Upgrade your plan to get more users limit',
    on_lite_text: 'You have completely used the number of users',
    enterprise_title: 'Purchase User addon to add more user',
    enterprise_text: 'You have completely used the quota of users',
  },
  custom_formats: {
    name: 'Custom Formats',
    id: 'MOD-0020',
    upgrade_title: '',
    upgrade_text: '',
    on_lite_title: '',
    on_lite_text: '',
  },
};
